import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MyWorkSheetService } from '../../services/my-work-sheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../services/urls.service';
import { NotifyService } from '../../services/notify.service';
import { DownloadCSVService } from '../../services/download-csv.service';
import * as moment from 'moment';
import { Profile } from '../../shared/model/profile';
import { ProfileService } from '../../services/profile.service';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-nurse-worksheet',
  templateUrl: './nurse-worksheet.component.html',
  styleUrls: ['./nurse-worksheet.component.css']
})
export class NurseWorksheetComponent implements OnInit {
  userSearchHit = false;
  userSearchKeyWord: string;
  suggestedUserList: Array<any> = [];
  loadingUserSearch = false;
  displaySuggestion = false;
  showProfileMessage = false;
  profileList: Array<Profile> = [];
  nurseList: Array<string> = [];
  profileId: number;
  myWorkSheetList;
  myWorkSheetListCSV;
  loading = true;
  rawSearchQuery: string = null;
  total: number;
  page = 1;
  limit = 10;
  status: any;
  userId;
  headerList = [
    'Date',
    'Nurse Name',
    'Patient Name',
    'Shift Start Time',
    'Shift End Time',
    'Total Shift Time',
    'Notes'
  ];
  advancedSearchForm = new FormGroup({
    query: new FormControl(''),
    nurseName: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl('')
  });
  today = new Date();
  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;

  constructor(
    private myWorkSheetService: MyWorkSheetService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService,
    private profileService: ProfileService,
    private downloadCSVService: DownloadCSVService,
    private userService: UserServiceService
  ) {
    this.today.setDate(this.today.getDate());
  }

  ngOnInit() {
    this.getUrlParams();
    this.getProfileList();
    this.getMyWorkSheet();
    this.hideToggleSearchFilters();
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  getUrlParams() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const userId: any = this.activatedRoute.snapshot.queryParamMap.get('userId');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.userId = userId;
    this.advancedSearchForm.patchValue({
      nurseName: this.activatedRoute.snapshot.queryParamMap.get('nurseName'),
      query: this.activatedRoute.snapshot.queryParamMap.get('query'),
      fromDate: this.activatedRoute.snapshot.queryParamMap.get('fromDate'),
      toData: this.activatedRoute.snapshot.queryParamMap.get('toData')
    });
    this.rawSearchQuery = this.getQueryFromInputParams();
  }

  setUrlParams() {
    const queryParams = {
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      userId: this.userId ? this.userId : null,
      query: this.advancedSearchForm.get('query').value
        ? this.advancedSearchForm.get('query').value
        : null,
      nurseName: this.advancedSearchForm.get('nurseName').value || null,
      fromDate: this.advancedSearchForm.get('fromDate').value
        ? this.advancedSearchForm.get('fromDate').value
        : null,
      toDate: this.advancedSearchForm.get('toDate').value
        ? this.advancedSearchForm.get('toDate').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'query') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value;
        } /*else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }*/
      }
    }
    return stringParams;
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['query', 'fromDate', 'toDate', 'nurseName'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      query: queryValue.trim()
    });
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.setUrlParams();
      this.getMyWorkSheet();
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('query').reset();
    this.applyAdvancedSearch();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getMyWorkSheet();
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getMyWorkSheet();
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getMyWorkSheet();
    this.setUrlParams();
  }
  statusProfileChange() {
    this.showProfileMessage = false;
    this.searchUsers();
  }
  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  parse(object) {
    return JSON.parse(object).join(', ') ? JSON.parse(object).join(', ') : 'No Tests';
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  getNurseId(nurseName) {
    const obj = this.profileList.find(
      (profile) => profile.name.toLowerCase() === nurseName.toLowerCase()
    );
    return obj.id;
  }
  resetAdvancedSearchForm() {
    this.advancedSearchForm.patchValue({
      nurseName: null,
      fromDate: null,
      toDate: null
    });
    this.page = 1;
    this.userId = '';
    this.setUrlParams();
    this.getMyWorkSheet();
  }
  getRemainingDuration(check_in, check_out) {
    return moment.duration(moment(check_out).diff(moment(check_in)));
  }
  getWorkingHours(check_in, check_out) {
    let appointmentRemainingDuration = '';
    if (check_in && check_out) {
      const duration = this.getRemainingDuration(check_in, check_out);
      appointmentRemainingDuration += duration.hours() === 0 ? '' : duration.hours() + ' Hrs ';
      appointmentRemainingDuration += duration.minutes() === 0 ? '' : duration.minutes() + ' min ';
    }
    return appointmentRemainingDuration || 'NA';
  }

  getMyWorkSheet() {
    this.loading = true;
    this.myWorkSheetService
      .getMyWorkSheetList(this.advancedSearchForm.value, this.userId, this.page, this.limit)
      .subscribe(
        (res: any) => {
          this.myWorkSheetList = res.data;
          this.total = res.size;
        },
        (err) => {
          this.loading = false;
          this.myWorkSheetList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }
  downloadCSV() {
    this.myWorkSheetService
      .getMyWorkSheetList(this.advancedSearchForm.value, this.userId)
      .subscribe(
        (res: any) => {
          this.myWorkSheetListCSV = res.data;
          this.total = res.size;
        },
        (err) => {
          this.loading = false;
          this.myWorkSheetList.length = 0;
        },
        () => {
          this.loading = false;
          const myWorkSheetData = this.formatMyWorkSheetForCSV(this.myWorkSheetListCSV);
          this.downloadCSVService.downloadFile(myWorkSheetData, 'worksheet', this.headerList);
        }
      );
  }

  formatMyWorkSheetForCSV(myWorkSheetListCSV) {
    const workSheetData = [];
    myWorkSheetListCSV.forEach((workSheet: any) => {
      if (workSheet.patient != null) {
        workSheetData.push({
          date: workSheet.check_in
            ? '"' + moment(workSheet.check_in).format('MMM Do, Y') + '"'
            : '"' + moment(workSheet.check_out).format('MMM Do, Y') + '"',
          nurse_name: '"' + workSheet.user.name + '"',
          patient_name:
            '"' + workSheet.patient.first_name + ' ' + workSheet.patient.last_name + '"',
          shift_start_time: workSheet.check_in
            ? '"' + moment(workSheet.check_in).format('h:mm a') + '"'
            : 'N/A',
          shift_end_time: workSheet.check_out
            ? '"' + moment(workSheet.check_out).format('h:mm a') + '"'
            : 'N/A',
          total_shift_time:
            '"' + this.getWorkingHours(workSheet.check_in, workSheet.check_out) + '"',
          notes: workSheet.note ? '"' + workSheet.note.note + '"' : 'N/A'
        });
      }
    });
    return workSheetData;
  }
  onOpenModal() {
    this.userSearchHit = false;
    this.userSearchKeyWord = '';
    this.suggestedUserList = [];
  }

  proceedToSearch(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.searchUsers();
    }
  }

  nurseSelected(e) {
    this.advancedSearchForm.get('nurseName').setValue(e)
  }

  searchUsers() {
    this.showProfileMessage = false;
    if (this.userSearchKeyWord.length > 0 && this.profileId) {
      this.loadingUserSearch = true;
      this.userSearchHit = true;
      const data = {
        profile_id: this.profileId,
        q: this.userSearchKeyWord
      };
      this.userService.getUserList(null, null, data).subscribe(
        (response: any) => {
          this.loadingUserSearch = false;
          this.suggestedUserList = response.data;
        },
        (error) => {
          this.loadingUserSearch = false;
          this.suggestedUserList.length = 0;
        },
        () => {
          if (this.suggestedUserList.length !== 0) {
            this.displaySuggestion = true;
          }
        }
      );
    } else {
      this.displaySuggestion = false;
      this.showProfileMessage = this.profileId ? false : true;
    }
  }
  searchSheetUser(user) {
    this.userId = user.id;
    const queryParams = {
      userId: user.id
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
    (document.getElementById('userSearchModalCloseBtn') as HTMLInputElement).click();
    this.getMyWorkSheet();
  }

  getProfileList() {
    const profile_type = 'service_provider';
    this.profileService.getProfilesList(profile_type).subscribe(
      (res) => {
        this.profileList = res;
        this.profileList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      },
      (error) => {},
      () => {}
    );
  }
}
