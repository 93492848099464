import { DashboardGraphs } from './../shared/model/dashboard';
import { Component, OnInit } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { DashboardService } from '../services/dashboard.service';
import { PermissionCheckService } from '../shared/services/permissions/permission-check.service';
import { PermissionsConstants } from '../shared/constant/permission.constants';
import { DashboardCounts, DashboardTotalCounts } from '../shared/model/dashboard';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashBoardAnalytics: Boolean = true;
  vitalsAlertCount: number;
  patientsCount: number;
  labBookingsCount: number;
  unreadConsumerMessageCount: number;
  unprocessedPrescriptionRefillRequestCount: number;
  consultationCount: number;
  dateFilter: boolean = false;
  loading: boolean;

  dashboardCountInfo: DashboardCounts;
  dashboardTotalCounts: DashboardGraphs;

  requiredPermissions = {
    callLog: PermissionsConstants.DASHBOARD_CALL_LOG,
    todoList: PermissionsConstants.DASHBOARD_TODO_LIST,
    labBookingCount: PermissionsConstants.DASHBOARD_LAB_BOOKING_COUNT,
    prescriptionRefillRequestsCount: PermissionsConstants.DASHBOARD_PRESCRIPTION_REFILL_COUNT,
    consumerMessageCount: PermissionsConstants.DASHBOARD_CONSUMER_MESSAGE_COUNT,
    vitalsAlertCount: PermissionsConstants.DASHBOARD_VITAL_ALERT_COUNT,
    patientsCount: PermissionsConstants.DASHBOARD_PATIENT_COUNT,
    consultationCount: PermissionsConstants.DASHBOARD_CONSULTATION_COUNT
  };
  selectedDate: any = '';
  fromDate: any = '';
  toDate: any = '';
  queryParams: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private dashboardService: DashboardService,
    private permissionCheckService: PermissionCheckService
  ) {}

  ngOnInit() {
    this.permissionCheckService.permissionListEm.subscribe((permissionListLoaded) => {
      this.getVitalCount();
      // this.getPatientsCount();
      // this.getLabBookingsCount();
      this.getPrescriptionRefillRequestCount();
      this.getConsumerMessageCount();
      // this.getConsultationCount();
    });
    this.getVitalCount();
    // this.getPatientsCount();
    // this.getLabBookingsCount();
    this.getPrescriptionRefillRequestCount();
    this.getConsumerMessageCount();
    // this.getConsultationCount();
    this.getDashboardCount();
    this.getUrlParams();
    this.setUrlParams();
    this.getDashboardTotals();
  }

  onClick(e) {
    if (e.target.innerText == 'Numbers') {
      this.dashBoardAnalytics = false;
    } else {
      this.dashBoardAnalytics = true;
    }
  }

  private getDashboardCount() {
    this.dashboardService.getDashboardData().subscribe(
      (response: DashboardCounts) => {
        this.dashboardCountInfo = response;
      },
      (error) => {
        this.dashboardCountInfo = undefined;
      }
    );
  }

  getDashboardTotals() {
    this.loading = true;
    this.dashboardService.getDashboardDataCounts(this.queryParams).subscribe(
      (response: DashboardGraphs) => {
        this.dashboardTotalCounts = response;
        this.loading = false;
        console.log('dashboard details data -->', response)
      },
      (error) => {
        this.dashboardTotalCounts = undefined;
      },
      () => {
        this.loading = false;
      }
    );
  }

  setUrlParams() {
    const queryParams = {
      date_from: this.queryParams.date_from || null,
      date_to: this.queryParams.date_to || null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  getUrlParams() {
    this.fromDate = this.activatedRoute.snapshot.queryParamMap.get('date_from');
    this.toDate = this.activatedRoute.snapshot.queryParamMap.get('date_to');
    this.queryParams = {
      date_from: this.fromDate || '',
      date_to: this.toDate || ''
    };
  }

  applyDateFilter(selectedDate) {
    this.queryParams = {
      date_from: selectedDate.date_from || '',
      date_to: selectedDate.date_to || ''
    };
    this.setUrlParams();
    this.getDashboardTotals();
  }

  resetDateFilter() {
    this.queryParams.date_from = '';
    this.queryParams.date_to = '';
    this.setUrlParams();
    this.getDashboardTotals();
  }

  private getVitalCount() {
    if (this.permissionCheckService.hasPermission(this.requiredPermissions.vitalsAlertCount)) {
      this.dashboardService.getVitalCount().subscribe(
        (response: any) => {
          this.vitalsAlertCount = response.total;
        },
        (error) => {
          this.vitalsAlertCount = 0;
        }
      );
    }
  }

  private getPrescriptionRefillRequestCount() {
    if (
      this.permissionCheckService.hasPermission(
        this.requiredPermissions.prescriptionRefillRequestsCount
      )
    ) {
      this.dashboardService.getPrescriptionRefillRequestCount().subscribe(
        (response: any) => {
          this.unprocessedPrescriptionRefillRequestCount = response.data;
        },
        (error) => {
          this.unprocessedPrescriptionRefillRequestCount = 0;
        }
      );
    }
  }

  private getConsumerMessageCount() {
    if (this.permissionCheckService.hasPermission(this.requiredPermissions.consumerMessageCount)) {
      this.dashboardService.getConsumerMessageCount().subscribe(
        (response: any) => {
          this.unreadConsumerMessageCount = response.data;
        },
        (error) => {
          this.unreadConsumerMessageCount = 0;
        }
      );
    }
  }

  private getConsultationCount() {
    if (this.permissionCheckService.hasPermission(this.requiredPermissions.consultationCount)) {
      this.dashboardService.getConsultationCount().subscribe(
        (response: any) => {
          this.consultationCount = response.data;
        },
        (error) => {
          this.consultationCount = 0;
        }
      );
    }
  }

  filterClicked() {
    this.dateFilter = !this.dateFilter;
  }


  checkPermission(todoList) {
    return this.permissionCheckService.hasPermission(todoList);
  }
}
