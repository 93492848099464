import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Patient, Prefix } from '../shared/model/patient';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PatientTypeList } from '../shared/model/patientTypeList';
import { RelationList } from '../shared/model/relationList';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private apiUrl = environment.apiUrl; // URL to web api

  urls = {
    index: `${this.apiUrl}patients`,
    show: (patientId) => `${this.apiUrl}patients/${patientId}`,
    store: `${this.apiUrl}patient`,
    destroy: (patientId) => `${this.apiUrl}patients/${patientId}`,
    update: (patientId) => `${this.apiUrl}patient/${patientId}`,
    familyHistory: (patientId) => `${this.apiUrl}${patientId}/family-history`,
    carePlan: (patientId) => `${this.apiUrl}${patientId}/care-plans`,
    latestAllergyInformation: (patientId) =>
      `${this.apiUrl}${patientId}/allergy-informations/latest`,
    relationList: `${this.apiUrl}relation-lists`,
    patientTypeList: `${this.apiUrl}patient-type-lists`,
    patientMenu: `${this.apiUrl}patient-menu`,
    patientSearch: `${this.apiUrl}search/patients`,
    patientVitals: (patientId, limit) => `${this.apiUrl}patient/${patientId}/vitals?limit=` + limit,
    userAssign: (patientId) => `${this.apiUrl}${patientId}/assign-users`,
    assignUsersList: (patientId) => `${this.apiUrl}${patientId}/assigned-users`,
    removeUserAssign: (patientId) => `${this.apiUrl}${patientId}/unassign-users`,
    patientHistory: (patientId) => `${this.apiUrl}${patientId}/patient-history`,
    patientInfoFromHash: `${this.apiUrl}patient-fetch/from-registration-hash`,
    registerAsUser: `${this.apiUrl}patients/register`,
    prefix: `${this.apiUrl}prefix`,
    setNewPassword: `${this.apiUrl}patients/register/set-password`
  };

  constructor(private http: HttpClient) {}

  getPatients(pageNo, limit, data): Observable<any> {
    pageNo = pageNo;
    limit = limit;
    const q = data.q;
    const patient_type_name = data.patient_type_name;
    const registered_at = data.registered_at;
    const gender = data.gender;
    const status = data.status;
    const verification = data.verification;
    return this.http.get<any>(this.urls.index, {
      params: { pageNo, limit, q, patient_type_name, registered_at, gender, status, verification }
    });
  }

  getPrefixList(): Observable<Prefix[]> {
    return this.http.get<Prefix[]>(this.apiUrl + 'prefix');
  }

  getPatient = (patientId: number): Observable<Patient> =>
    this.http.get<Patient>(this.urls.show(patientId));

  getFamilyHistory = (patientId) => this.http.get(this.urls.familyHistory(patientId));

  deletePatient = (patientId) => this.http.delete(this.urls.destroy(patientId));

  getRelationList = (): Observable<RelationList[]> =>
    this.http.get<RelationList[]>(this.urls.relationList);

  getPatientTypeList = (): Observable<PatientTypeList[]> =>
    this.http.get<PatientTypeList[]>(this.urls.patientTypeList);

  getLatestAllergyInformation = (patientId) =>
    this.http.get(this.urls.latestAllergyInformation(patientId));

  getPatientMenu = () => this.http.get(this.urls.patientMenu);

  register(patients) {
    const url = `${this.apiUrl}patients`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, patients, httpOptions);
  }

  save(patients, id) {
    const url = `${this.apiUrl}patients/` + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.put(url, patients, httpOptions);
  }

  // @ts-ignore
  getId = (route: ActivatedRoute) => Number(route.snapshot.paramMap.get('id'));

  searchPatients(query, pageNo, limit, filter = null) {
    return this.http.get(this.urls.patientSearch + '?query=' + query, {
      params: { pageNo, limit, filter }
    });
  }

  getVitals(patientId, limit): Observable<any> {
    return this.http.get(this.urls.patientVitals(patientId, limit));
  }

  assignUsers(patientId, users): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urls.userAssign(patientId), users, httpOptions);
  }

  getAssignedUsers(patientId, pageNo, limit): Observable<any> {
    return this.http.get(this.urls.assignUsersList(patientId), { params: { pageNo, limit } });
  }

  removeAssignedUser(patientId, users): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.urls.removeUserAssign(patientId), { users }, httpOptions);
  }

  getPatientHistory(patientId, dateFrom, dateTo) {
    return this.http.get(this.urls.patientHistory(patientId), { params: { dateFrom, dateTo } });
  }

  fetchPatientInfoFromRegistrationHash(registration_hash: string) {
    return this.http.get(this.urls.patientInfoFromHash, {
      params: { registration_hash }
    });
  }

  fetchPatientInfoFromOtp(otp: string) {
    return this.http.get(this.urls.patientInfoFromHash, {
      params: { otp }
    });
  }

  registerPatientAsUser(data) {
    return this.http.post(this.urls.registerAsUser, data);
  }

  setNewPassword(data) {
    return this.http.post(this.urls.setNewPassword, data);
  }
}
