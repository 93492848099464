import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DiagnosisNameService } from '../../../../services/diagnosis-name.service';
import { NotifyService } from '../../../../services/notify.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-diagnosis-form',
  templateUrl: './diagnosis-form.component.html',
  styleUrls: ['./diagnosis-form.component.css']
})
export class DiagnosisFormComponent implements OnInit {
  diagnosis: any;
  submitInProgress = false;
  submitted = false;
  diagnosisForm = new FormGroup({
    name: new FormControl('', [Validators.required])
  });
  constructor(
    private toastr: ToastrService,
    private diagnosisService: DiagnosisNameService,
    private router: Router,
    private dialogRef: MatDialogRef<DiagnosisFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.diagnosis = data;
  }

  ngOnInit() {
    console.log(this.diagnosis);
    if (this.diagnosis) {
      this.patchData();
    }
  }

  patchData() {
    this.diagnosisForm.patchValue({
      name: this.diagnosis.name
    });
  }
  submit() {
    if (this.diagnosisForm.status === 'VALID') {
      this.submitInProgress = true;
      const id = this.diagnosis ? this.diagnosis.id : null;
      this.diagnosisService.save(this.diagnosisForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
          this.submitInProgress = false;
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.submitInProgress = false;
        },
        () => {
          this.submitInProgress = false;
          this.submitted = false;
          this.dialogRef.close(true);
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
