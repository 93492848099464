import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-booked-investigations',
  templateUrl: './booked-investigations.component.html',
  styleUrls: ['./booked-investigations.component.css']
})
export class BookedInvestigationsComponent {
  @Input() booking_investigation;

  parse(object) {
    return object && JSON.parse(object) ? JSON.parse(object).join(', ') : false;
  }
}
