import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { NotifyService } from '../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-edit',
  styleUrls: ['./profile-edit.component.css'],
  templateUrl: './profile-edit.component.html'
})
export class ProfileEditComponent implements OnInit {
  profileId: number;
  profileForm: FormGroup;
  profile: object;
  is_connectycube = false;
  isServiceProviderStatus = false;
  profileTypes = [
    {
      slug: 'patient_representative',
      name: 'Patient Representative'
    },
    {
      slug: 'service_provider',
      name: 'Service Provider'
    }
  ];

  arr: Array<any> = [];
  nodes: Array<any>;
  // For Form Footer
  cancelUrl = '/profiles';
  submitInProgress = false;
  submitted = false;
  maxNameLength = 255;
  maxDescriptionLength = 65535;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.profileId = params.id;
      this.getActions();
    });
    this.profileForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      description: new FormControl('', [Validators.maxLength(65535)]),
      profile_type: new FormControl('', [Validators.required]),
      theme: new FormControl('red', [Validators.required])
    });
  }

  getProfile() {
    this.submitInProgress = true;
    this.profileService.getProfile(this.profileId).subscribe(
      (res) => {
        this.patchProfileDefaults(res);
        this.is_connectycube = res.is_connectycube === 1;
        this.setSelected(res.actionsArr);
        console.log(res);
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  patchProfileDefaults(profileDetail) {
    this.profileForm.patchValue({
      name: profileDetail.name,
      description: profileDetail.description,
      profile_type: profileDetail.profile_type,
      theme: profileDetail.theme
    });
    this.isServiceProvider();
  }

  changeProfileType() {
    this.is_connectycube = !this.is_connectycube;
    if (!this.is_connectycube) {
      this.profileForm.controls.profile_type.clearValidators();
      this.profileForm.controls.profile_type.patchValue('');
    } else {
      this.profileForm.controls.profile_type.setValidators([Validators.required]);
    }
    this.profileForm.controls.profile_type.updateValueAndValidity();
  }

  getActions() {
    return this.profileService.getActions().subscribe((actions) => {
      this.nodes = actions;
      this.getProfile();
    });
  }

  check(v): void {
    v.checked = true;
    console.log(v);
  }

  uncheck() {
    console.log('aaaa');
  }

  setSelected(arr) {
    this.nodes.map((x) => {
      if (arr.includes(x.id)) {
        x.checked = true;
      }
      if (x.children.length > 0) {
        x.children.map((y) => {
          this.setSelectedInChild(y, arr);
        });
      }
    });
  }

  setSelectedInChild(z, arr) {
    if (arr.includes(z.id)) {
      z.checked = true;
    }
    if (z.children.length > 0) {
      z.children.map((a) => {
        this.setSelectedInChild(a, arr);
      });
    }
  }

  submit() {
    this.submitted = true;
    this.submitInProgress = true;
    this.setSelectedPerms(); // assign selected perms to this.arr
    if (this.profileForm.status === 'VALID' && this.arr.length >= 1) {
      const data = this.profileForm.value;
      data.is_connectycube = this.is_connectycube ? 1 : 0;
      data.perms = this.arr; // this.arr assigned from this.setSelectedPerms();
      this.profileService.update(data, this.profileId).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['profiles']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setSelectedPerms() {
    this.arr = [];
    this.nodes.map((x) => {
      // console.log(x.id);
      if ('checked' in x && x.checked) {
        console.log(x.name + ' checked');
        this.arr.push(x.id);
      }
      if (x.children.length > 0) {
        x.children.map((y) => {
          if ('checked' in y && y.checked) {
            console.log(y.name + ' checked');
            this.arr.push(y.id);
          }
          // console.log(y.id);
          if (y.children.length > 0) {
            this.getValuesFormChildren(y);
          }
        });
      }
    });
  }

  getValuesFormChildren(z) {
    // z.map(a => {
    //   console.log(a);
    //   if('checked' in a && a.checked) console.log(a.name + ' checked');
    //   if( a.children.length > 0 ) {
    //     a.children.map(b => {
    //       this.getValuesFormChildren(b);
    //     });
    //   }
    //
    //
    // });
    // console.log(z);

    if ('checked' in z && z.checked) {
      console.log(z.name + ' checked');
      this.arr.push(z.id);
    }
    if (z.children.length > 0) {
      z.children.map((a) => {
        this.getValuesFormChildren(a);
      });
    }
  }

  isServiceProvider() {
    this.isServiceProviderStatus =
      this.profileForm.controls.profile_type.value === 'service_provider' ? true : false;
  }
}
