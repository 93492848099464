import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitLocationService {
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  store(data) {
    const url = this.apiUrl + 'locations/create';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }
}
