import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { InquiryFormService } from '../services/inquiry-form.service';
import { NotifyService } from '../services/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inquiry-form',
  templateUrl: './inquiry-form.component.html',
  styleUrls: ['./inquiry-form.component.css']
})
export class InquiryFormComponent implements OnInit {
  redirectHAHUrl = environment.redirectHAHUrl || 'https://healthathome.com.np/';
  step = 1;
  status;
  firstFormData;
  secondFormData;
  thirdFormData;
  fourthFormData;
  constructor(
    private inquiryService: InquiryFormService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {
    router.events.subscribe((val) => {
      this.status = activateRoute.snapshot.queryParamMap.get('status');
    });
  }

  ngOnInit() {}

  goTo(event) {
    if (event.currentStep === 1) {
      this.firstFormData = event.data;
      this.step = event.nextStep;
    } else if (event.currentStep === 2) {
      this.secondFormData = event.data;
      this.step = event.nextStep;
    } else if (event.currentStep === 3) {
      this.thirdFormData = event.data;
      this.step = event.nextStep;
    }
  }

  goToPrevious(event) {
    if (event.currentStep === 2) {
      this.secondFormData = event.data;
      this.step = event.previousStep;
    } else if (event.currentStep === 3) {
      this.thirdFormData = event.data;
      this.step = event.previousStep;
    } else if (event.currentStep === 4) {
      this.fourthFormData = event.data;
      this.step = event.previousStep;
    }
  }

  saveInquiryData(event) {
    this.fourthFormData = event.data;
    const data = this.formatData();
    this.inquiryService.save(data).subscribe(
      (res: any) => {},
      (error) => {
        this.toastr.success(error.error.messageData);
      },
      () => {
        this.router.navigate([], {
          relativeTo: this.activateRoute,
          queryParams: {
            status: 'success'
          },
          queryParamsHandling: 'merge',
          skipLocationChange: false
        });
        this.resetValue();
      }
    );
  }
  resetValue() {
    this.step = 1;
    this.firstFormData = {};
    this.secondFormData = {};
    this.thirdFormData = {};
    this.fourthFormData = {};
  }

  cancel() {
    window.location.href = this.redirectHAHUrl;
  }

  formatData() {
    return {
      first_name: this.firstFormData.firstName,
      last_name: this.firstFormData.lastName,
      mobile: this.firstFormData.mobile,
      country: this.firstFormData.country,
      email: this.firstFormData.email,
      address: this.firstFormData.address,
      monthly_visit_status: this.secondFormData.monthlyVisitStatus,
      total_enroll: this.secondFormData.totalEnroll,
      delivery_pharmacy_status: this.secondFormData.deliveryPharmacyStatus,
      partner_member: this.secondFormData.partnerMember,
      require_update: this.thirdFormData.requireUpdate,
      diagnosis_condition_status: this.thirdFormData.diagnosisConditionStatus,
      urgent_rating: this.thirdFormData.urgentRating,
      individual_diagnosis: this.thirdFormData.individualDiagnosis,
      enroll_reason: this.fourthFormData.enrollReason,
      service_status: this.fourthFormData.serviceStatus,
      individual_necessity_status: this.fourthFormData.individualNecessityStatus,
      dependent_care_status: this.fourthFormData.dependentCareStatus
    };
  }
}
