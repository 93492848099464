import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { LabBookingService } from '../../../services/lab-booking.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LabReportService } from '../../../services/lab-report.service';
import * as moment from 'moment';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/shared/services/dialog.service';

export interface LabStatus {
  id: number;
  slug: string;
  action: string;
  display_name: string;
  actions: LabStatusAction[];
}

export interface LabStatusAction {
  name: string;
  url: string;
  parent_status_id: number;
  icon: string;
}

@Component({
  selector: 'app-lab-booking-list',
  templateUrl: './lab-booking-list.component.html',
  styleUrls: ['./lab-booking-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LabBookingListComponent implements OnInit {
  @Input() booking: any;
  activeSlug: string;
  selectedIndex: number;

  bookingStatus: LabStatus[] = [];
  bookingList: any = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  labList: Array<any> = [];

  doctorList: string[] = [];
  filteredDoctorList: Observable<string[]>;

  rawSearchQuery: string;

  advancedSearchForm = new FormGroup({
    query: new FormControl(''),
    name: new FormControl(''),
    mobile: new FormControl(''),
    bookingFrom: new FormControl(''),
    bookingTo: new FormControl(''),
    scheduledFrom: new FormControl(''),
    scheduledTo: new FormControl(''),
    recommendedBy: new FormControl(''),
    labId: new FormControl('')
  });

  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;
  filterDataChanged: boolean;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private labReportService: LabReportService,
    private toastr: ToastrService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.hideToggleSearchFilters();
    this.getUrlParams();
    this.getDoctorList();
    this.getStatusList();
    this.getLabList();
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  getUrlParams() {
    const activeSlug: any = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.activeSlug = activeSlug !== null ? activeSlug : 'pending';

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    Object.keys(this.advancedSearchForm.controls).map((field) => {
      this.advancedSearchForm.patchValue({
        [field]: this.activatedRoute.snapshot.queryParamMap.get(field) || null
      })
    })
  }

  mapRouteParamsToObject(queryParams: Params): Record<string, string> {
    return this.activatedRoute.snapshot.queryParamMap.keys
      .map((key) => ({
        [key]: this.activatedRoute.snapshot.queryParamMap.get(key)
      }))
      .reduce((previous, current) => ({ ...previous, ...current }), {});
  }

  setUrlParams() {
    const queryParams = {
      status: this.activeSlug ? this.activeSlug : null,
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      query: this.advancedSearchForm.get('query').value
        ? this.advancedSearchForm.get('query').value
        : null,
      name: this.advancedSearchForm.get('name').value
        ? this.advancedSearchForm.get('name').value
        : null,
      mobile: this.advancedSearchForm.get('mobile').value
        ? this.advancedSearchForm.get('mobile').value
        : null,
      bookingFrom: this.advancedSearchForm.get('bookingFrom').value
        ? this.advancedSearchForm.get('bookingFrom').value
        : null,
      bookingTo: this.advancedSearchForm.get('bookingTo').value
        ? this.advancedSearchForm.get('bookingTo').value
        : null,
      scheduledFrom: this.advancedSearchForm.get('scheduledFrom').value
        ? this.advancedSearchForm.get('scheduledFrom').value
        : null,
      scheduledTo: this.advancedSearchForm.get('scheduledTo').value
        ? this.advancedSearchForm.get('scheduledTo').value
        : null,
      recommendedBy: this.advancedSearchForm.get('recommendedBy').value
        ? this.advancedSearchForm.get('recommendedBy').value
        : null,
      labId: this.advancedSearchForm.get('labId').value
        ? this.advancedSearchForm.get('labId').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'query') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value + ' ';
        } else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }
      }
    }
    return stringParams;
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = [
      'query',
      'name',
      'mobile',
      'bookingFrom',
      'bookingTo',
      'scheduledFrom',
      'scheduledTo',
      'recommendedBy',
      'labId'
    ];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      query: queryValue.trim()
    });
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.setUrlParams();
      this.getBookingList(this.activeSlug, this.limit);
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('query').reset();
    this.applyAdvancedSearch();
  }

  getDoctorList() {
    this.labReportService.getDoctorList().subscribe(
      (response: any) => {
        this.doctorList = response;
      },
      (error) => {
        this.doctorList.length = 0;
      },
      () => {
        this.filteredDoctorList = this.setACField(
          this.advancedSearchForm.controls.recommendedBy,
          'doctorList'
        );
      }
    );
  }

  getLabList() {
    this.labReportService.getLabListForLabReport().subscribe(
      (response: any) => {
        this.labList = response.data;
      },
      (error) => {
        this.labList.length = 0;
      }
    );
  }

  filterBookings(index) {
    this.page = 1;
    this.selectedIndex = index;
    this.activeSlug = this.getSlugFromIndex(index);
    this.rawSearchQuery = '';
    this.advancedSearchForm.reset({ recommendedBy: '' });
    this.getDoctorList();
    this.setUrlParams();
    this.getBookingList(this.activeSlug, this.limit);
  }

  getSlugFromIndex(index: number) {
    return this.bookingStatus.find((status) => {
      return this.bookingStatus.indexOf(status) === index;
    }).slug;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getBookingList(this.activeSlug, this.limit);
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getBookingList(this.activeSlug, this.limit);
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getBookingList(this.activeSlug, this.limit);
    this.setUrlParams();
  }

  getBookingList(statusSlug: string, limit) {
    const page = this.page;
    this.loading = true;

    this.bookingList.length = 1;

    this.labBookingService
      .getBookingList(statusSlug, page, limit, this.advancedSearchForm.value)
      .subscribe(
        (response: any) => {
          this.bookingList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.bookingList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  getStatusList() {
    this.labBookingService.getStatusList().subscribe(
      (response: any) => {
        this.bookingStatus = response;
      },
      (error) => {
        this.toastr.error('Error occurred, could not fetch data!');
        this.bookingList.length = 0;
      },
      () => {
        // if there is no lab booking status
        // redirect to homepage
        if (this.bookingStatus.length === 0) {
          window.location.href = '';
          return;
        }

        const selectedState = this.bookingStatus.find((status) => status.slug === this.activeSlug);

        this.activeSlug = selectedState ? selectedState.slug : this.bookingStatus[0].slug;
        this.selectedIndex = this.bookingStatus.indexOf(selectedState);

        this.setUrlParams();
        this.getBookingList(this.activeSlug, this.limit);
      }
    );
  }
  checkCurrentStatusInList() {
    const currentBookingStatus = this.bookingStatus.find((booking) => {
      return (this.activeSlug = booking.slug);
    });
    if (!currentBookingStatus) {
      if (this.bookingStatus.length === 0) {
        window.location.href = '';
      } else {
        this.activeSlug = this.bookingStatus[0].slug;
        this.setUrlParams();
      }
    }
  }

  parse(object) {
    return JSON.parse(object).join(', ') ? JSON.parse(object).join(', ') : 'No Tests';
  }

  changeStatus(bookingId, statusId) {
    this.labBookingService.updateStatus(bookingId, statusId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.getBookingList(this.activeSlug, this.limit);
      }
    );
  }

  getActionList(booking) {
    const statusActions = this.bookingStatus.find((status) => {
      return status.slug === booking.status_slug;
    }).actions;
    const actionArr = [];
    if (statusActions) {
      statusActions.forEach((action) => {
        if (action.url === '/lab-bookings/{booking-id}/status/update') {
          actionArr.push({
            name: action.name,
            icon: action.icon,
            type: 'statusChange',
            changeTo: this.getNextStatus(booking.status_slug)
          });
        } else {
          actionArr.push({
            name: action.name,
            icon: action.icon,
            type: 'redirect',
            url: action.url.replace('{booking-id}', booking.id)
          });
        }
      });
    }
    return actionArr;
  }

  getNextStatus(statusSlug) {
    let nextSlug = '';
    switch (statusSlug) {
      case 'pending':
        nextSlug = 'cancelled';
        break;
      case 'sample_collected':
        nextSlug = 'dispatched';
        break;
      case 'dispatched':
        nextSlug = 'lab_received';
        break;
      case 'lab_received':
        nextSlug = 'in_progress';
        break;
      case 'in_progress':
        nextSlug = 'completed';
        break;
      default:
        nextSlug = 'sample_collected';
    }
    const statusObj = this.bookingStatus.find((status) => {
      return status.slug === nextSlug;
    });

    if (typeof statusObj !== 'undefined') {
      return statusObj.id;
    }
  }

  applyRedirect(event, action, booking) {
    if (action.name === 'Lab Report' && booking.patient_lab_report) {
      this.router.navigate(['/lab-report/' + booking.patient_lab_report.id]);
      return;
    }
    this.router.navigateByUrl(action.url);
  }

  applyStatusChange(event, booking, action) {
    this.changeStatus(booking.id, action.changeTo);
  }

  deleteBooking(event, booking) {
    event.preventDefault();
    event.stopPropagation();
    this.labBookingService.deleteBooking(booking.id).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.removeLabBookingFromList(booking);
      }
    );
  }

  openConfirmationDialog(event, booking) {
    this.dialogService.confirmDialog({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this Lab Booking?',
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    }).subscribe((result)=>{
      if(result) {
        this.deleteBooking(event, booking);
      }
    });
  }

  removeLabBookingFromList(bookingObj) {
    this.bookingList = this.bookingList.filter((labBooking: any) => {
      return bookingObj.id !== labBooking.id;
    });
  }

  hasTabLabField() {
    return this.activeSlug === 'completed' || this.activeSlug === 'delivered';
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  resetAdvancedSearchForm() {
    this.advancedSearchForm.patchValue({
      name: null,
      mobile: null,
      bookingFrom: null,
      bookingTo: null,
      scheduledFrom: null,
      scheduledTo: null,
      recommendedBy: null,
      labId: null
     });
    this.page = 1;
    this.setUrlParams();
    this.getBookingList(this.activeSlug, this.limit);
  }
}
