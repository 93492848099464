import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServiceService } from '../../services/user-service.service';
import { NotifyService } from '../../services/notify.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {
  formErrorObj: Array<any> = [
    {
      controlName: 'old_password',
      error: false
    },
    {
      controlName: 'new_password',
      error: false
    },
    {
      controlName: 'confirm_password',
      error: false
    }
  ];

  cancelUrl = '/users';
  submitInProgress = false;

  formSubmitted = false;

  userForm = new FormGroup({
    old_password: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required])
  });

  // For password strength and validation
  PASSWORD_MIN_LENGTH = 6;
  containsUppercaseCharacter = false;
  containsNumericCharacter = false;
  isLongEnough = false;
  bothPasswordMatches = true;

  loggedInUser: any;
  userProfile: any;
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;

  constructor(private userService: UserServiceService, private storageService: StorageService) {}

  ngOnInit() {
    this.getLoggedInUser();
    this.getProfileDetail();
  }

  getProfileDetail() {
    this.userService.getProfile().subscribe(
      (response) => {
        this.userProfile = response;
      },
      (error) => {
        this.displayToastMessage(error.error.message, false);
      }
    );
  }
  getLoggedInUser() {
    this.loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
  }

  changePassword() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (
      this.userForm.status === 'VALID' &&
      this.containsUppercaseCharacter &&
      this.containsNumericCharacter &&
      this.isLongEnough
    ) {
      this.userService.changePassword(JSON.stringify(this.userForm.value)).subscribe(
        (response: any) => {
          this.displayToastMessage(response.message, true);
        },
        (error) => {
          this.submitInProgress = false;
          this.displayToastMessage(error.error.message, false);
          if (error.status === 422) {
            const errors = error.error.errors;
            if (errors.old_password) {
              this.setError('old_password', errors.old_password[0]);
            } else {
              this.unsetError('old_password');
            }
            if (errors.new_password) {
              this.setError('new_password', errors.new_password[0]);
            } else {
              this.unsetError('new_password');
            }
            if (errors.confirm_password) {
              this.setError('confirm_password', errors.confirm_password[0]);
            } else {
              this.unsetError('confirm_password');
            }
          }
        },
        () => {
          this.submitInProgress = false;
          // this.router.navigate(['/users']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  checkPasswordStrength(event) {
    event.preventDefault();
    const enteredPassword = event.target.value;
    const digitContainingRegex = new RegExp('.*\\d+.*');
    const uppercaseContainingRegex = new RegExp('.*[A-Z].*');
    this.isLongEnough = enteredPassword.length >= this.PASSWORD_MIN_LENGTH;
    this.containsNumericCharacter = digitContainingRegex.test(enteredPassword);
    this.containsUppercaseCharacter = uppercaseContainingRegex.test(enteredPassword);
  }

  checkConfirmPassword(event) {
    event.preventDefault();
    const enteredConfirmPassword = event.target.value;
    const newPassword = this.userForm.controls.new_password.value;
    this.bothPasswordMatches = enteredConfirmPassword === newPassword;
  }

  displayToastMessage(message: string, isSuccess: boolean) {
    this.successMessageDisplay = isSuccess;
    this.failureMessageDisplay = !isSuccess;
    this.responseMessage = message;
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }
}
