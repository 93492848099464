import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(private toastrService: ToastrService) {}

  setMessage(type: string, message: string) {
    window.scroll(0, 0); // Scroll to top
    const messageType = type.toLowerCase();
    if (messageType === 'success') {
      this.onSuccess(message);
    } else if (messageType === 'warning') {
      this.onWarning(message);
    } else if (messageType === 'info') {
      this.onInfo(message);
    } else {
      this.onError(message);
    }
  }

  onSuccess(message: string) {
    this.toastrService.success(message);
  }

  onInfo(message: string) {
    this.toastrService.info(message);
  }

  onWarning(message: string) {
    this.toastrService.warning(message);
  }

  onError(message: string) {
    this.toastrService.error(message);
  }
}
