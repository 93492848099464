import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../../services/lab-booking.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { NotifyService } from '../../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sample-create',
  templateUrl: './sample-create.component.html',
  styleUrls: ['./sample-create.component.scss']
})
export class SampleCreateComponent implements OnInit {
  bookingId: number;
  sampleTypes: Array<any> = ['Blood', 'Stool', 'Urine', 'Sputum', 'Fluids', 'Tissue'];
  sampleUOMs: Array<any> = ['ml', 'gm'];
  sampleList: Array<any> = [];

  staffList: Array<string> = [];
  filteredStaffList: Array<string> = [];

  bookingDetail: any;

  // For Form Footer
  cancelUrl = '/lab-bookings';
  submitInProgress = false;

  formSubmitted = false;

  filterStaffList(val: string) {
    if (val) {
      const filterValue = val.toLowerCase();
      return this.staffList.filter((state) => state.toLowerCase().startsWith(filterValue));
    } else {
      return this.staffList;
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private notification: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.bookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getBookingDetail(this.bookingId);
    this.getStaffList();
  }

  getStaffList() {
    this.submitInProgress = true;
    this.labBookingService.getStaffList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.staffList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.filteredStaffList = this.staffList;
      }
    );
  }

  getBookingDetail(bookingId) {
    this.submitInProgress = true;
    this.labBookingService.getBookingDetail(bookingId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.bookingDetail = response.booking;
        response.booking.samples.forEach((bookingSample) => {
          this.sampleList.push({
            type_of_sample: bookingSample.type_of_sample,
            quantity: bookingSample.quantity,
            uom: bookingSample.uom,
            collected_at: moment(bookingSample.collected_at).format('YYYY-MM-DD'),
            collected_at_time: new Date(bookingSample.collected_at).toLocaleTimeString(),
            collected_by: bookingSample.staffs.name,
            readonly: true
          });
        });
        console.log(this.sampleList);
      },
      (error) => {
        this.submitInProgress = false;
        console.log('could not fetch booking at the moment');
      }
    );
  }

  parse(object) {
    return object && JSON.parse(object) ? JSON.parse(object).join(', ') : 'No Tests';
  }

  addSample(event) {
    event.preventDefault();
    const sample = {
      type_of_sample: null,
      quantity: null,
      uom: null,
      collected_at: moment(new Date()).format('YYYY-MM-DD'),
      collected_at_time: new Date().toLocaleTimeString(),
      collected_by: null
    };
    this.sampleList.push(sample);
  }

  removeSample(id: number) {
    this.sampleList.splice(id, 1);
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  storeSamples() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    const bookingId = this.bookingId;
    const sampleList = this.processSample(this.sampleList);
    if (sampleList.length !== 0 && this.sampleListValid()) {
      this.labBookingService.storeSamples(bookingId, sampleList).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.toastr.success('Samples added successfully!');
          this.router.navigate(['/lab-bookings'], { queryParams: { status: 'sample_collected' } });
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error('Sorry! samples could not be added at the moment!');
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  sampleListValid(): boolean {
    for (const sample of this.sampleList) {
      if (!this.sampleValid(sample)) {
        return false;
      }
    }
    return true;
  }

  sampleValid(sample: any): boolean {
    return sample.type_of_sample && sample.quantity && sample.uom && sample.collected_by;
  }

  processSample(sampleList) {
    const returnList = [];
    sampleList.forEach((sample: any) => {
      returnList.push({
        collected_at: (sample.collected_at + ' ' + sample.collected_at_time).trim(),
        quantity: sample.quantity,
        type_of_sample: sample.type_of_sample,
        uom: sample.uom,
        collected_by: sample.collected_by
      });
    });
    return returnList;
  }
}
