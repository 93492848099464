import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {
  dateSearchFilter = new FormGroup({
    date_from: new FormControl(''),
    date_to: new FormControl('')
  });

  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;
  loading: boolean = false;
  filterDataChanged: boolean = false;
  @Output() filterApplied = new EventEmitter<any>();
  @Output() filterReset = new EventEmitter<any>();
  @Input() query: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.dateSearchFilter.patchValue({
      date_from: this.activatedRoute.snapshot.queryParamMap.get('date_from'),
      date_to: this.activatedRoute.snapshot.queryParamMap.get('date_to')
    });
  }

  applyDateSearchFilter() {
    this.loading = true;
    this.isEmptySearch();
    if (this.filterDataChanged) {
      this.filterApplied.emit(this.dateSearchFilter.value);
    }
  }

  resetAdvancedSearchForm() {
    this.dateSearchFilter.reset();
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.isEmptySearch();
    if (this.filterDataChanged) {
      this.filterReset.emit('reset');
    }
  }

  isEmptySearch() {
    this.filterDataChanged = false;
    for (const field in this.dateSearchFilter.controls) {
      if (
        this.dateSearchFilter.get(field).value !==
        this.activatedRoute.snapshot.queryParamMap.get(field)
      ) {
        this.filterDataChanged = true;
        return;
      }
    }
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }
}
