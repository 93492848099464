import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../../services/lab-booking.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { LabReportService } from '../../../../services/lab-report.service';
import * as moment from 'moment';
import { NotifyService } from '../../../../services/notify.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-dispatch-create',
  templateUrl: './lab-dispatch-create.component.html',
  styleUrls: ['./lab-dispatch-create.component.css']
})
export class LabDispatchCreateComponent implements OnInit {
  bookingId: number;

  labList: any;

  formErrorObj: Array<any> = [
    {
      controlName: 'date',
      error: false
    },
    {
      controlName: 'time',
      error: false
    },
    {
      controlName: 'lab_id',
      error: false
    },
    {
      controlName: 'dispatched_by',
      error: false
    },
    {
      controlName: 'received_by',
      error: false
    }
  ];

  // For Form Footer
  formDirty = false;
  cancelUrl = '/lab-bookings?status=sample_collected';
  submitInProgress = false;

  formSubmitted = false;

  labDispatchForm = new FormGroup({
    date: new FormControl(moment(new Date()).format('YYYY-MM-DD'), [Validators.required]),
    time: new FormControl(new Date().toLocaleTimeString(), [Validators.required]),
    lab_id: new FormControl('', [Validators.required]),
    dispatched_by: new FormControl('', [Validators.required]),
    received_by: new FormControl('', [Validators.required])
  });

  staffList: Array<string> = [];
  filteredStaffList: Observable<string[]>;

  labStaffList: Array<string> = [];
  filteredLabStaffList: Observable<string[]>;

  labListArray: Array<string> = [];
  filteredLabList: Observable<string[]>;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private labBookingService: LabBookingService,
    private labReportService: LabReportService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.bookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getLabList();
    this.getStaffList();
  }

  getStaffList() {
    this.submitInProgress = true;
    this.labBookingService.getStaffList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.staffList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.filteredStaffList = this.setACField(
          this.labDispatchForm.controls.dispatched_by,
          'staffList'
        );
      }
    );
  }

  getLabStaffList(labId: number) {
    this.labBookingService.getLabStaffList(labId).subscribe(
      (response: any) => {
        this.labStaffList = response.data;
      },
      (error) => {
        this.labStaffList.length = 0;
      },
      () => {
        this.filteredLabStaffList = this.setACField(
          this.labDispatchForm.controls.received_by,
          'labStaffList'
        );
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabListForLabReport().subscribe(
      (response: any) => {
        this.labList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.labList = this.labList.filter((d) => d.active === 1);
        this.convertLabListToArray();
        this.submitInProgress = false;
      }
    );
  }

  convertLabListToArray() {
    this.labListArray = this.labList.map((element) => element.name);
    console.log(this.labListArray);
    this.filteredLabList = this.setACField(
      this.labDispatchForm.controls.received_by,
      'labListArray'
    );
  }

  makeChanged() {
    this.formDirty = true;
  }

  dispatchBooking() {
    this.formSubmitted = true;
    this.submitInProgress = true;

    if (this.labDispatchForm.status === 'VALID') {
      const dispatchDate = moment(new Date(this.labDispatchForm.controls.date.value)).format(
        'YYYY-MM-DD'
      );
      const dispatchTime = this.labDispatchForm.controls.time.value;
      const data = {
        date: dispatchDate.trim(),
        time: moment(dispatchDate + ' ' + dispatchTime, 'YYYY-MM-DD hh:mm a').format('hh:mm a'),
        lab_id: this.labDispatchForm.controls.lab_id.value,
        dispatched_by: this.labDispatchForm.controls.dispatched_by.value,
        received_by: this.labDispatchForm.controls.received_by.value
      };
      this.labBookingService.dispatchBooking(this.bookingId, data).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          switch (error.status) {
            case 422:
              const errors = error.error.errors;
              if (errors.date) {
                this.setError('date', errors.date[0]);
              } else {
                this.unsetError('date');
              }
              if (errors.time) {
                this.setError('time', errors.time[0]);
              } else {
                this.unsetError('time');
              }
              if (errors.lab_id) {
                this.setError('lab_id', errors.lab_id[0]);
              } else {
                this.unsetError('lab_id');
              }
              if (errors.dispatched_by) {
                this.setError('dispatched_by', errors.dispatched_by[0]);
              } else {
                this.unsetError('dispatched_by');
              }
              if (errors.received_by) {
                this.setError('received_by', errors.received_by[0]);
              } else {
                this.unsetError('received_by');
              }
              this.toastr.error(error.error.message);
              break;
            default:
              this.toastr.error(error.error.message);
          }
        },
        () => {
          this.router.navigate(['/lab-bookings'], { queryParams: { status: 'dispatched' } });
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
