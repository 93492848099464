import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public title = new Subject();
  public patientId = new Subject();
  constructor() {}

  setTitle(title: string) {
    this.title.next(title);
  }

  setPatientId(patientId: any) {
    this.patientId.next(Number(patientId));
  }

  setTitleAndPatientId(title: string, patientId: any) {
    this.setTitle(title);
    this.setPatientId(patientId);
  }
}
