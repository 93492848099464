import { Component, OnInit } from '@angular/core';
import { RegionsService } from '../services/regions.service';
import { UserServiceService } from '../services/user-service.service';

@Component({
  selector: 'app-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.css']
})
export class RegionListComponent implements OnInit {
  userCountryName: string;
  regionList: any = [];
  userType: string;
  userId: number;
  displayedColumns: string[] = ['id', 'name', 'updated_at'];

  constructor(private regionService: RegionsService, private userService: UserServiceService) {}

  ngOnInit() {
    this.userService.getType().subscribe((res) => {
      this.userType = res.data.user_type;
      this.userId = res.data.user_id;
      if (this.userType !== 'country_admin') {
        alert('This page is only accessible to country admin!');
        window.location.href = '';
      }
    });
    this.userService.getCountry().subscribe((res) => {
      this.userCountryName = res.data.user_country;
    });
    this.regionService.getRegionList().subscribe((res) => {
      this.regionList = res.regions;
      console.log(this.regionList);
    });
  }
}
