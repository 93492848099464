import { Component, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { PrintService } from './print.service';

@Component({
  selector: 'app-print-component',
  templateUrl: './print-component.html',
  styleUrls: ['./print-component.css']
})
export class PrintComponent implements AfterViewInit {
  @ViewChild('printContent', { static: false }) printElement: ElementRef;

  constructor(private printcontent: PrintService) {}

  ngAfterViewInit() {
    this.printcontent.dataChange.subscribe((printInfo) => {
      console.log('Print Check-->');
      this.printElement.nativeElement.innerHTML = printInfo;
      setTimeout(() => {
        window.print();
        this.printElement.nativeElement.innerHTML = ' ';
      });
    });
  }
}
