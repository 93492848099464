import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VitalService } from '../../../services/vitals.service';

@Component({
  selector: 'app-vital-list',
  templateUrl: './vital-list.component.html',
  styleUrls: ['./vital-list.component.css']
})
export class VitalListComponent implements OnInit {
  hiddenSort;
  sortBy = '';
  sortOrder = '';
  patientId: number;
  patientVitalFields: Array<any> = [];
  patientVitalData: Array<any> = [];
  searchQuery;
  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vitalService: VitalService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getPatientVitals(this.patientId, this.limit);
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientVitals(this.patientId, this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientVitals(this.patientId, this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientVitals(this.patientId, this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  getPatientVitals(patientId, limit) {
    const page = this.page;
    this.loading = true;
    this.vitalService
      .getPatientVitals(patientId, page, limit, this.sortBy, this.sortOrder)
      .subscribe(
        (response: any) => {
          this.patientVitalFields = response.vital_fields;
          this.patientVitalData = response.vital_data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.patientVitalData.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  showVitalData(patientVitalField, patientVitalData) {
    const vitalData = patientVitalData.data.find((data: any) => {
      return patientVitalField.id === data.vital_list_id;
    });
    const primaryValue = vitalData && vitalData.value ? vitalData.value : 'N/A';
    const secondaryValue =
      vitalData && vitalData.secondary_value ? ' / ' + vitalData.secondary_value : '';
    const uom = vitalData && vitalData.uom ? ' (' + vitalData.uom + ')' : '';
    return primaryValue + secondaryValue + uom;
  }

  editVital(patientVital) {
    if (patientVital.data.length !== 0) {
      const vitalId = patientVital.data[0].patient_vital_id;
      this.router.navigate(['/patients/' + this.patientId + '/vitals/' + vitalId + '/edit']);
    } else {
      console.log('no data for this patient vital!');
      console.log(patientVital);
    }
  }

  clearSearch() {
    this.searchQuery = null;
  }

  sort(sort_by, order) {
    this.hiddenSort = sort_by + '_' + order;
    this.sortBy = sort_by;
    this.sortOrder = order;
    this.getPatientVitals(this.patientId, this.limit);
  }
}
