import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-delivery-detail',
  templateUrl: './delivery-detail.component.html',
  styleUrls: ['./delivery-detail.component.css']
})
export class DeliveryDetailComponent {
  @Input() bookingDetail;

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
