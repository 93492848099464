import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FamilyMember } from '../shared/model/family-members';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FamilyHistoryService {
  private apiUrl = environment.apiUrl; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  familyHistory(patientId: number): Observable<any> {
    const url = this.apiUrl + patientId + '/family-history';
    return this.http.get(url);
  }

  store(patientId, data) {
    console.log(data);
    const url = `${this.apiUrl}` + patientId + '/family-history';
    return this.http.post(url, data, this.httpOptions);
  }

  relationShipList() {
    const url = this.apiUrl + 'relationship-types';
    return this.http.get(url);
  }
}
