import { DialogService } from 'src/app/shared/services/dialog.service';
import { Component, OnInit } from '@angular/core';
import { LabPackageService } from '../../../services/lab-package.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-package-list',
  templateUrl: './lab-package-list.component.html',
  styleUrls: ['./lab-package-list.component.css']
})
export class LabPackageListComponent implements OnInit {
  labPackageList;

  loading = true;
  searchQuery: string;
  total: number;
  page = 1;
  limit = 10;
  status: any;

  constructor(
    private labPackageService: LabPackageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService,
    private dialogService: DialogService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.searchQuery = searchQuery ? searchQuery : null;

    this.getPackageList();
  }

  getPackageList() {
    this.loading = true;
    this.labPackageService
      .getLabPackageList(this.page, this.limit, this.searchQuery, this.status)
      .subscribe(
        (res) => {
          this.labPackageList = res.data;
          this.total = res.size;
        },
        (err) => {
          this.loading = false;
          this.labPackageList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPackageList();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPackageList();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPackageList();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  proceedToSearch(event) {
    event.preventDefault();
    this.page = 1;
    this.searchLabBookings();
  }

  searchLabBookings() {
    this.getPackageList();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  clearSearch() {
    this.searchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchLabBookings();
  }

  viewLabBookings(labPackageId) {
    this.router.navigate(['/lab-packages/' + labPackageId]);
  }
  addParamsToUrl(q, pageNo = null, limit = null) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        q,
        pageNo,
        limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  editlabPackage(labPackage) {
    this.router.navigate(['/lab-packages/' + labPackage.id + '/edit']);
  }

  openConfirmationDialog(labPackage) {
    this.dialogService.confirmDialog({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this Lab Packages?',
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    }).subscribe((result)=>{
      if(result) {
        this.deleteLabPackage(labPackage);
      }
    });
  }

  deleteLabPackage(labPackage) {
    this.labPackageService.delete(labPackage.id).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (err) => {
        this.toastr.error(err.message);
      },
      () => {
        this.removeLabPackageFromList(labPackage);
      }
    );
  }

  removeLabPackageFromList(labPackage) {
    this.labPackageList = this.labPackageList.filter((labPackageObj) => {
      return labPackage.id !== labPackageObj.id;
    });
  }

  getInvestigation(investigationList) {
    let investigationString = '';
    if (investigationList) {
      investigationList.forEach((investigation, index) => {
        investigationString += index === 0 ? investigation.name : ', ' + investigation.name;
      });
      return investigationString;
    }
    return '---';
  }

  updateLabPackageStatus(labPackage, status) {
    this.labPackageService.updateLabPackageStatus(labPackage.id, { status }).subscribe(
      (response: any) => {
        this._notify.show(response.message);
      },
      (error) => {
        this._notify.show(error.error.message);
      },
      () => {
        this.removeLabPackageFromList(labPackage);
      }
    );
  }

  changeStatus(status) {
    this.router.navigate(['/lab-packages'], {
      relativeTo: this.activatedRoute,
      queryParams: { status },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });

    this.status = status;
    this.getPackageList();
  }
}
