import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemReviewService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  getReviewTypeList() {
    const url = this.apiUrl + 'system-review-types';
    return this.http.get(url);
  }

  saveSystemReview(data, patient_id) {
    const url = this.apiUrl + 'patients/' + patient_id + '/system-reviews';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }
}
