import { Component, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-dispatch-details',
  templateUrl: './dispatch-details.component.html',
  styleUrls: ['./dispatch-details.component.css']
})
export class DispatchDetailsComponent {
  @Input() bookingDetail;

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
