import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appValidDate]'
})
export class ValidDateDirective {
  dateRegex = '^[0-9/]+$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.dateRegex).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    var validated = new RegExp(this.dateRegex).test(event.key);
    if (!validated) {
      event.preventDefault();
    }
  }
}
