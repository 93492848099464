import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryCode } from '../shared/model/country-code';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {
  private apiUrl = environment.apiUrl;

  urls = {
    countryCodes: `${this.apiUrl}countries`
  };

  constructor(private http: HttpClient) {}

  getCountryCodes = (): Observable<CountryCode[]> =>
    this.http.get<CountryCode[]>(this.urls.countryCodes);
}
