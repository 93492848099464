import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { AppModalDialogComponent } from './components/app-modal-dialog/app-modal-dialog.component';
import { AllowedCharactersDirective } from './directives/allowed-characters.directive';
import { ValidPhoneNumberDirective } from './directives/valid-phone-number.directive';
import { ToggleWrapperComponent } from './components/toggle-wrapper/toggle-wrapper.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { LabBookingActionsComponent } from './components/lab-booking-actions/lab-booking-actions.component';
import { RestrictAlphabetsDirective } from './directives/restrict-alphabets.directive';
import { ValidDateDirective } from './directives/valid-date.directive';
import { RestrictNumbersDirective } from './directives/restrict-numbers.directive';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeAheadComponent } from './components/type-ahead/type-ahead.component';
import { MatAutocompleteModule, MatFormFieldControl, MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmComponent } from './services/dialog/confirm/confirm.component';

@NgModule({
  declarations: [
    AppModalDialogComponent,
    AllowedCharactersDirective,
    ValidPhoneNumberDirective,
    ToggleWrapperComponent,
    ToastMessageComponent,
    LabBookingActionsComponent,
    RestrictAlphabetsDirective,
    ValidDateDirective,
    RestrictNumbersDirective,
    SearchFieldComponent,
    TypeAheadComponent,
    ConfirmComponent
  ],
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    CommonModule, FormsModule, MatInputModule, MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule],
  exports: [
    AppModalDialogComponent,
    AllowedCharactersDirective,
    ValidPhoneNumberDirective,
    ToggleWrapperComponent,
    ToastMessageComponent,
    LabBookingActionsComponent,
    RestrictAlphabetsDirective,
    ValidDateDirective,
    RestrictNumbersDirective,
    SearchFieldComponent,
    TypeAheadComponent,
    ConfirmComponent
  ],
  providers: [DatePipe, DecimalPipe]
})
export class SharedModule {}
