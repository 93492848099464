import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PhysiotherapyFormComponent } from '../physiotherapy-form/physiotherapy-form.component';
import { PhysiotherapyService } from '../../../../services/physiotherapy.service';

@Component({
  selector: 'app-physiotherapy-list',
  templateUrl: './physiotherapy-list.component.html',
  styleUrls: ['./physiotherapy-list.component.css']
})
export class PhysiotherapyListComponent implements OnInit {
  @Input() clearUrl: boolean;
  physicotherapy;
  physicotherapyList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  physicotherapySearchQuery: string;

  constructor(
    private physicotherapyService: PhysiotherapyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const physicotherapySearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.physicotherapySearchQuery = physicotherapySearchQuery ? physicotherapySearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getPhysicotherapy();
  }

  openModal(physicotherapy) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '500px';
    dialogConfig.width = '600px';
    dialogConfig.data = physicotherapy;

    const dialogRef = this.dialog.open(PhysiotherapyFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getPhysicotherapy();
      }
    });
  }

  getPhysicotherapy(): void {
    this.loading = true;
    this.physicotherapyService
      .getPhysicotherapyList(this.page, this.limit, this.physicotherapySearchQuery)
      .subscribe(
        (response: any) => {
          this.physicotherapyList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.physicotherapyList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchPhysicotherapy();
  }

  searchPhysicotherapy() {
    this.getPhysicotherapy();
    this.addParamsToUrl(this.page, this.limit, this.physicotherapySearchQuery);
  }

  clearSearch() {
    this.physicotherapyList = null;
    this.page = 1;
    this.limit = 10;
    this.physicotherapySearchQuery = null;
    this.searchPhysicotherapy();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPhysicotherapy();
    this.addParamsToUrl(this.page, this.limit, this.physicotherapySearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getPhysicotherapy();
    this.addParamsToUrl(this.page, this.limit, this.physicotherapySearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getPhysicotherapy();
    this.addParamsToUrl(this.page, this.limit, this.physicotherapySearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
