import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../../services/lab-booking.service';
import { MatSnackBar } from '@angular/material';
import { NotifyService } from '../../../../services/notify.service';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-received-create',
  templateUrl: './lab-received-create.component.html',
  styleUrls: ['./lab-received-create.component.css']
})
export class LabReceivedCreateComponent implements OnInit {
  labReceivedReportsForm = new FormGroup({
    receiver: new FormControl('', [Validators.required]),
    designation: new FormControl('', [Validators.required]),
    nhpc: new FormControl('', []),
    qualification: new FormControl('', [Validators.required])
  });
  bookingId: number;
  bookingDetail: any;

  // For Form Footer
  cancelUrl = '/lab-bookings';
  submitInProgress = false;

  formSubmitted = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private notification: MatSnackBar,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.bookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getBookingDetail(this.bookingId);
  }

  getBookingDetail(bookingId) {
    this.submitInProgress = true;
    this.labBookingService.getBookingDetail(bookingId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.bookingDetail = response.booking;
      },
      (error) => {
        this.submitInProgress = false;
        console.log('could not fetch booking at the moment');
      }
    );
  }

  parse(object) {
    return object && JSON.parse(object) ? JSON.parse(object).join(', ') : 'No Tests';
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  storeLabRecevied() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    const bookingId = this.bookingId;
    if (this.labReceivedReportsForm.valid) {
      this.labBookingService
        .storeLabRecevied(this.labReceivedReportsForm.value, bookingId)
        .subscribe(
          (res) => {
            this.submitInProgress = false;
            this.toastr.success('Lab Received added successfully!');
            this.router.navigate(['/lab-bookings'], { queryParams: { status: 'lab_received' } });
          },
          (err) => {
            this.submitInProgress = false;
            this.toastr.error('Sorry! Lab Received could not be added at the moment!');
          }
        );
    }
    this.submitInProgress = false;
  }

  getAddress(address) {
    return address
      ? address.province +
          ', ' +
          address.city +
          ',' +
          address.address +
          '<br>' +
          address.nearest_landmark
      : '';
  }
}
