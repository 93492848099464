import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicationService } from '../../../services/medication.service';

@Component({
  selector: 'app-medication-list',
  templateUrl: './medication-list.component.html',
  styleUrls: ['./medication-list.component.css']
})
export class MedicationListComponent implements OnInit {
  patientId: number;
  patientMedications: Array<any>;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  medicationSearchQuery: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private medicationService: MedicationService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const medicationSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.medicationSearchQuery = medicationSearchQuery ? medicationSearchQuery : null;

    this.getPatientMedication(this.limit);
  }

  getPatientMedication(limit) {
    this.loading = true;
    this.medicationService.getPatientMedicationList(this.patientId, this.page, limit, this.medicationSearchQuery).subscribe(
      (response: any) => {
        this.patientMedications = response.data;
        this.patientMedications.forEach((referral: any) => {
          referral.expandIcon = 'chevron-down';
          referral.expandMessage = 'Expand Details';
        });
        this.total = response.size;
      },
      (error) => {
        this.patientMedications.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchMedications();
  }

  searchMedications() {
    this.getPatientMedication(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.medicationSearchQuery);
  }

  clearSearch() {
    this.medicationSearchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchMedications();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientMedication(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientMedication(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientMedication(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(referral, event) {
    event.preventDefault();
    referral.expanded = !referral.expanded;
    if (referral.expanded) {
      referral.expandIcon = 'chevron-up';
      referral.expandMessage = 'Collapse Details';
    } else {
      referral.expandIcon = 'chevron-down';
      referral.expandMessage = 'Expand Details';
    }
  }

  editMedication(referralId, event) {
    event.stopPropagation();
    this.router.navigate(['/patients/' + this.patientId + '/medications/' + referralId + '/edit']);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
