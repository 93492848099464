import { Component, OnInit } from '@angular/core';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { StorageService } from '../../../services/storage.service';
import moment from 'moment';

@Component({
  selector: 'app-my-schedule',
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.css']
})
export class MyScheduleComponent implements OnInit {
  todayDayNumber;
  type;
  showData = false;
  timeSlots: any;
  bookedTimeList = [];
  scheduleTimeList = [];
  scheduleTimeId = [];
  bookedTimeId = [];
  loggedInUserId;
  constructor(
    private myAppointmentService: MyAppointmentService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.todayDayNumber = moment().day();
    this.getData(this.todayDayNumber);
    const loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    this.loggedInUserId = loggedInUser.id;
    this.getTimeSlots();
    this.getBookedTimeList();
    this.getSchedule();
  }

  counter(i: number) {
    return new Array(i);
  }

  getTimeSlots() {
    this.myAppointmentService.getTimeSlots().subscribe(
      (res: any) => {
        this.timeSlots = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.showData = true;
      }
    );
  }

  getSchedule() {
    this.myAppointmentService.getScheduleTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.scheduleTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {
        if (this.scheduleTimeList) {
          this.getScheduleId();
        }
      }
    );
  }
  getScheduleId() {
    this.scheduleTimeId = [];
    this.scheduleTimeList.forEach((bookedTime) => {
      if (this.type === bookedTime.day) {
        this.scheduleTimeId.push(bookedTime.consultation_time_id);
      }
    });
  }
  getBookedTimeList() {
    this.myAppointmentService.getBookedTimeList(this.loggedInUserId).subscribe(
      (res: any) => {
        this.bookedTimeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {
        if (this.bookedTimeList) {
          this.getBookedId();
        }
      }
    );
  }

  getBookedId() {
    this.bookedTimeId = [];
    this.bookedTimeList.forEach((bookedTime) => {
      const dayName = moment(bookedTime.date).format('dddd').toLowerCase();
      if (this.type === dayName) {
        this.bookedTimeId.push(bookedTime.consultation_time_id);
      }
    });
  }

  getDayName(n) {
    return moment().weekday(n).format('dddd');
  }

  getData(n) {
    this.type = moment().weekday(n).format('dddd').toLowerCase();
    this.getBookedTimeList();
    this.getSchedule();
  }

  getActiveStatus(n) {
    return moment().weekday(n).format('dddd').toLowerCase() === this.type ? 'active' : '';
  }
}
