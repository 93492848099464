import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanDeactivate,
  CanActivate
} from '@angular/router';
import { Observable } from 'rxjs';
import { VideoChatComponent } from '../video-chat-component/video-chat.component';
import { AuthService } from 'src/app/auth/shared/services/auth.service';
import { ToastMessageService } from 'src/app/shared/services/toast-message-service/toast-message.service';
import { SharedConstant } from 'src/app/shared/constant/shared-constant';
import { CallService } from '../service/call.service';

@Injectable({
  providedIn: 'root'
})
export class VideoChatGuard implements CanActivate, CanDeactivate<VideoChatComponent> {
  sharedConstant = SharedConstant;
  constructor(
    private auth: AuthService,
    private toastMessageService: ToastMessageService,
    private callService: CallService
  ) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.auth.isVoIPLoginSuccess() === true) {
      return true;
    }
    return false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    //return confirm('Are you sure to navigate Back');
    return true;
  }
}
