import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../../services/lab-booking.service';

@Component({
  selector: 'app-lab-report-detail',
  templateUrl: './lab-report-detail.component.html',
  styleUrls: ['./lab-report-detail.component.css']
})
export class LabReportDetailComponent implements OnInit {
  bookingId: number;
  labReport: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService
  ) {}

  ngOnInit() {
    this.bookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getLabReportDetail(this.bookingId);
  }

  getLabReportDetail(bookingId) {
    this.labBookingService.getLabReport(bookingId).subscribe((response: any) => {
      this.labReport = response.booking;
    });
  }

  parse(object) {
    return object && JSON.parse(object) ? JSON.parse(object).join(', ') : false;
  }
}
