import { Component, OnInit, OnDestroy } from '@angular/core';
import { CallLogService } from '../../../services/call-log.service';
import { CallService } from '../../../video-chat/service/call.service';
import { DashboardService } from '../../../services/dashboard.service';
import { Router } from '@angular/router';
import { STARTCALL } from '../../../shared/types/custom-types';
import { AuthService } from 'src/app/auth/shared/services/auth.service';
import { DateService } from '../../../shared/date.service';
import { interval } from 'rxjs/internal/observable/interval';
import { startWith, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-call-log-list',
  templateUrl: './call-log-list.component.html',
  styleUrls: ['./call-log-list.component.css']
})
export class CallLogListComponent implements OnInit, OnDestroy {
  PING_INTERVAL = 30;
  callLogs: Array<any> = [];
  activeIds: any = [];
  callLogResponse: Array<any>;
  CALL_LOG_DISPLAY_LIMIT = 10;
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  pingPatientSubscription: Subscription;

  callLogIconPath = {
    incoming: '/calls/incoming-call.svg',
    outgoing: '/calls/outgoing-call.svg',
    missed: '/calls/missed-call.svg'
  };

  constructor(
    private callLogService: CallLogService,
    private dashboardService: DashboardService,
    private callService: CallService,
    private dateService: DateService,
    private router: Router,
    private auth: AuthService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.getCallLogs();
    this.pingPatient();
    this.callService.callRejectEmmitter.subscribe((res) => {
      this.getCallLogs();
      this.pingPatient();
    });
  }

  pingPatient() {
    this.pingPatientSubscription = this.callLogService.getPingPatientList().subscribe(
      (res: any) => {
        this.activeIds = res.data.map((x) => {
          return x.id;
        });
        // console.log(this.activeIds);
        this.callLogs.forEach((log) => {
          log.active = this.checkActive(log.id);
        });
      },
      (error) => {}
    );
  }

  checkActive(id) {
    // console.log('ids', id, this.activeIds, this.activeIds.indexOf(id));
    // if (this.activeIds.indexOf(id !== -1)) {
    if (this.activeIds.includes(id)) {
      return true;
    }
    return false;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getCallLogs();
  }

  onNext(): void {
    this.page++;
    this.getCallLogs();
  }

  onPrev(): void {
    this.page--;
    this.getCallLogs();
  }

  getCallLogs() {
    // this.loading = true;
    this.limit = this.CALL_LOG_DISPLAY_LIMIT;
    this.callLogService.getCallLogList(this.page, this.limit).subscribe(
      (response: any) => {
        this.callLogResponse = response.data;
        this.sortCallLogs();
        this.total = response.size;
      },
      (error) => {
        console.log('could not fetch booking list at the moment!');
        this.loading = false;
        this.callLogs.length = 0;
      },
      () => {
        // console.log(this.callLogs);
        this.loading = false;
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  sortCallLogs() {
    this.callLogs = [];
    this.callLogResponse.forEach((callLog) => {
      const authUser = JSON.parse(this.storageService.getLoggedInUser());
      const isThisUserSender = authUser.id === callLog.sender_id;
      const userId = isThisUserSender ? callLog.receiver_id : callLog.sender_id;
      const logObj = {
        id: userId,
        name: isThisUserSender ? callLog.receiver.name : callLog.sender.name,
        connectycube_user_id: isThisUserSender
          ? callLog.receiver.connectycube_user_id
          : callLog.sender.connectycube_user_id,
        email: isThisUserSender ? callLog.receiver.email : callLog.sender.email,
        isMissed:
          callLog.started_at === null && callLog.ended_at === null && callLog.initiated_at !== null,
        started_at: moment(callLog.started_at).utc(true).format('ddd, MMM DD hh:mm a'),
        initiated_at: moment(callLog.initiated_at).utc(true).format('ddd, MMM DD hh:mm a'),
        type: isThisUserSender ? 'outgoing' : 'incoming',
        patient_id: callLog.patient_id,
        time: this.dateService.getTimeDiff(callLog.initiated_at, callLog.ended_at),
        active: this.checkActive(userId)
      };
      this.callLogs.push(logObj);
    });
  }

  startCall(patientDetail): void {
    const patientDetails = [];
    patientDetails.push({
      id: patientDetail.connectycube_user_id,
      name: patientDetail.name,
      patient_id: patientDetail.patient_id
    });
    const patientDetailList = JSON.stringify(patientDetails);

    this.router.navigate(['/video', STARTCALL], { queryParams: { patientDetailList } });
  }

  isCallServiceInit(): boolean {
    return this.auth.isVoIPLoginSuccess();
  }

  ngOnDestroy(): void {
    this.pingPatientSubscription.unsubscribe();
  }
}
