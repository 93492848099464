import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
import * as _moment from 'moment';

@Component({
  selector: 'app-date-picker-month-year',
  templateUrl: './date-picker-month-year.component.html',
  styleUrls: ['./date-picker-month-year.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerMonthYearComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() initialDate;
  @Input() clearDateValue;
  @Output() chooseDate: EventEmitter<any> = new EventEmitter<any>();
  date = new FormControl();

  ngOnInit() {
    if (this.initialDate) {
      this.date.setValue(this.initialDate);
    } else {
      this.clearDate();
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    console.log(changes);
    if (changes.clearDateValue.currentValue) {
      this.clearDate();
    }
  }
  clearDate() {
    this.date.reset();
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.chooseDate.emit(event);
  }
}
