import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ServiceBookingService } from 'src/app/services/service-booking.service';

@Component({
  selector: 'app-service-booking-detail',
  templateUrl: './service-booking-detail.component.html',
  styleUrls: ['./service-booking-detail.component.css']
})
export class ServiceBookingDetailComponent implements OnInit {
  serviceBookingId: string;
  serviceBookingDetail: any;
  firstName: string;
  middleName: string;
  lastName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private serviceBookingService: ServiceBookingService
  ) {}

  ngOnInit() {
    this.serviceBookingId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getServiceBookingDetail(this.serviceBookingId);
  }

  getName(serviceBookingDetail: any) {
    this.firstName = serviceBookingDetail.patient.first_name;
    this.middleName = serviceBookingDetail.patient.middle_name;
    this.lastName = serviceBookingDetail.patient.last_name;
  }

  getServiceBookingDetail(serviceBookingId) {
    this.serviceBookingService.getServiceBookingDetail(serviceBookingId).subscribe(
      (response: any) => {
        this.serviceBookingDetail = response;
      },
      (error) => {
        console.log('error fetching Service Booking data');
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  titleCase(str) {
    if (!str) {
      return;
    }
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  getAddress(addressString) {
    const address = JSON.parse(addressString);
    return address
      ? address.province +
          ', ' +
          address.city +
          '<br>' +
          address.address +
          ', ' +
          address.nearest_landmark
      : 'N/A';
  }

  formatNote(note) {
    return note ? note.replace(/(\r\n|\n|\r)/gm, '<br />') : '---';
  }
}
