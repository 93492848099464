import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class PhysiotherapyService {
  private apiUrl = environment.apiUrl; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getPhysiothreapyList(pageNo, limit, status = null, q, recommenderName) {
    const url = this.apiUrl + 'physiotherapy';
    const data: any = {
      pageNo: pageNo || '',
      limit: limit || '',
      status: status || '',
      q: q || '',
      recommended_by: recommenderName || ''
    }
    const params = FormDataUtil.filterEmptyParams(data);
    return this.http.get(url, { params });
  }

  getStatusList() {
    return this.http.get(`${this.apiUrl}physiotherapy/statuses`);
  }

  updateStatus(physiotherapyId, data) {
    return this.http.put(`${this.apiUrl}physiotherapy/${physiotherapyId}/status/`, data);
  }

  getPhysicotherapyList(pageNo, limit, q) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q });
    return this.http.get(`${this.apiUrl}physiotherapy-types`, { params });
  }

  getPhysiotherapyDetail(physiotherapyId: number) {
    return this.http.get(this.apiUrl + 'physiotherapy/' + physiotherapyId);
  }

  savePhysiotherapyType(data, physiotherapyId = null) {
    if (physiotherapyId) {
      return this.http.post(
        `${this.apiUrl}physiotherapy-types/${physiotherapyId}`,
        data,
        this.httpOptions
      );
    } else {
      return this.http.post(`${this.apiUrl}physiotherapy-types`, data, this.httpOptions);
    }
  }
}
