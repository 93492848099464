import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PasswordComponent } from './password.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetComponent } from './reset/reset.component';
import { SetComponent } from './set/set.component';

const routes: Routes = [
  {
    path: '',
    component: PasswordComponent,
    children: [
      {
        path: 'forgot',
        component: ForgotComponent
      },
      {
        path: 'reset/:token',
        component: ResetComponent
      },
      {
        path: 'set',
        component: SetComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  bootstrap: [PasswordComponent]
})
export class PasswordRoutingModule {}
