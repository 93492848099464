import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient, Prefix } from '../../shared/model/patient';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { PatientTypeList } from '../../shared/model/patientTypeList';
import { RelationList } from '../../shared/model/relationList';
import * as moment from 'moment';
import { PageService } from '../workflow-forms/page.service';
import { NotifyService } from '../../services/notify.service';
import { TransformUtil } from '../shared/utility/transform.util';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/shared/model/country-code';
import { CountryCodeService } from 'src/app/services/country-code.service';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.css']
})
export class PatientEditComponent implements OnInit {
  MOBILE_PATTERN = '^\\d{10}$';
  PHONE_PATTERN = '^\\d{9,10}$';
  PHONE_MAX_LENGTH = 10;
  PHONE_MIN_LENGTH = 9;
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;
  prefixList: Prefix[];
  nepalCountryCode: CountryCode = new CountryCode();
  countryCodes: CountryCode[];

  VALIDATION_MESSAGES = [
    {
      name: 'first_name',
      error_type: 'required',
      message: 'First Name is required'
    },
    {
      name: 'first_name',
      error_type: 'maxLength',
      message: 'First Name can be no more than 255 characters'
    },
    {
      name: 'middle_name',
      error_type: 'maxLength',
      message: 'Middle Name can be no more than 255 characters'
    },
    {
      name: 'last_name',
      error_type: 'required',
      message: 'Last Name is required'
    },
    {
      name: 'last_name',
      error_type: 'maxLength',
      message: 'Last Name can be no more than 255 characters'
    },
    {
      name: 'patient_type_id',
      error_type: 'required',
      message: 'Patient Type is required'
    },
    {
      name: 'gender',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'dob',
      error_type: 'required',
      message: 'Date of Birth is required'
    },
    {
      name: 'dob',
      error_type: 'max',
      message: 'Date of Birth cannot exceed today'
    },
    {
      name: 'address',
      error_type: 'required',
      message: 'Address is required'
    },
    {
      name: 'address',
      error_type: 'maxLength',
      message: 'Address can be no more than 255 Characters'
    },
    {
      name: 'country_code',
      error_type: 'required',
      message: 'Country Code is required'
    },
    {
      name: 'country_code',
      error_type: 'match_not_found',
      message: 'please select an option'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be at least ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'phone',
      error_type: 'minLength',
      message: 'Phone Number should be of minimum ' + this.PHONE_MIN_LENGTH + ' characters'
    },
    {
      name: 'phone',
      error_type: 'maxLength',
      message: 'Phone Number can be no more than ' + this.PHONE_MAX_LENGTH + ' characters'
    },
    {
      name: 'phone',
      error_type: 'pattern',
      message: 'Enter a Valid Phone Number'
    },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is Required'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'minLength',
      message: 'Secondary Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'maxLength',
      message:
        'Secondary Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'pattern',
      message: 'Enter a Valid Secondary Mobile Number'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'minLength',
      message: 'Relative Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'maxLength',
      message:
        'Relative Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'pattern',
      message: 'Enter a Valid Relative Mobile Number'
    },
    {
      name: 'status',
      error_type: 'required',
      message: 'Status is required'
    },
    {
      name: 'prefix',
      error_type: 'required',
      message: 'Prefix is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'first_name',
      error: false
    },
    {
      controlName: 'middle_name',
      error: false
    },
    {
      controlName: 'last_name',
      error: false
    },
    {
      controlName: 'patient_type_id',
      error: false
    },
    {
      controlName: 'blood_group',
      error: false
    },
    {
      controlName: 'gender',
      error: false
    },
    {
      controlName: 'dob',
      error: false
    },
    {
      controlName: 'address',
      error: false
    },
    {
      controlName: 'phone',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'secondary_mobile_no',
      error: false
    },
    {
      controlName: 'relation_name',
      error: false
    },
    {
      controlName: 'relation_id',
      error: false
    },
    {
      controlName: 'relative_mobile_no',
      error: false
    },
    {
      controlName: 'status',
      error: false
    }
  ];

  todayDate = new Date();

  editPatientForm = new FormGroup({
    prefix: new FormControl(''),
    first_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    middle_name: new FormControl('', [Validators.maxLength(255)]),
    last_name: new FormControl('', [Validators.maxLength(255)]),
    patient_type_id: new FormControl('', [Validators.required]),
    blood_group: new FormControl(''),
    gender: new FormControl(''),
    dob: new FormControl(''),
    address: new FormControl('', [Validators.maxLength(255)]),
    phone: new FormControl('', [
      Validators.minLength(this.PHONE_MIN_LENGTH),
      Validators.maxLength(this.PHONE_MAX_LENGTH),
      Validators.pattern(this.PHONE_PATTERN)
    ]),
    country_code: new FormControl(''),
    mobile: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH)
    ]),
    email: new FormControl('', [Validators.email]),
    secondary_mobile_no: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    relation_name: new FormControl(''),
    relation_id: new FormControl(''),
    relative_mobile_no: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    status: new FormControl('', [Validators.required])
  });
  formSubmitted = false;

  urlList: Array<string> = [];
  nextUrl: string;

  patientTypeLists: PatientTypeList[];

  relationLists: RelationList[];

  model: any;
  result: any;

  patientId: number;

  // For Save and Continue
  saveAndContinueClicked: boolean = false;

  bloodGroups = [
    {
      value: 'A+ve',
      name: 'A +ve'
    },
    {
      value: 'B+ve',
      name: 'B +ve'
    },
    {
      value: 'AB+ve',
      name: 'AB +ve'
    },
    {
      value: 'O+ve',
      name: 'O +ve'
    },
    {
      value: 'A-ve',
      name: 'A -ve'
    },
    {
      value: 'B-ve',
      name: 'B -ve'
    },
    {
      value: 'AB-ve',
      name: 'AB -ve'
    },
    {
      value: 'O-ve',
      name: 'O -ve'
    },
    {
      value: 'N/A',
      name: 'N/A'
    }
  ];

  patientStatus = [
    {
      value: 'active',
      name: 'Active'
    },
    {
      value: 'discharged',
      name: 'Discharged'
    },
    {
      value: 'service-discontinued',
      name: 'Service Discontinued'
    },
    {
      value: 'hospitalized',
      name: 'Hospitalized'
    },
    {
      value: 'dead',
      name: 'Dead'
    }
  ];

  user_active = [
    {
      value: 1,
      name: 'Verified'
    },
    {
      value: 0,
      name: 'Not Verified'
    }
  ];

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  hasChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService,
    private patientEdit: FormBuilder,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil,
    private countryCodeService: CountryCodeService
  ) {}

  ngOnInit() {
    this.patientId = this.patientService.getId(this.route);
    this.page.setTitleAndPatientId('Edit Patient', this.patientId);
    this.getCountryCodes();
    this.getPatient();
    this.getPatientTypeList();
    this.getRelationList();
    this.getPrefixList();

    this.patientService.getPatientMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(children.url.replace(/{patientId}/g, String(this.patientId)));
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getPrefixList() {
    this.patientService.getPrefixList().subscribe((prefixList: Prefix[]) => {
      this.prefixList = prefixList;
    });
  }

  getCountryCodes() {
    this.countryCodeService.getCountryCodes().subscribe(
      (response: CountryCode[]) => {
        this.countryCodes = response;
        response.map((x) => {
          if (x.name == 'Nepal') {
            this.nepalCountryCode = x;
          }
        });
      }
    );
  }

  getPatientTypeList(): void {
    this.patientService.getPatientTypeList().subscribe((patientTypeLists) => {
      this.patientTypeLists = patientTypeLists;
    });
  }

  getRelationList(): void {
    this.patientService.getRelationList().subscribe((relationLists) => {
      this.relationLists = relationLists;
    });
  }

  getPatient(): void {
    this.submitInProgress = true;
    const id = this.transform.toNumber(this.route.snapshot.paramMap.get('id'));
    this.patientService.getPatient(id).subscribe(
      (data) => {
        this.model = data;
        this.editPatientForm.patchValue({
          prefix: data.prefix,
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          patient_type_id: data.patient_type_id,
          blood_group: data.blood_group,
          gender: data.gender,
          dob: data.dob,
          address: data.address,
          phone: data.phone,
          country_code: data.country_code,
          mobile: data.mobile,
          secondary_mobile_no: data.secondary_mobile_no,
          relation_name: data.relation_name,
          relation_id: data.relation_id,
          relative_mobile_no: data.relative_mobile_no,
          status: data.status,
          patientId: data.id,
          email: data.email
        });
        this.submitInProgress = false;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.setMobileValidation(this.model.country_code);
        if (this.model.user_active !== null) {
          this.editPatientForm.addControl('user_active', new FormControl());
          this.editPatientForm.patchValue({
            user_active: this.model.user_active
          });
        }
      }
    );
  }

  savePatient(patient: Patient): void {
    this.formSubmitted = true;
    this.submitInProgress = true;
    this.checkForFormChange();
    if (this.editPatientForm.status === 'VALID' && this.hasChange) {
      const data = this.editPatientForm.getRawValue();
      data.dob = data.dob ? moment(data.dob, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD') : null;
      this.patientService.save(data, this.model.id).subscribe(
        (response: any) => {
          if (response) {
            this.result = response;
            this.toastr.success(response.message);
          }
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setMobileValidation(e) {
    this.unsetBackendValidationError('mobile');
    e === 977 ? this.MOBILE_MAX_LENGTH = 10 : this.MOBILE_MAX_LENGTH = 15;
    this.editPatientForm.get('mobile').setValidators([
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.minLength(this.MOBILE_MIN_LENGTH)
    ]);
    this.editPatientForm.get('mobile').updateValueAndValidity();
  }

  isMobileRequired() {
    return this.editPatientForm.get('country_code').value === 977 ? true : false;
  }

  checkForFormChange() {
    const FormControlName = this.editPatientForm.controls;
    for (let name in FormControlName) {
      if (this.editPatientForm.get(name).value !== this.model[name]) {
        this.hasChange = true;
        return;
      }
    }
    this.toastr.info('No changes to save');
  }

  setBackendValidationErrors(errors) {
    if (errors.first_name) {
      this.setBackendValidationError('first_name', errors.first_name[0]);
    } else {
      this.unsetBackendValidationError('first_name');
    }
    if (errors.middle_name) {
      this.setBackendValidationError('middle_name', errors.middle_name[0]);
    } else {
      this.unsetBackendValidationError('middle_name');
    }
    if (errors.last_name) {
      this.setBackendValidationError('last_name', errors.last_name[0]);
    } else {
      this.unsetBackendValidationError('last_name');
    }
    if (errors.patient_type_id) {
      this.setBackendValidationError('patient_type_id', errors.patient_type_id[0]);
    } else {
      this.unsetBackendValidationError('patient_type_id');
    }
    if (errors.blood_group) {
      this.setBackendValidationError('blood_group', errors.blood_group[0]);
    } else {
      this.unsetBackendValidationError('blood_group');
    }
    if (errors.gender) {
      this.setBackendValidationError('gender', errors.gender[0]);
    } else {
      this.unsetBackendValidationError('gender');
    }
    if (errors.dob) {
      this.setBackendValidationError('dob', errors.dob[0]);
    } else {
      this.unsetBackendValidationError('dob');
    }
    if (errors.address) {
      this.setBackendValidationError('address', errors.address[0]);
    } else {
      this.unsetBackendValidationError('address');
    }
    if (errors.phone) {
      this.setBackendValidationError('phone', errors.phone[0]);
    } else {
      this.unsetBackendValidationError('phone');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.secondary_mobile_no) {
      this.setBackendValidationError('secondary_mobile_no', errors.secondary_mobile_no[0]);
    } else {
      this.unsetBackendValidationError('secondary_mobile_no');
    }
    if (errors.relation_name) {
      this.setBackendValidationError('relation_name', errors.relation_name[0]);
    } else {
      this.unsetBackendValidationError('relation_name');
    }
    if (errors.relation_id) {
      this.setBackendValidationError('relation_id', errors.relation_id[0]);
    } else {
      this.unsetBackendValidationError('relation_id');
    }
    if (errors.relative_mobile_no) {
      this.setBackendValidationError('relative_mobile_no', errors.relative_mobile_no[0]);
    } else {
      this.unsetBackendValidationError('relative_mobile_no');
    }
    if (errors.status) {
      this.setBackendValidationError('status', errors.status[0]);
    } else {
      this.unsetBackendValidationError('status');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
