import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lab-received-detail',
  templateUrl: './lab-received-detail.component.html',
  styleUrls: ['./lab-received-detail.component.css']
})
export class LabReceivedDetailComponent {
  @Input() bookingDetail;
}
