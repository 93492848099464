import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  printInfo = new Subject<any>();

  constructor() {}

  set pushData(printInfo: any) {
    console.log('Print Service Push Data');
    this.printInfo.next(printInfo);
  }

  get dataChange(): Observable<any> {
    return this.printInfo.pipe();
  }
}
