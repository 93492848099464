import { Component, OnInit, Input } from '@angular/core';
import { NotifyService } from '../../../services/notify.service';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-schedule-card',
  templateUrl: './schedule-card.component.html',
  styleUrls: ['./schedule-card.component.css']
})
export class ScheduleCardComponent implements OnInit {
  @Input() timeSlots;
  @Input() weekDay;
  @Input() bookedTimeIdList;
  @Input() selectedTimeList;
  submitInProgress = false;
  morningList = [];
  afternoonList = [];
  eveningList = [];
  constructor(private toastr: ToastrService, private myAppointmentService: MyAppointmentService) {}

  ngOnInit() {
    this.mapTimeSlots();
  }

  mapTimeSlots() {
    this.morningList = this.timeSlots.filter((timeSlot) => {
      return timeSlot.category === 'morning';
    });

    this.afternoonList = this.timeSlots.filter((timeSlot) => {
      return timeSlot.category === 'afternoon';
    });

    this.eveningList = this.timeSlots.filter((timeSlot) => {
      return timeSlot.category === 'evening';
    });
  }

  getDisableStatus(id) {
    return this.bookedTimeIdList.includes(id) ? 'disabled' : '';
  }

  selectSchedule(selectedId) {
    if (this.bookedTimeIdList.includes(selectedId)) {
      this.toastr.warning('This time is already booked');
    } else {
      if (this.selectedTimeList.includes(selectedId)) {
        this.selectedTimeList = this.selectedTimeList.filter((timeId) => timeId !== selectedId);
      } else {
        this.selectedTimeList.push(selectedId);
      }
    }
  }

  checkSelectedId(selectedId) {
    return this.selectedTimeList.includes(selectedId) ? 'selected-time' : '';
  }

  saveSchedule() {
    if (this.selectedTimeList) {
      const request = [
        {
          week_day: this.weekDay,
          consultation_time_ids: this.selectedTimeList
        }
      ];

      this.myAppointmentService.saveSchedule(request).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    } else {
      this.toastr.warning('Please Select the Time Slot');
    }
  }
}
