import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LabReportService } from '../../../services/lab-report.service';
import { LabPackageService } from '../../../services/lab-package.service';
import { NotifyService } from '../../../services/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-package-create',
  templateUrl: './lab-package-create.component.html',
  styleUrls: ['./lab-package-create.component.css']
})
export class LabPackageCreateComponent implements OnInit {
  labPackageForm = new FormGroup({
    package_name: new FormControl('', [Validators.required]),
    package_price: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+(.[0-9]{1,2})?$')
    ]),
    lab_id: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    notice: new FormControl(''),
    actual_price: new FormControl({ value: '0', disabled: true })
  });
  diagnosticCenters: any[] = [];
  labPackageId: number;
  labPackage;
  testTypes;
  selectedInvestigations: Array<any> = [];
  suggestedInvestigations: Array<any> = [];
  displaySuggestionList = false;

  investigationKeyword = '';
  hasInvestigations = false;

  formSubmitted = false;
  formDirty = false;

  // For Form Footer
  cancelUrl = '/lab-packages';
  submitInProgress = false;

  formErrorObj: Array<any> = [
    {
      controlName: 'package_name',
      error: false
    },
    {
      controlName: 'package_price',
      error: false
    },
    {
      controlName: 'description',
      error: false
    },
    {
      controlName: 'notice',
      error: false
    },
    {
      controlName: 'lab_id',
      error: false
    },
    {
      controlName: 'packages',
      error: false
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private labPackageService: LabPackageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getLabList();

    this.activatedRoute.params.subscribe((params) => {
      this.labPackageId = params.labPackageId;
    });
    if (this.labPackageId) {
      this.getLabPackage(this.labPackageId);
    }
  }

  getLabPackage(labPackageId) {
    this.labPackageService.getLabPackage(labPackageId).subscribe(
      (labPackage) => {
        this.labPackage = labPackage.data;
      },
      (err) => {
        this.toastr.error(err.message);
      },
      () => {
        this.setLabPackageData();
      }
    );
  }

  getName(labId) {
    const labName = this.diagnosticCenters.find(
      (diagnosticCenter) => diagnosticCenter.id === labId
    );
    return labName.name;
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabList().subscribe(
      (y: any) => {
        this.diagnosticCenters = y.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
        if (!this.labPackageId) {
          this.labPackageForm.patchValue({
            lab_id: this.getLabId('Health at Home')
          });
        }
      }
    );
  }

  getLabId(diagnosticName) {
    const obj = this.diagnosticCenters.find(
      (diagnosticCenter) => diagnosticCenter.name.toLowerCase() === diagnosticName.toLowerCase()
    );
    return obj.id;
  }
  setLabPackageData() {
    this.labPackageForm.patchValue({
      package_name: this.labPackage.package_name,
      package_price: this.labPackage.package_price,
      description: this.labPackage.description,
      lab_id: this.labPackage.lab_id,
      notice: this.labPackage.notice,
      actual_price: this.labPackage.actual_price
    });
    this.setInvestigations();
  }
  setInvestigations() {
    this.selectedInvestigations = this.labPackage.packages;
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
    this.calculateTotalPrice();
  }
  showSuggestions() {
    const keyword = this.investigationKeyword;
    if (keyword.length > 2) {
      this.labReportService
        .getInvestigationServiceList(
          this.labPackageForm.controls.lab_id.value,
          keyword,
          this.getIdArrayOfObjects(this.selectedInvestigations)
        )
        .subscribe((investigations: any) => {
          this.suggestedInvestigations = investigations;
          this.displaySuggestionList = true;
        });
    } else {
      this.displaySuggestionList = false;
    }
  }
  changeTestedSelected() {
    this.calculateTotalPrice();
  }
  getIdArrayOfObjects(arrayOfObjects: Array<any>) {
    const arr = [];
    arrayOfObjects.forEach((obj: any) => {
      arr.push({
        id: obj.id
      });
    });
    return arr;
  }

  selectInvestigation(investigation) {
    investigation.tests = [];
    investigation.note = '';
    this.selectedInvestigations.push(investigation);
    this.displaySuggestionList = false;
    this.investigationKeyword = '';

    this.labReportService.getTestTypeServiceList(investigation.id).subscribe(
      (testTypes: any) => {
        this.testTypes = testTypes;
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.testTypes.forEach((testType) => {
          testType.selected = true;
          investigation.tests.push(testType);
        });
        this.calculateTotalPrice();
      }
    );
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }
  calculateTotalPrice() {
    let totalPrice = 0;
    for (const investigation of this.selectedInvestigations) {
      investigation.tests.forEach((test) => {
        console.log('test', test);
        if (test.selected) {
          totalPrice += test.price;
        }
      });
    }
    this.labPackageForm.patchValue({ actual_price: totalPrice });
  }
  unselectInvestigation(investigation) {
    this.selectedInvestigations = this.selectedInvestigations.filter((selectedInvestigation) => {
      return (
        this.selectedInvestigations.indexOf(investigation) !==
        this.selectedInvestigations.indexOf(selectedInvestigation)
      );
    });
    this.calculateTotalPrice();
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  submit() {
    this.formSubmitted = true;
    this.submitInProgress = true;

    if (this.selectedInvestigations.length !== 0 && this.labPackageForm.status === 'VALID') {
      const data = this.labPackageForm.value;
      data.packages = this.buildRequestInvestigation();
      if (this.labPackageId) {
        this.updatePackage(data);
      } else {
        this.savePackage(data);
      }
    } else {
      this.submitInProgress = false;
    }
  }

  clearInvestigation(lab_id?: number) {
    this.selectedInvestigations = this.selectedInvestigations.filter((it) => it.lab_id == lab_id);
    this.calculateTotalPrice();
  }
  updatePackage(data) {
    this.labPackageService.update(data, this.labPackageId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        this.toastr.error(error.error.message);
        if (error.status === 422) {
          const errors = error.error.errors;
          if (errors.package_name) {
            this.setError('package_name', errors.package_name[0]);
          } else {
            this.unsetError('package_name');
          }
          if (errors.package_price) {
            this.setError('package_price', errors.package_price[0]);
          } else {
            this.unsetError('package_price');
          }
          if (errors.description) {
            this.setError('description', errors.description[0]);
          } else {
            this.unsetError('description');
          }
          if (errors.notice) {
            this.setError('notice', errors.notice[0]);
          } else {
            this.unsetError('notice');
          }
          if (errors.notice) {
            this.setError('lab_id', errors.lab_id[0]);
          } else {
            this.unsetError('lab_id');
          }
          if (errors.packages) {
            this.setError('packages', errors.packages[0]);
          } else {
            this.unsetError('packages');
          }
        }
      },
      () => {
        this.router.navigate(['lab-packages']);
      }
    );
  }

  savePackage(data) {
    this.labPackageService.store(data).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        this.toastr.error(error.error.message);
        if (error.status === 422) {
          const errors = error.error.errors;
          if (errors.package_name) {
            this.setError('package_name', errors.package_name[0]);
          } else {
            this.unsetError('package_name');
          }
          if (errors.package_price) {
            this.setError('package_price', errors.package_price[0]);
          } else {
            this.unsetError('package_price');
          }
          if (errors.description) {
            this.setError('description', errors.description[0]);
          } else {
            this.unsetError('description');
          }
          if (errors.notice) {
            this.setError('notice', errors.notice[0]);
          } else {
            this.unsetError('notice');
          }
          if (errors.notice) {
            this.setError('lab_id', errors.lab_id[0]);
          } else {
            this.unsetError('lab_id');
          }
          if (errors.packages) {
            this.setError('packages', errors.packages[0]);
          } else {
            this.unsetError('packages');
          }
        }
      },
      () => {
        this.router.navigate(['lab-packages']);
      }
    );
  }

  buildRequestInvestigation() {
    const requestData: Array<any> = [];
    // Build Request Data
    this.selectedInvestigations.forEach((selectedInvestigation) => {
      const requestTest: any = [];
      selectedInvestigation.tests.forEach((test) => {
        if (test.selected === true) {
          requestTest.push({ id: test.id, name: test.name });
        }
      });
      requestData.push({
        id: selectedInvestigation.id,
        name: selectedInvestigation.name,
        tests: requestTest
      });
    });
    return requestData;
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
