import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storageKeys = {
    API_TOKEN: 'API_TOKEN',
    LOGGED_IN_USER: 'LOGGED_IN_USER'
  };

  constructor() {}

  setItem(key, value) {
    if (this.keyExists(key)) {
      localStorage.setItem(key, value);
    }
  }

  getItem(key) {
    if (this.keyExists(key)) {
      return localStorage.getItem(key);
    }
  }

  removeItem(key) {
    if (this.keyExists(key)) {
      return localStorage.removeItem(key);
    }
  }

  setAPIToken(tokenObj) {
    this.setItem(this.storageKeys.API_TOKEN, tokenObj);
  }

  getAPIToken() {
    return this.getItem(this.storageKeys.API_TOKEN);
  }

  removeAPIToken() {
    this.removeItem(this.storageKeys.API_TOKEN);
  }

  setLoggedInUser(userObj) {
    this.setItem(this.storageKeys.LOGGED_IN_USER, userObj);
  }

  getLoggedInUser() {
    return this.getItem(this.storageKeys.LOGGED_IN_USER);
  }

  removeLoggedInUser() {
    this.removeItem(this.storageKeys.LOGGED_IN_USER);
  }

  clear() {
    this.removeAPIToken();
    this.removeLoggedInUser();
  }

  keyExists(keyName) {
    return !!this.storageKeys[keyName];
  }
}
