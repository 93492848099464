import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MedicationService } from '../../../services/medication.service';
import { LabReportService } from '../../../services/lab-report.service';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { UrlsService } from '../../../services/urls.service';
import { MatSnackBar } from '@angular/material';
import { PageService } from '../../workflow-forms/page.service';

@Component({
  selector: 'app-medication-create',
  templateUrl: './medication-edit.component.html',
  styleUrls: ['./medication-edit.component.css']
})
export class MedicationEditComponent implements OnInit {
  saveAndContinueClicked: boolean = false;
  constructor(
    private medicationService: MedicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private diagnosisService: DiagnosisService,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService
  ) {}

  cancelUrl = '/patients';
  referredBy: any;
  patientId: number;
  medicationId: number;
  search: any;
  medicationList: any;
  showSuggestionForm: boolean;
  submitted: boolean = false;
  submitInProgress: boolean = false;
  selectedMedications: Array<any> = [];
  selectedReferredBy: string;
  diagnosisList: Array<object> = [];
  referredByFiltered: Observable<string[]>;

  medicationForm = new FormGroup({
    referredBy: new FormControl('', Validators.required)
  });

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.medicationId = +this.activatedRoute.snapshot.paramMap.get('medicationId');
    this.page.setTitleAndPatientId('Edit Medications', this.patientId);
    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.referredBy = y;
      this.referredByFiltered = this.setACField(
        this.medicationForm.controls.referredBy,
        'referredBy'
      );
    });

    this.diagnosisService.getDiagnosisListAll(this.patientId).subscribe((y: any) => {
      this.diagnosisList = y.data;
    });

    this.medicationService
      .getPatientMedication(this.patientId, this.medicationId)
      .subscribe((response: any) => {
        console.log('Medication details -->', response)
        this.selectedMedications = response.medications;
        this.selectedMedications.forEach((medication: any) => {
          medication.state = 'expanded';
          this.medicationForm.addControl(`${medication.id}_frequency`, new FormControl('', Validators.required));
          this.medicationForm.addControl(`${medication.id}_duration`, new FormControl('', Validators.required));
          this.medicationForm.controls[medication.id+'_frequency'].setValue(medication.frequency);
          this.medicationForm.controls[medication.id+'_duration'].setValue(medication.duration);
        });
        this.medicationForm.controls.referredBy.patchValue(response.referred_by);
      });
  }

  storeMedication() {
    const data: any = {};
    data.referred_by = this.medicationForm.controls.referredBy.value;
    data.medications = this.selectedMedications;
    this.submitted = true;
    this.submitInProgress = true;

    if (this.selectedMedications.length === 0 || this.medicationForm.controls.referredBy.invalid) {
      this.submitInProgress = false;
      return;
    }

    this.medicationService.updateMedication(this.patientId, this.medicationId, data).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.router.navigate(['/patients/' + this.patientId + '/medications']);
        this.submitInProgress = false;
      }
    );
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.medicationService.getMedicationList(keyword, this.selectedMedications).subscribe((x) => {
        this.medicationList = x;
        this.showSuggestionForm = true;
      });
    } else {
      this.showSuggestionForm = false;
    }
  }

  selectSuggestion(id) {
    console.log(id);
    const x = this.medicationList.find((y) => {
      return y.id === id;
    });
    x.state = 'expanded';
    x.expandIcon = 'angle-up';
    x.expandMessage = 'Collapse Details';
    console.log(this.selectedMedications);
    this.showSuggestionForm = false;
    this.search = '';
    this.medicationForm.addControl(`${x.id}_frequency`, new FormControl('', Validators.required));
    this.medicationForm.addControl(`${x.id}_duration`, new FormControl('', Validators.required));
    this.selectedMedications.unshift(x);
  }

  setQuantity(e, x): void {
    x.quantity = e.target.value;
  }
  setFrequency(e, x): void {
    x.frequency = e.target.value;
  }
  setDuration(e, x): void {
    x.duration = e.target.value;
  }
  setWayOfIntake(e, x): void {
    x.way_of_intake = e.target.value;
  }
  setUom(e, x): void {
    x.uom = e.target.value;
  }

  setDiagnosis(e, x): void {
    x.diagnosis = e.target.value;
  }
  setReferredBy(x): void {
    this.selectedReferredBy = x;
  }
  deleteMedication(medication): void {
    this.medicationForm.removeControl(`${medication.id}_frequency`);
    this.medicationForm.removeControl(`${medication.id}_duration`);
    this.selectedMedications = this.selectedMedications.filter((y) => {
      return y !== medication;
    });
  }

  toggleState(medication): void {
    medication.state = medication.state === 'collapsed' ? 'expanded' : 'collapsed';
  }

  showNotification(message: string) {
    this.toastr.info(message);
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }
}
