import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appValidFullName]'
})
export class ValidFullNameDirective {
  NAME_REGEX = '^[a-zA-Z. ]+$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.NAME_REGEX).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z. ]/g, '');
      event.preventDefault();
    }, 100);
  }
}
