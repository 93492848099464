import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inquiry-header',
  templateUrl: './inquiry-header.component.html',
  styleUrls: ['./inquiry-header.component.css']
})
export class InquiryHeaderComponent implements OnInit, OnChanges {
  @Input() status;
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  constructor(private router: Router) {}

  ngOnInit() {}
  ngOnChanges(changes: any) {
    if (changes.status.currentValue === 'success') {
      this.successMessageDisplay = true;
      this.responseMessage = 'Enquiry Form Submitted Successfully';
    }
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
    this.router.navigate([]);
  }
}
