import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../shared/model/user';
import { UserDeletedResponse } from '../users/shared/constants/user-deleted-response';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl;

  store(request: any) {
    return this.http.post(this.apiUrl + 'users/create', request, this.httpOptions);
  }

  storeGoOnline(request) {
    return this.http.post(this.apiUrl + 'go-online', request, this.httpOptions);
  }

  getGoOnline() {
    return this.http.get(this.apiUrl + 'go-online/status');
  }
  changePassword(request: any) {
    return this.http.put(this.apiUrl + 'user/change-password', request);
  }

  getUser(): Observable<User> {
    return this.http.get<User>(this.apiUrl + 'user');
  }

  getProfile(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'profile');
  }

  getType(): Observable<any> {
    return this.http.get(this.apiUrl + 'user/type');
  }

  getCountry(): Observable<any> {
    return this.http.get(this.apiUrl + 'user/country');
  }

  getRegion(): Observable<any> {
    return this.http.get(this.apiUrl + 'user/region');
  }

  getUserList(pageNo, limit, data: any): Observable<any> {
    data.pageNo = pageNo ? pageNo : '';
    data.limit = limit;
    const params = FormDataUtil.filterEmptyParams(data);
    return this.http.get(this.apiUrl + 'users', { params });
  }

  getSpecialityList(): Observable<any> {
    return this.http.get(this.apiUrl + 'specialities');
  }

  getConsulationTypeList(): Observable<any> {
    return this.http.get(this.apiUrl + 'consultation-types');
  }

  getUserFromId(userId): Observable<any> {
    return this.http.get(this.apiUrl + 'user/' + userId);
  }

  // For user assignation to patient
  searchUsersForAssignation(patientId, keyword, except) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const requestBody = { keyword, except };
    return this.http.post(`${this.apiUrl}${patientId}/users/search`, requestBody, httpOptions);
  }

  deleteUser(userId): Observable<UserDeletedResponse> {
    return this.http.delete<UserDeletedResponse>(`${this.apiUrl}users/${userId}`);
  }
  updateProfile(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(`${this.apiUrl}profile`, data, httpOptions);
  }

  update(userId: number, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.apiUrl}users/${userId}`, data, httpOptions);
  }
}
