import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyAppointmentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getAppointmentList(pageNo, limit, user_id, type) {
    if (type) {
      return this.http.get(`${this.apiUrl}consultations/my-bookings`, {
        params: { pageNo, limit, user_id, type }
      });
    } else {
      return this.http.get(`${this.apiUrl}consultations/my-bookings`, { params: { user_id } });
    }
  }
  updateStatus(consultationId, status) {
    const params = { status };
    return this.http.put(`${this.apiUrl}consultations/${consultationId}/status`, params);
  }

  getTimeSlots() {
    return this.http.get(`${this.apiUrl}consultations/timeslot`);
  }

  getBookedTimeList(user_id) {
    return this.http.get(`${this.apiUrl}consultations/bookings`, { params: { user_id } });
  }

  getScheduleTimeList(user_id) {
    return this.http.get(`${this.apiUrl}consultations/schedule`, { params: { user_id } });
  }

  saveSchedule(params) {
    return this.http.post(`${this.apiUrl}consultations/timeslot`, params, this.httpOptions);
  }
}
