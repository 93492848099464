import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllergyInformationService } from '../../../services/allergy-information.service';

@Component({
  selector: 'app-allergy-list',
  templateUrl: './allergy-list.component.html',
  styleUrls: ['./allergy-list.component.css']
})
export class AllergyListComponent implements OnInit {
  patientId: number;
  patientAllergies: Array<any>;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private allergyService: AllergyInformationService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientAllergies(this.limit);
  }

  getPatientAllergies(limit) {
    this.loading = true;
    this.allergyService.getPatientAllergyList(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.patientAllergies = response.data;
        this.total = response.size;
      },
      (error) => {
        this.loading = false;
        this.patientAllergies.length = 0;
      },
      () => {
        this.loading = false;
        this.patientAllergies.forEach((allergy: any) => {
          allergy.expandIcon = 'chevron-down';
          allergy.expandMessage = 'Expand Details';
          allergy.expanded = false;
        });
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientAllergies(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientAllergies(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientAllergies(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(allergy, event) {
    event.preventDefault();
    allergy.expanded = !allergy.expanded;
    if (allergy.expanded) {
      allergy.expandIcon = 'chevron-up';
      allergy.expandMessage = 'Collapse Details';
    } else {
      allergy.expandIcon = 'chevron-down';
      allergy.expandMessage = 'Expand Details';
    }
  }
}
