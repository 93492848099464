import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Investigation } from '../shared/model/investigation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabReportService {
  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getInvestigationServiceList(lab_id, keyword, arr): Observable<Investigation[]> {
    const url = this.apiUrl + 'investigations';
    return this.http.post<Investigation[]>(url, { keyword, except: arr, lab_id }, this.httpOptions);
  }

  getTestTypeServiceList(id) {
    return this.http.get(this.apiUrl + 'test-types-by-investigation/' + id, {});
  }

  add(refId, labReports) {
    const url = this.apiUrl + refId + '/lab-reports/create';
    const httpOptions = {};
    return this.http.post<any>(url, labReports, this.httpOptions);
  }
  getDoctorList() {
    const url = this.apiUrl + 'doctors';
    return this.http.get(url);
  }
  getLabList() {
    const url = this.apiUrl + 'lab-lists';
    return this.http.get(url);
  }
  getPatientLabReports(patientId, pageNo, limit) {
    const url = this.apiUrl + '';
    return this.http.get(`${this.apiUrl}${patientId}/lab-reports`, { params: { pageNo, limit } });
  }

  getPatientLabReport(patientId, labReportId) {
    return this.http.get(this.apiUrl + patientId + '/lab-reports/' + labReportId);
  }

  update(patientId, labReportId, labReports) {
    const url = this.apiUrl + patientId + '/lab-reports/' + labReportId;
    const httpOptions = {};
    return this.http.post<any>(url, labReports, this.httpOptions);
  }

  getLabListForLabReport() {
    const url = this.apiUrl + 'lab-lists';
    return this.http.get(url);
  }
}
