import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LabReportService } from '../../../../services/lab-report.service';
import { UrlsService } from '../../../../services/urls.service';
import { MatSnackBar } from '@angular/material';
import { PageService } from '../../../../patients/workflow-forms/page.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ConvertByte } from 'src/app/patients/shared/utility/convertByte.util';

@Component({
  selector: 'app-lab-report-create',
  templateUrl: './lab-report-create.component.html',
  styleUrls: ['./lab-report-create.component.css']
})
export class LabReportCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  myControl1 = new FormControl();
  doctors1: string[] = [];
  filteredDoctors1: Observable<string[]>;

  myControl2 = new FormControl();
  doctors2: string[] = [];
  filteredDoctors2: Observable<string[]>;

  myControl3 = new FormControl();
  doctors3: string[] = [];
  filteredDoctors3: Observable<string[]>;
  fileUploadedStatus = false;
  // For Save and Continue
  saveAndContinueClicked = false;

  l: Array<object> = [];
  arr: Array<any>;
  testTypes: Array<object> = [];
  patientId: string;
  showSuggestionForm = false;
  search = '';
  searchTestTypesearch: string;
  data: Array<object> = [];
  testType: Array<object> = [];
  form: Array<object> = [];
  file = '';
  isDisabled = true;

  conductedBy1 = '';
  conductedBy2 = '';
  conductedBy3 = '';

  submitted = false;

  file_attachment_err = '';
  fileSize: any;
  fileSizeUnit: string;
  rawFileSize: number;
  MAX_FILE_SIZE: number = 10048576;
  fileAttachmentError = '';

  labList: any;

  // For Form Footer
  cancelUrl = '/lab-bookings?status=in_progress';
  submitInProgress = false;

  labReportsForm = new FormGroup({
    reported_date: new FormControl('', [Validators.required]),
    lab_no: new FormControl('', [Validators.required]),
    registered_date: new FormControl('', [Validators.required]),
    lab_id: new FormControl('', [Validators.required]),
    conducted_by_1: new FormControl('', [Validators.required]),
    conducted_by_2: new FormControl('', [Validators.required]),
    conducted_by_3: new FormControl('', [Validators.required]),
    fileAttachment: new FormControl('', [Validators.required])
  });

  // For Lab Reports of Lab Bookings
  bookingId: string;
  // For Lab Reports of Lab Bookings End

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private urlService: UrlsService,
    private notification: MatSnackBar,
    private page: PageService,
    private toastr: ToastrService,
    private convertByte: ConvertByte
  ) {}

  ngOnInit() {
    this.bookingId = this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Lab Report', this.patientId);
    });

    this.getDoctorList();
    this.getLabList();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (y: any) => {
        this.doctors1 = y;
        this.doctors2 = y;
        this.doctors3 = y;
        this.filteredDoctors1 = this.setACField(
          this.labReportsForm.controls.conducted_by_1,
          'doctors1'
        );
        this.filteredDoctors2 = this.setACField(
          this.labReportsForm.controls.conducted_by_2,
          'doctors2'
        );
        this.filteredDoctors3 = this.setACField(
          this.labReportsForm.controls.conducted_by_3,
          'doctors3'
        );
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabListForLabReport().subscribe(
      (response: any) => {
        this.labList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.labList = this.labList.filter((d) => d.active === 1);
        this.submitInProgress = false;
      }
    );
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.labReportService
        .getInvestigationServiceList(this.labReportsForm.controls.lab_id.value, keyword, this.l)
        .subscribe((x: any) => {
          this.arr = x;
          this.showSuggestionForm = true;
        });
    } else {
      this.showSuggestionForm = false;
    }
  }
  showTestTypes(e, id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });

    this.showSuggestionForm = false;
    this.search = '';
    this.l.push(x);
    this.labReportService.getTestTypeServiceList(id).subscribe((y: any) => {
      console.log(y[0].name);
      this.testTypes.push(y);
    });
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });

    console.log(x);
    this.showSuggestionForm = false;
    this.search = '';
    this.l.push(x);
  }

  addLabReports() {
    this.submitInProgress = true;
    this.submitted = true;
    if (this.labReportsForm.invalid && !this.fileUploadedStatus) {
      this.submitInProgress = false;
      return;
    }
    this.showSuggestionForm = false;
    this.search = '';
    const data = this.labReportsForm.value;

    const formData: FormData = new FormData();
    formData.append('testType', JSON.stringify(this.testTypes));
    let refId: any;
    formData.append('bookingId', this.bookingId);
    refId = this.bookingId;
    formData.append('lab_no', data.lab_no);
    formData.append('reported_date', moment(data.reported_date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('lab_id', data.lab_id);
    formData.append('registered_date', moment(data.registered_date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('conducted_by_1', this.labReportsForm.controls.conducted_by_1.value);
    formData.append('conducted_by_2', this.labReportsForm.controls.conducted_by_2.value);
    formData.append('conducted_by_3', this.labReportsForm.controls.conducted_by_3.value);
    formData.append('file_attachment', this.file);

    this.labReportService.add(refId, formData).subscribe(
      (response: any) => {
        this.showNotification(response.message);
      },
      (err) => {
        this.submitInProgress = false;
        if (err.status === 422) {
          for (const error in err.error.errors) {
            // tslint:disable-next-line:triple-equals
            if (error == 'file_attachment') {
              this.file_attachment_err = err.error.errors[error];
            }
          }
        } else {
          this.showNotification(err.error.message);
        }
      },
      () => {
        this.submitInProgress = false;
        this.router.navigate(['/lab-bookings'], { queryParams: { status: 'completed' } });
      }
    );
  }

  displayFn(doctor): string {
    return doctor;
  }

  setvalue(e, x) {
    x.value = e.target.value;
  }
  setmethod(e, x) {
    x.method = e.target.value;
  }
  deleteValue(e, x) {
    const index: number = this.testTypes.indexOf(x);
    if (index !== -1) {
      this.testTypes.splice(index, 1);
    }
  }
  enableValue(e, id) {}
  uploadFile(event) {
    this.fileAttachmentError = null;
    this.fileSize = null;

    const files = event.target.files;
    if (files.length > 0) {
      this.rawFileSize = files[0].size;
      if (this.rawFileSize < this.MAX_FILE_SIZE && this.convertByte.extractExtension(files[0])) {
        this.fileSize = this.convertByte.convert(files[0].size);
        this.file = files[0];
      } else {
        this.toastr.error(
          'The file attachment must be a file type of jpg, jpeg, png, pdf',
          'File size should not exceed 10 MB.'
        );
        this.labReportsForm.controls.fileAttachment.setValue(null);
      }
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.fileUploadedStatus = false;
    this.file = null;
    this.fileSize = null;
    this.fileSizeUnit = null;
    this.rawFileSize = null;
    this.labReportsForm.controls.fileAttachment.setValue(null);
  }

  removeInvestigation(investigation) {
    this.testTypes.splice(this.testTypes.indexOf(investigation), 1);
    this.l.splice(this.l.indexOf(investigation), 1);
  }

  showNotification(message: string) {
    this.notification.open(message, 'Ok', {
      duration: 5000
    });
  }
  setEnable(e, x) {
    // tslint:disable-next-line:triple-equals
    if (x.enabledRow == false) {
      x.enabledRow = true;
    } else {
      x.enabledRow = false;
    }
  }
}
