import { Component, OnInit } from '@angular/core';
import { PageService } from '../../workflow-forms/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRequestService } from '../../../services/service-request.service';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-request-create',
  templateUrl: './service-request-create.component.html',
  styleUrls: ['./service-request-create.component.css']
})
export class ServiceRequestCreateComponent implements OnInit {
  careRequestList: Array<any> = [];
  careNeededForList: Array<any> = [];
  serviceTypes: Array<any> = [];
  referralGroups: Array<any> = [];
  MOBILE_MAX_LENGTH = 10;

  selectedCareRequestList: Array<any> = [];
  selectedCareNeededForList: Array<any> = [];

  selectedDiagnosesList: Array<any> = [];

  serviceRequestForm = new FormGroup({
    requested_care: new FormControl('', [Validators.required]),
    care_needed_for: new FormControl('', [Validators.required]),
    request_date: new FormControl('', [Validators.required]),
    referral_name: new FormControl('', [Validators.required]),
    filled_by: new FormControl('', [Validators.required]),
    relation_with_client: new FormControl('', [Validators.required]),
    contact_person: new FormControl('', [Validators.required]),
    contact_number: new FormControl('', [
      Validators.required,
      Validators.maxLength(this.MOBILE_MAX_LENGTH)
    ]),
    contact_email: new FormControl('', [Validators.required, Validators.email]),
    home_address: new FormControl('', [Validators.required]),
    refferal_info_group_list_id: new FormControl('', [Validators.required]),
    care_duration_service_type_list_id: new FormControl('', [Validators.required]),
    searchDiagnosis: new FormControl()
  });

  patientId: number;

  urlList: Array<string> = [];
  nextUrl: string;

  showSuggestionForm: boolean;

  diagnosisArr: Array<any>;

  selectedDiagnoses: Array<object> = [];

  submitted = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  constructor(
    private page: PageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private serviceRequestService: ServiceRequestService,
    private diagnosisService: DiagnosisService
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getMenu();
    this.getServiceRequestCarePlanList();
    this.getCareNeededForList();
    this.getCareDurationServiceTypeList();
    this.refferalInfoGroupList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Service Request', this.patientId);
    });
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getServiceRequestCarePlanList(): void {
    this.serviceRequestService
      .getServiceRequestCarePlanList()
      .subscribe((serviceRequestCarePlanList) => {
        this.careRequestList = serviceRequestCarePlanList;
      });
  }

  getCareNeededForList(): void {
    this.serviceRequestService.getCareNeededForList().subscribe((careNeededForList) => {
      this.careNeededForList = careNeededForList;
    });
  }

  getCareDurationServiceTypeList(): void {
    this.serviceRequestService
      .getCareDurationServiceTypeList()
      .subscribe((careDurationServiceTypeList) => {
        this.serviceTypes = careDurationServiceTypeList;
      });
  }
  refferalInfoGroupList(): void {
    this.serviceRequestService.getRefferalInfoGroupList().subscribe((refferalInfoGroupList) => {
      this.referralGroups = refferalInfoGroupList;
    });
  }

  storeServiceRequest() {
    this.submitted = true;
    this.submitInProgress = true;
    const data = this.serviceRequestForm.value;

    data.care_needed_for = this.selectedCareNeededForList;
    data.requested_care = this.selectedCareRequestList;
    data.diagnosis = this.selectedDiagnosesList;

    if (this.serviceRequestForm.status === 'VALID') {
      this.serviceRequestService.storeserviceRequest(data, this.patientId).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setCareRequestListValue(e, id) {
    if (e.checked) {
      this.selectedCareRequestList.push(id);
    }
  }

  setCareNeededForListValue(e, id) {
    if (e.checked) {
      this.selectedCareNeededForList.push(id);
    }
  }

  showSuggestions(e) {
    const keyword = e.target.value;

    // get value of diagnoses list according to keyword
    if (keyword.length > 2) {
      this.diagnosisService
        .getDiagnosisList(keyword, this.selectedDiagnoses)
        .subscribe((x: any) => {
          console.log(x);
          this.diagnosisArr = x;
          this.showSuggestionForm = true;
        });
    } else {
      this.showSuggestionForm = false;
    }
  }

  selectSuggestion(id) {
    const x = this.diagnosisArr.find((y) => {
      return y.id === id;
    });

    this.showSuggestionForm = false;
    this.serviceRequestForm.controls.searchDiagnosis.setValue('');
    this.selectedDiagnoses.push(x);
    this.selectedDiagnosesList.push(x.id);
  }

  unSelectDiagnosis(x): void {
    this.selectedDiagnoses = this.selectedDiagnoses.filter((y) => {
      return y !== x;
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }
}
