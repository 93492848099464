import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../services/user-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionsService } from '../services/regions.service';
import { RouterService } from '../shared/router.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.css']
})
export class AddRegionComponent implements OnInit {
  userType: string;
  userCountryId: number;
  userCountryName: string;
  userId: number;

  regionForm = new FormGroup({
    regionName: new FormControl('', [Validators.required]),
    userName: new FormControl('', [Validators.required]),
    userEmail: new FormControl('', [Validators.required])
  });

  // For Form Footer
  cancelUrl = '/regions';
  submitInProgress = false;

  constructor(
    private userService: UserServiceService,
    private regionService: RegionsService,
    private router: RouterService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userService.getType().subscribe((res) => {
      this.userType = res.data.user_type;
      this.userId = res.data.user_id;
      if (this.userType !== 'country_admin') {
        alert('This page is only accessible to country admin!');
        window.location.href = '';
      }
    });
    this.userService.getCountry().subscribe((res) => {
      this.userCountryName = res.data.user_country;
      this.userCountryId = res.data.user_country_id;
    });
  }

  createRegion() {
    this.submitInProgress = true;
    const formData = this.regionForm.value;
    if (this.regionForm.status === 'VALID') {
      formData.countryId = this.userCountryId;
      formData.userId = this.userId;
      this.regionService.storeRegion(formData).subscribe(
        (response: any) => {
          this.router.redirectToNextPageWithMessage(response.message, '/regions');
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.success(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          console.log('Completed');
        }
      );
    } else {
      this.submitInProgress = false;
      console.log('Form invalid');
    }
  }
}
