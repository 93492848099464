import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CarePlanTypeList } from '../shared/model/carePlanTypeList';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PersonalCarePlanService {
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getCarePlanList() {
    return this.http.get(this.apiUrl + 'care-plan-list');
  }

  storeCarePlan(patientId, carePlan) {
    return this.http.post(this.apiUrl + patientId + '/care-plans', carePlan, this.httpOptions);
  }

  getPatientPersonalCarePlan(patientId, carePlanId) {
    return this.http.get(this.apiUrl + patientId + '/care-plans/' + carePlanId);
  }

  updatePatientPersonalCarePlan(patientId, carePlanId, data) {
    const url = `${this.apiUrl}${patientId}/care-plans/${carePlanId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, data, httpOptions);
  }
}
