import { Injectable } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PipeService {
  constructor(private _decimalPipe: DecimalPipe, private _datePipe: DatePipe) {}

  transformDecimal(val: any, format: string): string {
    return this._decimalPipe.transform(val, format);
  }

  transformDate(val: any, format: string): string {
    return this._datePipe.transform(val, format);
  }
}
