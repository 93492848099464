import { Component, OnInit } from '@angular/core';
import { PrescriptionRefillService } from '../../services/prescription-refill.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-prescription-refill-requests',
  templateUrl: './prescription-refill-requests.component.html',
  styleUrls: ['./prescription-refill-requests.component.css']
})
export class PrescriptionRefillRequestsComponent implements OnInit {
  prescriptionId;
  refillType = 'both';
  refillStatus: string = null;
  prescriptionRefillList: Array<any> = [];

  statusList: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private prescriptionRefillService: PrescriptionRefillService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.prescriptionId = params.id;
    });

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const refillStatus: any = this.activatedRoute.snapshot.queryParamMap.get('status');
    const refillType: any = this.activatedRoute.snapshot.queryParamMap.get('refillType');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.refillStatus = refillStatus ? refillStatus : this.refillStatus;
    this.refillType = refillType ? refillType : this.refillType;

    this.getRefillStatusList();
  }

  updateRefillStatus(refill, status) {
    if (confirm('Are you sure to update this status?')) {
      const data = { status };
      this.prescriptionRefillService.updatePrescriptionRefill(refill.id, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          if (this.refillStatus) {
            this.removeRefillRequest(refill);
          }
        }
      );
    }
  }

  removeRefillRequest(refill) {
    this.prescriptionRefillList = this.prescriptionRefillList.filter((refillRequest: any) => {
      return refillRequest.id !== refill.id;
    });
  }

  getSelectedIndex() {
    return this.statusList.indexOf(
      this.statusList.find((status) => {
        return status.slug === this.refillStatus;
      })
    );
  }

  filterRefillList(slug) {
    this.refillStatus = slug;
    this.page = 1;
    this.getPrescriptionRefillList();
    this.addParamsToUrl(this.page, this.limit, this.refillStatus, this.refillType);
  }

  getRefillStatusList() {
    this.loading = true;
    this.prescriptionRefillService.getRefillStatusList().subscribe(
      (response: any) => {
        this.statusList = response.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.statusList.length = 0;
        this.prescriptionRefillList.length = 0;
      },
      () => {
        this.statusList.unshift({
          slug: null,
          display: 'All'
        });
        this.getPrescriptionRefillList();
      }
    );
  }

  getPrescriptionRefillList() {
    this.loading = true;
    this.prescriptionRefillService
      .getPrescriptionRefillList(
        this.page,
        this.limit,
        this.refillStatus,
        this.refillType,
        this.prescriptionId
      )
      .subscribe(
        (response: any) => {
          this.prescriptionRefillList = response.data;
          this.total = response.size;
          this.loading = false;
        },
        (error) => {
          this.prescriptionRefillList.length = 0;
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPrescriptionRefillList();
    this.addParamsToUrl(this.page, this.limit, this.refillStatus, this.refillType);
  }

  onNext(): void {
    this.page++;
    this.getPrescriptionRefillList();
    this.addParamsToUrl(this.page, this.limit, this.refillStatus, this.refillType);
  }

  onPrev(): void {
    this.page--;
    this.getPrescriptionRefillList();
    this.addParamsToUrl(this.page, this.limit, this.refillStatus, this.refillType);
  }

  addParamsToUrl(pageNo, limit, status, refillType) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit, status, refillType },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  selectRefillType(refillType) {
    this.page = 1;
    this.limit = 10;
    this.refillStatus = null;
    this.addParamsToUrl(this.page, this.limit, this.refillStatus, refillType);
    this.getRefillStatusList();
  }
}
