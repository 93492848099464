import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CarePlanTypeList } from '../shared/model/carePlanTypeList';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService {
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getPaymentHistory(params, limit = null, pageNo = null) {
    params.limit = limit ? limit : '';
    params.pageNo = pageNo ? pageNo : '';
    return this.http.get(this.apiUrl + 'payment/history/', { params });
  }

  getPaymentDetail(paymentId) {
    return this.http.get(this.apiUrl + 'invoices/' + paymentId);
  }
}
