import { Component, OnInit } from '@angular/core';
import { RadiologyService } from '../../../services/radiology.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-radiology-list',
  templateUrl: './radiology-list.component.html',
  styleUrls: ['./radiology-list.component.css']
})
export class RadiologyListComponent implements OnInit {
  patientId: number;
  patientRadiologyList: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  radiologyForList = [
    {
      id: 1,
      slug: 'head',
      name: 'Head'
    },
    {
      id: 2,
      slug: 'chest',
      name: 'Chest'
    },
    {
      id: 3,
      slug: 'elbow',
      name: 'Elbow'
    },
    {
      id: 4,
      slug: 'shoulder',
      name: 'Shoulder'
    },
    {
      id: 5,
      slug: 'left-hand',
      name: 'Left Hand'
    },
    {
      id: 6,
      slug: 'right-hand',
      name: 'Right Hand'
    },
    {
      id: 7,
      slug: 'knee',
      name: 'Knee'
    },
    {
      id: 8,
      slug: 'left-ankle',
      name: 'Left Ankle'
    },
    {
      id: 9,
      slug: 'right-ankle',
      name: 'Right Ankle'
    },
    {
      id: 10,
      slug: 'foot',
      name: 'Foot'
    },
    {
      id: 11,
      slug: 'spine',
      name: 'Spine'
    },
    {
      id: 12,
      slug: 'wrist',
      name: 'Wrist'
    },
    {
      id: 13,
      slug: 'left-hip',
      name: 'Left Hip'
    },
    {
      id: 14,
      slug: 'right-hip',
      name: 'Right Hip'
    },
    {
      id: 15,
      slug: 'finger',
      name: 'Finger'
    },
    {
      id: 16,
      slug: 'other',
      name: 'Other'
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private radiologyService: RadiologyService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientRadiology(this.limit);
  }

  getPatientRadiology(limit) {
    this.loading = true;
    this.radiologyService.getPatientRadiologyList(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.patientRadiologyList = response.data;
        this.total = response.size;
      },
      (error) => {
        this.loading = false;
        this.patientRadiologyList.length = 0;
      },
      () => {
        this.loading = false;
        this.patientRadiologyList.forEach((radiologyData: any) => {
          radiologyData.expandIcon = 'chevron-down';
          radiologyData.expandMessage = 'Expand Details';
          radiologyData.expanded = false;
        });
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientRadiology(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientRadiology(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientRadiology(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(radiologyData, event) {
    event.preventDefault();
    radiologyData.expanded = !radiologyData.expanded;
    if (radiologyData.expanded) {
      radiologyData.expandIcon = 'chevron-up';
      radiologyData.expandMessage = 'Collapse Details';
    } else {
      radiologyData.expandIcon = 'chevron-down';
      radiologyData.expandMessage = 'Expand Details';
    }
  }

  getRadiologyForName(radiologyForSlug: string) {
    const radiologyObj = this.radiologyForList.find((radiology: any) => {
      return radiology.slug === radiologyForSlug;
    });
    return radiologyObj.name;
  }
}
