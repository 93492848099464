import { Component, Input, OnInit } from '@angular/core';
import { VitalService } from '../../../services/vitals.service';
import moment from 'moment';

@Component({
  selector: 'app-chart-create',
  templateUrl: './chart-create.component.html',
  styleUrls: ['./chart-create.component.css']
})
export class ChartCreateComponent implements OnInit {
  vitalsData: any;
  processedData: any;

  @Input() patientId: number;
  @Input() vitalType: string;

  @Input() chartWidth = 1000;
  @Input() chartHeight = 300;

  // Chart Options
  view: any[] = [this.chartWidth, this.chartHeight];
  animations = true;
  legend = true;
  legendTitle = 'Vital Type';
  legendPosition = 'right';
  xAxis = true;
  yAxis = true;
  timeline = true;
  xAxisLabel = 'Date/Time';
  yAxisLabel: string;
  showXAxisLabel = true;
  showYAxisLabel = true;

  constructor(private vitalService: VitalService) {}

  ngOnInit() {
    this.getVitalData(this.patientId, this.vitalType);
  }

  getVitalData(patientId, vitalType) {
    this.vitalService.getVitalsData(patientId, vitalType).subscribe(
      (response: any) => {
        this.vitalsData = response;
      },
      (error) => {
        this.vitalsData = null;
      },
      () => {
        this.processedData = this.processVitalsData(this.vitalsData);
        this.yAxisLabel = this.vitalsData.vital_type;
      }
    );
  }

  processVitalsData(vitalsDataRaw) {
    const dataList: Array<any> = [];
    const vitalsDataPrimary: any = {};
    const vitalsDataSecondary: any = {};
    const secondaryFieldExists = vitalsDataRaw.vital_fields.secondary_label !== null;

    // Process for Primary Values
    vitalsDataPrimary.name = vitalsDataRaw.vital_fields.label.toUpperCase().replace('_', ' ');
    vitalsDataPrimary.series = [];
    vitalsDataRaw.data.forEach((primaryDataObj: any) => {
      const singlePrimaryDataObj = {
        value: primaryDataObj.value,
        name: moment(primaryDataObj.date).format('YYYY-MM-DD')
      };
      vitalsDataPrimary.series.push(singlePrimaryDataObj);
    });

    dataList.push(vitalsDataPrimary);

    // Process for Secondary Values
    if (secondaryFieldExists && vitalsDataRaw.vital_fields.secondary_label !== 'measurement_type') {
      vitalsDataSecondary.name = vitalsDataRaw.vital_fields.secondary_label
        .toUpperCase()
        .replace('_', ' ');
      vitalsDataSecondary.series = [];
      vitalsDataRaw.data.forEach((secondaryDataObj: any) => {
        const singleSecondaryDataObj = {
          value: isNaN(secondaryDataObj.secondary_value) ? 0 : secondaryDataObj.secondary_value,
          name: moment(secondaryDataObj.date).format('YYYY-MM-DD')
        };
        vitalsDataSecondary.series.push(singleSecondaryDataObj);
      });

      dataList.push(vitalsDataSecondary);
    }
    console.log(dataList);
    return dataList;
  }
}
