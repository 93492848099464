import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentHistoryService } from '../../../services/payment-history.service';

@Component({
  selector: 'app-payment-history-detail',
  templateUrl: './payment-history-detail.component.html',
  styleUrls: ['./payment-history-detail.component.css']
})
export class PaymentHistoryDetailComponent implements OnInit {
  paymentId;
  paymentDetail;
  productDetails;
  images = {
    fonepay: 'assets/images/Fonepay.svg',
    esewa: 'assets/images/esewa.png',
    cod: 'assets/images/cash.svg'
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private paymentHistoryService: PaymentHistoryService
  ) {}

  ngOnInit() {
    this.paymentId = +this.activatedRoute.snapshot.paramMap.get('invoiceId');
    this.getPaymentHistory(this.paymentId);
  }

  getPaymentHistory(paymentId) {
    this.paymentHistoryService.getPaymentDetail(paymentId).subscribe(
      (response: any) => {
        this.paymentDetail = response.data;
      },
      (error) => {},
      () => {
        this.stringToJson(this.paymentDetail.product_details);
      }
    );
  }

  stringToJson(paymentProductDetails) {
    this.productDetails = JSON.parse(paymentProductDetails);
  }
  getColor(payment_status) {
    return payment_status === 'completed' ? 'green-button' : 'pink-button';
  }

  titleCase(str) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  getDeliveryImage(payment_type) {
    return this.images[payment_type];
  }

  getAddress(address) {
    return address
      ? address.province +
          ', ' +
          address.city +
          '<br>' +
          address.address +
          ', ' +
          address.nearest_landmark
      : 'N/A';
  }
}
