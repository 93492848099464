import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotifyService } from 'src/app/services/notify.service';
import { SpecialityListService } from 'src/app/services/speciality-list.service';

@Component({
  selector: 'app-speciality-form',
  templateUrl: './speciality-form.component.html',
  styleUrls: ['./speciality-form.component.css']
})
export class SpecialityFormComponent implements OnInit {
  speciality: any;
  submitInProgress = false;
  submitted = false;
  specialityForm = new FormGroup({
    name: new FormControl('', [Validators.required])
  });
  constructor(
    private toastr: ToastrService,
    private specialityService: SpecialityListService,
    private router: Router,
    private dialogRef: MatDialogRef<SpecialityFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.speciality = data;
  }

  ngOnInit() {
    console.log(this.speciality);
    if (this.speciality) {
      this.patchData();
    }
  }

  patchData() {
    this.specialityForm.patchValue({
      name: this.speciality.name
    });
  }
  submit() {
    this.submitInProgress = true;
    if (this.specialityForm.status === 'VALID') {
      const id = this.speciality ? this.speciality.id : null;
      this.specialityService.save(this.specialityForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.submitted = false;
          this.dialogRef.close(true);
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
