import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsumerMessageService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getConsumerMessages(pageNo, limit, q) {
    if (!q) {
      return this.http.get(this.apiUrl + 'video-app/consumer-messages', {
        params: { pageNo, limit }
      });
    } else {
      return this.http.get(this.apiUrl + 'video-app/consumer-messages', {
        params: { pageNo, limit, q }
      });
    }
  }

  updateConsumerMessage(messageId, data) {
    return this.http.put(`${this.apiUrl}video-app/consumer-messages/${messageId}`, data);
  }

  getMessageAttributes() {
    return this.http.get(this.apiUrl + 'video-app/consumer-message-attributes');
  }

  deleteConsumerMessage(messageId: number) {
    return this.http.delete(`${this.apiUrl}video-app/consumer-messages/${messageId}`);
  }
}
