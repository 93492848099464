import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.css']
})
export class ToastMessageComponent implements OnInit {
  alertElement: any;
  private timerElement: any;
  private timerWidth: number;
  private timerInterval: any;

  @Input() alertBarStatus;
  @Input() failureMessageDisplay;
  @Input() successMessageDisplay;
  @Input() responseMessage;
  @Output() closeToggle: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.alertElement = document.querySelector('div.alert');
    if (this.alertBarStatus) {
      this.timerElement = document.getElementById('alert-timer');
      this.resetTimer();
    }
  }

  resetTimer() {
    if (this.alertBarStatus) {
      this.timerElement = document.getElementById('alert-timer');
      this.timerWidth = 98;
      this.timerElement.style.width = this.timerWidth + '%';
      clearInterval(this.timerInterval);
      this.timerInterval = setInterval(this.decrementTimer.bind(this), 30);
    }
  }

  stopTimer() {
    if (this.alertBarStatus) {
      this.timerWidth = 98;
      this.timerElement.style.width = this.timerWidth + '%';
      clearInterval(this.timerInterval);
    }
  }

  decrementTimer() {
    if (this.timerWidth <= 0) {
      clearInterval(this.timerInterval);
      this.toggleAlert();
    } else {
      this.timerWidth--;
      this.timerElement.style.width = this.timerWidth + '%';
    }
  }

  toggleAlert() {
    this.alertElement.classList.toggle('hide');
    setTimeout(() => {
      this.closeToggle.emit('');
    }, 700);
  }
}
