import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appValidPhoneNumber]'
})
export class ValidPhoneNumberDirective {
  PHONE_NUMBER = '^[0-9]+$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.PHONE_NUMBER).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9]/g, '');
      event.preventDefault();
    }, 100);
  }
}
