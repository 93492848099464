import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from '../../services/user-service.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  user: any = {};
  userId: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserServiceService
  ) {}

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    this.userService.getUserFromId(this.userId).subscribe((user: any) => {
      this.user = user.data;
    });
  }

  getUserLocation(location: any) {
    if (location !== null) {
      return location;
    }
    return 'N/A';
  }

  getUserBusinessUnit(bu, buFromLocation) {
    if (bu !== null || buFromLocation !== null) {
      return (bu ? bu : '') + (buFromLocation ? buFromLocation : '');
    }
    return 'N/A';
  }

  getUserRegion(region, regionFromBu, regionFromBuFromLocation) {
    if (region !== null || regionFromBu !== null || regionFromBuFromLocation !== null) {
      return (
        (region ? region : '') +
        (regionFromBu ? regionFromBu : '') +
        (regionFromBuFromLocation ? regionFromBuFromLocation : '')
      );
    }
    return 'N/A';
  }

  getUserCountry(
    country,
    countryFromRegion,
    countryFromRegionFromBu,
    countryFromRegionFromBuFromLocation
  ) {
    if (
      country !== null ||
      countryFromRegion !== null ||
      countryFromRegionFromBu !== null ||
      countryFromRegionFromBuFromLocation !== null
    ) {
      return (
        (country ? country : '') +
        (countryFromRegion ? countryFromRegion : '') +
        (countryFromRegionFromBu ? countryFromRegionFromBu : '') +
        (countryFromRegionFromBuFromLocation ? countryFromRegionFromBuFromLocation : '')
      );
    }
    return 'N/A';
  }
  getSpeciality(specialities) {
    let speciality = '';
    if (specialities) {
      specialities.forEach((spec, index) => {
        speciality += specialities.length === index + 1 ? spec.name : spec.name + ', ';
      });
    }
    return speciality;
  }
  getUserPermission(permission) {
    if (permission) {
      // tslint:disable-next-line:no-shadowed-variable
      permission = permission.map((permission: any) =>
        permission.description ? permission.description : ''
      );

      // tslint:disable-next-line:no-shadowed-variable
      permission = permission.filter((permission) => permission !== '');
    }
    return permission
      ? '<ul><li class="permission-list">' +
          permission.join('</li><li class="permission-list">') +
          '</li></ul>'
      : 'N/A';
  }
}
