import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinct,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { CallQuery, CallQueryList } from 'src/app/shared/model/call-query';

export interface Pageable {
  pagesToShow: number;
  page: number;
  perPage: number;
  count: number;
}

export interface CallQueryFilterParams {
  toDate?: string;
  fromDate?: string;
  query?: string;
}

export interface CallQueryRetrieveCriteria {
  pageEvent$: Subject<Pageable>;
  searchCriteria$: Subject<CallQueryFilterParams | undefined>;
}

export class CallQueryRetrieveCriteria implements CallQueryRetrieveCriteria {
  constructor(pageEvent$: Subject<Pageable>, searchCriteria$: Subject<CallQueryFilterParams>) {
    this.pageEvent$ = pageEvent$;
    this.searchCriteria$ = searchCriteria$;
  }
  onChange(
    callQueryList: (
      pageable: Pageable,
      searchCriteria?: CallQueryFilterParams
    ) => Observable<CallQueryList>
  ): Observable<[CallQueryList, Pageable]> {
    return combineLatest([this.pageEvent$, this.searchCriteria$]).pipe(
      debounceTime(200),
      distinctUntilChanged((p, n) => {
        return (
          p[0].page === n[0].page &&
          p[0].perPage === n[0].perPage &&
          p[1].query == n[1].query &&
          p[1].fromDate === n[1].fromDate &&
          p[1].toDate === n[1].toDate
        );
      }),
      filter(([pageable]) => {
        if (pageable.count) {
          return pageable.page < pageable.count;
        }
        return true;
      }),
      switchMap(([pageable, searchCriteria]) =>
        combineLatest([callQueryList(pageable, searchCriteria), of(pageable)])
      ),
      tap(([callQueryList, pageable]) => {
        if (callQueryList.size && !pageable.count) {
          pageable.count = callQueryList.size;
        }
      })
    );
  }
}
