import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeListService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getServiceTypeList(params = null) {
    return this.http.get(`${this.apiUrl}service-types`, { params });
  }
  getServiceManagementDetail(serviceId) {
    return this.http.get(`${this.apiUrl}services/${serviceId}`);
  }
  save(data, id): Observable<any> {
    if (id) {
      const api = this.apiUrl + 'service-types/' + id;
      return this.http.put(api, data, this.httpOptions);
    } else {
      return this.http.post(`${this.apiUrl}service-types`, data, this.httpOptions);
    }
  }

  saveService(data) {
    return this.http.post(`${this.apiUrl}services`, data, this.httpOptions);
  }

  updateSerivce(data, id) {
    return this.http.post(`${this.apiUrl}services/` + id, data);
  }

  serviceManagementListService(pageNo, limit, q, status: any) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q, status });
    return this.http.get(`${this.apiUrl}services/`, { params });
  }

  getServiceStatus() {
    return this.http.get(`${this.apiUrl}service-status-list`);
  }
  getServiceBookingList(pageNo, limit, filterBody, status) {
    const params: any = {
      q: filterBody.query ? filterBody.query : '',
      bookingFrom: filterBody.bookingFrom ? filterBody.bookingFrom : '',
      bookingTo: filterBody.bookingTo ? filterBody.bookingTo : ''
    };
    // const params = filterBody;
    params.pageNo = pageNo;
    params.limit = limit;
    params.status = status;
    return this.http.get(`${this.apiUrl}service-bookings`, { params });
  }

  updateServiceStatus(id, status) {
    return this.http.put(`${this.apiUrl}service-bookings/${id}/status`, status, this.httpOptions);
  }
}
