import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.css']
})
export class FormFooterComponent implements OnInit {
  @Output() saveAndContinue = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  saveAndContinueAction() {
    this.saveAndContinue.emit();
  }
}
