import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardGraphs } from 'src/app/shared/model/dashboard';

@Component({
  selector: 'app-dashboard-total-card',
  templateUrl: './dashboard-total-card.component.html',
  styleUrls: ['./dashboard-total-card.component.scss']
})
export class DashboardTotalCardComponent implements OnInit {
  @Input() total: DashboardGraphs;

  dashBoardTotals = [
    {
      image: 'patients',
      title: 'Patients',
      slug: 'patients',
      redirect: '/patients',
      query: 'null',
      total: 'patients_count'
    },
    {
      image: 'consultations',
      title: 'Doctor Consultations',
      slug: 'doctor_consultations',
      redirect: '/my-appointment',
      query: 'completed',
      total: 'consultation_bookings_count'
    },
    {
      image: 'lab-booking',
      title: 'Lab Bookings',
      slug: 'lab_bookings',
      redirect: '/lab-bookings',
      query: 'delivered',
      total: 'lab_bookings_count'
    },
    {
      image: 'service-booking',
      title: 'Service Bookings',
      slug: 'service_bookings',
      redirect: '/service-booking',
      query: 'completed',
      total: 'service_bookings_count'
    }
  ];

  constructor(private router: Router) {}

  ngOnInit() {}

  onClick(countInfo) {
    let q = {};

    switch (countInfo.redirect) {
      case '/my-appointment':
        q = { queryParams: { appointment_type: countInfo.query } };
        break;
      case '/lab-bookings':
      case '/service-booking':
        q = { queryParams: { status: countInfo.query } };
        break;
    }

    this.router.navigate([countInfo.redirect], q);
  }
}
