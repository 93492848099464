import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  criticalVitalList: Array<any> = [];
  groupedCriticalVitalList: Array<any> = [];
  filteredCriticalVitalList: Array<any> = [];

  availableVitalTypeNames: Array<string> = [];
  availableVitalTypeNamesFiltered: Observable<string[]>;

  filterForm: FormGroup = new FormGroup({
    vitalType: new FormControl('')
  });

  filterApplied = false;

  ICONS = {
    Respiratory: 'respiratory.svg',
    'Blood Pressure': 'blood-pressure.svg',
    'SpO2 Recording': 'oxygen.svg',
    'Sugar Level': 'drop-counter.svg',
    Temperature: 'temperature.svg',
    'Pulse Rate': 'pulse.svg',
    Weight: 'weight.svg'
  };

  constructor(private dashboardService: DashboardService) {}

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  ngOnInit() {
    this.getCriticalVitalList();
  }

  getCriticalVitalList() {
    this.dashboardService.getVitalList().subscribe(
      (response: any) => {
        this.criticalVitalList = response.data;
      },
      (error) => {
        this.criticalVitalList.length = 0;
      },
      () => {
        this.criticalVitalList.sort((a, b) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        this.groupCriticalVitals(this.criticalVitalList);
      }
    );
  }

  groupCriticalVitals(vitalList: Array<any>) {
    // Get Vital Types
    vitalList.forEach((vital: any) => {
      if (!this.availableVitalTypeNames.includes(vital.name)) {
        this.availableVitalTypeNames.push(vital.name);
      }
    });
    // Use Vital Types as type filter values
    this.availableVitalTypeNamesFiltered = this.setACField(
      this.filterForm.controls.vitalType,
      'availableVitalTypeNames'
    );

    // group according to patient ID
    const groupedVitalList: Array<any> = [];
    vitalList.forEach((value) => {
      //value.created_at = new Date(Date.parse((value.created_at + ' UTC').toLocaleString()));
      groupedVitalList['patient-' + value.patient_id] =
        groupedVitalList['patient-' + value.patient_id] || [];
      groupedVitalList['patient-' + value.patient_id].push(value);
    });
    Object.values(groupedVitalList).forEach((patientVitalGroup) => {
      this.filteredCriticalVitalList.push(Object.values(patientVitalGroup));
      this.groupedCriticalVitalList.push(Object.values(patientVitalGroup));
    });
  }

  filterAlerts() {
    this.filterApplied = true;
    const filter = {
      name: this.filterForm.controls.vitalType.value
    };

    if (!filter.name) {
      this.filteredCriticalVitalList = this.groupedCriticalVitalList;
      return;
    }

    const filteredList: Array<any> = [];
    this.groupedCriticalVitalList.forEach((group: Array<any>) => {
      const filtered = group.filter((vital: any) => {
        return vital.name === filter.name;
      });
      if (!!filtered.length) {
        filteredList.push(filtered);
      }
    });
    this.filteredCriticalVitalList = filteredList;
  }

  calculateAge(dob) {
    const newDate = new Date(dob);
    const timeDiff = Math.abs(Date.now() - newDate.getTime());
    const days = timeDiff / (1000 * 3600 * 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    let result = '';
    if (years === 0 && months > 0) {
      result = months + 'm';
    }
    if (years > 0 && months > 0) {
      result = years + 'y ' + (months % 12) + 'm';
    }
    if (years === 0 && months === 0) {
      result = '< 1m';
    }
    if (years > 0 && months === 0) {
      result = years + 'm';
    }
    return result;
  }

  clearFilter() {
    this.filteredCriticalVitalList = this.groupedCriticalVitalList;
    this.filterForm.patchValue({ vitalType: '' });
    this.filterApplied = false;
  }

  getColor(vital) {
    switch (vital.label) {
      case 'spo2_level':
        if (vital.value < 90) {
          return 'red';
        }
        if (vital.value >= 90 && vital.value <= 94) {
          return 'orange';
        }
        break;

      case 'temperature':
        if (vital.value > 102.8 || vital.value < 96.8) {
          return 'red';
        }
        if (vital.value >= 100.1 && vital.value <= 102.6) {
          return 'orange';
        }
        break;

      case 'sugar_level':
        if (vital.secondary_value == 'Fasting') {
          if (vital.value >= 126) {
            return 'red';
          }
          if (vital.value >= 101 && vital.value <= 125) {
            return 'orange';
          }
        }
        if (vital.secondary_value == 'After Meal') {
          if (vital.value > 200) {
            return 'red';
          }
          if (vital.value >= 140 && vital.value <= 200) {
            return 'orange';
          }
        }
        break;

      case 'bp_systolic':
        if (vital.value > 130) {
          return 'red';
        }
        if (vital.value >= 120 && vital.value <= 130) {
          return 'orange';
        }
        break;

      case 'pulse_rate':
        if (vital.value > 100) {
          return 'red';
        }
        break;
    }
  }
}
