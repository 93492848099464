import { LabBookingCount, PatientCount, DoctorConsultationCounts, ServiceBookingCounts, Graphs, DashboardGraphs } from './../../shared/model/dashboard';
import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GraphColors, GraphLabels } from 'src/app/shared/constant/dashboard-details.constants';

@Component({
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss'],
  providers: [MessageService]
})
export class DashboardGraphComponent implements OnInit {
  doctorConsultationDoghnutData: Graphs;
  serviceBookingDoghnutData: Graphs;
  labBookingGraphData: Graphs;
  patientlineGraphData: Graphs;
  options: any;
  bargraphDataOptions: any;

  @Input() chartData: DashboardGraphs;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.checkPermissions();
  }

  checkPermissions() {
    if(this.chartData.patients) {
      this.initializePatientChart();
    } if(this.chartData.lab_bookings) {
      this.initializeLabBookingChart();
    } if(this.chartData.doctor_consultations) {
      this.initializeDoctorConsultation();
    } if(this.chartData.service_bookings) {
      this.initializeServiceBooking();
    }
  }

  initializeLabBookingChart() {
    const labBookingData: LabBookingCount = this.chartData.lab_bookings.bar_graphs;
    const labBookingValues = [
      labBookingData.pending,
      labBookingData.in_progress,
      labBookingData.completed,
      labBookingData.cancelled
    ]
    // For Bar graph
    this.labBookingGraphData = {
      labels: [
        GraphLabels.labBooking.pending,
        GraphLabels.labBooking.inProgress,
        GraphLabels.labBooking.completed,
        GraphLabels.labBooking.cancelled
      ],
      datasets: [
        {
          backgroundColor: [
            GraphColors.green,
            GraphColors.lightGreen,
            GraphColors.blue,
            GraphColors.orange
          ],
          data: labBookingValues
        }
      ]
    };

    this.bargraphDataOptions = {
      legend: { display: false }
    };
  }

  initializePatientChart() {
    const inPatientValues: Array<number> = [];
    const outPatientValues: Array<number> = [];
    const patientValues: Array<PatientCount> = this.chartData.patients.line_graphs;
    const patientLineGraphDates: Array<string> = Object.keys(patientValues);
    const patientCounts: Array<PatientCount> = Object.values(patientValues);
    patientCounts.map((x) => {
      inPatientValues.push(x.in_patient);
      outPatientValues.push(x.out_patient);
    });

    // line chart
    this.patientlineGraphData = {
      labels: patientLineGraphDates,
      datasets: [
        {
          label: GraphLabels.patient.inPatients,
          data: inPatientValues,
          fill: false,
          borderColor: GraphColors.orange
        },
        {
          label: GraphLabels.patient.outPatients,
          data: outPatientValues,
          fill: false,
          borderColor: GraphColors.grey
        }
      ]
    };
  }

  initializeDoctorConsultation() {
    const doctorConsultationValues: DoctorConsultationCounts = this.chartData.doctor_consultations.pie_charts;
    const doctorConsultationData: Array<number> = [
      doctorConsultationValues.upcoming,
      doctorConsultationValues.instant_call,
      doctorConsultationValues.completed
    ]
    // For donut chart DoctorConsultation
    this.doctorConsultationDoghnutData = {
      labels: [
        GraphLabels.doctorConsultation.upcoming,
        GraphLabels.doctorConsultation.instantCall,
        GraphLabels.doctorConsultation.completed
      ],
      datasets: [
        {
          data: doctorConsultationData,
          backgroundColor: [
            GraphColors.grey,
            GraphColors.green,
            GraphColors.lightGreen
          ]
        }
      ]
    };

    this.options = {
      legend: { position: 'bottom' },
      responsive: false,
      maintainAspectRatio: false
    };
  }

  initializeServiceBooking() {
    const serviceBookingValues: ServiceBookingCounts = this.chartData.service_bookings.pie_charts;
    const serviceBookingData: Array<number> = [
      serviceBookingValues.pending,
      serviceBookingValues.in_progress,
      serviceBookingValues.completed,
      serviceBookingValues.cancelled
    ]

    // For donut chart ServiceBooking
    this.serviceBookingDoghnutData = {
      labels: [
        GraphLabels.serviceConsultation.pending,
        GraphLabels.serviceConsultation.inProgress,
        GraphLabels.serviceConsultation.completed,
        GraphLabels.serviceConsultation.cancelled
      ],
      datasets: [
        {
          data: serviceBookingData,
          backgroundColor: [
            GraphColors.green,
            GraphColors.lightGreen,
            GraphColors.blue,
            GraphColors.orange
          ]
        }
      ]
    };

    this.options = {
      legend: { position: 'bottom' },
      responsive: false,
      maintainAspectRatio: false
    };
  }
}
