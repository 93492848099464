export default class CountDownTimer {
  private readonly _timerInterval: any;
  private readonly _endTimeInMillis: any;
  private _currentTime: any;
  private _timerIdentifier: any | null = null;
  private _callback: null | ((timeLeftInMillis: number) => void) = null;
  constructor(endTimeInMillis: number, interval: number = 1000) {
    this._timerInterval = interval;
    this._endTimeInMillis = endTimeInMillis;
    this._currentTime = endTimeInMillis;
    this.startTimer();
  }
  startTimer() {
    this.stopTimer();
    this._currentTime = this._endTimeInMillis;
    this._timerIdentifier = setInterval(() => {
      this._currentTime = this._currentTime - this._timerInterval;
      if (this._currentTime < 0) {
        this.stopTimer();
      }
      if (this._callback) {
        this._callback(this._currentTime);
      }
    }, this._timerInterval);
  }
  stopTimer() {
    if (this._timerIdentifier !== null) {
      clearInterval(this._timerIdentifier);
      this._timerIdentifier = null;
    }
    this._currentTime = this._endTimeInMillis;
  }
  setListener(callback: (tileLeftInMillis: number) => void) {
    this._callback = callback;
    this._callback(this._currentTime);
  }
}
