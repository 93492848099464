import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../services/lab-booking.service';
import * as moment from 'moment';

@Component({
  selector: 'app-lab-booking-detail',
  templateUrl: './lab-booking-detail.component.html',
  styleUrls: ['./lab-booking-detail.component.css']
})
export class LabBookingDetailComponent implements OnInit {
  labBookingId: number;
  bookingDetail: any;
  samples: Array<any> = [];
  bookingLog: Array<any> = [];
  toggleTitle = {
    sample: 'Sample Collected',
    delivery: 'Delivery Details',
    booksInvestigation: 'Booked Investigations',
    labReport: 'Lab Report',
    bookingLogs: 'Booking Logs',
    dispatchDetails: 'Dispatch Details',
    lab_received: 'Lab Received'
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService
  ) {}

  ngOnInit() {
    this.labBookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getBookingDetail(this.labBookingId);
  }

  getBookingDetail(bookingId) {
    this.labBookingService.getBookingDetail(bookingId).subscribe(
      (response: any) => {
        this.bookingDetail = response.booking;
      },
      (error) => {
        console.log('error fetching lab booking detail.');
      },
      () => {
        this.samples = this.bookingDetail.samples;
        this.bookingLog = this.bookingDetail.lab_booking_log;
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  titleCase(str) {
    if (!str) {
      return;
    }
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  formatNote(note) {
    return note ? note.replace(/(\r\n|\n|\r)/gm, '<br />') : '---';
  }

  getAddress(addressString) {
    const address = JSON.parse(addressString);
    return address
      ? address.province +
          ', ' +
          address.city +
          '<br>' +
          address.address +
          ', ' +
          address.nearest_landmark
      : 'N/A';
  }
}
