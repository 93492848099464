import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientsRoutingModule } from './patients-routing.module';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { PatientCreateComponent } from './patient-create/patient-create.component';
import { VitalListComponent } from './vitals/vital-list/vital-list.component';
import { MedicationListComponent } from './medications/medication-list/medication-list.component';
import { LabBookingCreateComponent } from '../feature/lab-bookings/lab-booking-create/lab-booking-create.component';
import { EncounterListComponent } from './encounters/encounter-list/encounter-list.component';
import { DiagnosisListComponent } from './diagnoses/diagnosis-list/diagnosis-list.component';
import { LabReportListComponent } from './lab-reports/lab-report-list/lab-report-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from '../pagination/pagination.component';
import { WorkflowFormsModule } from './workflow-forms/workflow-forms.module';
import { FormActionButtonsComponent } from './patient-shared/form-action-buttons/form-action-buttons.component';
import { ServiceRequestListComponent } from './service-requests/service-request-list/service-request-list.component';
import { PastMedicalHistoryListComponent } from './past-medical-histories/past-medical-history-list/past-medical-history-list.component';
import { RadiologyListComponent } from './radiology/radiology-list/radiology-list.component';
import { SurgicalHistoryListComponent } from './surgical-histories/surgical-history-list/surgical-history-list.component';
import { ImmunizationListComponent } from './immunizations/immunization-list/immunization-list.component';
import { AllergyListComponent } from './allergies/allergy-list/allergy-list.component';
import { VitalGraphComponent } from './vitals/vital-graph/vital-graph.component';
import { ChartCreateComponent } from './vitals/chart-create/chart-create.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UsersModule } from '../users/users.module';
import { PrescriptionRefillRequestsComponent } from './prescription-refill-requests/prescription-refill-requests.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatMenuModule,
  MatSortModule,
  MatPaginatorModule,
  MatTabsModule,
  MatListModule,
  MatExpansionModule
} from '@angular/material';

@NgModule({
  declarations: [
    PatientCreateComponent,
    PatientListComponent,
    PatientDetailComponent,

    VitalListComponent,

    MedicationListComponent,
    LabReportListComponent,

    EncounterListComponent,

    DiagnosisListComponent,

    LabBookingCreateComponent,

    ServiceRequestListComponent,
    PastMedicalHistoryListComponent,
    RadiologyListComponent,
    SurgicalHistoryListComponent,
    ImmunizationListComponent,
    AllergyListComponent,
    VitalGraphComponent,
    ChartCreateComponent,
    PrescriptionRefillRequestsComponent
  ],
  imports: [
    CommonModule,
    PatientsRoutingModule,
    WorkflowFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    UsersModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule,
    WorkflowFormsModule
  ],
  exports: [LabBookingCreateComponent, PaginationComponent, FormActionButtonsComponent]
})
export class PatientsModule {}
