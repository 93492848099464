import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncounterService } from '../../../services/encounter.service';

@Component({
  selector: 'app-encounter-list',
  templateUrl: './encounter-list.component.html',
  styleUrls: ['./encounter-list.component.css']
})
export class EncounterListComponent implements OnInit {
  patientId: number;
  patientEncounters: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private encounterService: EncounterService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.getPatientEncounters(this.limit);
  }

  getPatientEncounters(limit) {
    this.loading = true;
    this.encounterService.getPatientEncounters(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.patientEncounters = response.data;
        this.total = response.size;
      },
      (error) => {
        this.patientEncounters.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientEncounters(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientEncounters(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientEncounters(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
