import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { environment } from './../environments/environment';
import { AuthService } from './auth/shared/services/auth.service';
import { map } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UrlsService } from './services/urls.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'EMR';
  userLoggedIn: boolean;

  headerCollapsed = false;
  loggedOutUrlList = ['/inquiry-form', '/subscription-form', '/instant-pay', '/call'];
  notloggedOutUrlList: boolean;
  toggleHeader() {
    this.headerCollapsed = !this.headerCollapsed;
  }

  constructor(public auth: AuthService, private router: Router, private urlService: UrlsService, private renderer: Renderer2) {
    router.events.subscribe((val) => {
      const urlSeperator = this.urlService.getCurrentUrl().split('?');
      this.notloggedOutUrlList =
        this.loggedOutUrlList.includes(urlSeperator[0]) ||
        this.loggedOutUrlList.includes(this.urlService.getCurrentUrl())
          ? true
          : false;
    });
    console.log('Environment Url: ' + environment.apiUrl);
    this.auth.isAuthenticated().subscribe((res: any) => {
      this.userLoggedIn = res;
    });
    console.log('UserLoggedIn status: ' + this.userLoggedIn);
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }
}
