import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/shared/services/auth.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  hide = true;
  hide_another = true;
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;

  otp: string;
  userId: any;

  formSubmitted = false;
  submitInProgress = false;

  registrationSuccess = false;
  alreadyRegistered = false;
  showRegistrationForm = true;

  // For password strength and validation
  PASSWORD_MIN_LENGTH = 8;
  containsUppercaseCharacter = false;
  containsNumericCharacter = false;
  isLongEnough = false;
  bothPasswordMatches = true;

  // consumerAppDownloadUrl = environment.consumerAppDownloadUrl || 'https://play.google.com/store/apps/details?id=com.logicabeans.healthathome';

  // TODO: Adding static URL for now, need to add in environment file later
  consumerAppDownloadUrl =
    'https://play.google.com/store/apps/details?id=com.logicabeans.healthathome';

  patientUserRegistrationForm: FormGroup = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]),
    confirm_password: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.required])
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private toastr: ToastrService
  ) {
    this.auth.showSidebarMenu = false;
    this.auth.showHeader = false;
  }

  ngOnInit() {
    this.otp = this.activatedRoute.snapshot.queryParamMap.get('otp');
    this.userId = this.activatedRoute.snapshot.queryParamMap.get('user_id');
    this.fillPatientForm();
    // this.fetchPatientInfo(this.otp);
  }

  fillPatientForm() {
    this.patientUserRegistrationForm.patchValue({
      user_id: this.userId,
      otp: this.otp
    });
  }

  submit() {
    this.formSubmitted = true;
    this.submitInProgress = true;
    if (
      this.isLongEnough &&
      this.containsUppercaseCharacter &&
      this.containsNumericCharacter &&
      this.patientUserRegistrationForm.status === 'VALID'
    ) {
      this.patientService.setNewPassword(this.patientUserRegistrationForm.value).subscribe(
        (response: any) => {
          this.responseMessage = response.message;
          this.successMessageDisplay = true;
          this.submitInProgress = false;
        },
        (error) => {
          this.registrationSuccess = false;
          this.submitInProgress = false;
          if (error.email && !error.mobile) {
            this.toastr.error(error.email);
          } else if (error.mobile && !error.email) {
            this.toastr.error(error.mobile);
          } else if (error.mobile && error.email) {
            this.toastr.error(error.mobile + ' and ' + error.email);
          }
          // this.toastr.error(error.error.message);
          this.toastr.error(error.error.message);
          console.log(error);
        },
        () => {
          this.registrationSuccess = true;
          this.showRegistrationForm = false;
          this.submitInProgress = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }

  getPatientName(firstName: string, middleName: string, lastName: string): string {
    let name = firstName;
    name += middleName ? ' ' + middleName + ' ' + lastName : ' ' + lastName;

    return name;
  }

  checkPasswordStrength(event) {
    event.preventDefault();
    const enteredPassword = event.target.value;
    const digitContainingRegex = new RegExp('.*\\d+.*');
    const uppercaseContainingRegex = new RegExp('.*[A-Z].*');
    this.isLongEnough = enteredPassword.length >= this.PASSWORD_MIN_LENGTH;
    this.containsNumericCharacter = digitContainingRegex.test(enteredPassword);
    this.containsUppercaseCharacter = uppercaseContainingRegex.test(enteredPassword);
    this.bothPasswordMatches =
      event.target.value === this.patientUserRegistrationForm.controls.confirm_password.value;
  }

  checkConfirmPassword(event) {
    event.preventDefault();
    const enteredConfirmPassword = event.target.value;
    const newPassword = this.patientUserRegistrationForm.controls.password.value;
    this.bothPasswordMatches = enteredConfirmPassword === newPassword;
  }
}
