import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MedicationService } from '../../../services/medication.service';
import { LabReportService } from '../../../services/lab-report.service';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { UrlsService } from '../../../services/urls.service';
import { PageService } from '../../workflow-forms/page.service';
import { ToastrService } from 'ngx-toastr';

function createValidate(referredBy: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!referredBy.includes(control.value)) {
      return { match_not_found: true };
    }
    return null;
  };
}
@Component({
  selector: 'app-medication-create',
  templateUrl: './medication-create.component.html',
  styleUrls: ['./medication-create.component.css']
})
export class MedicationCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  patientId: number;
  medicationList: any;
  showSuggestionForm: boolean;
  submitted = false;
  selectedMedications: Array<any> = [];

  diagnosisList: Array<object> = [];
  referredByFiltered: Observable<string[]>;

  referredBy: string[] = [];
  medications: Array<object> = [];

  // For Save and Continue
  saveAndContinueClicked = false;

  medicationForm = new FormGroup({
    referred_by: new FormControl('', [Validators.required, createValidate([])]),
    medication: new FormControl('')
  });

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private medicationService: MedicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private diagnosisService: DiagnosisService,
    private urlService: UrlsService,
    private page: PageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('Patient Id: ' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Medications', this.patientId);
    });

    this.getDoctorList();
    this.getReferredByList();
    this.getDiagnosisList();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getDiagnosisList() {
    this.submitInProgress = true;
    this.diagnosisService.getDiagnosisListAll(this.patientId).subscribe(
      (y: any) => {
        this.diagnosisList = y.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.referredBy = response;
        this.referredByFiltered = this.setACField(
          this.medicationForm.controls.referred_by,
          'referredBy'
        );
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  getReferredByList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.medicationForm.controls.referred_by.setValidators([
          Validators.required,
          createValidate(response || [])
        ]);
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  storeMedication() {
    this.submitted = true;
    this.submitInProgress = true;
    this.showSuggestionForm = false;
    const data = this.medicationForm.value;
    data.referred_by = this.medicationForm.controls.referred_by.value;
    data.medications = this.selectedMedications;

    if (
      this.medicationForm.invalid ||
      this.medicationForm.controls.referred_by.invalid ||
      this.selectedMedications.length === 0
    ) {
      this.submitInProgress = false;
      return;
    }

    this.medicationService.storeMedication(data, this.patientId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
        this.submitInProgress = false;
      },
      (error) => {
        this.submitInProgress = false;
        this.toastr.error(error.error.message);
      },
      () => {
        this.submitInProgress = false;
        this.submitted = false;
        if (this.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate([this.nextUrl]);
        }
        this.saveAndContinueClicked = false;
      }
    );
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.medicationService.getMedicationList(keyword, this.selectedMedications).subscribe((x) => {
        this.medicationList = x;
        this.showSuggestionForm = true;
      });
    } else {
      this.showSuggestionForm = false;
    }
  }

  selectSuggestion(id) {
    const x = this.medicationList.find((y) => {
      return y.id === id;
    });
    x.state = 'expanded';
    x.expandIcon = 'angle-up';
    x.expandMessage = 'Collapse Details';
    this.showSuggestionForm = false;
    this.medicationForm.patchValue({
      medication: ''
    });
    this.medicationForm.addControl(`${x.id}_frequency`, new FormControl('', Validators.required));
    this.medicationForm.addControl(`${x.id}_duration`, new FormControl('', Validators.required));

    this.selectedMedications.unshift(x);
  }

  setFrequency(e, x): void {
    x.frequency = e.target.value.toString();
    console.log('Frequency is set to', x);
  }
  setDuration(e, x): void {
    x.duration = e.target.value;
  }

  setDiagnosis(e, x): void {
    x.diagnosis = e.target.value;
    console.log(x);
  }

  deleteMedication(medication): void {
    this.medicationForm.removeControl(`${medication.id}_frequency`);
    this.medicationForm.removeControl(`${medication.id}_duration`);
    this.selectedMedications = this.selectedMedications.filter((y) => {
      return y !== medication;
    });
  }

  toggleState(medication): void {
    medication.state = medication.state === 'collapsed' ? 'expanded' : 'collapsed';
    if (medication.state === 'expanded') {
      medication.expandIcon = 'angle-up';
      medication.expandMessage = 'Collapse Details';
    } else {
      medication.expandIcon = 'angle-down';
      medication.expandMessage = 'Expand Details';
    }
  }

  showNotification(message: string) {
    this.toastr.info(message);
  }
}
