import { E } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CallCenterService } from 'src/app/services/call-center.service';
import { CallCenter } from 'src/app/shared/model/call-center';
import moment from 'moment';

@Component({
  selector: 'app-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.css']
})
export class CallCenterComponent implements OnInit {
  callCenters: Array<CallCenter> = [];
  searchQuery: string = null;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  clearDate = false;
  rawSearchQuery: string;
  advancedSearchForm = new FormGroup({
    q: new FormControl('')
  });

  constructor(
    private callCenterService: CallCenterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.searchQuery = searchQuery ? searchQuery : null;

    this.getCallCenter();
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  clearSearch() {
    this.searchQuery = null;
    this.page = 1;
    this.limit = 10;

    this.getCallCenter();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getCallCenter();
  }

  onNext(): void {
    this.page++;
    this.getCallCenter();
  }

  onPrev(): void {
    this.page--;
    this.getCallCenter();
  }

  proceedToSearch(event) {
    if (this.searchQuery) {
      event.preventDefault();
      this.page = 1;
      this.getCallCenter();
    }
  }

  getCallCenter(): void {
    this.loading = true;
    this.addParamsToUrl(this.page, this.limit, this.searchQuery);

    this.callCenterService.getCallCenterList(this.page, this.limit, this.searchQuery).subscribe(
      (callCenter: any) => {
        this.total = callCenter.size;
        this.callCenters = callCenter.data;
      },
      (err) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  onCallCenterClick(id: number) {
    this.router.navigate(['contact-us/' + id]);
  }

  formatData() {
    const data: any = {
      q: this.advancedSearchForm.value.q ? this.advancedSearchForm.value.q : ''
    };
    return data;
  }

  counter(i: number) {
    return new Array(i);
  }

  getColor(rating, i) {
    return i <= rating ? 'icon-color' : '';
  }

  sliceDescription(message: string) {
    return message.length > 50 ? message.slice(0, 49) + '...' : message;
  }

  addParamsToUrl(pageNo = null, limit = null, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        q,
        pageNo,
        limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
