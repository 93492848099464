import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CallQueryService } from 'src/app/services/call-query.service';
import { NotifyService } from 'src/app/services/notify.service';
import { PrintService } from 'src/app/shared/components/print-component/print.service';
import { CallQuery } from 'src/app/shared/model/call-query';

@Component({
  selector: 'app-call-query-detail',
  templateUrl: './call-query-detail.component.html',
  styleUrls: ['./call-query-detail.component.css']
})
export class CallQueryDetailComponent implements OnInit {
  callQueryId: number;
  callQueryDetail: CallQuery;
  queryStatus: string = null;

  querystatus = [
    {
      slug: 'pending',
      display: 'Pending'
    },
    {
      slug: 'processed',
      display: 'Processed'
    }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private callQeryService: CallQueryService,
    private toastr: ToastrService,
    private printService: PrintService
  ) {}

  ngOnInit() {
    this.callQueryId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getCallQueryDetail(this.callQueryId);
  }

  getCallQueryDetail(id: number) {
    this.callQeryService.getCallQueryDetail(id).subscribe(
      (response: CallQuery) => {
        this.callQueryDetail = response;
      },
      (error) => {
        console.log('error fetching Call Query Details data');
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  printDiv(divName) {
    console.log('PushData -- >');
    var printContents = document.getElementById(divName).innerHTML;
    this.printService.pushData = printContents;
  }

  updateStatus(callQueryDetail, status) {
    console.log('ChangeData-->', callQueryDetail);
    if (confirm('Are you sure to update this status?')) {
      this.callQueryDetail.status = status;
      this.callQeryService.updateStatus(callQueryDetail.id, callQueryDetail).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        }
        // ,() => {
        //   if (this.queryStatus) {
        //     this.removeQueryRequest(callQueryDetail);
        //   }
        // }
      );
    }
  }

  // removeQueryRequest(callQuery: CallQuery) {
  //   this.callQueryDetail = this.callQuery.filter((queryRequest: any) => {
  //     return queryRequest.id !== callQuery.id;
  //   });
  // }
}
