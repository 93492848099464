import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InquiryFormService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  save(inquiryData): Observable<any> {
    return this.http.post(`${this.apiUrl}inquiry-form`, inquiryData, this.httpOptions);
  }

  saveSubscription(subscriptionData): Observable<any> {
    return this.http.post(`${this.apiUrl}subscription-form`, subscriptionData, this.httpOptions);
  }

  saveInstantPay(instantPayData): Observable<any> {
    return this.http.post(`${this.apiUrl}instant-pay`, instantPayData, this.httpOptions);
  }
}
