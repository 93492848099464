import { Doctors, DoctorList } from './../../shared/model/physiotherapy';
import { DoctorListService } from './../../services/doctor-list.service';
import { Component, OnInit } from '@angular/core';
import { Physiotherapy } from '../../shared/model/physiotherapy';
import { ActivatedRoute, Router } from '@angular/router';
import { PhysiotherapyService } from '../../services/physiotherapy.service';
import { NotifyService } from '../../services/notify.service';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/shared/model/profile';

@Component({
  selector: 'app-physiotherapy',
  templateUrl: './physiotherapy.component.html',
  styleUrls: ['./physiotherapy.component.css', '../nurse-worksheet/nurse-worksheet.component.css']
})
export class PhysiotherapyComponent implements OnInit {
  physiothrepyList: Physiotherapy[];
  profileList: Array<Profile> = [];
  userSearchKeyWord: string = null;

  statusList: Array<any> = [];
  physiotherapySearchQuery: string;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  status: string;
  doctorList: any;
  showProfileMessage: boolean;
  profileId: boolean;
  loadingUserSearch: boolean;
  userSearchHit: boolean;
  displaySuggestion: boolean;
  suggestedList: Array<Doctors>;
  recommenderName: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private physiotherapyService: PhysiotherapyService,
    private router: Router,
    private doctorListService: DoctorListService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const status: any = this.activatedRoute.snapshot.queryParamMap.get('status');
    const physiotherapySearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.status = status ? status : this.status;
    this.physiotherapySearchQuery = physiotherapySearchQuery ? physiotherapySearchQuery : null;

    this.getStatusList();
    this.getProfileList();
  }
  StopPropagation(e: Event) {
    e.stopPropagation();
  }
  updateStatus(physiotherapy, status) {
    if (confirm('Are you sure to update this status?')) {
      const data = { status };
      this.physiotherapyService.updateStatus(physiotherapy.id, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          if (this.status) {
            this.removeRefillRequest(physiotherapy);
          }
        }
      );
    }
  }

  removeRefillRequest(refill) {
    this.physiothrepyList = this.physiothrepyList.filter((refillRequest: any) => {
      return refillRequest.id !== refill.id;
    });
  }

  getSelectedIndex() {
    return this.statusList.indexOf(
      this.statusList.find((status) => {
        return status.slug === this.status;
      })
    );
  }

  filterRefillList(slug) {
    this.status = slug;
    this.page = 1;
    this.getPhysiotherapyList();
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery);
  }

  getStatusList() {
    this.loading = true;
    this.physiotherapyService.getStatusList().subscribe(
      (response: any) => {
        this.statusList = response;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.statusList.length = 0;
        this.physiothrepyList.length = 0;
      },
      () => {
        this.statusList.unshift({
          slug: null,
          display: 'All'
        });
        this.getPhysiotherapyList();
      }
    );
  }

  getPhysiotherapyList() {
    this.loading = true;
    this.physiotherapyService.getPhysiothreapyList(this.page, this.limit, this.status, this.physiotherapySearchQuery, this.recommenderName).subscribe(
      (response: any) => {
        this.physiothrepyList = response.data;
        this.total = response.size;
        this.loading = false;
      },
      (error) => {
        this.physiothrepyList.length = 0;
        this.loading = false;
      }
    );
  }

  clearSearch() {
    this.physiotherapySearchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchPhysiotherapy();
  }

  searchPhysiotherapy() {
    this.getPhysiotherapyList();
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery, this.recommenderName);
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchPhysiotherapy();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPhysiotherapyList();
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getPhysiotherapyList();
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getPhysiotherapyList();
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery);
  }

  addParamsToUrl(pageNo, limit, status, q, recommenderName = null) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit, status, q, recommendedBy: recommenderName },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  selectRefillType() {
    this.page = 1;
    this.limit = 10;
    this.status = null;
    this.addParamsToUrl(this.page, this.limit, this.status, this.physiotherapySearchQuery);
    this.getPhysiotherapyList();
  }

  onPhysiotherapyClick(id: number) {
    this.router.navigate(['physiotherapy/' + id]);
  }

  getProfileList() {
    const profile_type = 'service_provider';
    this.doctorListService.getDoctorList(this.page, this.limit, this.userSearchKeyWord).subscribe(
      (response) => {
        this.doctorList = response.data;
      },
      (error) => {},
      () => {}
    );
  }

  statusProfileChange() {
    this.showProfileMessage = false;
    this.searchUsers();
  }

  searchUsers() {
    this.showProfileMessage = false;
    if (this.userSearchKeyWord.length > 0) {
      this.loadingUserSearch = true;
      this.userSearchHit = true;
      this.doctorListService.getDoctorList(this.page = null, this.limit = null, this.userSearchKeyWord).subscribe(
        (response: DoctorList) => {
          this.loadingUserSearch = false;
          this.suggestedList = response.data;
        },
        (error) => {
          this.loadingUserSearch = false;
          this.suggestedList.length = 0;
        },
        () => {
          if (this.suggestedList.length !== 0) {
            this.displaySuggestion = true;
          }
        }
      );
    } else {
      this.displaySuggestion = false;
    }
  }

  searchSheetUser(recommender) {
    this.recommenderName = recommender.name;
    const queryParams = {
      recommenderName: recommender.name
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
    (document.getElementById('userSearchModalCloseBtn') as HTMLInputElement).click();
    this.searchPhysiotherapy();
  }
}
