import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImmunizationService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  store(patientId, data) {
    const url = `${this.apiUrl}patients/${patientId}/immunizations`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }

  getPatientImmunizationList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}patients/${patientId}/immunizations`, {
      params: { pageNo, limit }
    });
  }
}
