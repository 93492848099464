import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkflowFormsModule } from '../patients/workflow-forms/workflow-forms.module';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import {
  MatCardModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import { ValidFullNameDirective } from './shared/directives/valid-full-name.directive';
import { ValidJobTitleDirective } from './shared/directives/valid-job-title.directive';
import { ValidNmcNumberDirective } from './shared/directives/valid-nmc-number.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ValidNumberDirective } from './shared/directives/valid-number.directive';
import { DatePickerMonthYearComponent } from './shared/component/date-picker-month-year/date-picker-month-year.component';
import { LoginUserDetailComponent } from './login-user-detail/login-user-detail.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserCreateComponent,
    UserDetailComponent,
    UserChangePasswordComponent,
    UserEditComponent,
    ValidFullNameDirective,
    ValidJobTitleDirective,
    ValidNmcNumberDirective,
    ValidNumberDirective,
    DatePickerMonthYearComponent,
    LoginUserDetailComponent,
    ProfileEditComponent
  ],
  exports: [ValidFullNameDirective, ValidNumberDirective, DatePickerMonthYearComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    WorkflowFormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule
  ]
})
export class UsersModule {}
