import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.auth.loggingOut = true;
    this.auth.isAuthenticated().subscribe((res) => {
      if (res) {
        this.auth.sendLogOutRequest().subscribe(
          (res) => {
            this.storageService.clear();
            this.auth.VoIPLogout();
            window.location.href = 'auth/login';
          },
          (err) => {},
          () => {}
        );
      }
    });
  }
}
