import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Session } from '../../type/connectycube';
import { PipeService } from 'src/app/shared/services/pipe/pipe.service';
import { VoIPConversationDetails } from 'src/app/shared/model/VoIPConversationDetails';
import { FormDataUtil } from 'src/app/shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient, private pipeService: PipeService) {}

  getUserDetail(connectyCubeId: number) {
    return this.http.get(`${this.apiUrl}video-app/users/${connectyCubeId}`);
  }

  initiateVoIPConversation(session: Session): Observable<any> {
    const data: VoIPConversationDetails = {
      sender_id: session.currentUserID,
      receiver_id: session.opponentsIDs[0],
      conversation_hash: session.ID,
      type: 'call'
    };

    return this.http.post(`${this.apiUrl}video-app/conversations`, data, this.httpOptions);
  }

  startVoIPConversation(session: Session): Observable<any> {
    if (!session) {
      return;
    }
    const conversationHash = session.ID;
    const data = {
      field: 'started_at'
    };
    // const httpPrams = new HttpParams().set();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.put(
      `${this.apiUrl}video-app/conversations/${conversationHash}`,
      data,
      httpOptions
    );
  }

  endVoIPConversation(session: Session): Observable<any> {
    if (!session) {
      return;
    }
    const conversationHash = session.ID;
    const data = {
      field: 'ended_at'
    };
    // const httpPrams = new HttpParams().set();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.put(
      `${this.apiUrl}video-app/conversations/${conversationHash}`,
      data,
      httpOptions
    );
  }
}
