import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DoctorFormComponent } from '../doctor-form/doctor-form.component';
import { DoctorListService } from '../../../../services/doctor-list.service';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent implements OnInit {
  @Input() clearUrl: boolean;
  doctorList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  doctorSearchQuery: string;

  constructor(
    private doctorService: DoctorListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const doctorSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.doctorSearchQuery = doctorSearchQuery ? doctorSearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getDoctor();
  }

  openModal(doctor) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '300px';
    dialogConfig.width = '300px';
    dialogConfig.data = doctor;

    const dialogRef = this.dialog.open(DoctorFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getDoctor();
      }
    });
  }

  getDoctor(): void {
    this.loading = true;
    this.doctorService.getDoctorList(this.page, this.limit, this.doctorSearchQuery).subscribe(
      (response: any) => {
        console.log(response.data);
        this.doctorList = response.data;
        this.total = response.size;
      },
      (error) => {
        this.loading = false;
        this.doctorList.length = 0;
      },
      () => {
        this.loading = false;
      }
    );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchDoctor();
  }

  searchDoctor() {
    this.getDoctor();
    this.addParamsToUrl(this.page, this.limit, this.doctorSearchQuery);
  }

  clearSearch() {
    this.doctorList = null;
    this.page = 1;
    this.limit = 10;
    this.doctorSearchQuery = null;
    this.searchDoctor();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDoctor();
    this.addParamsToUrl(this.page, this.limit, this.doctorSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getDoctor();
    this.addParamsToUrl(this.page, this.limit, this.doctorSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getDoctor();
    this.addParamsToUrl(this.page, this.limit, this.doctorSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
