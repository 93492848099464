import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-wrapper',
  templateUrl: './toggle-wrapper.component.html',
  styleUrls: ['./toggle-wrapper.component.css']
})
export class ToggleWrapperComponent implements OnInit {
  @Input() toggle: boolean = true;
  @Input() title: string;
  @Input() colspan: string;
  constructor() {}

  ngOnInit() {}

  onToggle() {
    this.toggle = !this.toggle;
  }
}
