import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class DoctorListService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDoctorList(pageNo, limit, query): Observable<any> {
    const data = {
      pageNo: pageNo || '',
      limit: limit || '',
      q: query || ''
    }
    const params = FormDataUtil.filterEmptyParams(data);
    return this.http.get(`${this.apiUrl}doctors-list`, { params });
  }

  save(name, id): Observable<any> {
    if (id) {
      const api = this.apiUrl + 'doctors-list/' + id;
      return this.http.put(api, name, this.httpOptions);
    } else {
      return this.http.post(`${this.apiUrl}doctors-list`, name, this.httpOptions);
    }
  }
}
