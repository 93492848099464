import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../services/notify.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InvestigationService } from '../../../services/investigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-csv-form',
  templateUrl: './upload-csv-form.component.html',
  styleUrls: ['./upload-csv-form.component.css']
})
export class UploadCsvFormComponent implements OnInit {
  uploadCsv: any;
  submitInProgress = false;
  submitted = false;
  lab_id;
  uploadCsvForm = new FormGroup({
    name: new FormControl('', [Validators.required])
  });
  csv_file: any;
  backendValidationErrors: Array<any> = [
    {
      controlName: 'csv_file',
      error: false
    }
  ];

  constructor(
    private toastr: ToastrService,
    private investigationService: InvestigationService,
    private router: Router,
    private dialogRef: MatDialogRef<UploadCsvFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.lab_id = data;
    console.log(this.lab_id);
  }

  ngOnInit() {
    if (this.uploadCsv) {
      this.patchData();
    }
  }

  patchData() {
    this.uploadCsvForm.patchValue({
      name: this.uploadCsv.name
    });
  }

  submit() {
    this.submitted = true;
    if (this.csv_file) {
      this.submitInProgress = true;
      const requestData = this.createRequestData({
        csv_file: this.csv_file,
        lab_id: this.lab_id
      });
      this.investigationService.saveCsv(requestData).subscribe(
        (res: any) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.error);
          this.submitInProgress = false;
          this.submitted = false;
        },
        () => {
          this.dialogRef.close(true);
          this.submitInProgress = false;
          this.submitted = false;
        }
      );
    }
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  close() {
    this.dialogRef.close();
  }

  uploadImage(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.csv_file = files[0];
    } else {
      this.csv_file = '';
    }
  }

  removeImage(event) {
    event.preventDefault();
    this.csv_file = '';
    this.submitted = true;
  }
}
