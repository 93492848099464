import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceBookingService {
  private apiUrl = environment.apiUrl; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getServiceBookingDetail(serviceBookingId: number) {
    return this.http.get(this.apiUrl + 'service-bookings/' + serviceBookingId);
  }
}
