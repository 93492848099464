export const PermissionsConstants = {
  // Dashboard Permission List
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_CALL_LOG: 'DASHBOARD#CALL_LOG',
  DASHBOARD_LAB_BOOKING_COUNT: 'DASHBOARD#LAB_BOOKING_COUNT',
  DASHBOARD_VITAL_ALERT_COUNT: 'DASHBOARD#VITAL_ALERT_COUNT',
  DASHBOARD_PATIENT_COUNT: 'DASHBOARD#PATIENT_COUNT',
  DASHBOARD_PRESCRIPTION_REFILL_COUNT: 'DASHBOARD#PRESCRIPTION_REFILL_COUNT',
  DASHBOARD_CONSUMER_MESSAGE_COUNT: 'DASHBOARD#CONSUMER_MESSAGE_COUNT',
  DASHBOARD_TODO_LIST: 'DASHBOARD#TODO_LIST',
  DASHBOARD_CONSULTATION_COUNT: 'DASHBOARD#CONSULTATION_COUNT'
};
