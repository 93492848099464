import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../workflow-forms/page.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SurgicalHistoryService } from '../../../services/surgical-history.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-surgical-history-create',
  templateUrl: './surgical-history-create.component.html',
  styleUrls: ['./surgical-history-create.component.css']
})
export class SurgicalHistoryCreateComponent implements OnInit {
  patientId: number;

  urlList: Array<string> = [];
  nextUrl: string;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  // For form Validation
  submitted = false;

  surgicalHistoryForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{4}$')]),
    location: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required])
  });

  formErrorObj: Array<any> = [
    {
      controlName: 'type',
      error: false
    },
    {
      controlName: 'year',
      error: false
    },
    {
      controlName: 'location',
      error: false
    },
    {
      controlName: 'reason',
      error: false
    },
    {
      controlName: 'file_discharge_summary',
      error: false
    }
  ];

  file: File;

  locationList: string[] = [];
  locationListFiltered: Observable<string[]>;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private page: PageService,
    private urlService: UrlsService,
    private surgicalHistoryService: SurgicalHistoryService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getMenu();
    this.getLocationList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Surgical History', this.patientId);
    });
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getLocationList() {
    this.submitInProgress = true;
    this.surgicalHistoryService.getLocationList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.locationList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
        this.locationList.length = 0;
        this.toastr.error(error.error.message);
      },
      () => {
        this.locationListFiltered = this.setACField(
          this.surgicalHistoryForm.controls.location,
          'locationList'
        );
      }
    );
  }

  submit() {
    this.submitted = true;
    this.submitInProgress = true;
    if (this.surgicalHistoryForm.status === 'VALID') {
      const data = this.surgicalHistoryForm.value;
      data.file_discharge_summary = this.file;

      const requestData = this.createRequestData(data);

      this.surgicalHistoryService.store(this.patientId, requestData).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          if (error.status === 422) {
            const errors = error.error.errors;
            if (errors.type) {
              this.setError('type', errors.type[0]);
            } else {
              this.unsetError('type');
            }
            if (errors.year) {
              this.setError('year', errors.year[0]);
            } else {
              this.unsetError('year');
            }
            if (errors.location) {
              this.setError('location', errors.location[0]);
            } else {
              this.unsetError('location');
            }
            if (errors.reason) {
              this.setError('reason', errors.reason[0]);
            } else {
              this.unsetError('reason');
            }
            if (errors.file_discharge_summary) {
              this.setError('file_discharge_summary', errors.file_discharge_summary[0]);
            } else {
              this.unsetError('file_discharge_summary');
            }
          }
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
