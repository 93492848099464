import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceRequestService } from '../../../services/service-request.service';

@Component({
  selector: 'app-service-request-list',
  templateUrl: './service-request-list.component.html',
  styleUrls: ['./service-request-list.component.css']
})
export class ServiceRequestListComponent implements OnInit {
  patientId: number;
  patientServiceRequests: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serviceRequestService: ServiceRequestService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientServiceRequests(this.limit);
  }

  getPatientServiceRequests(limit) {
    this.loading = true;
    this.serviceRequestService
      .getPatientServiceRequestList(this.patientId, this.page, limit)
      .subscribe(
        (response: any) => {
          this.patientServiceRequests = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.patientServiceRequests.length = 0;
        },
        () => {
          this.loading = false;
          this.patientServiceRequests.forEach((request: any) => {
            request.expandIcon = 'chevron-down';
            request.expandMessage = 'Expand Details';
            request.expanded = false;
          });
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientServiceRequests(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientServiceRequests(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientServiceRequests(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(request, event) {
    event.preventDefault();
    request.expanded = !request.expanded;
    if (request.expanded) {
      request.expandIcon = 'chevron-up';
      request.expandMessage = 'Collapse Details';
    } else {
      request.expandIcon = 'chevron-down';
      request.expandMessage = 'Expand Details';
    }
  }

  getCommaSeparatedList(dataList: Array<any>) {
    const nameList = [];
    dataList.forEach((data: any) => {
      nameList.push(data.name);
    });
    return nameList.join(', ');
  }
}
