import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, pipe } from 'rxjs';
import { CallQueryService } from 'src/app/services/call-query.service';
import { CallQuery, CallQueryList } from 'src/app/shared/model/call-query';
import { CallQueryRetrieveCriteria, Pageable } from './call-query-retrive-criteria';

@Component({
  selector: 'app-call-query-list',
  templateUrl: './call-query-list.component.html',
  styleUrls: ['./call-query-list.component.css']
})
export class CallQueryListComponent implements OnInit {
  pageable: Pageable;
  callQueries: Array<CallQuery> = [];
  callQeryRetriveCriteria: CallQueryRetrieveCriteria;

  statusList = [
    {
      slug: 'pending',
      display: 'Pending'
    },
    {
      slug: 'processed',
      display: 'Processed'
    }
  ];
  initialPageInfo = {
    pagesToShow: 3,
    page: 1,
    perPage: 10,
    count: 0
  };

  // Pagination Variables
  loading = true;
  total: number;

  advancedSearchForm = new FormGroup({
    query: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl('')
  });
  tomorrow: Date = new Date();
  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;

  constructor(
    private callQueryService: CallQueryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  ngOnInit() {
    this.initRetriveCriteria();
    this.initList();
    this.hideToggleSearchFilters();
  }

  initRetriveCriteria() {
    const pageNo: any =
      this.activatedRoute.snapshot.queryParamMap.get('pageNo') || this.initialPageInfo.page;
    const limit: any =
      this.activatedRoute.snapshot.queryParamMap.get('limit') || this.initialPageInfo.perPage;
    this.advancedSearchForm.patchValue({
      query: this.activatedRoute.snapshot.queryParamMap.get('query') || null,
      fromDate: this.activatedRoute.snapshot.queryParamMap.get('fromDate') || null,
      toDate: this.activatedRoute.snapshot.queryParamMap.get('toDate') || null
    });
    this.pageable = {
      ...this.initialPageInfo,
      page: pageNo,
      perPage: limit
    };
    this.callQeryRetriveCriteria = new CallQueryRetrieveCriteria(
      new BehaviorSubject(this.pageable),
      new BehaviorSubject(this.advancedSearchForm.value)
    );
  }

  initList() {
    this.fetchListItem().subscribe(
      ([response]) => {
        this.loading = false;
        this.callQueries = response.data;
      },
      () => {
        this.loading = false;
      }
    );
  }

  fetchListItem() {
    return this.callQeryRetriveCriteria.onChange((pageable, values) => {
      this.loading = true;
      this.setUrlParams(pageable, values);
      return this.callQueryService.getCallQueryList(pageable.page, pageable.perPage, values);
    });
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  clearSearch() {
    this.advancedSearchForm.patchValue({ query: null });
    this.applyAdvancedSearch();
  }

  goToPage(n: number): void {
    this.callQeryRetriveCriteria.pageEvent$.next({ ...this.pageable, page: n });
  }

  onNext(): void {
    this.callQeryRetriveCriteria.pageEvent$.next({
      ...this.pageable,
      page: this.pageable.page + 1
    });
  }

  onPrev(): void {
    this.callQeryRetriveCriteria.pageEvent$.next({
      ...this.pageable,
      page: this.pageable.page - 1
    });
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  onCallQueryClick(id: number) {
    this.router.navigate(['call-query/' + id]);
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  applyAdvancedSearch() {
    this.initialPageInfo.page = 1;
    const isFormDirty = Object.keys(this.advancedSearchForm.controls).some(
      (key) => this.advancedSearchForm.controls[key].dirty
    );
    if (!isFormDirty) {
      return;
    }
    this.callQeryRetriveCriteria.searchCriteria$.next({
      query: this.advancedSearchForm.value.query || null,
      fromDate: this.advancedSearchForm.value.fromDate || null,
      toDate: this.advancedSearchForm.value.toDate || null
    });
    this.callQeryRetriveCriteria.pageEvent$.next(this.initialPageInfo);
  }

  resetAdvancedSearchForm() {
    this.advancedSearchForm.reset();
    this.callQeryRetriveCriteria.searchCriteria$.next({
      query: this.advancedSearchForm.value.query || null,
      fromDate: this.advancedSearchForm.value.fromDate || null,
      toDate: this.advancedSearchForm.value.toDate || null
    });
    this.callQeryRetriveCriteria.pageEvent$.next(this.initialPageInfo);
  }

  setUrlParams(pageable: Pageable, formValues) {
    const queryParams = {
      pageNo: pageable.page > 1 ? pageable.page : null,
      limit: pageable.perPage && pageable.page > 1 ? pageable.perPage : null,
      query: formValues.query || null,
      fromDate: formValues.fromDate || null,
      toDate: formValues.toDate || null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  updateStatus(callQuery, status) {
    if (confirm('Are you sure to update this status?')) {
      const data = { status };
      this.callQueryService.updateStatus(callQuery.id, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        }
      );
    }
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
