import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.css']
})
export class TreeNodeComponent implements OnInit {
  constructor() {}

  @Input() node;
  @Input() expand;
  @Output() parentEvent = new EventEmitter<any>();
  @Output() callingComponentUpdate = new EventEmitter<any>();

  ngOnInit() {
    this.node.expand = true;
  }

  callParent() {
    this.parentEvent.next();
  }

  uncheck() {
    /**
     * TODO: uncomment this for unchecking
     * checkbox if child is unchechekd
     */
    // console.log('bbbb');
    // console.log(this.node);
    this.node.checked = false;
    this.parentEvent.next();
  }

  // deletePhone(e) {
  //   console.log('sdffssd');
  // }

  checkToggle() {
    this.callingComponentUpdate.next();
    if (!this.node.checked) {
      this.callParent();
    }

    // console.log(this.nodes);
    // if node has children
    const val = !!this.node.checked;
    // // if(this.node.checked)
    // this.node.children.map(x => x.checked = val);
    this.node.children.map(function (x) {
      x.checked = val;
      this.toggleCheckChildren(x, val);
    }, this);

    // console.log(this.node.children[0].checked = this.node.checked);
  }

  toggleCheckChildren(x, val) {
    x.children.map(function (y) {
      y.checked = val;
      this.toggleCheckChildren(y, val);
    }, this);
  }

  toggleChildrenVisibility() {
    this.node.expand = this.node.expand ? false : true;
  }
}
