import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConvertByte } from 'src/app/patients/shared/utility/convertByte.util';

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.css']
})
export class FileAttachmentComponent implements OnInit {
  selectedFile: File;
  @Output() file = new EventEmitter<File>();
  @Input() formSubmitted: Boolean;
  @Input() required: Boolean;
  @Input() title: string;
  fileSize: any;
  fileSizeUnit: string;
  rawFileSize: number;
  MAX_FILE_SIZE: number = 10048576;
  file_attachment_err: boolean = false;
  file_attachment_desc: string = "Only png, jpg and pdf types are allowed. (Max size: 10MB)"
  file_attachment_err_msg: string = "The file attachment must be a file type of jpg, jpeg, png, pdf, File size should not exceed 10 MB."

  constructor(
    private convertByte: ConvertByte,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  uploadFile(event) {
    this.fileSize = null;
    this.file_attachment_err = false;

    const files = event.target.files;
    if (files.length > 0) {
      this.rawFileSize = files[0].size;
      if (this.rawFileSize < this.MAX_FILE_SIZE && this.convertByte.extractExtension(files[0])) {
        this.fileSize = this.convertByte.convert(files[0].size);
        this.selectedFile = files[0];
        this.file.emit(files[0]);
      } else {
        this.file_attachment_err = true;
        this.file.emit(null);
        this.toastr.error(
          this.file_attachment_err_msg, 'error'
        );
      }
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.selectedFile = null;
    this.fileSize = null;
    this.fileSizeUnit = null;
    this.rawFileSize = null;
  }
}
