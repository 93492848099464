import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetService } from '../shared/services/reset.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  PASSWORD_MIN_LENGTH = 8;

  passwordResetForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]),
    password_confirmation: new FormControl('', [Validators.required]),
    token: new FormControl('', [Validators.required])
  });

  VALIDATION_MESSAGES = [
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'password',
      error_type: 'required',
      message: 'Password is required'
    },
    {
      name: 'password',
      error_type: 'minlength',
      message: 'Password should be of minimum ' + this.PASSWORD_MIN_LENGTH + ' characters'
    },
    {
      name: 'password',
      error_type: 'numericMissing',
      message: 'Password should contain at least one numeric character'
    },
    {
      name: 'password',
      error_type: 'uppercaseMissing',
      message: 'Password should contain at least one uppercase character'
    },
    {
      name: 'password_confirmation',
      error_type: 'required',
      message: 'Confirm Password is required'
    },
    {
      name: 'password_confirmation',
      error_type: 'confirm',
      message: 'Confirm Password and New Password does not match'
    }
  ];

  backendValidationErrors = [
    {
      controlName: 'email',
      error: false,
      message: ''
    },
    {
      controlName: 'password',
      error: false,
      message: ''
    },
    {
      controlName: 'password_confirmation',
      error: false,
      message: ''
    }
  ];

  formSubmitted = false;

  submitInProgress = false;

  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  isLongEnough = false;
  passwordContainsNumericCharacter = false;
  bothPasswordMatches = false;
  passwordContainsUppercaseCharacter = false;
  hide = true;
  hide_another = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private resetService: ResetService
  ) {}

  ngOnInit() {
    this.patchFormDefaults({
      token: this.activatedRoute.snapshot.paramMap.get('token'),
      email: this.activatedRoute.snapshot.queryParamMap.get('email')
    });
  }

  submit() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (
      this.passwordResetForm.status === 'VALID' &&
      this.passwordContainsUppercaseCharacter &&
      this.passwordContainsNumericCharacter &&
      this.bothPasswordMatches
    ) {
      const data = this.passwordResetForm.value;
      this.resetService.resetPassword(data).subscribe(
        (response) => {
          this.submitInProgress = false;
          this.router.navigate(['auth/login'], {
            state: { data: { flashMessage: response.message } }
          });
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          this.displayToastMessage(error.error.message, false);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  patchFormDefaults(defaultValueObj: object): void {
    this.passwordResetForm.patchValue(defaultValueObj);
  }

  displayToastMessage(message: string, isSuccess: boolean) {
    this.successMessageDisplay = isSuccess;
    this.failureMessageDisplay = !isSuccess;
    this.responseMessage = message;
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }

  checkConfirmPassword(event) {
    this.bothPasswordMatches =
      event.target.value === this.passwordResetForm.controls.password.value;
  }

  checkPasswordStrength(event) {
    const enteredPassword = event.target.value;
    const digitContainingRegex = new RegExp('.*\\d+.*');
    const uppercaseContainingRegex = new RegExp('.*[A-Z].*');
    this.isLongEnough = enteredPassword.length >= this.PASSWORD_MIN_LENGTH;
    this.passwordContainsNumericCharacter = digitContainingRegex.test(enteredPassword);
    this.passwordContainsUppercaseCharacter = uppercaseContainingRegex.test(enteredPassword);
    this.bothPasswordMatches =
      event.target.value === this.passwordResetForm.controls.password_confirmation.value;
  }

  setBackendValidationErrors(errors) {
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.password) {
      this.setBackendValidationError('password', errors.password[0]);
    } else {
      this.unsetBackendValidationError('password');
    }
    if (errors.password_confirmation) {
      this.setBackendValidationError('password_confirmation', errors.password_confirmation[0]);
    } else {
      this.unsetBackendValidationError('password_confirmation');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
