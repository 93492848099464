import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Token } from './shared/model/token';
import { StorageService } from './services/storage.service';
import { AuthService } from './auth/shared/services/auth.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService, private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get token from session storage
    const token_string = this.storageService.getAPIToken();
    const token: Token = token_string ? JSON.parse(token_string) : null;

    // check if token is valid
    if (token) {
      // tslint:disable-next-line:triple-equals
      if (req.method == 'POST' && req.body == '[object FormData]') {
        const dupReq = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token.access_token
          })
        });
        return next.handle(dupReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401 && this.auth.hasTokenExpired(token_string) == false) {
              this.storageService.clear();
              this.auth.VoIPLogout();
              window.location.href = 'auth/login';
            }
            return throwError(error);
          })
        );
      } else {
        const dupReq = req.clone({
          headers: new HttpHeaders({
            // tslint:disable-next-line:triple-equals
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token.access_token
          })
        });
        return next.handle(dupReq).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status == 401 && this.auth.hasTokenExpired(token_string) == false) {
              this.storageService.clear();
              this.auth.VoIPLogout();
              window.location.href = 'auth/login';
            }
            return throwError(error);
          })
        );
      }
    }

    // add token in request
    return next.handle(req);
  }
}

@NgModule({
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }]
})
export class InterceptorModule {}
