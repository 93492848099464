import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllergyInformationService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  getAllergyList(type): any {
    return this.http.get(this.apiUrl + 'allergies/' + type);
  }
  getReactionList(): any {
    return this.http.get(this.apiUrl + 'reactions');
  }
  storeAllergyInformation(data, patientId) {
    const url = this.apiUrl + patientId + '/allergy-information';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log('sdfdsf');

    return this.http.post(url, data, httpOptions);
  }

  getPatientAllergyList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}patients/${patientId}/allergy-informations`, {
      params: { pageNo, limit }
    });
  }
}
