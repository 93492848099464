import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ModalIcon } from '../../enums/modal-icon';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './app-modal-dialog.component.html',
  styleUrls: ['./app-modal-dialog.component.css']
})
export class AppModalDialogComponent implements OnInit {
  @Output() confirmation: EventEmitter<boolean> = new EventEmitter();
  @Input() header: string;
  @Input() message: string;
  @Input() callerName: string;
  @Input() RejectButton: string;
  @Input() AcceptButton: string;
  @Input() modalIcon: ModalIcon;
  @Input() openModal: boolean;
  constructor() {}

  ngOnInit() {}
  /**
   * This method calls on model buttons click
   */

  onConfirmModal(callResponseType: boolean): void {
    this.confirmation.emit(callResponseType);
  }
}
