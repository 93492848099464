import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';
import { CallCenter } from '../shared/model/call-center';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallCenterService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  printInfo = new Subject<any>();
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getCallCenterList(pageNo, limit, q) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q });
    return this.http.get(`${this.apiUrl}contact-us`, { params });
  }

  getCallCenterDetail(id: number) {
    return this.http.get<CallCenter>(`${this.apiUrl}contact-us/${id}`);
  }

  set pushData(printInfo: any) {
    console.log('Print Service Push Data');
    this.printInfo.next(printInfo);
  }

  get dataChange(): Observable<any> {
    return this.printInfo.pipe();
  }
}
