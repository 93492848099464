import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabReportService } from '../../../services/lab-report.service';

@Component({
  selector: 'app-lab-report-list',
  templateUrl: './lab-report-list.component.html',
  styleUrls: ['./lab-report-list.component.css']
})
export class LabReportListComponent implements OnInit {
  patientId: number;
  patientLabReports: Array<any>;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientLabReports(this.limit);
  }

  getPatientLabReports(limit) {
    this.loading = true;
    this.labReportService.getPatientLabReports(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.patientLabReports = response.data;
        this.patientLabReports.forEach((report: any) => {
          report.expandIcon = 'chevron-down';
          report.expandMessage = 'Expand Details';
        });
        this.total = response.size;
      },
      (error) => {
        this.patientLabReports.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientLabReports(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientLabReports(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientLabReports(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(report, event) {
    event.preventDefault();
    report.expanded = !report.expanded;
    if (report.expanded) {
      report.expandIcon = 'chevron-up';
      report.expandMessage = 'Collapse Details';
    } else {
      report.expandIcon = 'chevron-down';
      report.expandMessage = 'Expand Details';
    }
  }

  editLabReport(reportId, event) {
    event.stopPropagation();
    this.router.navigate(['/patients/' + this.patientId + '/lab-reports/' + reportId + '/edit']);
  }
}
