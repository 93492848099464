import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  @Input() searchQuery: string;
  @Input() placeHolder: string;
  profileSearchQuery: string;
  @Output() clear = new EventEmitter<Boolean>();
  @Output() search = new EventEmitter<Event>();
  @Output() searchQueryFromUrl = new EventEmitter<string>();

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.profileSearchQuery = this.searchQuery || null;
  }

  clearSearch() {
    this.profileSearchQuery = null;
    this.checkUrlForQueryParams() ? this.clear.emit(true) : null;
  }

  searchClicked(event) {
    this.profileSearchQuery = this.checkIfSearchEmptyString(this.profileSearchQuery);
    this.searchQueryFromUrl.emit(this.profileSearchQuery);
    if (this.checkChangeInSearchVariable(this.profileSearchQuery)) {
      event.preventDefault();
      this.search.emit(event);
    }
  }

  checkUrlForQueryParams() {
    return this.activatedRoute.snapshot.queryParamMap.get('q' || 'query') ? true : false;
  }

  checkIfSearchEmptyString(searchQuery) {
    return searchQuery === '' ? null : searchQuery;
  }

  checkChangeInSearchVariable(searchQuery) {
    return searchQuery !== this.activatedRoute.snapshot.queryParamMap.get('q') || this.activatedRoute.snapshot.queryParamMap.get('query');
  }

}
