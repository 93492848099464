import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription-third-step',
  templateUrl: './subscription-third-step.component.html',
  styleUrls: ['./subscription-third-step.component.css']
})
export class SubscriptionThirdStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() cancelEmmitter = new EventEmitter();
  @Output() nextEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  subscriptionThirdForm = new FormGroup({
    whyEnroll: new FormControl('', [Validators.required]),
    requireUpdate: new FormControl('', [Validators.required]),
    standbyService: new FormControl('', [Validators.required]),
    pharmacy: new FormControl('', [Validators.required]),
    preDiagnosis: new FormControl('', [Validators.required]),
    urgent: new FormControl('', [Validators.required])
    //payment: new FormControl('', [Validators.required]),
  });
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.subscriptionThirdForm.patchValue({
      whyEnroll: this.data.whyEnroll,
      requireUpdate: this.data.requireUpdate,
      standbyService: this.data.standbyService,
      pharmacy: this.data.pharmacy,
      preDiagnosis: this.data.preDiagnosis,
      urgent: this.data.urgent
      //payment: this.data.payment,
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.subscriptionThirdForm.valid) {
      const formData = {
        currentStep: 3,
        nextStep: 4,
        data: this.subscriptionThirdForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 3,
      previousStep: 2,
      data: this.subscriptionThirdForm.value
    };
    this.previousEmmitter.emit(formData);
  }
  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
