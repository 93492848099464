import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkflowFormsRoutingModule } from './workflow-forms-routing.module';
import { WorkflowFormsComponent } from './workflow-forms.component';
import { PatientEditComponent } from '../patient-edit/patient-edit.component';
import { VitalCreateComponent } from '../vitals/vital-create/vital-create.component';
import { VitalEditComponent } from '../vitals/vital-edit/vital-edit.component';
import { MedicationCreateComponent } from '../medications/medication-create/medication-create.component';
import { MedicationEditComponent } from '../medications/medication-edit/medication-edit.component';
import { LabReportCreateComponent } from '../lab-reports/lab-report-create/lab-report-create.component';
import { LabReportEditComponent } from '../lab-reports/lab-report-edit/lab-report-edit.component';
import { PersonalCarePlanCreateComponent } from '../personal-care-plans/personal-care-plan-create/personal-care-plan-create.component';
import { PersonalCarePlansEditComponent } from '../personal-care-plans/personal-care-plans-edit/personal-care-plans-edit.component';
import { AllergyCreateComponent } from '../allergies/allergy-create/allergy-create.component';
import { FamilyHistoryCreateComponent } from '../family-histories/family-history-create/family-history-create.component';
import { KycCreateComponent } from '../kycs/kyc-create/kyc-create.component';
import { DiagnosisCreateComponent } from '../diagnoses/diagnosis-create/diagnosis-create.component';
import { DiagnosisEditComponent } from '../diagnoses/diagnosis-edit/diagnosis-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
  MatChipsModule
} from '@angular/material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormFooterComponent } from '../patient-shared/form-footer/form-footer.component';
import { HeaderMenuComponent } from '../patient-shared/header-menu/header-menu.component';
import { DiagnosisFormComponent } from '../diagnoses/diagnosis-form/diagnosis-form.component';
import { PaginationComponent } from '../../pagination/pagination.component';
import { FormActionButtonsComponent } from '../patient-shared/form-action-buttons/form-action-buttons.component';
import { AssignUsersComponent } from '../assign-users/assign-users.component';
import { EncounterCreateComponent } from '../encounters/enounter-create/encounter-create.component';
import { EncounterEditComponent } from '../encounters/encounter-edit/encounter-edit.component';
import { ServiceRequestCreateComponent } from '../service-requests/service-request-create/service-request-create.component';
import { RadiologyCreateComponent } from '../radiology/radiology-create/radiology-create.component';
import { ImmunizationCreateComponent } from '../immunizations/immunization-create/immunization-create.component';
import { SurgicalHistoryCreateComponent } from '../surgical-histories/surgical-history-create/surgical-history-create.component';
import { PastMedicalHistoryCreateComponent } from '../past-medical-histories/past-medical-history-create/past-medical-history-create.component';
import { SystemReviewCreateComponent } from '../system-reviews/system-review-create/system-review-create.component';
import { SharedModule } from '../../shared/shared.module';
import { ValidNumberDecimalDirective } from './../shared/valid-number-decimal.directive';
import { NurseAssessmentFormCreateComponent } from '../nurse-assessment-form/nurse-assessment-form-create/nurse-assessment-form-create.component';
import { FileAttachmentComponent } from 'src/app/shared/components/file-attachment copy/file-attachment.component';

@NgModule({
  declarations: [
    WorkflowFormsComponent,
    PatientEditComponent,
    VitalCreateComponent,
    VitalEditComponent,
    MedicationCreateComponent,
    MedicationEditComponent,
    LabReportCreateComponent,
    LabReportEditComponent,
    PersonalCarePlanCreateComponent,
    PersonalCarePlansEditComponent,
    AllergyCreateComponent,
    FamilyHistoryCreateComponent,
    KycCreateComponent,
    DiagnosisCreateComponent,
    DiagnosisEditComponent,
    DiagnosisFormComponent,
    HeaderMenuComponent,
    FormFooterComponent,
    PaginationComponent,
    AssignUsersComponent,
    FormActionButtonsComponent,
    EncounterCreateComponent,
    EncounterEditComponent,
    ServiceRequestCreateComponent,
    RadiologyCreateComponent,
    ImmunizationCreateComponent,
    SurgicalHistoryCreateComponent,
    PastMedicalHistoryCreateComponent,
    SystemReviewCreateComponent,
    ValidNumberDecimalDirective,
    NurseAssessmentFormCreateComponent,
    FileAttachmentComponent
  ],
  imports: [
    CommonModule,
    WorkflowFormsRoutingModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    FormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDividerModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    SweetAlert2Module,
    MatChipsModule,
    SharedModule
  ],
  exports: [
    HeaderMenuComponent,
    FormFooterComponent,
    PaginationComponent,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDividerModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    FormActionButtonsComponent,
    MatChipsModule,
    SharedModule,
    FileAttachmentComponent
  ],
  bootstrap: [WorkflowFormsComponent]
})
export class WorkflowFormsModule {}
