import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

type OnEvent = (...args: any[]) => void;
type EventListener = (...args: any[]) => void;
type EventListenerRemove = () => void;
type ConnectionListener = (connected: boolean) => void;

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  static events = {
    EVENT_ROOM_INVITE: 'room_invite_event',
    EVENT_ROOM_IO_INVITE: 'room_invite_io_event',
    EVENT_USER_STATUS: 'user_status_changed_event',
    EVENT_USER_TYPE_STATUS: 'user_status_of_{type}_changed_event',
    EVENT_CONNECT: 'connect',
    EVENT_DISCONNECT: 'disconnect',
    EVENT_CONNECT_ERROR: 'connect_error',
    EVENT_MESSAGE: 'message',
    JOIN_ROOM: 'room_join_event',
    LEAVE_ROOM: 'room_leave_event',
    USER_STATUS_JOIN: 'user_status_join_event',
    USER_STATUS_LEAVE: 'user_status_leave_event'
  };

  static room = {
    USER: 'user_room',
    USER_TYPE: 'user_type_room'
  };

  private socketUrl = environment.socketUrl ? environment.socketUrl : '';

  private socket: Socket;
  public roomService: RoomService;
  private events: Record<string, OnEvent> = {};
  private _connectionRequested: boolean = false;
  private listeners: Record<string, Set<EventListener>> = {};
  private connectionListener: Set<ConnectionListener> = new Set<ConnectionListener>();
  private _connectCallback?: {
    resolve: any;
    reject: any;
  } = undefined;

  private connectionEvent = (args: any[]) => {
    this.onConnected(Array.isArray(args) ? args : [args]);
  };
  private connectionErrorEvent = (args: any[]) => {
    this.onConnectError(Array.isArray(args) ? args : [args]);
  };
  private roomInviteEvent = (args: any[]) => {
    this.onRoomInvite(Array.isArray(args) ? args : [args]);
  };
  private userStatusChangeEvent = (args: any[]) => {
    this.onUserStatusChanged(Array.isArray(args) ? args : [args]);
  };
  private disconnectionEvent = (args: any[]) => {
    this.onDisconnected(Array.isArray(args) ? args : [args]);
  };

  constructor(private storage: StorageService) {
    this.roomService = new RoomService(this);
  }

  setUpSocketConnection() {
    const token = JSON.parse(this.storage.getAPIToken());
    this.socket = io(this.socketUrl, {
      query: { token: token && token.access_token ? token.access_token : undefined },
      auth: (cb) => {
        cb({ token: JSON.parse(this.storage.getAPIToken()).access_token });
      }
    });
  }
  isConnected(): boolean {
    return this.socket && this.socket.connected;
  }
  connect() {
    if (this.isConnected() || this._connectionRequested) {
      console.log('SocketService connect() -> already connected/connecting', this.socket.id);
      return;
    }
    console.log('SocketService connect()', this.socket !== undefined);
    this._connectionRequested = true;
    this.startListening();
    this.socket.connect();
  }

  connectAsync() {
    return new Promise((resolve, reject) => {
      this._connectCallback = { resolve, reject };
      this.connect();
    });
  }
  disconnect() {
    this.socket.disconnect();
    this.stopListening();
    this._connectionRequested = false;
    console.log('SocketService disconnect()');
  }

  emit(event: string, ...payload: object[]) {
    this.socket.emit(event, payload);
  }

  listen(event: string, listener: EventListener): EventListenerRemove {
    console.log('SocketService, listen() -> event:', event);
    const eListeners = this.listeners[event] || new Set<EventListener>();
    eListeners.add(listener);
    this.listeners[event] = eListeners;
    this.addEventListener(event);
    return () => {
      this.listeners[event].delete(listener);
    };
  }

  addOnConnectionListener(listener: ConnectionListener): EventListenerRemove {
    this.connectionListener.add(listener);
    listener(this.isConnected());
    return () => {
      this.connectionListener.delete(listener);
    };
  }
  private addEventListener(event: string) {
    if (this.events.hasOwnProperty(event) || this.events[event] === null) {
      return;
    }
    const listener = (...args: any[]) => {
      this.listeners[event].forEach((callback) => {
        callback(args);
      });
    };
    this.socket.on(event, listener);
    this.events[event] = listener;
  }
  private startListening() {
    this.socket.on(SocketService.events.EVENT_CONNECT, this.connectionEvent);
    this.socket.on(SocketService.events.EVENT_CONNECT_ERROR, this.connectionErrorEvent);
    this.socket.on(SocketService.events.EVENT_ROOM_INVITE, this.roomInviteEvent);
    this.socket.on(SocketService.events.EVENT_DISCONNECT, this.disconnectionEvent);
    this.socket.on(SocketService.events.EVENT_USER_STATUS, this.userStatusChangeEvent);
    this.events[SocketService.events.EVENT_CONNECT] = this.connectionEvent;
    this.events[SocketService.events.EVENT_CONNECT_ERROR] = this.connectionErrorEvent;
    this.events[SocketService.events.EVENT_DISCONNECT] = this.disconnectionEvent;
    this.events[SocketService.events.EVENT_ROOM_INVITE] = this.roomInviteEvent;
    this.events[SocketService.events.EVENT_USER_STATUS] = this.userStatusChangeEvent;
  }
  private stopListening() {
    if (this.events) {
      Object.keys(this.events).forEach((event) => {
        this.socket.off(event, this.events[event]);
      });
      this.events = {};
    }
  }
  private onConnected(args: any[]) {
    if (this._connectCallback) {
      this._connectCallback.resolve(args);
    }
    if (this.connectionListener) {
      this.connectionListener.forEach((listener) => {
        listener(true);
      });
    }
    console.log('SocketService onConnected()->', args);
  }
  private onDisconnected(args: any[]) {
    if (this.connectionListener) {
      this.connectionListener.forEach((listener) => {
        listener(false);
      });
    }
    console.log('SocketService onDisconnected()->', args);
  }
  private onConnectError(args: any[]) {
    if (this._connectCallback) {
      this._connectCallback.reject(args);
      this._connectionRequested = false;
      console.log('SocketService onConnectError()->', JSON.stringify(args));
    }
  }
  private onRoomInvite(args: any[]) {
    console.log('SocketService onRoomInvite()->', args);
    const events = this.listeners[SocketService.events.EVENT_ROOM_INVITE];
    if (events) {
      events.forEach((listener) => {
        listener(args);
        console.log(listener);
      });
    }
  }
  private onUserStatusChanged(args: any[]) {
    const events = this.listeners[SocketService.events.EVENT_USER_STATUS];
    if (events) {
      console.log('SocketService onUserStatusChanged()->', args, ' listeners: ', events.size);
      events.forEach((listener) => {
        listener(args);
      });
    }
  }
}

export enum VoIPStatus {
  AVAILABLE = 'AVAILABLE',
  ON_CALL = 'ON_CALL',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
  UNKNOWN = 'UNKNOWN'
}
export interface UserStatus {
  id: number;
  status: VoIPStatus;
}

class RoomService {
  private eventType: Record<string, number> = {};
  private userStatusList: Record<number, UserStatus> = {};

  constructor(private delicate: SocketService) {}

  onUserStatus(id: number, onStatusUpdated: (status: UserStatus) => void) {
    const unsubscribe = this.delicate.listen(SocketService.events.EVENT_USER_STATUS, (args) => {
      args.forEach((it: UserStatus) => {
        if (+it.id === id) {
          onStatusUpdated({ id, status: it.status });
        }
        this.userStatusList[it.id] = { id, status: it.status };
      });
    });
    const userRoom = SocketService.events.USER_STATUS_JOIN + '_' + id;
    this.addEventCount(userRoom);
    this.delicate.emit(SocketService.events.USER_STATUS_JOIN, { id });
    return () => {
      unsubscribe();
      this.unsubscribe(userRoom);
    };
  }

  private unsubscribe(room: string) {
    const rem = this.addEventCount(room, -1);
    if (rem <= 0) {
      this.delicate.emit(SocketService.events.LEAVE_ROOM, { room });
    }
  }

  private addEventCount(type: string, count: number = 1): number {
    this.eventType[type] = count + this.eventType[type] || 0;
    return this.eventType[type];
  }
}
