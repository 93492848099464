import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../services/user-service.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-user-detail',
  templateUrl: './login-user-detail.component.html',
  styleUrls: ['./login-user-detail.component.css']
})
export class LoginUserDetailComponent implements OnInit {
  loggedInUser: any;
  userProfile: any;
  color = 'accent';
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  checked;

  constructor(
    private userService: UserServiceService,
    private storageService: StorageService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getProfileDetail();
    this.getGoOnlineStatus();
    this.getLoggedInUser();
  }

  getProfileDetail() {
    this.userService.getProfile().subscribe(
      (response) => {
        this.userProfile = response;
      },
      (error) => {
        this.displayToastMessage(error.error.message, false);
      }
    );
  }
  getLoggedInUser() {
    this.loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
  }

  displayToastMessage(message: string, isSuccess: boolean) {
    this.successMessageDisplay = isSuccess;
    this.failureMessageDisplay = !isSuccess;
    this.responseMessage = message;
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }
  changePassword() {
    this.router.navigate(['users/change-password']);
  }

  editInfo() {
    this.router.navigate(['users/editprofile']);
  }

  goToMyAppointment() {
    this.router.navigate(['my-appointment']);
  }
  getGoOnlineStatus() {
    this.userService.getGoOnline().subscribe((res: any) => {
      this.checked = res.status === 'online' ? true : false;
    });
  }

  changed() {
    const data = {
      status: this.checked ? 'online' : 'offline'
    };
    this.userService.storeGoOnline(data).subscribe(
      (res: any) => {
        this.toastr.success(res.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }
}
