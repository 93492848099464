import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-lab-booking-actions',
  templateUrl: './lab-booking-actions.component.html',
  styleUrls: ['./lab-booking-actions.component.css']
})
export class LabBookingActionsComponent implements OnInit {
  @Input() action;
  @Input() booking;
  @Output() applyRedirect = new EventEmitter<boolean>();
  @Output() statusChange = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  applyRedirectAction() {
    this.applyRedirect.emit(true);
  }

  applyStatusChange() {
    this.statusChange.emit(true);
  }

  cancelBooking() {
    if (confirm('Are you sure you want to cancel Booking?')) {
      this.statusChange.emit(true);
    }
  }
}
