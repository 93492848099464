import { Component, ElementRef, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { StorageService } from '../../../services/storage.service';
@Component({
  selector: 'app-conversation-box',
  templateUrl: './conversation-box.component.html',
  styleUrls: ['./conversation-box.component.css']
})
export class ConversationBoxComponent implements AfterViewInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  @Input() messageData;
  sendingMessage;
  selectedMessageData;

  sendingStatus = false;
  sendingProcessStatus = true;
  itemSelected = false;
  callStatus;
  loadingStatus = false;

  input_message;
  pageNo;
  limit;

  replyStatus = true;
  conversationList = [];
  opponent_id;

  constructor(private messageService: MessageService, private storageService: StorageService) {}

  ngOnChanges(changes: any) {
    this.selectedMessageData = changes.messageData.currentValue;
    if (this.selectedMessageData) {
      this.conversationList = [];
      this.callStatus = true;
      this.pageNo = 1;
      this.limit = 10;
      this.getMessageList();
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  changeReplyStatus() {
    this.replyStatus = !this.replyStatus;
  }

  getMessageList() {
    this.opponent_id =
      this.getLoggedInUserId() === this.selectedMessageData.sender_id
        ? this.selectedMessageData.receiver_id
        : this.selectedMessageData.sender_id;
    this.callStatus = false;
    this.messageService.getMessageList(this.pageNo, this.limit, this.opponent_id).subscribe(
      (messages: any) => {
        for (let message of messages.data) {
          this.conversationList.push(message);
        }
        this.itemSelected = true;
        this.loadingStatus = false;
        if (this.conversationList.length < messages.size) {
          this.pageNo++;
          this.callStatus = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  onElementScroll() {
    if (
      Math.abs(this.myScrollContainer.nativeElement.scrollTop) +
        this.myScrollContainer.nativeElement.offsetHeight >=
        this.myScrollContainer.nativeElement.scrollHeight - 1 &&
      this.callStatus
    ) {
      this.loadingStatus = true;
      this.getMessageList();
    }
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = 0;
    } catch (err) {}
  }
  getLoggedInUserId() {
    var loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    return loggedInUser.id;
  }
  mySubmit() {
    if (this.sendingProcessStatus && this.input_message) {
      this.sendingProcessStatus = false;
      this.sendingMessage = this.input_message;
      this.input_message = '';
      this.sendingStatus = true;

      var request = {
        receiver_id: this.opponent_id,
        message: this.sendingMessage
      };
      this.messageService.sendMessage(request).subscribe(
        (response: any) => {
          this.conversationList.unshift(response.data);
          this.sendingStatus = false;
          this.sendingProcessStatus = true;
          this.scrollToBottom();
        },
        (err) => {
          console.log(err);
          this.sendingStatus = false;
          this.sendingProcessStatus = true;
        },
        () => {}
      );
    }
  }

  showUserImage(index) {
    if (index === 0) {
      return true;
    } else if (
      this.conversationList[index].is_type_sent === this.conversationList[index - 1].is_type_sent
    ) {
      return false;
    } else {
      return true;
    }
  }
}
