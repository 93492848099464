import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(private _notify: ToastrService, private _router: Router) {}

  redirectToNextPageWithMessage(message: string, nextUrl: string) {
    setTimeout(() => {
      this._router.navigate([nextUrl]);
    }, 1000);
    this._notify.success(message);
  }

  redirect(url: string) {
    this._router.navigate([url]);
  }

  navigate(arr: string[]) {
    this._router.navigate(arr);
  }

  notify(message: string) {
    this._notify.success(message);
  }
}
