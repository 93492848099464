import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InvestigationService } from '../../../services/investigation.service';
import { NotifyService } from '../../../services/notify.service';

@Component({
  selector: 'app-investigation-edit',
  templateUrl: './investigation-edit.component.html',
  styleUrls: ['./investigation-edit.component.css']
})
export class InvestigationEditComponent implements OnInit {
  formErrorObj: Array<any> = [
    {
      controlName: 'name',
      error: false
    }
  ];

  investigationId: number;
  diagnosticCenterId: number;
  testTypes: Array<any> = [];

  investigationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    testTypes: new FormControl('')
  });

  // For Form Footer
  cancelUrl;
  afterSaveUrl;
  submitInProgress = false;

  submitted = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private investigationService: InvestigationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.diagnosticCenterId = +this.activatedRoute.snapshot.paramMap.get('diagnosticCenterId');
    this.cancelUrl = '/diagnostic-center/' + this.diagnosticCenterId;
    this.afterSaveUrl = '/diagnostic-center/' + this.diagnosticCenterId;
    this.investigationId = +this.activatedRoute.snapshot.paramMap.get('investigationId');
    this.getInvestigation(this.investigationId);
  }

  getInvestigation(investigationId) {
    this.submitInProgress = true;
    this.investigationService.getInvestigation(investigationId).subscribe(
      (response: any) => {
        this.investigationForm.patchValue({
          name: response.name
        });
        response.test_type_list.forEach((testType: any) => {
          const test = {
            id: testType.id,
            name: testType.name,
            price: testType.price ? testType.price : '',
            prerequisite: testType.prerequisite || '',
            validName: false,
            validPrice: false,
            saved: true,
            saveTooltipText: 'Edit test',
            touched: true
          };
          this.testTypes.push(test);
        });
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  addTestType(event) {
    event.preventDefault();
    const test = {
      name: '',
      price: '',
      validName: false,
      validPrice: false,
      saved: false,
      saveTooltipText: 'Save test',
      touched: false
    };
    this.testTypes.push(test);
  }

  removeTestType(index, event) {
    event.preventDefault();
    this.testTypes = this.testTypes.filter((testType: any) => {
      return index !== this.testTypes.indexOf(testType);
    });
  }

  storeInvestigation() {
    this.submitted = true;
    this.submitInProgress = true;

    // Validate Form
    if (this.investigationForm.invalid || this.testTypes.length === 0 || !this.isTestListValid()) {
      this.submitInProgress = false;
      return;
    }

    // build data
    const data = this.investigationForm.value;
    data.testTypes = this.testTypes;
    // push data
    this.investigationService.update(this.investigationId, data).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        if (error.status === 422) {
          const errors = error.error.errors;
          if (errors.name) {
            this.setError('name', errors.name[0]);
          } else {
            this.unsetError('name');
          }
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error(error.error.message);
        }
      },
      () => {
        this.submitInProgress = false;
        this.router.navigate([this.afterSaveUrl]);
      }
    );
  }

  isTestListValid() {
    const numOfTests = this.testTypes.length;
    let numOfValidTests = 0;
    this.testTypes.forEach((testType) => {
      if (testType.name.trim() !== '' && testType.price.length !== 0) {
        numOfValidTests++;
      }
    });
    return numOfValidTests === numOfTests;
  }

  isTestListTouched() {
    const numOfTests = this.testTypes.length;
    let numOfTouchedTests = 0;
    this.testTypes.forEach((testType) => {
      if (testType.touched) {
        numOfTouchedTests++;
      }
    });
    return numOfTouchedTests === numOfTests;
  }

  getFormattedTestList() {
    const testList: Array<string> = [];
    this.testTypes.forEach((testType: any) => {
      testList.push(testType.name.trim());
    });
    return testList;
  }

  toggleEditTest(test, event) {
    event.stopPropagation();
    event.preventDefault();
    if (test.saved) {
      test.saved = !test.saved;
      test.saveTooltipText = test.saved ? 'Edit test' : 'Save test';
    }

    if (test.name.trim().length !== 0 && test.price.trim().length !== 0) {
      test.validName = false;
      test.validPrice = false;
      test.saved = !test.saved;
      test.saveTooltipText = test.saved ? 'Edit test' : 'Save test';
    }
    if (test.name.trim().length === 0) {
      test.validName = true;
    }
    if (test.price.trim().length === 0) {
      test.validPrice = true;
    }
  }

  processKeyUp(test, event) {
    event.preventDefault();
    test.touched = true;
    if (!test.saved && event.key === 'Enter') {
      this.toggleEditTest(test, event);
      if (test.saved) {
        this.addTestType(event);
      }
    }
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
