import { map } from 'rxjs/operators';

export class FormDataUtil {
  /**
   * This method identify is there only one slab in table
   * @param params is key value pair of data(object)
   * @return FormData
   */
  static mapToFormData(params: object): FormData {
    const formData = new FormData();
    Object.keys(params).forEach((keys) => {
      formData.append(keys, params[keys]);
    });
    return formData;
  }

  public static filterEmptyParams(params: object) {
    const mapParams = {};
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value) {
        mapParams[key] = value;
      }
    });
    return mapParams;
  }

  public static convertModelToFormData(
    model: any,
    form?: FormData,
    namespace: string = ''
  ): FormData {
    const formData = form ? form : new FormData();
    for (const propertyName in model) {
      if (
        !model.hasOwnProperty(propertyName) ||
        model[propertyName] === undefined ||
        model[propertyName] === null
      ) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      const formValue = model[propertyName];
      if (formValue instanceof Date) {
        formData.append(formKey, formValue.toISOString());
      } else if (formValue instanceof Array) {
        formValue.forEach((value, index) => {
          FormDataUtil.convertModelToFormData(value, formData, `${formKey}[${index}]`);
        });
      } else if (formValue === 'object' && !(formValue instanceof File)) {
        FormDataUtil.convertModelToFormData(formValue, formData, formKey);
      } else if (formValue !== undefined && formValue !== null) {
        formData.append(formKey, formValue);
      }
    }
    return formData;
  }
}
