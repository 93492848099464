import { Component, OnInit } from '@angular/core';
import { PageService } from '../../workflow-forms/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseAssessmentFormService } from '../../../services/nurse-assessment-form.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nurse-assessment-form-create',
  templateUrl: './nurse-assessment-form-create.component.html',
  styleUrls: ['./nurse-assessment-form-create.component.css']
})
export class NurseAssessmentFormCreateComponent implements OnInit {
  patientId: number;
  urlList: Array<string> = [];
  nextUrl: string;
  formSubmitted = false;

  // For Save and Continue
  saveAndContinueClicked = false;
  nurseAssessmentData;
  loading = false;

  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  nurseAssessmentFormData = new FormGroup({
    note: new FormControl('', [Validators.required])
  });
  constructor(
    private page: PageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nurseAssessmentForm: NurseAssessmentFormService,
    private urlService: UrlsService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Nurse Assessment', this.patientId);
    });
    this.getNurseAssessmentForm();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }
  store() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (this.nurseAssessmentFormData.status === 'VALID') {
      const data = this.nurseAssessmentFormData.value;
      this.nurseAssessmentForm.storeAssesmentNote(this.patientId, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.formSubmitted = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
      console.log('form validation failed');
    }
  }
  getNurseAssessmentForm() {
    this.loading = true;
    this.nurseAssessmentForm.getNurseAssessmentForm(this.patientId).subscribe(
      (res) => {
        this.nurseAssessmentData = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {
        console.log(this.nurseAssessmentData);
        this.loading = false;
      }
    );
  }
  getTotalTime(sub_tasks) {
    let total = 0;
    sub_tasks.forEach((sub_task) => {
      total += sub_task.time;
    });
    return total + ' min';
  }
  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }
}
