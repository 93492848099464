export class Patient {
  prefix: string;
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  dob: string;
  temp_address: string;
  perm_address: string;
  phone: string;
  mobile: string;
  patient_type_id: number;
  relation_id: number;
  relation_name: string;
  data: { id: number };
  blood_group: string;
  address: string;
  secondary_mobile_no: string;
  relative_mobile_no: string;
  value: any;
  status: string;
  email: string;
  unique_id: string;
  menuExpanded?: boolean;
  profile_image: string;
  user_active: Boolean;
  active: Boolean;
  created_at: string;
  country_code: number;
}

export interface Prefix {
  id: string;
  prefix: string;
}
