import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Patient } from '../../shared/model/patient';
import { PatientService } from '../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../services/urls.service';
import { NotifyService } from '../../services/notify.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { isEmpty } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientListComponent implements OnInit {
  urlList: Array<any> = [];

  patientStatus: Array<any> = [
    {
      value: 'active',
      name: 'Active'
    },
    {
      value: 'discharged',
      name: 'Discharged'
    },
    {
      value: 'service-discontinued',
      name: 'Service Discontinued'
    },
    {
      value: 'hospitalized',
      name: 'Hospitalized'
    },
    {
      value: 'dead',
      name: 'Dead'
    }
  ];

  forbiddenMsg = 'Sorry, you do not have permission to delete patient.';

  patients: Patient[] = Array(new Patient());
  searchQuery: string = null;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  clearDate = false;
  rawSearchQuery: string;
  liveSearchQuery: string;
  patient_type_name: string;
  advancedSearchForm = new FormGroup({
    q: new FormControl(''),
    date: new FormControl(''),
    patient_type_name: new FormControl(''),
    gender: new FormControl(''),
    // status: new FormControl(''),
    verification: new FormControl('')
  });
  constructor(
    private patientService: PatientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService,
    private dialogService: DialogService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');
    const patient_type_name = this.activatedRoute.snapshot.queryParamMap.get('patient_type_name');
    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.liveSearchQuery = searchQuery || null;
    this.patient_type_name = patient_type_name || null;

    this.advancedSearchForm.patchValue({
      q: searchQuery,
      date: this.activatedRoute.snapshot.queryParamMap.get('date'),
      patient_type_name,
      gender: this.activatedRoute.snapshot.queryParamMap.get('gender'),
      // status: this.activatedRoute.snapshot.queryParamMap.get('status'),
      verification: this.activatedRoute.snapshot.queryParamMap.get('verification')
    });
    //this.rawSearchQuery = this.getQueryFromInputParams();
    this.setUrlParams();
    this.hideToggleSearchFilters();
    this.getPatients();

    this.urlService.getPatientListMenu().subscribe((response: any) => {
      response.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push({ url: children.url, name: children.name });
        });
      });
    });
  }

  getPatientActions(patient) {
    const patientActions = [];
    this.urlList.forEach((action: any) => {
      if (action.url === '/patients/{patientId}/assign-users') {
        if (patient.status === 'active') {
          patientActions.push(action);
        }
      } else {
        patientActions.push(action);
      }
    });
    return patientActions;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatients();
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getPatients();
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getPatients();
    this.setUrlParams();
  }

  getPatients(): void {
    this.loading = true;
    const data = this.formatData();
    this.patientService.getPatients(this.page, this.limit, data).subscribe(
      (patients) => {
        this.patients = patients.data;
        this.total = patients.size;
      },
      (err) => {
        this.loading = false;
        this.patients.length = 0;
      },
      () => {
        this.loading = false;
        this.addMenuStatusFalse();
      }
    );
  }
  addMenuStatusFalse() {
    this.patients.forEach((p) => {
      p.menuExpanded = false;
    });
  }

  formatData() {
    const data: any = {
      q: this.advancedSearchForm.value.q ? this.advancedSearchForm.value.q : '',
      patient_type_name: this.advancedSearchForm.value.patient_type_name
        ? this.advancedSearchForm.value.patient_type_name
        : '',
      registered_at: this.advancedSearchForm.value.date
        ? moment(this.advancedSearchForm.value.date).format('YYYY-MM-DD')
        : '',
      gender: this.advancedSearchForm.value.gender || '',
      status: this.advancedSearchForm.value.status || '',
      verification: this.advancedSearchForm.value.verification || ''
    };
    return data;
  }

  deletePatient(id, name) {
    this.patientService.deletePatient(id).subscribe(
      (res: any) => {
        this.toastr.success(res.message)
        this.getPatients();
      },
      (err) => {
        if (err.status == 403 || err.status == 401) {
          this.toastr.error(this.forbiddenMsg);
        }
      }
    );
  }

  getUrl(patientId, actionUrl) {
    return actionUrl.replace(/{patientId}/g, patientId);
  }

  viewPatient(patientId) {
    this.router.navigate(['/patients/' + patientId]);
  }

  getStatus(statusVal: string) {
    const status = this.patientStatus.find((y) => {
      return y.value === statusVal;
    });
    return status ? status.name : 'N/A';
  }

  getPatientName(callLog) {}

  getFormatedName(names) {
    const fullName = names.filter((it) => it).join(' ');
    return fullName.length > 20 ? fullName.slice(0, 20) + '...' : fullName;
  }

  getFormatedAddress(address) {
    if (address) {
      return address.length > 20 ? address.slice(0, 20) + '...' : address;
    }
    return '---';
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.formatData();
      this.setUrlParams();
      this.getPatients();
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('q').reset();
    this.applyAdvancedSearch();
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'q') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value + ' ';
        } else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }
      }
    }
    return stringParams;
  }

  setUrlParams() {
    const queryParams = {
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      q: this.advancedSearchForm.get('q').value ? this.advancedSearchForm.get('q').value : null,
      patient_type_name: this.advancedSearchForm.get('patient_type_name').value
        ? this.advancedSearchForm.get('patient_type_name').value
        : null,
      date: this.advancedSearchForm.get('date').value
        ? this.advancedSearchForm.get('date').value
        : null,
      gender: this.advancedSearchForm.get('gender').value
        ? this.advancedSearchForm.get('gender').value
        : null,
      // status: this.advancedSearchForm.get('status').value
      //   ? this.advancedSearchForm.get('status').value
      //   : null,
      verification: this.advancedSearchForm.get('verification').value
        ? this.advancedSearchForm.get('verification').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['q', 'patient_type_name', 'date', 'gender', 'verification'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      q: queryValue.trim()
    });
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
      this.clearDate = true;
    });
    document.getElementById('clear-button').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
      this.clearDate = false;
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
      this.clearDate = false;
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  resetAdvancedSearchForm() {
    this.page = 1;
    this.advancedSearchForm.patchValue({
      patient_type_name: null,
      date: null,
      gender: null,
      // status: null,
      verification: null
    });
    this.clearDate = true;
    this.setUrlParams();
    this.getPatients();
  }

  chooseDate($event: MatDatepickerInputEvent<Date>) {
    this.advancedSearchForm.patchValue({
      date: moment($event.target.value).format('YYYY-MM-DD')
    });
  }
  menuExpandedStatus(patient) {
    this.patients.forEach((p) => {
      if (p.id === patient.id) {
        p.menuExpanded = p.menuExpanded ? false : true;
      } else {
        p.menuExpanded = false;
      }
    });
  }

  openConfirmationDialog(patientId, patientFirstName) {
    this.dialogService.confirmDialog({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this patient?',
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    }).subscribe((result)=>{
      if(result) {
        this.deletePatient(patientId, patientFirstName);
      }
    });
  }
}
