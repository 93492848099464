import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InquiryFormService } from '../services/inquiry-form.service';
import { NotifyService } from '../services/notify.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.css']
})
export class SubscriptionFormComponent implements OnInit {
  redirectHAHUrl = environment.redirectHAHUrl || 'https://healthathome.com.np/';
  loading: boolean;
  status;
  payment_type;
  responseData;
  esewaPaymentData;
  step = 1;
  firstFormData;
  secondFormData;
  thirdFormData;
  fourthFormData;
  constructor(
    private inquiryService: InquiryFormService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {
    router.events.subscribe((val) => {
      this.status = activateRoute.snapshot.queryParamMap.get('status');
      this.payment_type = activateRoute.snapshot.queryParamMap.get('payment_type');
    });
  }

  ngOnInit() {
    this.status = this.activateRoute.snapshot.queryParamMap.get('status');
    this.payment_type = this.activateRoute.snapshot.queryParamMap.get('payment_type');
  }

  goTo(event) {
    if (event.currentStep === 1) {
      this.firstFormData = event.data;
      this.step = event.nextStep;
    } else if (event.currentStep === 2) {
      this.secondFormData = event.data;
      this.step = event.nextStep;
    } else if (event.currentStep === 3) {
      this.thirdFormData = event.data;
      this.step = event.nextStep;
    }
  }

  goToPrevious(event) {
    if (event.currentStep === 2) {
      this.secondFormData = event.data;
      this.step = event.previousStep;
    } else if (event.currentStep === 3) {
      this.thirdFormData = event.data;
      this.step = event.previousStep;
    } else if (event.currentStep === 4) {
      this.fourthFormData = event.data;
      this.step = event.previousStep;
    }
  }

  saveSubscriptionData(event) {
    this.loading = true;
    this.fourthFormData = event;
    const data = this.formatData();
    console.log(data);
    this.inquiryService.saveSubscription(data).subscribe(
      (res: any) => {
        this.responseData = res;
      },
      (error) => {
        this.toastr.error('Error Submitting Form. Please Try Again');
        this.loading = false;
      },
      () => {
        if (data.payment_method === 'eSewa') {
          this.esewaPaymentData = this.responseData;
          setTimeout(function () {
            //(document.querySelector('.esewa-payment-form')).submit();
            this.loading = false;
          }, 3000);
        } else if (data.payment_method === 'PayPal') {
          window.location = this.responseData.redirect_url;
        } else if (data.payment_method === 'cod') {
          this.router.navigate([], {
            relativeTo: this.activateRoute,
            queryParams: {
              status: 'success',
              payment_type: 'cod'
            },
            queryParamsHandling: 'merge',
            skipLocationChange: false
          });
          this.loading = false;
        }
        this.resetValue();
      }
    );
  }

  resetValue() {
    this.step = 1;
    this.firstFormData = {};
    this.secondFormData = {};
    this.thirdFormData = {};
  }
  cancel() {
    window.location.href = this.redirectHAHUrl;
  }
  formatData() {
    return {
      email: this.firstFormData.email,
      first_name: this.firstFormData.firstName,
      last_name: this.firstFormData.lastName,
      dob: this.firstFormData.dob,
      residential_address: this.firstFormData.residentialAddress,
      postal_address: this.firstFormData.postalAddress,
      mobile: this.firstFormData.mobile,
      alternate_number: this.firstFormData.alternateNumber,
      beneficiary_first_name: this.secondFormData.beneficiaryFirstName,
      beneficiary_last_name: this.secondFormData.beneficiaryLastName,
      beneficiary_dob: this.secondFormData.beneficiaryDob,
      beneficiary_residental_address: this.secondFormData.beneficiaryResidentialAddress,
      beneficiary_contact_number: this.secondFormData.beneficiaryContactNumber,
      beneficiary_contact_email: this.secondFormData.beneficiaryContactEmail,
      beneficiary_alternate_contact_person: this.secondFormData.beneficiaryAlternateContactPerson,
      beneficiary_alternate_contact_number: this.secondFormData.beneficiaryAlternateContactNumber,
      why_enroll: this.thirdFormData.whyEnroll,
      require_update: this.thirdFormData.requireUpdate,
      standby_service: this.thirdFormData.standbyService,
      pharmacy: this.thirdFormData.pharmacy,
      pre_diagnosis: this.thirdFormData.preDiagnosis,
      urgent: this.thirdFormData.urgent,
      //payment : this.thirdFormData.payment,
      payment_method: this.fourthFormData.paymentMethod,
      amount: this.fourthFormData.amount
    };
  }
}
