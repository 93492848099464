import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getMessageList(pageNo, limit, opponent_id): Observable<any> {
    const url = this.apiUrl + 'chats';
    if (opponent_id) {
      return this.http.get(url, { params: { pageNo, limit, opponent_id } });
    } else {
      return this.http.get(url, { params: { pageNo, limit } });
    }
  }
  updateStatus(request): Observable<any> {
    const url = this.apiUrl + 'chats';
    return this.http.put(url, request, this.httpOptions);
  }

  sendMessage(request) {
    const url = this.apiUrl + 'chats';
    return this.http.post(url, request, this.httpOptions);
  }
}
