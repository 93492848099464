import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.css']
})
export class TypeAheadComponent implements OnInit, OnChanges{
  @Input() dataList: string[];
  @Input() placeHolder: string;
  @Input() clear: boolean;
  @Output() selectedData = new EventEmitter<string>();
  acFieldDataList: Observable<string>;
  myGroup = new FormGroup({
    autoComplete: new FormControl('')
 });


  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.checkUrlParams();
    setTimeout(()=>{
      this.acFieldDataList = this.setACField(this.myGroup.controls.autoComplete, 'dataList');
    }, 1000);
  }

  ngOnChanges(change: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(change.clear.currentValue === null) {
      this.myGroup.get('autoComplete').reset();
    }
  }

  checkUrlParams() {
    const nurseNameFromUrl = this.activatedRoute.snapshot.queryParamMap.get('nurseName') || null;
    this.myGroup.get('autoComplete').setValue(nurseNameFromUrl);
  }

  selected(e) {
    this.selectedData.emit(this.myGroup.get('autoComplete').value);
  }

  clearInput() {
    this.checkUrlParams();
    this.myGroup.get('autoComplete').reset();
  }

}
