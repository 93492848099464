import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ImmunizationNameService } from '../../../../services/immunization-name.service';
import { ImmunizationFormComponent } from '../immunization-form/immunization-form.component';

@Component({
  selector: 'app-immunization-list',
  templateUrl: './immunization-list.component.html',
  styleUrls: ['./immunization-list.component.css']
})
export class ImmunizationListComponent implements OnInit {
  @Input() clearUrl: boolean;
  immunization;
  immunizationList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  immunizationSearchQuery: string;

  constructor(
    private immunizationService: ImmunizationNameService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const immunizationSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.immunizationSearchQuery = immunizationSearchQuery ? immunizationSearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getImmunization();
  }

  openModal(immunization) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '400px';
    dialogConfig.width = '600px';
    dialogConfig.data = immunization;

    const dialogRef = this.dialog.open(ImmunizationFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getImmunization();
      }
    });
  }

  getImmunization(): void {
    const page = this.page;
    this.loading = true;
    this.immunizationService
      .getImmunizationList(this.page, this.limit, this.immunizationSearchQuery)
      .subscribe(
        (response: any) => {
          this.immunizationList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.immunizationList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchImmunization();
  }

  searchImmunization() {
    this.getImmunization();
    this.addParamsToUrl(this.page, this.limit, this.immunizationSearchQuery);
  }

  clearSearch() {
    this.immunizationList = null;
    this.page = 1;
    this.limit = 10;
    this.immunizationSearchQuery = null;
    this.searchImmunization();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getImmunization();
    this.addParamsToUrl(this.page, this.limit, this.immunizationSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getImmunization();
    this.addParamsToUrl(this.page, this.limit, this.immunizationSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getImmunization();
    this.addParamsToUrl(this.page, this.limit, this.immunizationSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
