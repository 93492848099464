import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../services/lab-booking.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { LabReportService } from '../../../services/lab-report.service';
import * as moment from 'moment';
import { PatientService } from '../../../services/patient.service';
import { NotifyService } from '../../../services/notify.service';
import { UrlsService } from '../../../services/urls.service';
import { FilteredInvestigation, Investigation } from '../../../shared/model/investigation';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/shared/model/country-code';
import { CountryCodeService } from 'src/app/services/country-code.service';

@Component({
  selector: 'app-lab-booking-edit',
  templateUrl: './lab-booking-edit.component.html',
  styleUrls: ['./lab-booking-edit.component.css']
})
export class LabBookingEditComponent implements OnInit {
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;
  NAME_MAX_LENGTH = 255;
  MOBILE_PATTERN = '^\\d{10}$';

  DEBOUNCE_TIME = 300;
  investigationSearchInputChanged: Subject<string> = new Subject<string>();
  today = new Date();
  nepalCountryCode: CountryCode = new CountryCode();
  countryCodes: CountryCode[];

  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'required',
      message: 'Name is required'
    },
    {
      name: 'name',
      error_type: 'maxLength',
      message: 'Name can be no more than ' + this.NAME_MAX_LENGTH + ' characters'
    },
    {
      name: 'country_code',
      error_type: 'required',
      message: 'Country Code is required'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'recommended_by',
      error_type: 'required',
      message: 'Recommended By is required'
    },
    {
      name: 'investigation',
      error_type: 'required',
      message: 'At least one investigation should be selected'
    },
    {
      name: 'lab_id',
      error_type: 'required',
      message: 'Diagnostic Center is required'
    }
  ];

  labBookingForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    email: new FormControl('', [Validators.email]),
    // country_code: new FormControl('', Validators.required),
    mobile: new FormControl('', [
      Validators.required,
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    recommended_by: new FormControl('', [Validators.required]),
    date: new FormControl(''),
    time: new FormControl(''),
    note: new FormControl(''),
    lab_id: new FormControl('', [Validators.required])
  });

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'recommended_by',
      error: false
    },
    {
      controlName: 'date',
      error: false
    },
    {
      controlName: 'time',
      error: false
    },
    {
      controlName: 'note',
      error: false
    },
    {
      controlName: 'bookings',
      error: false
    },
    {
      controlName: 'lab_id',
      error: false
    }
  ];

  INVESTIGATION_SELECT_ALL_MESSAGE = 'Select All';
  INVESTIGATION_DESELECT_ALL_MESSAGE = 'Deselect All';
  patientId: number;
  labBookingId: number;

  selectedBookingPatientType = 'new';
  fieldDisabled = true;
  patientSearchKeyword = '';
  displayPatientSuggestion = false;
  suggestedPatients: Array<any> = [];

  diagnosticCenters: any[] = [];

  displayExistingPatientSuggestion = false;
  existingSuggestedPatients: Array<any> = [];

  selectedInvestigations: Array<any> = [];
  suggestedInvestigations: Array<any> = [];
  displaySuggestionList = false;

  investigationKeyword = '';
  hasInvestigations = false;

  doctorList: string[] = [];
  filteredDoctorList: Observable<string[]>;

  // For Form Footer
  cancelUrl = '/lab-bookings';
  submitInProgress = false;

  formSubmitted = false;
  formDirty = false;

  loadingPatientSearch = false;
  patientSearchHit = false;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private urlService: UrlsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private labReportService: LabReportService,
    private toastr: ToastrService,
    private patientService: PatientService,
    private ngZone: NgZone,
    private countryCodeService: CountryCodeService
  ) {}

  ngOnInit() {
    this.labBookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getCountryCodes();
    this.getDoctorList();
    this.getLabList();
    this.getLabBooking(this.labBookingId);
    this.initiateInvestigationSearchTimer();
  }

  initiateInvestigationSearchTimer() {
    this.investigationSearchInputChanged
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((search) => {
        this.labReportService
          .getInvestigationServiceList(
            this.labBookingForm.controls.lab_id.value,
            this.investigationKeyword,
            this.selectedInvestigations
          )
          .subscribe((investigations: any) => {
            this.suggestedInvestigations = this.getProcessedSuggestedInvestigations(investigations);
            this.displaySuggestionList = true;
          });
      });
  }

  getCountryCodes() {
    this.countryCodeService.getCountryCodes().subscribe(
      (response: CountryCode[]) => {
        this.countryCodes = response;
        response.map((x) => {
          if (x.name == 'Nepal') {
            this.nepalCountryCode = x;
          }
        });
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  clearInvestigation() {
    this.selectedInvestigations = [];
  }
  getName(labId) {
    return this.diagnosticCenters.find((diagnosticCenter) => diagnosticCenter.id === labId).name;
  }
  getLabBooking(bookingId) {
    this.submitInProgress = true;
    this.labBookingService.getBookingDetail(bookingId).subscribe(
      (response: any) => {
        this.setDefaultValues(response.booking);
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
        if (this.selectedInvestigations.length > 0) {
          this.hasInvestigations = true;
        }
      }
    );
  }

  setDefaultValues(booking) {
    this.labBookingForm.patchValue({
      name: booking.name,
      // country_code: booking.country_code,
      mobile: booking.mobile,
      email: booking.email,
      recommended_by: booking.recommended_by,
      date: booking.date !== null ? moment(booking.date).format('YYYY-MM-DD') : '',
      time: booking.date !== null ? new Date(booking.date).toLocaleTimeString() : '',
      note: booking.note,
      lab_id: booking.lab_id
    });
    booking.lab_booking_investigation.forEach((investigation) => {
      const investigationObj = {
        id: investigation.investigation_id,
        name: investigation.investigation.name,
        tests: investigation.testTypes
      };
      this.selectedInvestigations.push(investigationObj);
      this.setInvestigationCheckedStatus(investigationObj);
    });
    this.patientId = booking.patient_id;
  }

  setInvestigationCheckedStatus(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    if (this.areAllTestSelected(investigation)) {
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests === 0) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests <= totalTests) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = true;
      return;
    }
  }

  areAllTestSelected(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    return selectedTests === totalTests;
  }

  toggleSelectTest(investigation, test) {
    test.selected = !test.selected;
    this.setInvestigationCheckedStatus(investigation);
  }

  toggleSelectAllInvestigation(investigation) {
    if (!this.areAllTestSelected(investigation)) {
      investigation.tests.map((test) => (test.selected = true));
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
    } else {
      investigation.tests.map((test) => (test.selected = false));
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
    }
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (y: any) => {
        this.submitInProgress = false;
        this.doctorList = y;
        this.filteredDoctorList = this.setACField(
          this.labBookingForm.controls.recommended_by,
          'doctorList'
        );
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabList().subscribe(
      (y: any) => {
        this.diagnosticCenters = y.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.diagnosticCenters = this.diagnosticCenters.filter((d) => d.active === 1);
        this.submitInProgress = false;
      }
    );
  }
  searchPatients() {
    this.displayPatientSuggestion = false;
    const pageNo = 1;
    const limit = 10;
    const filter = 'mobile';
    if (this.patientSearchKeyword.length > 2) {
      this.loadingPatientSearch = true;
      this.patientSearchHit = true;
      this.patientService
        .searchPatients(this.patientSearchKeyword, pageNo, limit, filter)
        .subscribe(
          (response: any) => {
            if (response.size >= 1) {
              this.displayPatientSuggestion = true;
              this.suggestedPatients = response.data;
            } else {
              this.suggestedPatients.length = 0;
            }
            this.loadingPatientSearch = false;
          },
          (error) => {
            this.suggestedPatients.length = 0;
            this.displayPatientSuggestion = false;
            this.loadingPatientSearch = false;
          }
        );
    } else {
      this.displayPatientSuggestion = false;
    }
  }

  proceedToSearch(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.searchPatients();
    }
  }

  selectPatient(patient) {
    const patientName =
      patient.first_name +
      ' ' +
      (patient.middle_name ? patient.middle_name + ' ' : '') +
      patient.last_name;
    this.labBookingForm.patchValue({
      name: patientName,
      mobile: patient.mobile,
      email: patient.email
    });
    this.patientId = patient.id;

    this.patientSearchKeyword = '';
    this.displayPatientSuggestion = false;
    this.displayExistingPatientSuggestion = false;

    this.selectedBookingPatientType = 'old';
  }

  showSuggestions() {
    if (this.investigationKeyword.length <= 3) {
      this.displaySuggestionList = false;
      return;
    }
    this.investigationSearchInputChanged.next(this.investigationKeyword);
    return true;
  }

  getProcessedSuggestedInvestigations(investigations: Investigation[]) {
    const filteredResult: FilteredInvestigation[] = [];
    investigations = this.renameTestKey(investigations);
    investigations.forEach((investigation) => {
      if (
        investigation.tests.length <= 0 ||
        investigation.tests.filter((test) => test.found === true).length <= 0
      ) {
        // if keyword matches investigation,
        // return instance of investigation

        filteredResult.push(investigation);
      } else {
        // if keyword matches test type,
        // filter matched test type and
        // return instance of investigation
        // with test type

        const matchedTestResults = investigation.tests.filter((test) => test.found);

        matchedTestResults.forEach((test) => {
          const matchedInvestigation: FilteredInvestigation = { ...investigation };
          matchedInvestigation.test_type_suggested = test;
          filteredResult.push(matchedInvestigation);
        });
      }
    });
    return filteredResult;
  }

  renameTestKey(investigations) {
    // renamed test key from test_type_list to tests
    // this implementation can be removed once
    // test key is returned as tests from API

    return investigations.map((investigation) => {
      investigation.tests = investigation.test_type_list;
      delete investigation.test_type_list;
      return investigation;
    });
  }

  selectInvestigation(investigation) {
    if (investigation.test_type_suggested) {
      investigation.tests
        .filter((test) => test.id === investigation.test_type_suggested.id)
        .map((test) => {
          test.selected = true;
        });
    } else {
      investigation.tests.map((test) => {
        test.selected = true;
      });
    }

    if (
      this.selectedInvestigations.filter(
        (selectedInvestigation) => investigation.id === selectedInvestigation.id
      ).length === 1
    ) {
      const foundTest = investigation.tests.find((test) => test.found === true);
      this.selectedInvestigations
        .find((selectedInv) => investigation.id === selectedInv.id)
        .tests.filter((test) => test.id === foundTest.id)
        .map((test) => (test.selected = true));
    } else {
      this.selectedInvestigations.push(investigation);
    }

    this.displaySuggestionList = false;
    this.investigationKeyword = '';

    this.setInvestigationCheckedStatus(investigation);
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  removeInvestigation(investigation) {
    this.selectedInvestigations = this.selectedInvestigations.filter((selectedInvestigation) => {
      return (
        this.selectedInvestigations.indexOf(investigation) !==
        this.selectedInvestigations.indexOf(selectedInvestigation)
      );
    });
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  changeDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  submit() {
    this.submitInProgress = true;
    this.formSubmitted = true;

    if (this.labBookingForm.status === 'VALID' && this.selectedInvestigations.length >= 1) {
      const date = this.labBookingForm.controls.date.value;
      const time = this.labBookingForm.controls.time.value;

      const request = this.labBookingForm.value;

      request.date = this.getMergedDateTime(date, time);
      delete request.time;

      if (this.patientId) {
        request.patient_id = this.patientId;
      }

      request.bookings = this.getSanitizedBookings(this.selectedInvestigations);

      this.labBookingService.updateBooking(this.labBookingId, request).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          this.toastr.error(error.error.message);
        },
        () => {
          this.ngZone.run(() =>
            this.router.navigate(['/lab-bookings'], { queryParams: { status: 'pending' } })
          );
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.recommended_by) {
      this.setBackendValidationError('recommended_by', errors.recommended_by[0]);
    } else {
      this.unsetBackendValidationError('recommended_by');
    }
    if (errors.date) {
      this.setBackendValidationError('date', errors.date[0]);
    } else {
      this.unsetBackendValidationError('date');
    }
    if (errors.time) {
      this.setBackendValidationError('time', errors.time[0]);
    } else {
      this.unsetBackendValidationError('time');
    }
    if (errors.note) {
      this.setBackendValidationError('note', errors.note[0]);
    } else {
      this.unsetBackendValidationError('note');
    }
    if (errors.bookings) {
      this.setBackendValidationError('bookings', errors.bookings[0]);
    } else {
      this.unsetBackendValidationError('bookings');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getSanitizedBookings(selectedInvestigations: Array<any>) {
    const investigations = [];
    selectedInvestigations.forEach((investigation: any) => {
      investigations.push({
        id: investigation.id,
        name: investigation.name,
        selected: true,
        tests: investigation.tests.filter((test) => test.selected === true)
      });
    });
    return investigations;
  }

  getMergedDateTime(date, time) {
    console.log(time);
    return date
      ? moment((date + ' ' + time).trim(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      : '';
  }

  checkIfNumberUsed() {
    if (this.fieldDisabled) {
      return;
    }
    this.displayExistingPatientSuggestion = false;
    const mobile = this.labBookingForm.controls.mobile.value;
    const pageNo = 1;
    const limit = 10;
    const filter = 'mobile';
    if (mobile.length > 2) {
      this.patientService.searchPatients(mobile, pageNo, limit, filter).subscribe(
        (response: any) => {
          if (response.size >= 1) {
            this.displayExistingPatientSuggestion = true;
            this.existingSuggestedPatients = response.data;
          }
        },
        (error) => {
          this.existingSuggestedPatients.length = 0;
          this.displayExistingPatientSuggestion = false;
        }
      );
    } else {
      this.displayExistingPatientSuggestion = false;
    }
  }

  skipSelection() {
    this.patientId = null;
    this.displayExistingPatientSuggestion = false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
