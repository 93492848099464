import { Injectable, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessageService } from 'src/app/shared/services/toast-message-service/toast-message.service';
import { SharedConstant } from 'src/app/shared/constant/shared-constant';
import { UserService } from './user.service';
import { Session } from '../../type/connectycube';
import { AuthService } from '../../auth/shared/services/auth.service';
import { BrowserNotificationService } from '../../shared/services/browser-notification.service';

interface CallEmitterType {
  type: string;
  value?: boolean;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CallService {
  // const iOS = Window.device.platform === "iOS";
  sharedConstant = SharedConstant;
  iOS = false;

  users: any = {};

  _session: Session;
  callDialogEmitter: EventEmitter<CallEmitterType> = new EventEmitter();
  callEmitter: EventEmitter<CallEmitterType> = new EventEmitter();
  callRejectEmmitter: EventEmitter<any> = new EventEmitter();

  mediaDevicesIds = [];
  activeDeviceId = null;
  isAudioMuted = false;
  isVideoOff = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private auth: AuthService,
    private browserNotification: BrowserNotificationService
  ) {}
  init() {
    this.auth.connectyCubeClient.chat.markActive();

    this.auth.connectyCubeClient.videochat.onCallListener = this.onCallListener.bind(this);
    this.auth.connectyCubeClient.videochat.onAcceptCallListener = this.onAcceptCallListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onRejectCallListener = this.onRejectCallListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onStopCallListener = this.onStopCallListener.bind(this);
    this.auth.connectyCubeClient.videochat.onUserNotAnswerListener = this.onUserNotAnswerListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onRemoteStreamListener = this.onRemoteStreamListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onDevicesChangeListener = this.onDevicesChangeListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onSessionCloseListener = this.onSessionCloseListener.bind(
      this
    );
    this.auth.connectyCubeClient.videochat.onSessionConnectionStateChangedListener = this.onSessionConnectionStateChangedListener.bind(
      this
    );
  }

  onSessionConnectionStateChangedListener = (userID, connectionState) => {
    // console.log('HAH: onSessionConnectionStateChangedListener', userID, connectionState)
  };

  onCallListener = (session, extension) => {
    console.log('HAH: onCallListener');
    if (session.initiatorID === session.currentUserID) {
      console.log('HAH: onCallListener same');

      return false;
    }

    if (this._session) {
      this.rejectCall(session, { busy: true });
      return false;
    }
    this._session = session;
    const initiatorId = this._session.initiatorID;

    if (this.users[initiatorId] !== undefined) {
      this.browserNotification.generateNotification(
        this.getNotificationObj(this.users[initiatorId].name)
      );
      this.emitDialogOpen(this.users[initiatorId].name);
      console.log('HAH: onCallListener users');

      return;
    }

    this.userService.getUserDetail(initiatorId).subscribe(
      (response: any) => {
        this.users[initiatorId] = response;
        this.browserNotification.generateNotification(
          this.getNotificationObj(this.users[initiatorId].name)
        );
        this.emitDialogOpen(this.users[initiatorId].name);
      },
      (error) => {
        this.browserNotification.generateNotification(this.getNotificationObj());
        this.emitDialogOpen();
      }
    );
  };

  emitDialogOpen(userName: string = 'Unknown') {
    const payload: CallEmitterType = {
      type: 'onCallListener',
      data: { userName }
    };
    this.callDialogEmitter.emit(payload);
  }

  rejectCall = (session?, extension = {}) => {
    if (session) {
      console.log('HAH: session if rejectCall', session);
      session.reject(extension);
    } else {
      console.log('HAH: session eles rejectCall', session);
      this._session.reject(extension);
      this._session = null;

      const payload: CallEmitterType = {
        type: 'rejectCall'
      };

      this.callDialogEmitter.emit(payload);
      this.callRejectEmmitter.emit(true);
    }
  };

  onAcceptCallListener = (session, userId, extension) => {
    if (userId === session.currentUserID) {
      if (true) {
        this._session = null;

        const payload: CallEmitterType = {
          type: 'onAcceptCallListener'
        };
        this.callDialogEmitter.emit(payload);

        this.toastMessageService.setMessage(
          this.sharedConstant.info,
          'You have accepted the call on other side'
        );
      }

      return false;
    } else {
      const userName = this._getUserById(userId, 'name');
      const infoText = `${userName} has accepted the call`;

      this.toastMessageService.setMessage(this.sharedConstant.info, infoText);

      this.startVoIPSession(session);

      const data: CallEmitterType = {
        type: 'dialing',
        data: 'pause'
      };
      this.callEmitter.emit(data);
    }
  };

  onRejectCallListener = (session, userId, extension: any = {}) => {
    console.log('HAH: onRejectCallListener', session, userId, extension);
    if (userId === session.currentUserID) {
      if (true) {
        this._session = null;

        const payload: CallEmitterType = {
          type: 'onRejectCallListener'
        };
        this.callDialogEmitter.emit(payload);

        this.toastMessageService.setMessage(
          this.sharedConstant.info,
          'You have reject the call on other side'
        );
      }
      return false;
    } else {
      const userName = this._getUserById(userId, 'name');
      const infoText = extension.busy
        ? `${userName} is busy`
        : `${userName} rejected the call request`;

      this.stopCall(userId);
      this.toastMessageService.setMessage(this.sharedConstant.info, infoText);
    }
  };

  onStopCallListener = (session, userId, extension) => {
    if (!this._session) {
      return false;
    }

    const isStoppedByInitiator = session.initiatorID === userId;
    const userName = this._getUserById(userId, 'name');
    const infoText = `${userName} has ${isStoppedByInitiator ? 'stopped' : 'left'} the call`;

    this.toastMessageService.setMessage(this.sharedConstant.info, infoText);

    if (isStoppedByInitiator) {
      // check if video request dialog is open
      // check pop up model is open if true close it but now we put true
      if (true) {
        // emit hide incomming call model
        const payload: CallEmitterType = {
          type: 'onStopCallListener'
        };
        this.callDialogEmitter.emit(payload);
      }
      this.stopCall();
    } else {
      this.stopCall(userId);
    }
  };

  onUserNotAnswerListener = (session, userId) => {
    if (!this._session) {
      return false;
    }

    const userName = this._getUserById(userId, 'name');
    const infoText = `${userName} did not answer`;
    this.toastMessageService.setMessage(this.sharedConstant.info, infoText);

    this.stopCall(userId);
  };

  onRemoteStreamListener = (session, userId, stream) => {
    if (!this._session) {
      return false;
    }

    const remoteStreamSelector = `${userId}`;

    this._session.attachMediaStream(remoteStreamSelector, stream);

    const muteUnmute: CallEmitterType = {
      type: 'muteUnmute',
      value: false
    };
    this.callEmitter.emit(muteUnmute);

    this.onDevicesChangeListener();

    const loader: CallEmitterType = {
      type: 'videochat-stream-loader',
      data: { userId }
    };

    this.callEmitter.emit(loader);
    const prepareVideoElement: CallEmitterType = {
      type: 'prepareVideoElement',
      data: { videoElement: remoteStreamSelector }
    };

    this.callEmitter.emit(prepareVideoElement);
  };

  setActiveDeviceId = (stream) => {
    if (stream && !this.iOS) {
      const videoTracks = stream.getVideoTracks();
      const videoTrackSettings = videoTracks[0].getSettings();

      this.activeDeviceId = videoTrackSettings.deviceId;
    }
  };

  setUsers = (users: Array<any>) => {
    users.forEach((user) => {
      this.users[user.id] = user;
    });
  };

  _getUserById = (userId: number, key: any) => {
    let user: any = this.users[userId] || {
      name: 'Name while not loaded'
    };
    this.userService.getUserDetail(userId).subscribe(
      (response: any) => {
        user = response;
        this.users[userId] = response;
      },
      (error) => {
        user = {
          name: 'Name N/A'
        };
      }
    );
    return typeof key === 'string' ? user[key] : user;
  };

  onDevicesChangeListener = () => {
    this.auth.connectyCubeClient.videochat.getMediaDevices('videoinput').then((mediaDevices) => {
      this.mediaDevicesIds = mediaDevices.map(({ deviceId }) => deviceId);

      const mediaDeviceIds: CallEmitterType = {
        type: 'mediaDeviceIds',
        data: 'deviceId'
      };
      this.callEmitter.emit(mediaDeviceIds);
      if (this.mediaDevicesIds.length < 2) {
        const switchCamera: CallEmitterType = {
          type: 'switchCamera',
          value: true
        };

        this.callEmitter.emit(switchCamera);
        if (this.mediaDevicesIds && this.mediaDevicesIds[0] !== this.activeDeviceId) {
          this.switchCamera();
        }
      } else {
        const switchCamera: CallEmitterType = {
          type: 'switchCamera',
          value: false
        };

        this.callEmitter.emit(switchCamera);
      }
    });
  };

  switchCamera = () => {
    const mediaDevicesId = this.mediaDevicesIds.find(
      (deviceId) => deviceId !== this.activeDeviceId
    );

    this._session.switchMediaTracks({ video: mediaDevicesId }).then(() => {
      this.activeDeviceId = mediaDevicesId;

      if (this.isAudioMuted) {
        this._session.mute('audio');
      }
    });
  };

  stopCallDelicate = (userId?: number) => {
    if (!userId && this._session) {
      this._session.stop({});
      this.auth.connectyCubeClient.videochat.clearSession(this._session.ID);
      this._session = null;
      this.mediaDevicesIds = [];
      this.activeDeviceId = null;
      this.isAudioMuted = false;
      this.router.navigate(['/dashboard']);
    }
  };

  stopCall = (userId?: number) => {
    this.endVoIPSession(this._session);
    if (!userId) {
      this.stopCallDelicate();
    }
    const payload: CallEmitterType = {
      type: 'stopCall',
      data: { userId, session: this._session }
    };
    this.callEmitter.emit(payload);
  };

  startCall(opponentsIds, type, options) {
    this._session = this.auth.connectyCubeClient.videochat.createNewSession(
      opponentsIds,
      type,
      options
    );
  }

  setAudioMute = () => {
    if (this.isAudioMuted) {
      this._session.unmute('audio');
      this.isAudioMuted = false;
    } else {
      this._session.mute('audio');
      this.isAudioMuted = true;
    }
  };

  setVideoOff = () => {
    if (this.isVideoOff) {
      this._session.unmute('video');
      this.isVideoOff = false;
    } else {
      this._session.mute('video');
      this.isVideoOff = true;
    }
  };

  endVoIPSession(session: Session) {
    if (!session) {
      return;
    }
    console.log('HAH: endVoIPSession');

    this.userService.endVoIPConversation(session).subscribe(
      (response) => {
        console.log('HAH: Response', response);
      },
      (error) => {
        console.log('HAH: Response', error);
      }
    );
  }

  initiateVoIPSession = (session?: Session) => {
    if (!session) {
      return;
    }
    this.userService.initiateVoIPConversation(session).subscribe(
      (response) => {
        console.log('HAH: Response', response);
      },
      (error) => {
        console.log('HAH: Response', error);
      }
    );
  };

  startVoIPSession = (session?: Session) => {
    if (!session) {
      return;
    }
    this.userService.startVoIPConversation(session).subscribe(
      (response) => {
        console.log('HAH: Response', response);
      },
      (error) => {
        console.log('HAH: Response', error);
      }
    );
  };

  onSessionCloseListener = (session: Session) => {
    if (this._session.ID === session.ID) {
      this.endVoIPSession(session);
      setTimeout(() => {
        this.stopCallDelicate();
        const payload = {
          type: 'onSessionCloseListener'
        };
        this.callEmitter.emit(payload);
      }, 100);
    }
  };

  getNotificationObj(callerName = null) {
    return {
      title: 'Incoming Call',
      alertContent: 'You have an incoming call ' + (callerName ? 'from ' + callerName : '')
    };
  }
}
