import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { UrlsService } from '../../../services/urls.service';
import { PatientService } from '../../../services/patient.service';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-diagnosis-edit',
  templateUrl: './diagnosis-edit.component.html',
  styleUrls: ['./diagnosis-edit.component.css']
})
export class DiagnosisEditComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('ngModel')
  search: any;
  data: any;
  patientId: number;
  urlList: Array<string> = [];
  nextUrl: string;
  saveAndContinueClick = false;
  diagnosisId: number;

  constructor(
    private router: Router,
    private diagnosisService: DiagnosisService,
    private patient: PatientService,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService
  ) {}

  ngOnInit(): void {
    this.page.title.next('Patient Diagnosis');
    this.patientId = this.patient.getId(this.activatedRoute);
    this.diagnosisId = this.diagnosisService.getId(this.activatedRoute);
    this.diagnosisService.getDiagnosis(this.patientId, this.diagnosisId).subscribe((res) => {
      this.data = res;
    });

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  addDiagnosis(formData) {
    this.diagnosisService.storeDiagnosis(formData.data, this.patientId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        if (formData.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate([this.nextUrl]);
        }
        formData.saveAndContinueClicked = false;
      }
    );
  }

  saveAndContinueClicked(saveAndContinueClicked) {
    this.saveAndContinueClick = saveAndContinueClicked;
  }

  toggleDiagnosisMenu(e) {
    document.getElementById('wrapper-div').classList.toggle('control-sidebar-open');
    e.target.classList.toggle('open-btn');
    e.target.classList.toggle('close-btn');
  }
}
