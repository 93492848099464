import { Component, OnInit } from '@angular/core';
import { VitalService } from '../../../services/vitals.service';
import { VitalList } from '../../../shared/model/vitalList';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { PatientService } from 'src/app/services/patient.service';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vital-create',
  templateUrl: './vital-create.component.html',
  styleUrls: ['./vital-create.component.css']
})
export class VitalCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  vitalsForm = new FormGroup({});
  vitalList: VitalList[];
  patientId: number;

  atLeastOneError: string;

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  submitted = false;

  constructor(
    private vitalService: VitalService,
    private router: Router,
    private patient: PatientService,
    private route: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil
  ) {}

  ngOnInit() {
    this.patientId = this.patient.getId(this.route);
    this.page.setTitleAndPatientId('Add Vitals', this.patientId);
    console.log('Patient Id: ' + this.patient.getId(this.route));
    this.page.patientId.next(this.patientId);

    this.getVitals();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.route.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getVitals(): void {
    this.submitInProgress = true;
    this.vitalService.getVitalList('', 'vital').subscribe(
      (vitalList) => {
        vitalList.map((vital) => {
          if (typeof vital.hasOwnProperty('rules') !== undefined) {
            const rulesMin = vital.rules.split('|')[0];
            const MinValue = rulesMin.split(':')[1];
            const rulesMax = vital.rules.split('|')[1];
            const MaxValue = rulesMax.split(':')[1];
            this.vitalsForm.addControl(
              vital.label,
              new FormControl('', [
                Validators.min(this.transform.toNumber(MinValue)),
                Validators.max(this.transform.toNumber(MaxValue))
              ])
            );
          }
          if (vital.secondary_rules !== null) {
            const rulesMinSecondary = vital.secondary_rules.split('|')[0];
            const MinValueSecondary = rulesMinSecondary.split(':')[1];
            const rulesMaxSecondary = vital.secondary_rules.split('|')[1];
            const MaxValueSecondary = rulesMaxSecondary.split(':')[1];
            this.vitalsForm.addControl(
              vital.secondary_label,
              new FormControl('', [
                Validators.min(this.transform.toNumber(MinValueSecondary)),
                Validators.max(this.transform.toNumber(MaxValueSecondary))
              ])
            );
          } else {
            this.vitalsForm.addControl(vital.secondary_label, new FormControl(''));
          }
          this.vitalsForm.addControl(vital.uom_label.concat('_' + vital.label), new FormControl());
          this.vitalsForm
            .get(vital.uom_label.concat('_' + vital.label))
            .setValue(vital.default_uom);
        });
        this.vitalList = vitalList;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  addVitals(): void {
    this.submitted = true;
    this.atLeastOneError = 'At least one field is required.';

    this.vitalList.map((vital) => {
      if (this.vitalsForm.value[vital.label] !== '') {
        this.atLeastOneError = '';
      }
      this.vitalsForm.controls['uom_' + vital.label].setErrors(null);
    });

    if (this.atLeastOneError !== '') {
      this.submitInProgress = false;

      return;
    }
    this.submitInProgress = true;
    if (this.vitalsForm.invalid) {
      this.submitInProgress = false;

      return;
    }

    this.vitalService.add(this.patientId, this.vitalsForm.value).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (err) => {
        this.submitted = false;
        this.submitInProgress = false;
        if (err.status === 422) {
          // tslint:disable-next-line:forin
          for (const error in err.error.errors) {
            this.vitalsForm.controls[error].setErrors({
              invalid: true,
              msg: err.error.errors[error]
            });
          }
        } else {
          this.toastr.error(err.error.message);
        }
      },
      () => {
        this.submitted = false;
        this.submitInProgress = false;
        if (this.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate([this.nextUrl]);
        }
        this.saveAndContinueClicked = false;
      }
    );
  }
}
