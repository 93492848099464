import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LabBookingService {
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  store(data) {
    const url = this.apiUrl + 'lab-bookings';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post(url, data, this.httpOptions);
  }

  storeLabRecevied(data, labBookingId) {
    const url = this.apiUrl + 'lab-bookings/' + labBookingId + '/lab-received';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post(url, data, this.httpOptions);
  }
  getStatusList() {
    return this.http.get(this.apiUrl + 'lab-status-list');
  }

  getBookingList(status: string, pageNo, limit, filterBody) {
    const params: any = {
      query: filterBody.query ? filterBody.query : '',
      name: filterBody.name ? filterBody.name : '',
      mobile: filterBody.mobile ? filterBody.mobile : '',
      bookingFrom: filterBody.bookingFrom ? filterBody.bookingFrom : '',
      bookingTo: filterBody.bookingTo ? filterBody.bookingTo : '',
      scheduledFrom: filterBody.scheduledFrom ? filterBody.scheduledFrom : '',
      scheduledTo: filterBody.scheduledTo ? filterBody.scheduledTo : '',
      recommendedBy: filterBody.recommendedBy ? filterBody.recommendedBy : '',
      labId: filterBody.labId ? filterBody.labId : ''
    };
    // const params = filterBody;
    params.pageNo = pageNo;
    params.limit = limit;
    return this.http.get(this.apiUrl + 'lab-bookings/' + status, { params });
  }

  getBookingListOfInterval(status: string, pageNo, limit, interval) {
    return this.http.get(this.apiUrl + 'lab-bookings/' + status, {
      params: { pageNo, limit, todo: interval }
    });
  }

  updateStatus(bookingId, statusId) {
    const url = this.apiUrl + 'lab-bookings/' + bookingId;
    const body = { status_id: statusId };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, body, httpOptions);
  }

  getBookingDetail(bookingId: number) {
    return this.http.get(this.apiUrl + 'lab-booking/' + bookingId);
  }

  storeSamples(bookingId: number, sampleList) {
    const url = this.apiUrl + 'lab-bookings/' + bookingId + '/add-samples';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, sampleList, httpOptions);
  }

  getLabReport(bookingId: number) {
    const url = this.apiUrl + 'patient-lab-reports/' + bookingId;
    return this.http.get(url);
  }

  deliverBooking(bookingId: number, request: any) {
    const url = this.apiUrl + bookingId + '/lab-report-deliveries';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, request, httpOptions);
  }

  updateBooking(bookingId: number, request: any) {
    const url = this.apiUrl + 'lab-bookings/' + bookingId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, request, httpOptions);
  }

  deleteBooking(bookingId: number) {
    const url = this.apiUrl + 'lab-bookings/' + bookingId;
    return this.http.delete(url);
  }

  dispatchBooking(bookingId: number, request: any) {
    const url = this.apiUrl + 'lab-bookings/' + bookingId + '/lab-dispatches';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, request, httpOptions);
  }

  getStaffList() {
    return this.http.get(this.apiUrl + 'staffs');
  }

  getLabStaffList(labId: number) {
    return this.http.get(this.apiUrl + 'labs/' + labId + '/staffs');
  }
}
