import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceTypeListService } from '../../../services/service-type-list.service';
import { NotifyService } from '../../../services/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-management-edit',
  templateUrl: './service-management-edit.component.html',
  styleUrls: ['./service-management-edit.component.css']
})
export class ServiceManagementEditComponent implements OnInit {
  serviceId;
  serviceDetail;
  formSubmitted;
  serviceTypeList;
  cancelUrl = '/service-management';
  submitInProgress;
  selectedPayments = [];
  serviceManagementForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    service_type_id: new FormControl('', [Validators.required]),
    active: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required])
  });
  file = '';
  oldFileName = '';
  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'required',
      message: 'Name is required'
    },
    {
      name: 'amount',
      error_type: 'required',
      message: 'Amount is required'
    },
    {
      name: 'service_type_id',
      error_type: 'required',
      message: 'Service Type is required'
    },
    {
      name: 'description',
      error_type: 'required',
      message: 'Description is required'
    },
    {
      name: 'payment_method',
      error_type: 'required',
      message: 'Payment Method is required'
    },
    {
      name: 'service_image',
      error_type: 'required',
      message: 'Service Image is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'amount',
      error: false
    },
    {
      controlName: 'service_type_id',
      error: false
    },
    {
      controlName: 'description',
      error: false
    },
    {
      controlName: 'service_image',
      error: false
    },
    {
      controlName: 'payment_method',
      error: false
    }
  ];

  constructor(
    private serviceTypeService: ServiceTypeListService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.serviceId = this.activateRoute.snapshot.paramMap.get('id');
    this.getServiceType();
    this.getServiceManagementDetail();
  }

  storeServiceManagement() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (this.serviceManagementForm.status === 'VALID' && this.selectedPayments.length !== 0) {
      console.log(this.serviceManagementForm.value);
      const requestData = this.createRequestData({
        name: this.serviceManagementForm.controls.name.value,
        amount: this.serviceManagementForm.controls.amount.value,
        service_type_id: this.serviceManagementForm.controls.service_type_id.value,
        description: this.serviceManagementForm.controls.description.value,
        active: this.serviceManagementForm.controls.active.value,
        service_image: this.file
      });
      console.log(requestData);
      this.selectedPayments.forEach((payment, index) => {
        requestData.append('payment_method[' + index + ']', payment);
      });
      this.serviceTypeService.updateSerivce(requestData, this.serviceDetail.id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/service-management']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  getServiceManagementDetail() {
    this.serviceTypeService.getServiceManagementDetail(this.serviceId).subscribe(
      (res) => {
        this.serviceDetail = res;
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.patchData();
      }
    );
  }

  patchData() {
    this.serviceManagementForm.patchValue({
      name: this.serviceDetail.name,
      amount: this.serviceDetail.amount,
      description: this.serviceDetail.description,
      service_type_id: this.serviceDetail.service_type_id,
      active: this.serviceDetail.active
    });
    this.serviceDetail.payment_methods.forEach((payment) => {
      this.selectedPayments.push(payment.payment_method);
    });
    this.oldFileName = this.serviceDetail.service_image ? this.serviceDetail.service_image : '';
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  dataAddOrRemove(selectedPayment) {
    const payment = this.selectedPayments.filter((payment: any) => {
      return payment === selectedPayment;
    });
    if (payment.length === 0) {
      this.selectedPayments.push(selectedPayment);
    } else {
      this.selectedPayments = this.selectedPayments.filter((payment: any) => {
        return payment !== selectedPayment;
      });
    }
  }

  getCheckedStatus(paymentMethod) {
    return this.selectedPayments.includes(paymentMethod);
  }

  getServiceType(): void {
    this.serviceTypeService.getServiceTypeList().subscribe(
      (response: any) => {
        console.log(response.data);
        this.serviceTypeList = response.data;
      },
      (error) => {
        this.serviceTypeList.length = 0;
      },
      () => {}
    );
  }

  uploadFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.file = files[0];
      this.oldFileName = '';
    } else {
      this.file = '';
    }
  }

  removeFile() {
    this.file = '';
  }

  removeOldFileName(event) {
    event.preventDefault();
    this.oldFileName = '';
    this.file = '';
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.amount) {
      this.setBackendValidationError('amount', errors.amount[0]);
    } else {
      this.unsetBackendValidationError('amount');
    }
    if (errors.description) {
      this.setBackendValidationError('description', errors.description[0]);
    } else {
      this.unsetBackendValidationError('description');
    }
    if (errors.service_image) {
      this.setBackendValidationError('service_image', errors.service_image[0]);
    } else {
      this.unsetBackendValidationError('service_image');
    }
    if (errors.payment_method) {
      this.setBackendValidationError('payment_method', errors.payment_method[0]);
    } else {
      this.unsetBackendValidationError('payment_method');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
