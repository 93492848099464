import { DialogService } from 'src/app/shared/services/dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserServiceService } from '../../services/user-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDeletedResponse } from '../shared/constants/user-deleted-response';
import { NotifyService } from '../../services/notify.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../shared/model/profile';
import * as moment from 'moment';
import { DatePickerMonthYearComponent } from '../shared/component/date-picker-month-year/date-picker-month-year.component';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userList: Array<any> = [];
  @ViewChild(DatePickerMonthYearComponent, { static: false }) child: DatePickerMonthYearComponent;
  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  date;
  clearDate = false;
  profileList: Array<Profile> = [];
  userSearchQuery: string;
  rawSearchQuery: string;
  advancedSearchForm = new FormGroup({
    q: new FormControl(''),
    date: new FormControl(''),
    profile: new FormControl('')
  });
  constructor(
    private userService: UserServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.advancedSearchForm.patchValue({
      q: this.activatedRoute.snapshot.queryParamMap.get('q'),
      date: this.activatedRoute.snapshot.queryParamMap.get('date'),
      profile: this.activatedRoute.snapshot.queryParamMap.get('profile')
    });
    //this.rawSearchQuery = this.getQueryFromInputParams();
    this.hideToggleSearchFilters();
    this.getUserList(this.limit);
    this.getProfileList();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getUserList(this.limit);
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getUserList(this.limit);
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getUserList(this.limit);
    this.setUrlParams();
  }

  getProfileList() {
    const profile_type = 'service_provider';
    this.profileService.getProfilesList(profile_type).subscribe(
      (res) => {
        this.profileList = res;
        this.profileList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      },
      (error) => {},
      () => {}
    );
  }

  getProfileName(profile) {
    let profileName = '';
    profileName += profile.name;
    profileName += profile.is_connectycube === 1 ? ' (Video Call Feature)' : '';
    return profileName;
  }

  getUserList(limit): void {
    const page = this.page;
    this.loading = true;
    const data = this.formatData();
    this.userService.getUserList(page, limit, data).subscribe(
      (userList: any) => {
        this.userList = userList.data;
        this.total = userList.size;
      },
      (error) => {
        this.userList.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }
  formatData() {
    const data: any = {
      q: this.advancedSearchForm.value.q ? this.advancedSearchForm.value.q : '',
      profile_id: this.advancedSearchForm.value.profile
        ? this.advancedSearchForm.value.profile
        : '',
      registered_at: this.advancedSearchForm.value.date
        ? moment(this.advancedSearchForm.value.date).format('YYYY-MM-DD')
        : ''
    };
    return data;
  }
  editUser(user) {
    this.router.navigate(['users/' + user.id + '/edit']);
  }

  showDetail(user) {
    this.router.navigate(['users/' + user.id]);
  }

  deleteUser(user) {
    this.userService.deleteUser(user.id).subscribe(
      (response: UserDeletedResponse) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.userList.splice(this.userList.indexOf(user), 1);
      }
    );
  }

  openConfirmationDialog(user) {
    this.dialogService.confirmDialog({
      title: 'Confirmation',
      message: 'Are you sure you want to delete this user?',
      confirmText: 'Confirm',
      cancelText: 'Cancel',
    }).subscribe((result)=>{
      if(result) {
        this.deleteUser(user);
      }
    });
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      //this.rawSearchQuery = this.getQueryFromInputParams();
      this.setUrlParams();
      this.getUserList(this.limit);
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('q').reset();
    this.applyAdvancedSearch();
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'q') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value + ' ';
        } else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }
      }
    }
    return stringParams;
  }

  setUrlParams() {
    const queryParams = {
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      q: this.advancedSearchForm.get('q').value ? this.advancedSearchForm.get('q').value : null,
      profile: this.advancedSearchForm.get('profile').value
        ? this.advancedSearchForm.get('profile').value
        : null,
      date: this.advancedSearchForm.get('date').value
        ? this.advancedSearchForm.get('date').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['q', 'profile', 'date'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      q: queryValue.trim()
    });
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
      this.clearDate = true;
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
      this.clearDate = false;
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }
  resetAdvancedSearchForm() {
    this.page = 1;
    this.advancedSearchForm.patchValue({
      profile: null,
      date: null
    });
    this.clearDate = true;
    this.setUrlParams();
    this.getUserList(this.limit);
  }

  chooseDate($event: MatDatepickerInputEvent<Date>) {
    this.advancedSearchForm.patchValue({
      date: moment($event.target.value).utc().format('YYYY-MM-DD')
    });
  }
}
