import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VitalService } from '../../../services/vitals.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VitalList } from '../../../shared/model/vitalList';
import { NotifyService } from '../../../services/notify.service';
import { PageService } from '../../workflow-forms/page.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vital-edit',
  templateUrl: './vital-edit.component.html',
  styleUrls: ['./vital-edit.component.css']
})
export class VitalEditComponent implements OnInit {
  patientId: number;
  vitalId: number;

  atLeastOneError: string;

  vitalsForm = new FormGroup({});
  vitalList: VitalList[];

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private vitalService: VitalService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil
  ) {}

  ngOnInit() {
    this.patientId = this.transform.toNumber(this.activatedRoute.snapshot.paramMap.get('id'));
    this.vitalId = this.transform.toNumber(this.activatedRoute.snapshot.paramMap.get('vitalId'));
    this.page.setTitleAndPatientId('Edit Vitals', this.patientId);
    this.getPatientVital(this.patientId, this.vitalId);
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getPatientVital(patientId, vitalId) {
    this.submitInProgress = true;
    this.vitalService.getPatientVital(patientId, vitalId).subscribe(
      (vitalList: any) => {
        vitalList.map((vital) => {
          if (typeof vital.hasOwnProperty('rules') !== undefined) {
            const rulesMin = vital.rules.split('|')[0];
            const MinValue = rulesMin.split(':')[1];
            const rulesMax = vital.rules.split('|')[1];
            const MaxValue = rulesMax.split(':')[1];
            this.vitalsForm.addControl(
              vital.label,
              new FormControl(vital.value, [
                Validators.min(this.transform.toNumber(MinValue)),
                Validators.max(this.transform.toNumber(MaxValue))
              ])
            );
          }
          if (vital.secondary_rules !== null) {
            const rulesMinSecondary = vital.secondary_rules.split('|')[0];
            const MinValueSecondary = rulesMinSecondary.split(':')[1];
            const rulesMaxSecondary = vital.secondary_rules.split('|')[1];
            const MaxValueSecondary = rulesMaxSecondary.split(':')[1];
            this.vitalsForm.addControl(
              vital.secondary_label,
              new FormControl(vital.secondary_value, [
                Validators.min(this.transform.toNumber(MinValueSecondary)),
                Validators.max(this.transform.toNumber(MaxValueSecondary))
              ])
            );
          } else {
            this.vitalsForm.addControl(
              vital.secondary_label,
              new FormControl(vital.secondary_value)
            );
          }
          this.vitalsForm.addControl(
            vital.uom_label.concat('_' + vital.label),
            new FormControl(vital.uom_selected)
          );
        });
        this.vitalList = vitalList;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  saveVitals(): void {
    this.atLeastOneError = 'At least one field is required.';

    this.vitalList.map((vital) => {
      if (this.vitalsForm.value[vital.label] !== '') {
        this.atLeastOneError = '';
      }
      this.vitalsForm.controls['uom_' + vital.label].setErrors(null);
    });

    if (this.atLeastOneError !== '') {
      this.submitInProgress = false;
      return;
    }

    this.submitInProgress = true;
    if (this.vitalsForm.invalid) {
      this.submitInProgress = false;
      return;
    }
    this.vitalService
      .updatePatientVital(this.patientId, this.vitalId, this.vitalsForm.value)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate(['/patients/' + this.patientId + '/vitals']);
          }
          this.saveAndContinueClicked = false;
        }
      );
  }
}
