import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordRoutingModule } from './password-routing.module';
import { PasswordComponent } from './password.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ResetComponent } from './reset/reset.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { SetComponent } from './set/set.component';
@NgModule({
  declarations: [ForgotComponent, ResetComponent, PasswordComponent, SetComponent],
  imports: [
    CommonModule,
    PasswordRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ]
})
export class PasswordModule {}
