import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalCarePlanService } from '../../../services/personal-care-plan.service';
import { NotifyService } from '../../../services/notify.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarePlanTypeList } from '../../../shared/model/carePlanTypeList';
import { PageService } from '../../workflow-forms/page.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-personal-care-plans-edit',
  templateUrl: './personal-care-plans-edit.component.html',
  styleUrls: ['./personal-care-plans-edit.component.css']
})
export class PersonalCarePlansEditComponent implements OnInit {
  patientId: number;
  personalCarePlanId: number;
  patientPersonalCarePlan: any;

  carePlanForm = new FormGroup({
    nurse_name: new FormControl('', [Validators.required]),
    nurse_phone: new FormControl('', [Validators.required]),
    plan_type: new FormControl(''),
    dependency: new FormControl(''),
    care_plan: new FormControl('', [Validators.required])
  });

  formErrorObj: Array<any> = [
    {
      controlName: 'nurse_name',
      error: false
    },
    {
      controlName: 'nurse_phone',
      error: false
    },
    {
      controlName: 'carePlan',
      error: false
    }
  ];

  planTypes: Array<object>;
  dependency: Array<object>;
  carePlan: Array<CarePlanTypeList> = [];
  totalTime = 0;
  selectedPlans: Array<any> = [];

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private personalCarePlanService: PersonalCarePlanService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil
  ) {}

  ngOnInit() {
    this.page.title.next('Edit Personal Care Plan');
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.personalCarePlanId = +this.activatedRoute.snapshot.paramMap.get('carePlanId');
    this.getPersonalCarePlanList();
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getPatientPersonalCarePlan(patientId, carePlanId) {
    this.submitInProgress = true;
    this.personalCarePlanService.getPatientPersonalCarePlan(patientId, carePlanId).subscribe(
      (response: any) => {
        this.patientPersonalCarePlan = response;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
        this.carePlanForm.patchValue({
          nurse_name: this.patientPersonalCarePlan.nurse_name,
          nurse_phone: this.patientPersonalCarePlan.nurse_phone,
          plan_type: this.patientPersonalCarePlan.plan_type_list_id,
          dependency: this.patientPersonalCarePlan.dependency_list_id
        });
        this.setCarePlanDefaults(this.patientPersonalCarePlan.carePlan);
      }
    );
  }

  setCarePlanDefaults(patientCarePlans) {
    this.carePlan.forEach((plan: any) => {
      patientCarePlans.forEach((carePlan: any) => {
        if (plan.id === carePlan.id) {
          plan.frequency = carePlan.frequency;
          plan.description = carePlan.description;
          plan = this.getPlanTasksDefaults(plan, carePlan);
          plan.disabled = true;
          this.selectedPlans.push(plan);
        }
      });
    });
  }

  getPlanTasksDefaults(defaultPlan, patientPlan) {
    let planTime = 0;
    patientPlan.sub_tasks.forEach((selectedSubTask: any) => {
      defaultPlan.sub_tasks.forEach((defaultSubTask: any) => {
        if (!defaultSubTask.selected === true) {
          defaultSubTask.selected = false;
          if (selectedSubTask.id === defaultSubTask.id) {
            defaultSubTask.selected = true;
            planTime += +selectedSubTask.time;
          }
        }
      });
    });
    defaultPlan.selectedLength = planTime;
    this.totalTime += planTime;
    return defaultPlan;
  }

  getPersonalCarePlanList() {
    this.submitInProgress = true;
    this.personalCarePlanService.getCarePlanList().subscribe(
      (res: any) => {
        this.carePlan = res.carePlans;
        this.carePlan.forEach((element) => {
          element.selectedLength = 0;
          element.sub_tasks.forEach((innerElement) => {
            innerElement.selected = false;
          });
        });
        this.dependency = res.dependency_list;
        this.planTypes = res.plan_type_list;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
        this.getPatientPersonalCarePlan(this.patientId, this.personalCarePlanId);
      }
    );
  }

  selectPlan(id: number) {
    const result = this.carePlan.find((y) => {
      return y.id === id;
    });
    return result;
  }

  pushPlan(id: any): void {
    if (!isNaN(this.transform.toNumber(id))) {
      const planObj = this.selectPlan(id);
      planObj.disabled = true;
      this.selectedPlans.unshift(planObj);
    }
  }

  unselectPlan(id: number): void {
    const result = this.selectPlan(id);
    result.disabled = false;
    result.sub_tasks.forEach((element) => {
      element.selected = false;
    });
    this.totalTime -= result.selectedLength;
    result.selectedLength = 0;
    this.selectedPlans = this.selectedPlans.filter((obj) => obj !== result);
  }

  pushFrequency(frequency: any, planId: number): void {
    const plan = this.selectPlan(planId);
    plan.frequency = frequency;
  }

  pushDescription(description: any, planId: number): void {
    const plan = this.selectPlan(planId);
    plan.description = description;
  }

  addSelectedLength(planId: number, task, time: number): void {
    const thisPlan = this.selectPlan(planId);
    const thisTask = thisPlan.sub_tasks.find((x) => {
      return x === task;
    });
    this.toogleTaskSelect(thisTask);
    if (thisTask.selected === true) {
      thisPlan.selectedLength += time;
      this.totalTime += time;
    } else {
      thisPlan.selectedLength -= time;
      this.totalTime -= time;
    }
  }

  store() {
    this.submitInProgress = true;
    const data = this.carePlanForm.value;
    data.carePlan = this.selectedPlans;
    this.personalCarePlanService
      .updatePatientPersonalCarePlan(this.patientId, this.personalCarePlanId, data)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          switch (error.status) {
            case 422:
              const errors = error.error.errors;
              if (errors.nurse_name) {
                this.setError('nurse_name', errors.nurse_name[0]);
              } else {
                this.unsetError('nurse_name');
              }
              if (errors.nurse_phone) {
                this.setError('nurse_phone', errors.nurse_phone[0]);
              } else {
                this.unsetError('nurse_phone');
              }
              if (errors.carePlan) {
                this.setError('carePlan', errors.carePlan[0]);
              } else {
                this.unsetError('carePlan');
              }
              this.toastr.error(error.error.message);
              break;
            default:
              this.toastr.error(error.error.message);
          }
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate(['/patients/' + this.patientId + '/personal-care-plans']);
          }
          this.saveAndContinueClicked = false;
        }
      );
  }

  toogleTaskSelect(task): void {
    task.selected = task.selected === false;
  }
}
