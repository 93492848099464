import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-instant-pay-first-step',
  templateUrl: './instant-pay-first-step.component.html',
  styleUrls: ['./instant-pay-first-step.component.css']
})
export class InstantPayFirstStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() nextEmmitter = new EventEmitter();
  @Output() cancelEmmitter = new EventEmitter();
  instantPayFirstForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.instantPayFirstForm.patchValue({
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      mobile: this.data.mobile,
      address: this.data.address,
      email: this.data.email
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.instantPayFirstForm.valid) {
      const formData = {
        currentStep: 1,
        nextStep: 2,
        data: this.instantPayFirstForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  cancel() {
    this.instantPayFirstForm.patchValue({
      firstName: null,
      lastName: null,
      mobile: null,
      address: null,
      email: null
    });
    this.cancelEmmitter.emit(true);
  }
}
