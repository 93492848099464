import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../services/user-service.service';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../shared/model/profile';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/shared/model/country-code';
import { CountryCodeService } from 'src/app/services/country-code.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  profileList: Array<Profile> = [];
  showSuggestionForm: boolean;
  specialitySelected: boolean;
  specialityList = [];
  arr = [];
  specialities = [];
  search: string;
  filteredSpecialityList: Observable<string[]>;
  backendExistMobile = [];
  NAME_REGEX = '^[a-zA-Z. ]+$';
  REGD_NUM_REGEX = '^[a-zA-Z0-9-]*$';
  JOB_TITLE_REGEX = '^[a-zA-Z0-9. ]+$';

  MOBILE_PATTERN = '^\\d{10}$';
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;

  BIOGRAPHY_MAX_LENGTH = 65535;
  SPECIALITY_MAX_LENGTH = 255;

  MIN_CONSULTATION_FEE = 0;

  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'pattern',
      message: 'Enter a valid Full Name'
    },
    {
      name: 'name',
      error_type: 'required',
      message: 'Full Name is required'
    },
    {
      name: 'name',
      error_type: 'maxLength',
      message: 'Name can be no more than 255 characters'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'maxLength',
      message: 'Email can be no more than 255 characters'
    },
    {
      name: 'profile',
      error_type: 'required',
      message: 'Profile is required'
    },
    {
      name: 'regdNumber',
      error_type: 'minLength',
      message: 'License Number should be of minimum 4 characters'
    },
    {
      name: 'regdNumber',
      error_type: 'pattern',
      message: 'Enter a valid License Number'
    },
    {
      name: 'ein',
      error_type: 'minLength',
      message: 'EIN should be of minimum 4 characters'
    },
    {
      name: 'ein',
      error_type: 'pattern',
      message: 'Enter a valid EIN'
    },
    {
      name: 'jobTitle',
      error_type: 'required',
      message: 'Job Title is required'
    },
    {
      name: 'jobTitle',
      error_type: 'pattern',
      message: 'Enter a valid Job Title'
    },
    {
      name: 'jobTitle',
      error_type: 'maxLength',
      message: 'Job Title can be no more than 255 characters'
    },
    {
      name: 'joinedDate',
      error_type: 'required',
      message: 'Joined Date is required'
    },
    {
      name: 'status',
      error_type: 'required',
      message: 'Status is required'
    },
    {
      name: 'country_code',
      error_type: 'required',
      message: 'Country Code is required'
    },
    {
      name: 'country_code',
      error_type: 'match_not_found',
      message: 'please select an option'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'speciality',
      error_type: 'maxLength',
      message: 'Speciality can be no more than ' + this.SPECIALITY_MAX_LENGTH + ' characters'
    },
    {
      name: 'biography',
      error_type: 'maxLength',
      message: 'Speciality can be no more than ' + this.BIOGRAPHY_MAX_LENGTH + ' characters'
    },
    {
      name: 'consultation_fee',
      error_type: 'min',
      message: 'Consultation Fee can be minimum of Rs ' + this.MIN_CONSULTATION_FEE
    },
    {
      name: 'gender',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'dob',
      error_type: 'required',
      message: 'Date of Birth is required'
    },
    {
      name: 'dob',
      error_type: 'max',
      message: 'Date of Birth cannot exceed today'
    },
    {
      name: 'address',
      error_type: 'required',
      message: 'Address is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'profile',
      error: false
    },
    {
      controlName: 'regd_number',
      error: false
    },
    {
      controlName: 'job_title',
      error: false
    },
    {
      controlName: 'joined_date',
      error: false
    },
    {
      controlName: 'status',
      error: false
    },
    {
      controlName: 'ein',
      error: false
    },
    {
      controlName: 'cv',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'speciality',
      error: false
    },
    {
      controlName: 'biography',
      error: false
    },
    {
      controlName: 'profile_image',
      error: false
    },
    {
      controlName: 'consultation_fee',
      error: false
    },
    {
      controlName: 'gender',
      error: false
    },
    {
      controlName: 'dob',
      error: false
    },
    {
      controlName: 'address',
      error: false
    },
    {
      controlName: 'education',
      error: false
    },
    {
      controlName: 'experience',
      error: false
    }
  ];

  // For Form Footer
  cancelUrl = '/users';
  submitInProgress = false;

  formSubmitted: Boolean = false;

  userForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(this.NAME_REGEX),
      Validators.maxLength(255)
    ]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    country_code: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    profile: new FormControl('', [Validators.required]),
    regdNumber: new FormControl('', [
      Validators.minLength(4),
      Validators.pattern(this.REGD_NUM_REGEX)
    ]),
    ein: new FormControl('', [Validators.minLength(4), Validators.pattern(this.REGD_NUM_REGEX)]),
    consultation_fee: new FormControl('', [Validators.min(this.MIN_CONSULTATION_FEE)]),
    gender: new FormControl('male', [Validators.required]),
    jobTitle: new FormControl('', [
      Validators.required,
      Validators.pattern(this.JOB_TITLE_REGEX),
      Validators.maxLength(255)
    ]),
    education: new FormControl(''),
    experience: new FormControl(''),
    joinedDate: new FormControl(moment(new Date()).format('YYYY-MM-DD'), [Validators.required]),
    status: new FormControl('active', [Validators.required]),
    biography: new FormControl('', [Validators.maxLength(this.BIOGRAPHY_MAX_LENGTH)]),
    dob: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required])
  });
  file: File;
  profile_image = '';
  countryCodes: CountryCode[] = [];
  nepalCountryCode: CountryCode = new CountryCode();
  todayDate = new Date();

  constructor(
    private userService: UserServiceService,
    private profileService: ProfileService,
    private router: Router,
    private toastr: ToastrService,
    private countryCodeService: CountryCodeService
  ) {}

  ngOnInit() {
    // get profile list from profile service
    this.getCountryCodes();
    this.getSpecialityList();
    this.getProfileList();
  }

  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  getCountryCodes() {
    this.countryCodeService.getCountryCodes().subscribe(
      (response: CountryCode[]) => {
        this.countryCodes = response;
        response.map((x) => {
          if (x.name == 'Nepal') {
            this.nepalCountryCode = x;
          }
        });
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  getSpecialityList() {
    this.submitInProgress = true;
    this.userService.getSpecialityList().subscribe(
      (res) => {
        this.specialityList = res;
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getProfileList() {
    this.submitInProgress = true;
    this.profileService.getProfiles().subscribe(
      (res) => {
        this.profileList = res.data.filter((p) => p.profile_type !== 'patient_representative');
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  storeUser() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (this.userForm.status === 'VALID' && !this.alreadyExistMobileValue('mobile')) {
      const requestData = this.createRequestData(
          Object.assign({}, {
          name: this.userForm.controls.name.value,
          email: this.userForm.controls.email.value,
          profile: this.userForm.controls.profile.value,
          mobile: this.userForm.controls.mobile.value ? this.userForm.controls.mobile.value : '',
          country_code: this.userForm.controls.country_code.value || '',
          regd_number: this.userForm.controls.regdNumber.value
            ? this.userForm.controls.regdNumber.value
            : '',
          ein: this.userForm.controls.ein.value ? this.userForm.controls.ein.value : '',
          gender: this.userForm.controls.gender.value,
          consultation_fee: this.userForm.controls.consultation_fee.value
            ? this.userForm.controls.consultation_fee.value
            : '',
          job_title: this.userForm.controls.jobTitle.value,
          joined_date: moment(this.userForm.controls.joinedDate.value).format('YYYY-MM-DD'),
          dob: moment(this.userForm.controls.dob.value).format('YYYY-MM-DD'),
          address: this.userForm.controls.address.value,
          status: this.userForm.controls.status.value,
          biography: this.userForm.controls.biography.value,
          education: this.userForm.controls.education.value,
          experience: this.userForm.controls.experience.value

          //profile_image: this.profile_image
        }, (this.file ? {cv: this.file} : {}))
      );
      this.specialities.forEach((speciality, index) => {
        requestData.append('speciality_id[' + index + ']', speciality.id);
      });
      this.userService.store(requestData).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          if (error.status === 422 && error.error.errors.email) {
            this.toastr.error(error.error.errors.email[0]);
          } else {
            this.toastr.error(error.error.message);
          }
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/users']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.profile) {
      this.setBackendValidationError('profile', errors.profile[0]);
    } else {
      this.unsetBackendValidationError('profile');
    }
    if (errors.regd_number) {
      this.setBackendValidationError('regd_number', errors.regd_number[0]);
    } else {
      this.unsetBackendValidationError('regd_number');
    }
    if (errors.ein) {
      this.setBackendValidationError('ein', errors.ein[0]);
    } else {
      this.unsetBackendValidationError('ein');
    }
    if (errors.job_title) {
      this.setBackendValidationError('job_title', errors.job_title[0]);
    } else {
      this.unsetBackendValidationError('job_title');
    }
    if (errors.joined_date) {
      this.setBackendValidationError('joined_date', errors.joined_date[0]);
    } else {
      this.unsetBackendValidationError('joined_date');
    }
    if (errors.status) {
      this.setBackendValidationError('status', errors.status[0]);
    } else {
      this.unsetBackendValidationError('status');
    }
    if (errors.cv) {
      this.setBackendValidationError('cv', errors.cv[0]);
    } else {
      this.unsetBackendValidationError('cv');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
      this.addBackendExistMobile();
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.speciality) {
      this.setBackendValidationError('speciality', errors.speciality[0]);
    } else {
      this.unsetBackendValidationError('speciality');
    }
    if (errors.biography) {
      this.setBackendValidationError('biography', errors.biography[0]);
    } else {
      this.unsetBackendValidationError('biography');
    }
    if (errors.profile_image) {
      this.setBackendValidationError('profile_image', errors.profile_image[0]);
    } else {
      this.unsetBackendValidationError('profile_image');
    }

    if (errors.consultation_fee) {
      this.setBackendValidationError('consultation_fee', errors.consultation_fee[0]);
    } else {
      this.unsetBackendValidationError('consultation_fee');
    }
    if (errors.gender) {
      this.setBackendValidationError('gender', errors.gender[0]);
    } else {
      this.unsetBackendValidationError('gender');
    }
    if (errors.dob) {
      this.setBackendValidationError('dob', errors.dob[0]);
    } else {
      this.unsetBackendValidationError('dob');
    }
    if (errors.address) {
      this.setBackendValidationError('address', errors.address[0]);
    } else {
      this.unsetBackendValidationError('address');
    }
    if (errors.education) {
      this.setBackendValidationError('education', errors.education[0]);
    } else {
      this.unsetBackendValidationError('education');
    }
    if (errors.experience) {
      this.setBackendValidationError('experience', errors.experience[0]);
    } else {
      this.unsetBackendValidationError('experience');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  uploadFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.file = files[0];
    } else {
      this.file = null;
    }
  }

  uploadImage(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.profile_image = files[0];
    } else {
      this.profile_image = '';
    }
  }

  removeImage(event) {
    event.preventDefault();
    this.profile_image = '';
  }
  removeFile(event) {
    event.preventDefault();
    this.file = null;
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  getProfileName(profile) {
    let profileName = '';
    profileName += profile.name;
    profileName += profile.is_connectycube === 1 ? ' (Video Call Feature)' : '';
    return profileName;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }

  addBackendExistMobile() {
    if (!this.backendExistMobile.includes(this.userForm.controls.mobile.value)) {
      this.backendExistMobile.push(this.userForm.controls.mobile.value);
    }
  }
  alreadyExistMobileValue(keyName) {
    if (this.backendExistMobile.includes(this.userForm.controls.mobile.value)) {
      this.setBackendValidationError(keyName, 'Mobile Number is already taken');
      return true;
    } else {
      this.unsetBackendValidationError(keyName);
      return false;
    }
  }
  removeSpeciality(speciality) {
    this.specialities = this.specialities.filter((item) => {
      return item.id !== speciality.id;
    });
  }

  selectSpeciality(speciality) {
    const spec = this.specialities.find((x) => x.id === speciality.id);
    if (spec) {
      this.toastr.warning('Speciality already exist');
    } else {
      this.specialities.push(speciality);
    }
    this.search = '';
    this.showSuggestionForm = false;
  }

  showSuggestions(e) {
    if (this.search.length === 0) {
      this.specialitySelected = true;
      this.showSuggestionForm = false;
    } else {
      this.specialitySelected = false;
      this.showSuggestionForm = true;
    }

    this.arr = this.specialityList.filter((x) => {
      return x.name.includes(this.search);
    });
    this.specialities.forEach((speciality) => {
      this.arr = this.arr.filter((x) => {
        return x.id !== speciality.id;
      });
    });
  }
}
