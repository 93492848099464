import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNodeComponent } from './shared/tree-node/tree-node.component';
import { ProfileCreateComponent } from './profile-create/profile-create.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { MatCardModule, MatCheckboxModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkflowFormsModule } from '../patients/workflow-forms/workflow-forms.module';
import { RouterModule } from '@angular/router';
import { ProfilesRoutingModule } from './profiles-routing.module';

@NgModule({
  declarations: [
    TreeNodeComponent,
    ProfileListComponent,
    ProfileCreateComponent,
    ProfileEditComponent
  ],
  imports: [
    ProfilesRoutingModule,
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    WorkflowFormsModule,
    RouterModule,
    FormsModule
  ]
})
export class ProfilesModule {}
