import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vital-graph',
  templateUrl: './vital-graph.component.html',
  styleUrls: ['./vital-graph.component.css']
})
export class VitalGraphComponent implements OnInit {
  patientId: number;

  vitalTypes = [
    'height',
    'weight',
    'blood-pressure',
    'spo2-recording',
    'temperature',
    'pulse-rate',
    'sugar-level'
  ];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
  }
}
