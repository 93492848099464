import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageService } from './page.service';

window.addEventListener('resize', togglecontrolsidebar);
document.addEventListener('DOMContentLoaded', togglecontrolsidebar);
function togglecontrolsidebar() {
  if (document.body.contains(document.getElementById('wrapper-div'))) {
    if (window.innerWidth <= 768) {
      document.getElementById('wrapper-div').classList.remove('control-sidebar-open');
    } else {
      document.getElementById('wrapper-div').classList.add('control-sidebar-open');
    }
  }
}

@Component({
  selector: 'app-workflow-forms',
  templateUrl: './workflow-forms.component.html',
  styleUrls: ['./workflow-forms.component.css']
})
export class WorkflowFormsComponent implements OnInit {
  title: any = '';
  constructor(private page: PageService, private activatedRoute: ActivatedRoute) {
    this.page.title.subscribe((n) => {
      this.title = n;
      console.log('Page Title: ' + n);
    });
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((param) => {
      console.log('Workflow Form -- id => ' + param.get('id'));
    });
  }

  togglePatientMenu(e) {
    if (document.body.contains(document.getElementById('wrapper-div'))) {
      document.getElementById('wrapper-div').classList.toggle('control-sidebar-open');
      if (e.target.classList.contains('toggle-btn')) {
        e.target.classList.toggle('open-btn');
        e.target.classList.toggle('close-btn');
      }
      if (e.target.classList.contains('fa')) {
        const classButton = document.getElementsByClassName('toggle-btn');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < classButton.length; i++) {
          classButton[0].classList.toggle('close-btn');
        }
      }
    }
  }
}
