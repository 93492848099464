import { Component, OnInit } from '@angular/core';
import { InvestigationService } from '../../../services/investigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { DownloadCSVService } from '../../../services/download-csv.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-investigation-list',
  templateUrl: './investigation-list.component.html',
  styleUrls: ['./investigation-list.component.css']
})
export class InvestigationListComponent implements OnInit {
  investigationListCSV: Array<any>;
  diagnosticCenterId;
  headerList = ['Investigation Name', 'Test Name', 'Price'];
  investigationList: Array<any> = [
    {
      name: 'Kidney Function Test',
      testTypes: [
        {
          id: 2,
          name: 'Creatine'
        },
        {
          id: 3,
          name: 'Sodium'
        }
      ],
      created_at: '2019-11-22'
    },
    {
      name: 'Liver Function Test',
      testTypes: [
        {
          id: 1,
          name: 'Urea'
        },
        {
          id: 2,
          name: 'Creatine'
        }
      ],
      created_at: '2019-11-22'
    }
  ];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  searchQuery: string = null;
  status: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private investigationService: InvestigationService,
    private downloadCSVService: DownloadCSVService
  ) {}

  ngOnInit() {
    this.diagnosticCenterId = +this.activatedRoute.snapshot.paramMap.get('diagnosticCenterId');
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');
    const status: string = this.activatedRoute.snapshot.queryParamMap.get('status');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.status = status ? status : 'active';
    this.searchQuery = searchQuery ? searchQuery : null;

    this.getInvestigationList();
  }

  getInvestigationList() {
    this.loading = true;
    this.investigationService
      .getInvestigationList(
        this.page,
        this.limit,
        this.searchQuery,
        this.status,
        this.diagnosticCenterId
      )
      .subscribe(
        (response: any) => {
          this.investigationList = response.data;
          this.investigationList.forEach((investigation: any) => {
            investigation.expandIcon = 'angle-down';
            investigation.expandMessage = 'Expand Details';
          });
          this.total = response.size;
        },
        (error) => {
          this.investigationList.length = 0;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getInvestigationList();
    this.addPaginationParamsToUrl(this.searchQuery, this.page, this.limit, this.status);
  }

  onNext(): void {
    this.page++;
    this.getInvestigationList();
    this.addPaginationParamsToUrl(this.searchQuery, this.page, this.limit, this.status);
  }

  onPrev(): void {
    this.page--;
    this.getInvestigationList();
    this.addPaginationParamsToUrl(this.searchQuery, this.page, this.limit, this.status);
  }

  proceedToSearch(event) {
    if (this.searchQuery) {
      event.preventDefault();
      this.page = 1;
      this.searchInvestigation();
    }
  }

  searchInvestigation() {
    this.getInvestigationList();
    this.addPaginationParamsToUrl(this.searchQuery, this.page, this.limit, this.status);
  }

  clearSearch() {
    this.searchQuery = null;
    this.page = 1;
    this.searchInvestigation();
  }

  toggleDetail(investigation, event) {
    investigation.expanded = !investigation.expanded;
    if (investigation.expanded) {
      investigation.expandIcon = 'angle-up';
      investigation.expandMessage = 'Collapse Details';
    } else {
      investigation.expandIcon = 'angle-down';
      investigation.expandMessage = 'Expand Details';
    }
  }

  addPaginationParamsToUrl(q, pageNo, limit, status) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        q,
        pageNo,
        status,
        limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  parse(testArray) {
    let tests;
    if (testArray.length !== 0) {
      const testNames = [];
      testArray.forEach((testObj: any) => {
        if (testNames.length <= 5) {
          const data = testObj.price ? testObj.name + '(Rs ' + testObj.price + ')' : testObj.name;
          testNames.push(data);
        }
      });
      tests = testNames.slice(0, 5);
    } else {
      tests = 'No Tests';
    }
    return tests;
  }

  parsePrerequisites(testList) {
    const prerequisiteList:Array<string> = [];
      testList.map(test=>{
        prerequisiteList.push(test.prerequisite)
      })
    return prerequisiteList;
  }

  editInvestigation(investigationId, event) {
    event.stopPropagation();
    this.router.navigate([
      'diagnostic-center/' +
        this.diagnosticCenterId +
        '/investigations/' +
        investigationId +
        '/edit'
    ]);
  }

  updateInvestigationStatus(investigation, status) {
    this.investigationService.updateInvestigationStatus(investigation.id, { status }).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        this.removeInvestigation(investigation);
      }
    );
  }

  removeInvestigation(investigation) {
    this.investigationList = this.investigationList.filter((i: any) => {
      return i.id !== investigation.id;
    });
  }

  changeStatus(status) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { status },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });

    this.status = status;
    this.getInvestigationList();
  }

  downloadCSV() {
    this.investigationService.downloadCSV(this.searchQuery, this.status).subscribe(
      (response: any) => {
        this.investigationListCSV = response.data;
      },
      (error) => {},
      () => {
        this.downloadCSVService.downloadFile(
          this.investigationListCSV,
          'investigation-csv',
          this.headerList
        );
      }
    );
  }

  showDetail(investigation) {
    this.router.navigate(['investigations/' + investigation.id]);
  }

  goTo() {
    this.router.navigate([
      'diagnostic-center/' + this.diagnosticCenterId + '/investigations/create'
    ]);
  }
}
