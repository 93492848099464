import { Component, OnInit } from '@angular/core';
import { PersonalCarePlanService } from '../../../services/personal-care-plan.service';
import { CarePlanTypeList } from '../../../shared/model/carePlanTypeList';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../../services/urls.service';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { TransformUtil } from '../../shared/utility/transform.util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-personal-care-plan-create',
  templateUrl: './personal-care-plan-create.component.html',
  styleUrls: ['./personal-care-plan-create.component.css']
})
export class PersonalCarePlanCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  // For Save and Continue
  saveAndContinueClicked = false;

  carePlanForm = new FormGroup({
    nurse_name: new FormControl('', [Validators.required]),
    nurse_phone: new FormControl('', [Validators.required]),
    plan_type: new FormControl(''),
    dependency: new FormControl(''),
    care_plan: new FormControl('', [Validators.required])
  });

  planTypes: Array<object>;
  dependency: Array<object>;
  carePlan: Array<CarePlanTypeList>;
  totalTime = 0;
  selectedPlans: Array<object> = [];
  patientId: number;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  constructor(
    private personalCarePlanService: PersonalCarePlanService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService,
    private transform: TransformUtil
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Personal Care Plan', this.patientId);
    });
    // call api
    this.getCarePlanList();
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getCarePlanList() {
    this.submitInProgress = true;
    this.personalCarePlanService.getCarePlanList().subscribe(
      (res: any) => {
        this.carePlan = res.carePlans;
        this.carePlan.forEach((plan: any) => {
          // initiate total selected length of a plan with zero
          plan.selectedLength = 0;
          plan.sub_tasks.forEach((innerElement) => {
            // initiate task's selected attribute with false
            innerElement.selected = false;
          });
        });
        this.dependency = res.dependency_list;
        this.planTypes = res.plan_type_list;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  selectPlan(id: number) {
    const result = this.carePlan.find((y) => {
      return y.id === id;
    });
    return result;
  }

  pushPlan(id: any): void {
    if (!isNaN(this.transform.toNumber(id))) {
      const planObj = this.selectPlan(id);
      planObj.disabled = true;
      this.selectedPlans.unshift(planObj);
    }
  }

  unselectPlan(id: number): void {
    const result = this.selectPlan(id);
    result.disabled = false;
    result.sub_tasks.forEach((subTask) => {
      subTask.selected = false;
    });
    this.totalTime -= result.selectedLength;
    result.selectedLength = 0;
    this.selectedPlans = this.selectedPlans.filter((obj) => obj !== result);
  }

  pushFrequency(frequency: any, planId: number): void {
    const plan = this.selectPlan(planId);
    plan.frequency = frequency;
  }

  pushDescription(description: any, planId: number): void {
    const plan = this.selectPlan(planId);
    plan.description = description;
  }

  addSelectedLength(planId: number, task, time: number): void {
    const thisPlan = this.selectPlan(planId);
    const thisTask = thisPlan.sub_tasks.find((x) => {
      return x === task;
    });
    this.toogleTaskSelect(thisTask);
    if (thisTask.selected === true) {
      thisPlan.selectedLength += time;
      this.totalTime += time;
    } else {
      thisPlan.selectedLength -= time;
      this.totalTime -= time;
    }
  }

  toogleTaskSelect(task): void {
    if (task.selected === false) {
      task.selected = true;
    } else {
      task.selected = false;
    }
  }

  store() {
    this.submitInProgress = true;
    if (this.carePlanForm.status === 'VALID') {
      const data = this.carePlanForm.value;
      data.carePlan = this.selectedPlans;
      this.personalCarePlanService.storeCarePlan(this.patientId, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
      console.log('form validation failed');
    }
  }
}
