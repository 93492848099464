import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class DoctorFeedbackService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDoctorFeedbackList(pageNo, limit, q) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q });
    return this.http.get(`${this.apiUrl}video-app/doctors/review`, { params });
  }
}
