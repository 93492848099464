import { Component, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-booking-logs',
  templateUrl: './booking-logs.component.html',
  styleUrls: ['./booking-logs.component.css']
})
export class BookingLogsComponent {
  @Input() bookingDetail;

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
