import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

export type RoomType = 'PRIVATE' | 'PUBLIC';
export type RoomInviteType =
  | 'CALL_STARTED'
  | 'INCOMING_CALL'
  | 'CALL_REJECTED'
  | 'CALL_IGNORED'
  | 'CALL_ACCEPTED'
  | 'USER_BUSY';

interface ParticipantUser {
  id: number;
  name: string;
  profileImage?: string;
}
export interface RoomInvite {
  room: string;
  roomType: RoomType;
  callType: RoomInviteType;
  initiator: ParticipantUser;
  opponents: ParticipantUser[];
  additionalParams?: any;
}

export interface RoomDetails {
  token: string;
  room: RoomInvite;
}

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];
@Injectable({
  providedIn: 'root'
})
export class VideoChatService {
  $roomsUpdated: Observable<boolean>;
  private callRejectedStatus = new BehaviorSubject(false);
  private socketUrl = environment.socketUrl ? environment.socketUrl : '';
  private apiUrl = environment.apiUrl ? environment.apiUrl : '';
  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly http: HttpClient) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  private async createOrGetRoom(appointmentId: number) {
    const auth = await this.http
      .post<RoomDetails>(this.socketUrl + `/rooms/create`, {
        appointment_id: appointmentId
      })
      .toPromise();

    return auth;
  }

  getAllRooms() {
    return this.http.get<Rooms>('api/video/room').toPromise();
  }
  getpatientId(user_id) {
    return this.http.get(this.apiUrl + 'user-patient', { params: { user_id } });
  }
  async joinOrCreateRoom(appointmentId: number, tracks: LocalTrack[]) {
    let info: RoomDetails = null;
    let room: Room = null;
    try {
      info = await this.createOrGetRoom(appointmentId);
      room = await connect(info.token, {
        name: info.room.room,
        tracks,
        dominantSpeaker: true
      } as ConnectOptions);
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }

    return { info, room };
  }

  nudge() {
    this.roomBroadcast.next(true);
  }

  setCallRejectedStatus(status: boolean) {
    this.callRejectedStatus.next(status);
  }

  getCallRejectStatus(): BehaviorSubject<boolean> {
    return this.callRejectedStatus;
  }
}
