import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/shared/services/auth.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {
  hide = true;
  hide_another = true;
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;

  registrationHash: string;
  patientInfo: any;

  formSubmitted = false;
  submitInProgress = false;

  registrationSuccess = false;
  alreadyRegistered = false;
  showRegistrationForm = true;

  // For password strength and validation
  PASSWORD_MIN_LENGTH = 8;
  containsUppercaseCharacter = false;
  containsNumericCharacter = false;
  isLongEnough = false;
  bothPasswordMatches = true;

  // consumerAppDownloadUrl = environment.consumerAppDownloadUrl || 'https://play.google.com/store/apps/details?id=com.logicabeans.healthathome';

  // TODO: Adding static URL for now, need to add in environment file later
  consumerAppDownloadUrl =
    'https://play.google.com/store/apps/details?id=com.logicabeans.healthathome';

  patientUserRegistrationForm: FormGroup = new FormGroup({
    patient_id: new FormControl('', [Validators.required]),
    registration_code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    email: new FormControl(''),
    mobile: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.PASSWORD_MIN_LENGTH)
    ]),
    confirm_password: new FormControl('', [Validators.required]),
    registration_type: new FormControl('user_only'),
    registration_hash: new FormControl('', [Validators.required])
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private toastr: ToastrService
  ) {
    this.auth.showSidebarMenu = false;
    this.auth.showHeader = false;
  }

  ngOnInit() {
    this.registrationHash = this.activatedRoute.snapshot.queryParamMap.get('hash');
    this.fetchPatientInfo(this.registrationHash);
  }

  fetchPatientInfo(registrationHash: string) {
    if (registrationHash) {
      this.patientService.fetchPatientInfoFromRegistrationHash(registrationHash).subscribe(
        (response: any) => {
          this.patientInfo = response.data;
        },
        (error) => {
          this.submitInProgress = false;
          this.failureMessageDisplay = true;
          this.responseMessage = error.error.message;
          if (error.error.status && error.error.status === 'already_registered') {
            this.alreadyRegistered = true;
          }
          this.showRegistrationForm = false;
        },
        () => {
          this.fillPatientForm(this.patientInfo);
        }
      );
    }
  }

  fillPatientForm(patientInfo) {
    this.patientUserRegistrationForm.patchValue({
      patient_id: patientInfo.patient.id,
      registration_code: patientInfo.registration_code,
      name: this.getPatientName(
        patientInfo.patient.first_name,
        patientInfo.patient.middle_name,
        patientInfo.patient.last_name
      ),
      email: patientInfo.patient.email,
      mobile: patientInfo.patient.mobile,
      registration_hash: this.registrationHash
    });
  }

  submit() {
    this.formSubmitted = true;
    this.submitInProgress = true;
    console.log('Register has been clicked');
    console.log('isLongEnough-->', this.isLongEnough);
    console.log('containsUppercaseCharacter-->', this.containsUppercaseCharacter);
    console.log('containsNumericCharacter-->', this.containsNumericCharacter);
    console.log('patientUserRegistrationForm-->', this.patientUserRegistrationForm);
    if (
      this.isLongEnough &&
      this.containsUppercaseCharacter &&
      this.containsNumericCharacter &&
      this.patientUserRegistrationForm.status === 'VALID'
    ) {
      console.log('Register has been validated');
      this.patientService.registerPatientAsUser(this.patientUserRegistrationForm.value).subscribe(
        (response: any) => {
          console.log('Register has been succesfull-->', response);
          this.responseMessage = response.message;
          this.successMessageDisplay = true;
          this.submitInProgress = false;
        },
        (error) => {
          this.registrationSuccess = false;
          this.submitInProgress = false;
          console.log('Register has found error -->', error);
          const error_check = error.error.errors;
          if (error_check.email && !error_check.mobile) {
            this.toastr.error(error_check.email);
          } else if (error_check.mobile && !error_check.email) {
            this.toastr.error(error_check.mobile);
          } else if (error_check.mobile && error_check.email) {
            this.toastr.error(error_check.mobile + ' and ' + error_check.email);
          }
        },
        () => {
          console.log('Register has been ended');
          this.registrationSuccess = true;
          this.showRegistrationForm = false;
          this.submitInProgress = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }

  getPatientName(firstName: string, middleName: string, lastName: string): string {
    let name = firstName;
    name += middleName ? ' ' + middleName + ' ' + lastName : ' ' + lastName;

    return name;
  }

  checkPasswordStrength(event) {
    event.preventDefault();
    const enteredPassword = event.target.value;
    const digitContainingRegex = new RegExp('.*\\d+.*');
    const uppercaseContainingRegex = new RegExp('.*[A-Z].*');
    this.isLongEnough = enteredPassword.length >= this.PASSWORD_MIN_LENGTH;
    this.containsNumericCharacter = digitContainingRegex.test(enteredPassword);
    this.containsUppercaseCharacter = uppercaseContainingRegex.test(enteredPassword);
  }

  checkConfirmPassword(event) {
    event.preventDefault();
    const enteredConfirmPassword = event.target.value;
    const newPassword = this.patientUserRegistrationForm.controls.password.value;
    this.bothPasswordMatches = enteredConfirmPassword === newPassword;
  }
}
