import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictAlphabets]'
})
export class RestrictAlphabetsDirective {
  numberRegex = '^[0-9]+$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.validateFields(event);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    var numbers = new RegExp(this.numberRegex).test(event.key);
    if (!numbers) {
      event.preventDefault();
    }
  }
}
