import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAllowedCharacters]'
})
export class AllowedCharactersDirective {
  ALPHABETIC_REGEX = '^[a-zA-Z]+$';
  ALPHANUMERIC_REGEX = '^[a-zA-Z0-9]+$';

  @Input('appAllowedCharacters') allowedCharacters: Array<string> = [];
  @Input() restrictDigits = true;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return this.satisfiesRestriction(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.removeUnwantedCharacters(event);
  }

  satisfiesRestriction(character) {
    return this.satisfiesRegex(character) || this.includesInGivenCharacterList(character);
  }

  removeUnwantedCharacters(event) {
    setTimeout(() => {
      const val = this.el.nativeElement.value;
      const stringArray = val.split('');
      const resultArray = [];
      stringArray.forEach((character: string) => {
        if (this.satisfiesRestriction(character)) {
          resultArray.push(character);
        }
      });
      this.el.nativeElement.value = resultArray.join('');
      event.preventDefault();
    }, 100);
  }

  satisfiesRegex(character) {
    return this.restrictDigits
      ? new RegExp(this.ALPHABETIC_REGEX).test(character)
      : new RegExp(this.ALPHANUMERIC_REGEX).test(character);
  }

  includesInGivenCharacterList(character) {
    return this.allowedCharacters.includes(character);
  }
}
