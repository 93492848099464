import { Component, OnInit } from '@angular/core';
import { LabBookingService } from '../../../services/lab-booking.service';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.css']
})
export class TodoListComponent implements OnInit {
  todayBookings: Array<any>;
  thisWeekBookings: Array<any>;

  TODO_DISPLAY_LIMIT = 10;

  constructor(private labBookingService: LabBookingService) {}

  ngOnInit() {
    this.getTodayTodo();
    this.getThisWeekTodo();
  }

  getTodayTodo() {
    const status = 'pending';
    const interval = 'today';
    this.labBookingService
      .getBookingListOfInterval(status, 1, this.TODO_DISPLAY_LIMIT, interval)
      .subscribe(
        (response: any) => {
          this.todayBookings = response.data;
          this.todayBookings.forEach((booking: any) => {
            if (new Date(booking.date).getTime() < new Date().getTime()) {
              booking.todoStatus = 'late';
            } else {
              booking.todoStatus = 'ok';
            }
          });
        },
        (error) => {
          console.log('could not fetch booking list at the moment!');
        },
        () => {
          console.log(this.todayBookings);
        }
      );
  }

  getThisWeekTodo() {
    const status = 'pending';
    const interval = 'week';
    this.labBookingService
      .getBookingListOfInterval(status, 1, this.TODO_DISPLAY_LIMIT, interval)
      .subscribe((response: any) => {
        this.thisWeekBookings = response.data;
      });
  }
}
