import { Injectable, EventEmitter } from '@angular/core';
import { PermissionStore } from '../../state-store/permission.store';
import { Permission } from '../../model/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckService {
  permissionListEm: EventEmitter<boolean> = new EventEmitter();
  private permissionList: Permission[];
  permissionListLoaded: boolean;
  constructor(private permissionStore: PermissionStore) {
    this.getPermissionList();
  }

  getPermissionList() {
    this.permissionStore.permissions.subscribe((permissionList: Permission[]) => {
      this.permissionList = permissionList;
      this.permissionListLoaded = this.permissionList.length === 0 ? false : true;
      this.permissionListEm.emit(this.permissionListLoaded);
    });
  }

  hasPermission(permissionName: Permission) {
    return this.permissionList.includes(permissionName);
  }
}
