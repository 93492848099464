import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityListService } from 'src/app/services/speciality-list.service';
import { SpecialityFormComponent } from '../speciality-form/speciality-form.component';
import { UserDeletedResponse } from '../../../../users/shared/constants/user-deleted-response';
import { NotifyService } from '../../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-speciality-list',
  templateUrl: './speciality-list.component.html',
  styleUrls: ['./speciality-list.component.css']
})
export class SpecialityListComponent implements OnInit {
  @Input() clearUrl: boolean;
  specialityList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  specialitySearchQuery: string;

  constructor(
    private specialityService: SpecialityListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const specialitySearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.specialitySearchQuery = specialitySearchQuery ? specialitySearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getSpeciality();
  }

  openModal(speciality) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '300px';
    dialogConfig.data = speciality;

    const dialogRef = this.dialog.open(SpecialityFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getSpeciality();
      }
    });
  }

  getSpeciality(): void {
    const page = this.page;
    this.loading = true;
    this.specialityService
      .getSpecialityList(this.page, this.limit, this.specialitySearchQuery)
      .subscribe(
        (response: any) => {
          console.log(response.data);
          this.specialityList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.specialityList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchSpeciality();
  }

  searchSpeciality() {
    this.getSpeciality();
    this.addParamsToUrl(this.page, this.limit, this.specialitySearchQuery);
  }

  clearSearch() {
    this.specialityList = null;
    this.page = 1;
    this.limit = 10;
    this.specialitySearchQuery = null;
    this.searchSpeciality();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getSpeciality();
    this.addParamsToUrl(this.page, this.limit, this.specialitySearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getSpeciality();
    this.addParamsToUrl(this.page, this.limit, this.specialitySearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getSpeciality();
    this.addParamsToUrl(this.page, this.limit, this.specialitySearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeModal(event) {
    this.showModal = false;
  }

  deleteSpeciality(speciality) {
    if (confirm('Are you sure to delete this Speciality?')) {
      if (speciality.total_doctors > 0) {
        this.toastr.error('Speciality has one or more doctors assigned');
      } else {
        this.specialityService.deleteSpeciality(speciality.id).subscribe(
          (response: any) => {
            this.toastr.success(response.message);
          },
          (error) => {
            this.toastr.error(error.error.message);
          },
          () => {
            this.getSpeciality();
          }
        );
      }
    }
  }
}
