import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { DoctorListService } from '../../../../services/doctor-list.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.css']
})
export class DoctorFormComponent implements OnInit {
  doctor: any;
  submitInProgress = false;
  submitted = false;
  doctorForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    active: new FormControl(1)
  });
  constructor(
    private toastr: ToastrService,
    private doctorService: DoctorListService,
    private router: Router,
    private dialogRef: MatDialogRef<DoctorFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.doctor = data;
  }

  ngOnInit() {
    console.log(this.doctor);
    if (this.doctor) {
      this.patchData();
    }
  }

  patchData() {
    this.doctorForm.patchValue({
      name: this.doctor.name,
      active: this.doctor.active
    });
  }
  submit() {
    if (this.doctorForm.status === 'VALID') {
      this.submitInProgress = true;
      const id = this.doctor ? this.doctor.id : null;
      this.doctorService.save(this.doctorForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.submitInProgress = false;
        },
        () => {
          this.dialogRef.close(true);
          this.submitInProgress = false;
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
