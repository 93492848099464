import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisCenterListService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDiagnosisCenterList(pageNo, limit, q, filter) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q, filter });
    return this.http.get(`${this.apiUrl}lab-lists`, { params });
  }

  save(name, id): Observable<any> {
    if (id) {
      const api = this.apiUrl + 'lab-lists/' + id;
      return this.http.put(api, name, this.httpOptions);
    } else {
      return this.http.post(`${this.apiUrl}lab-lists`, name, this.httpOptions);
    }
  }
}
