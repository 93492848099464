import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-inquiry-sidebar',
  templateUrl: './inquiry-sidebar.component.html',
  styleUrls: ['./inquiry-sidebar.component.css']
})
export class InquirySidebarComponent implements OnInit {
  @Input() step;
  constructor() {}

  ngOnInit() {}

  getDesign(stepNo) {
    if (this.step === stepNo) {
      return 'active';
    } else if (stepNo < this.step) {
      return 'completed';
    } else {
      return '';
    }
  }
}
