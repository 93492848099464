import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription-second-step',
  templateUrl: './subscription-second-step.component.html',
  styleUrls: ['./subscription-second-step.component.css']
})
export class SubscriptionSecondStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() cancelEmmitter = new EventEmitter();
  @Output() nextEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();

  subscriptionSecondForm = new FormGroup({
    beneficiaryFirstName: new FormControl('', [Validators.required]),
    beneficiaryLastName: new FormControl('', [Validators.required]),
    beneficiaryContactNumber: new FormControl('', [Validators.required]),
    beneficiaryDob: new FormControl('', [Validators.required]),
    beneficiaryResidentialAddress: new FormControl('', [Validators.required]),
    beneficiaryContactEmail: new FormControl('', [Validators.required, Validators.email]),
    beneficiaryAlternateContactPerson: new FormControl(),
    beneficiaryAlternateContactNumber: new FormControl()
  });
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.subscriptionSecondForm.patchValue({
      beneficiaryFirstName: this.data.beneficiaryFirstName,
      beneficiaryLastName: this.data.beneficiaryLastName,
      beneficiaryContactNumber: this.data.beneficiaryContactNumber,
      beneficiaryDob: this.data.beneficiaryDob,
      beneficiaryResidentialAddress: this.data.beneficiaryResidentialAddress,
      beneficiaryContactEmail: this.data.beneficiaryContactEmail,
      beneficiaryAlternateContactPerson: this.data.beneficiaryAlternateContactPerson,
      beneficiaryAlternateContactNumber: this.data.beneficiaryAlternateContactNumber
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.subscriptionSecondForm.valid) {
      const formData = {
        currentStep: 2,
        nextStep: 3,
        data: this.subscriptionSecondForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 2,
      previousStep: 1,
      data: this.subscriptionSecondForm.value
    };
    this.previousEmmitter.emit(formData);
  }
  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
