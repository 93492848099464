import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MedicationListService } from 'src/app/services/medication-list.service';
import { NotifyService } from 'src/app/services/notify.service';
import { Medication } from 'src/app/shared/model/Medication';
import { MedicationFormComponent } from '../medication-form/medication-form.component';

@Component({
  selector: 'app-medication-detail',
  templateUrl: './medication-detail.component.html',
  styleUrls: ['./medication-detail.component.css']
})
export class MedicationDetailComponent implements OnInit {
  medicationId: number;
  medicationDetail: Medication;
  loading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private medicationService: MedicationListService,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.medicationId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getMedicationDetail(this.medicationId);
    console.log('Medicatio Detail -->', this.medicationDetail);
    console.log('Medicatio ID -->', this.medicationId);
  }

  getMedicationDetail(id: number) {
    this.medicationService.getMedicationDetail(id).subscribe(
      (response: Medication) => {
        this.medicationDetail = response;
      },
      (error) => {
        console.log('error fetching Medication details data');
      }
    );
  }

  openModal(medication) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '620px';
    dialogConfig.width = '800px';
    dialogConfig.data = medication;

    const dialogRef = this.dialog.open(MedicationFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        console.log('afterclosed data -->', data);
        this.getMedicationDetail(this.medicationId);
      }
    });
  }

  onGoBack() {
    const setting = 'medication';
    this.router.navigate(['emr-settings'], {
      queryParams: {
        setting
      }
    });
  }

  getStatus(prescription_status) {
    return prescription_status == 1 ? 'Active' : 'Inactive';
  }

  getActive(active) {
    return active == 1 ? 'Required' : 'Not Required';
  }

  getMedicationType(type) {
    if (type == 1) {
      return 'Capsule';
    }
    if (type == 2) {
      return 'Tablet';
    }
    if (type == 3) {
      return 'Liquid';
    }
  }
}
