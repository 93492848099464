import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-first-step',
  templateUrl: './subscription-first-step.component.html',
  styleUrls: ['./subscription-first-step.component.css']
})
export class SubscriptionFirstStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() nextEmmitter = new EventEmitter();
  @Output() cancelEmmitter = new EventEmitter();
  subscriptionFirstForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    alternateNumber: new FormControl(),
    postalAddress: new FormControl(),
    residentialAddress: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.subscriptionFirstForm.patchValue({
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      mobile: this.data.mobile,
      dob: this.data.dob,
      alternateNumber: this.data.alternateNumber,
      postalAddress: this.data.postalAddress,
      residentialAddress: this.data.residentialAddress,
      email: this.data.email
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.subscriptionFirstForm.valid) {
      const formData = {
        currentStep: 1,
        nextStep: 2,
        data: this.subscriptionFirstForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  cancel() {
    this.subscriptionFirstForm.patchValue({
      firstName: null,
      lastName: null,
      mobile: null,
      dob: null,
      alternateNumber: null,
      postalAddress: null,
      residentialAddress: null,
      email: null
    });
    this.cancelEmmitter.emit(true);
  }
  goToInstantPay() {
    this.router.navigate(['instant-pay']);
  }
}
