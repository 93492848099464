import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictNumbers]'
})
export class RestrictNumbersDirective {
  numberRegex = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    this.validateFields(event);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    var validated = new RegExp(this.numberRegex).test(event.key);
    if (validated) {
      event.preventDefault();
    }
  }
}
