import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurgicalHistoryService } from '../../../services/surgical-history.service';

@Component({
  selector: 'app-surgical-history-list',
  templateUrl: './surgical-history-list.component.html',
  styleUrls: ['./surgical-history-list.component.css']
})
export class SurgicalHistoryListComponent implements OnInit {
  patientId: number;
  patientSurgicalHistories: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private surgicalHistoryService: SurgicalHistoryService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientSurgicalHistories(this.limit);
  }

  getPatientSurgicalHistories(limit) {
    this.loading = true;
    this.surgicalHistoryService
      .getPatientSurgicalHistoryList(this.patientId, this.page, limit)
      .subscribe(
        (response: any) => {
          this.patientSurgicalHistories = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.patientSurgicalHistories.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientSurgicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientSurgicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientSurgicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
