import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-emr-settings-list',
  templateUrl: './emr-settings-list.component.html',
  styleUrls: ['./emr-settings-list.component.css']
})
export class EmrSettingsListComponent implements OnInit {
  setting = 'diagnosis';
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    const setting: any = this.activatedRoute.snapshot.queryParamMap.get('setting');
    this.setting = setting ? setting : this.setting;
  }

  selectSettings() {
    this.addParamsToUrl(this.setting);
  }

  addParamsToUrl(setting) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        setting
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
