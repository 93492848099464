import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { BannerService } from '../services/banner.service';
import { BannerFormComponent } from './banner-form/banner-form.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() clearUrl: boolean;
  banner;
  bannerList = [];
  searchQuery: string = null;
  queryStatus: string = null;
  showModal = true;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  status = 'all';
  clearDate = false;
  rawSearchQuery: string = null;

  advancedSearchForm = new FormGroup({
    query: new FormControl(''),
    fromDate: new FormControl(''),
    toDate: new FormControl('')
  });
  notify: any;
  tomorrow = new Date();
  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;

  constructor(
    private bannerService: BannerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  ngOnInit() {
    this.getUrlParams();
    this.getBannerList();
    this.hideToggleSearchFilters();
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  openModal(banner) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '620px';
    dialogConfig.width = '800px';
    dialogConfig.data = banner;

    const dialogRef = this.dialog.open(BannerFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getBannerList();
      }
    });
  }

  getUrlParams() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.advancedSearchForm.patchValue({
      query: this.activatedRoute.snapshot.queryParamMap.get('query'),
      fromDate: this.activatedRoute.snapshot.queryParamMap.get('fromDate'),
      toData: this.activatedRoute.snapshot.queryParamMap.get('toDate')
    });
    this.rawSearchQuery = this.getQueryFromInputParams();
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'query') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value;
        }
      }
    }
    return stringParams;
  }

  getBannerList(): void {
    this.loading = true;
    this.bannerService
      .getBannerList(this.page, this.limit, this.status, this.advancedSearchForm.value)
      .subscribe(
        (banner: any) => {
          this.total = banner.size;
          this.bannerList = banner.data;
        },
        (err) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }

  setUrlParams() {
    const queryParams = {
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      query: this.advancedSearchForm.get('query').value
        ? this.advancedSearchForm.get('query').value
        : null,
      fromDate: this.advancedSearchForm.get('fromDate').value
        ? this.advancedSearchForm.get('fromDate').value
        : null,
      toDate: this.advancedSearchForm.get('toDate').value
        ? this.advancedSearchForm.get('toDate').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  StopPropagation(e: Event) {
    e.stopPropagation();
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  goToPage(n: number): void {
    this.page = n;
    this.getBannerList();
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getBannerList();
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getBannerList();
    this.setUrlParams();
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['query', 'fromDate', 'toDate'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      query: queryValue.trim()
    });
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.setUrlParams();
      this.getBannerList();
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('query').reset();
    this.applyAdvancedSearch();
  }

  resetAdvancedSearchForm() {
    this.advancedSearchForm.patchValue({
      fromDate: null,
      toDate: null
    });
    this.page = 1;
    this.setUrlParams();
    this.getBannerList();
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  closeModal(event) {
    this.showModal = false;
  }
}
