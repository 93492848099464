import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DiagnosisCenterListService } from '../../../services/diagnosis-center-list.service';
import { DiagnosisCenterFormComponent } from '../diagnosis-center-form/diagnosis-center-form.component';

@Component({
  selector: 'app-diagnosis-center-list',
  templateUrl: './diagnosis-center-list.component.html',
  styleUrls: ['./diagnosis-center-list.component.css']
})
export class DiagnosisCenterListComponent implements OnInit {
  @Input() clearUrl: boolean;
  diagnosisCenterList = [];
  showModal = false;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  filter = 'all';
  diagnosisCenterSearchQuery: string;

  constructor(
    private diagnosisCenterService: DiagnosisCenterListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const diagnosisCenterSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.diagnosisCenterSearchQuery = diagnosisCenterSearchQuery
        ? diagnosisCenterSearchQuery
        : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getDiagnosisCenter();
  }

  openModal(diagnosisCenter) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '300px';
    dialogConfig.width = '300px';
    dialogConfig.data = diagnosisCenter;

    const dialogRef = this.dialog.open(DiagnosisCenterFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getDiagnosisCenter();
      }
    });
  }

  getDiagnosisCenter(): void {
    const page = this.page;
    this.loading = true;
    this.diagnosisCenterService
      .getDiagnosisCenterList(this.page, this.limit, this.diagnosisCenterSearchQuery, this.filter)
      .subscribe(
        (response: any) => {
          console.log(response.data);
          this.diagnosisCenterList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.diagnosisCenterList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchDiagnosisCenter();
  }

  searchDiagnosisCenter() {
    this.getDiagnosisCenter();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisCenterSearchQuery);
  }

  clearSearch() {
    this.diagnosisCenterList = null;
    this.page = 1;
    this.limit = 10;
    this.diagnosisCenterSearchQuery = null;
    this.searchDiagnosisCenter();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDiagnosisCenter();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisCenterSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getDiagnosisCenter();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisCenterSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getDiagnosisCenter();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisCenterSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
  changeStatus(filter) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { filter } ,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });

    this.filter = filter;
    this.getDiagnosisCenter();
  }
  closeModal(event) {
    this.showModal = false;
  }
}
