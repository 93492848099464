import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-samples-collected',
  templateUrl: './samples-collected.component.html',
  styleUrls: ['./samples-collected.component.css']
})
export class SamplesCollectedComponent {
  @Input() samples_list;

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
