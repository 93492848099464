import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LabBookingService } from '../../../../services/lab-booking.service';
import { NotifyService } from '../../../../services/notify.service';

@Component({
  selector: 'app-delivery-create',
  templateUrl: './delivery-create.component.html',
  styleUrls: ['./delivery-create.component.css']
})
export class DeliveryCreateComponent implements OnInit {
  labBookingId: number;
  labBooking: any;
  formSubmitted = false;

  deliveryForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
    approvedBy: new FormControl('', [Validators.required])
  });

  type = 'offline';
  date: any;
  time: any;
  approvedBy: any;

  // For Form Footer
  cancelUrl = '/lab-bookings?status=completed';
  submitInProgress = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.labBookingId = +this.activatedRoute.snapshot.paramMap.get('bookingId');
    this.getBookingDetail(this.labBookingId);
  }

  getBookingDetail(bookingId) {
    this.submitInProgress = true;
    this.labBookingService.getBookingDetail(bookingId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.labBooking = response.booking;
      },
      (error) => {
        this.submitInProgress = false;
        console.log('error fetching lab booking detail.');
      }
    );
  }

  changeDate(event) {
    return event.target.value ? moment(event.target.value).format('YYYY-MM-DD') : '';
  }

  deliverBooking() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    let request: any = {};
    if (this.deliveryForm.status === 'VALID') {
      const date = this.type === 'offline' ? this.date : moment(new Date()).format('YYYY-MM-DD');
      const time = this.type === 'offline' ? this.time : moment(new Date()).format('h:mm:ss a');
      if (this.type === 'offline') {
        if (date && time) {
          request = {
            delivery_method: this.type,
            date: (date + ' ' + time).trim()
          };
        } else {
          this.submitInProgress = false;
          return;
        }
      } else {
        if (this.labBooking.email) {
          request = {
            delivery_method: this.type,
            date: (date + ' ' + time).trim()
          };
        } else {
          this.submitInProgress = false;
          return;
        }
      }
      request.approved_by = this.approvedBy;

      // Submit the request
      this.labBookingService.deliverBooking(this.labBookingId, request).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/lab-bookings'], { queryParams: { status: 'delivered' } });
        }
      );
    } else {
      this.submitInProgress = false;
      console.log('form validation failed!');
    }
  }
}
