import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {
  private apiUrl = environment.apiUrl; // URL to web api\
  private currentUrl: string;
  private previousUrl: string;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getCurrentUrl() {
    return this.currentUrl;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getMenu() {
    return this.http.get(this.apiUrl + 'patient-menu');
  }

  getPatientListMenu() {
    return this.http.get(this.apiUrl + 'patient-main-menu');
  }

  getNextUrl(currentUrl, patientId): Observable<any> {
    return new Observable((observer) => {
      this.getMenu().subscribe((y: any) => {
        const urlList: string[] = [];
        y.forEach((headerMenu: any) => {
          headerMenu.children.forEach((children) => {
            urlList.push(children.url.replace(/{patientId}/g, String(patientId)));
          });
        });
        if (currentUrl === urlList[urlList.length - 1]) {
          // this.nextUrl = '/patient-list';
          observer.next('/patient-list');
        } else {
          // this.nextUrl = urlList[urlList.indexOf(currentUrl) + 1];
          observer.next(urlList[urlList.indexOf(currentUrl) + 1]);
        }
        observer.complete();
      });
    });
  }
}
