import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from 'src/app/services/lab-booking.service';

@Component({
  selector: 'app-lab-reports',
  templateUrl: './lab-reports.component.html',
  styleUrls: ['./lab-reports.component.css']
})
export class LabReportsComponent implements OnInit {
  bookingId: number;
  labReport: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService
  ) {}

  ngOnInit() {
    this.bookingId = +this.activatedRoute.snapshot.paramMap.get('labReportId');
    this.getLabReportDetail(this.bookingId);
  }

  getLabReportDetail(bookingId) {
    this.labBookingService.getLabReport(bookingId).subscribe((response: any) => {
      this.labReport = response.data;
    });
  }

  parse(object) {
    return object && JSON.parse(object) ? JSON.parse(object).join(', ') : false;
  }
}
