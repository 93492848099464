import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { CallCenterService } from 'src/app/services/call-center.service';
import { PrintService } from 'src/app/shared/components/print-component/print.service';
import { CallCenter } from 'src/app/shared/model/call-center';

@Component({
  selector: 'app-call-center-detail',
  templateUrl: './call-center-detail.component.html',
  styleUrls: ['./call-center-detail.component.css']
})
export class CallCenterDetailComponent implements OnInit {
  callCenterId: number;
  callCenterDetail: CallCenter;
  constructor(
    private activatedRoute: ActivatedRoute,
    private callCenterService: CallCenterService,
    private printService: PrintService
  ) {}

  ngOnInit() {
    this.callCenterId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getCallCenter(this.callCenterId);
  }

  getCallCenter(id: number) {
    this.callCenterService.getCallCenterDetail(id).subscribe(
      (response: CallCenter) => {
        console.log(response, 'network success');
        this.callCenterDetail = response;
        console.log(this.callCenterDetail, 'network success2');
      },
      (error) => {}
    );
  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    this.printService.pushData = printContents;
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
