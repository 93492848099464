import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Permission } from '../model/permission';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionStore {
  private apiUrl = environment.apiUrl;
  private subject = new BehaviorSubject<Permission[]>([]);

  permissions: Observable<Permission[]> = this.subject.asObservable();

  constructor(private http: HttpClient) {
    this.loadAllPermissions();
  }

  private loadAllPermissions() {
    const permissionLoader$ = this.http
      .get<Permission[]>(this.apiUrl + 'profiles/permissions')
      .pipe(
        map((response) => response),
        catchError((err) => {
          console.log('Could not load permissions');
          return throwError(err);
        }),
        tap((permission) => this.subject.next(permission))
      );

    permissionLoader$.subscribe();
  }
}
