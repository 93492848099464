import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-lab-report',
  templateUrl: './lab-report.component.html',
  styleUrls: ['./lab-report.component.css']
})
export class LabReportComponent {
  @Input() bookingDetail;

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }
}
