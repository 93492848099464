import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientService } from '../../../services/patient.service';
import { PageService } from '../../workflow-forms/page.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderMenuComponent implements OnInit {
  @Input() formChanges;
  patientId: number;
  patient: any;

  headerMenu: Array<any> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientService: PatientService,
    private page: PageService
  ) {}

  ngOnInit() {
    this.page.patientId.subscribe((patientId) => {
      this.patientId = Number(patientId);
      this.getPatient();
    });
    this.getMenu();
  }

  changeUrl(e) {
    if (this.formChanges === true) {
      if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
        this.router.navigateByUrl(e.target.value);
        console.log('confirmed');
      } else {
        console.log('Not confirmed');
      }
    } else {
      this.router.navigateByUrl(e.target.value);
    }
  }

  getPatient() {
    this.patientService.getPatient(this.patientId).subscribe(
      (response: any) => {
        this.patient = response;
      },
      (error) => {
        this.patient = null;
      }
    );
  }

  getMenu() {
    this.patientService.getPatientMenu().subscribe(
      (res: any) => {
        if (res.length !== 0) {
          this.headerMenu = res.filter((menu) => menu.show === 1);
        }
      },
      (error) => {
        this.headerMenu.length = 0;
      },
      () => {
        this.headerMenu.forEach((menu: any) => {
          if (menu.children) {
            menu.children = menu.children.filter((child) => child.show === 1);
          }
        });
      }
    );
  }

  getMenuUrl(url: string) {
    return url.replace(/{patientId}/g, String(this.patientId));
  }
}
