import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  constructor(private notification: MatSnackBar) {}

  show(message: string) {
    if (message == 'error' || 'err') {
    }
    this.notification.open(message, 'Ok', {
      // duration: 5000
      duration: 3000
    });
  }
}
