import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionsService } from '../services/regions.service';
import { BusinessUnitService } from '../services/business-unit.service';
import { MatSnackBar } from '@angular/material';
import { UserServiceService } from '../services/user-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.css']
})
export class BusinessUnitComponent implements OnInit {
  regions: any;
  userType: any;

  // If user is a region admin
  userRegion: any;
  userRegionId: number;
  userRegionName: string;
  userRegionCountry: string;

  modules: Array<object> = [
    {
      id: 1,
      name: 'Doctor Suites'
    },
    {
      id: 2,
      name: 'Nurse Suites'
    },
    {
      id: 3,
      name: 'Lab Suites'
    }
  ];

  selectedModules: Array<object> = [];

  businessUnitForm = new FormGroup({
    unitName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    operatingCountry: new FormControl('', [Validators.required]),
    contactPerson: new FormControl('', [Validators.required]),
    phone: new FormControl(),
    mobile: new FormControl('', [Validators.required]),
    fax: new FormControl(''),
    address: new FormControl('', [Validators.required])
  });

  // For Form Footer
  cancelUrl = '/business-units';
  submitInProgress = false;

  constructor(
    private regionService: RegionsService,
    private businessUnitService: BusinessUnitService,
    private notification: MatSnackBar,
    private userService: UserServiceService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.userService.getType().subscribe((res) => {
      this.userType = res.data.user_type;
      if (this.userType === 'country_admin') {
        this.regionService.getRegionList().subscribe((res1) => {
          this.regions = res1.regions;
        });
      } else if (this.userType === 'region_admin') {
        this.userService.getRegion().subscribe((res2) => {
          this.userRegion = res2;
          this.userRegionId = res2.data.user_region_id;
          this.userRegionName = res2.data.user_region_name;
          this.userRegionCountry = res2.data.user_country_name;
        });
      } else {
        console.log('neither region admin nor country admin');
      }
    });
  }

  selectModule(module) {
    module.status = !module.status;
    if (module.status === false) {
      this.selectedModules = this.selectedModules.filter((y) => {
        return y !== module.id;
      });
    } else {
      this.selectedModules.push(module.id);
    }
  }

  submitForm() {
    this.submitInProgress = true;
    this.businessUnitForm.markAllAsTouched();
    const formData = this.businessUnitForm.value;
    formData.modules = this.selectedModules;
    if (this.businessUnitForm.status === 'VALID') {
      this.businessUnitService.store(formData).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.toastr.success(response.message);
          }
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/business-units']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  showNotification(message: string) {
    this.notification.open(message, 'Ok', {
      duration: 5000
    });
  }

  makePristine(targetValue, controlField) {
    const control = this.businessUnitForm.controls[controlField];
    if (targetValue === '' || targetValue === null) {
      control.reset();
      control.markAsPristine();
    }
  }
}
