import { Component, OnInit } from '@angular/core';
import { Patient } from '../../shared/model/patient';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PatientService } from '../../services/patient.service';
import { VitalService } from '../../services/vitals.service';
import { MedicationService } from '../../services/medication.service';
import { LabReportService } from '../../services/lab-report.service';
import { DiagnosisService } from '../../services/diagnosis.service';
import { EncounterService } from '../../services/encounter.service';
import * as moment from 'moment';
import { NotifyService } from '../../services/notify.service';
import { KycInformationService } from '../../services/kyc-information.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit {
  showPatientHistorySection = false;
  viewHistoryText = 'View History';

  historyDateFrom: any = '';
  historyDateTo: any = '';

  patientHistory: any;
  showPatientHistory = false;
  loadingPatientHistory = false;

  patientKycInformation: any;

  patient: Patient;
  patientId: any;

  patientSearchKeyword: string;
  displayPatientSuggestion = false;
  suggestedPatients: Array<any> = [];

  VITAL_DISPLAY_MIN_LIMIT = 5;
  VITAL_DISPLAY_MAX_LIMIT = 10;
  displayLimit = this.VITAL_DISPLAY_MIN_LIMIT;
  VITAL_EXPAND_TOGGLE_TEXT: any = 'click anywhere to expand';
  totalVitalCount: number;

  patientVitalsFields: any;
  patientVitalsData: any;

  showDiagnoses = false;
  diagnosesLoading = true;
  patientDiagnoses: Array<any> = [];
  DIAGNOSIS_DISPLAY_MIN_LIMIT = 5;
  DIAGNOSIS_DISPLAY_MAX_LIMIT = 10;
  totalDiagnosisCount: number;
  diagnosisDisplayLimit = this.DIAGNOSIS_DISPLAY_MIN_LIMIT;
  DIAGNOSIS_EXPAND_TOGGLE_TEXT: any = 'click anywhere to expand';

  showEncounters = false;
  encountersLoading = true;
  patientEncounters: Array<any> = [];
  ENCOUNTER_DISPLAY_MIN_LIMIT = 5;
  ENCOUNTER_DISPLAY_MAX_LIMIT = 10;
  mintotalEncounterCount = 1;
  totalEncounterCount: number;
  encounterDisplayLimit = this.ENCOUNTER_DISPLAY_MIN_LIMIT;
  ENCOUNTER_EXPAND_TOGGLE_TEXT: any = 'click anywhere to expand';

  medicationsLoading = true;
  patientMedications: Array<any> = [];
  MED_DISPLAY_MIN_LIMIT = 5;
  MED_DISPLAY_MAX_LIMIT = 10;
  totalMedicationCount: number;
  medicationDisplayLimit = this.MED_DISPLAY_MIN_LIMIT;
  MED_EXPAND_TOGGLE_TEXT: any = 'click anywhere to expand';

  medicationList: Array<any> = [];
  totalMedicationListCount = 0;

  allergiesLoading = true;
  patientAllergyInformations: any;
  allAllergiesAvailable = true;
  foodAllergies: Array<any> = [];
  drugAllergies: Array<any> = [];
  environmentAllergies: Array<any> = [];

  showLabReports = false;
  labReportsLoading = true;
  patientLabReports: Array<any> = [];
  LR_DISPLAY_MIN_LIMIT = 5;
  LR_DISPLAY_MAX_LIMIT = 10;
  totalLabReportCount: number;
  labReportDisplayLimit = this.LR_DISPLAY_MIN_LIMIT;
  LR_EXPAND_TOGGLE_TEXT: any = 'click anywhere to expand';

  familyHistoryLoading = true;
  patientFamilyHistory: Array<any> = [];

  diagnosis: any;
  medications: any;
  carePlans: any;
  birthday = '';
  age: number;
  imageLoading: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private location: Location,
    private vitalService: VitalService,
    private medicationService: MedicationService,
    private diagnosisService: DiagnosisService,
    private encounterService: EncounterService,
    private labReportService: LabReportService,
    private kycInformationService: KycInformationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.patientId = params.id;
    });
    this.getPatient(this.patientId);
  }

  togglePatientHistoryShow() {
    this.showPatientHistorySection = !this.showPatientHistorySection;
    this.showPatientHistory = false;
    if (this.showPatientHistorySection) {
      this.viewHistoryText = 'Hide History';
    } else {
      this.viewHistoryText = 'View History';
    }
  }

  getPatientHistory() {
    this.loadingPatientHistory = true;
    this.showPatientHistory = false;
    if (this.historyDateFrom || this.historyDateTo) {
      const fromDate = this.historyDateFrom
        ? this.formatDate(this.historyDateFrom, 'YYYY-MM-DD')
        : '';
      const toDate = this.historyDateTo ? this.formatDate(this.historyDateTo, 'YYYY-MM-DD') : '';
      this.patientService.getPatientHistory(this.patientId, fromDate, toDate).subscribe(
        (response: any) => {
          this.patientHistory = response;
        },
        (error) => {
          this.showPatientHistory = false;
          this.patientHistory = null;
          this.loadingPatientHistory = false;
        },
        () => {
          this.showPatientHistory = true;
          this.loadingPatientHistory = false;
        }
      );
    } else {
      this.loadingPatientHistory = false;
    }
  }

  getFieldName(data) {
    return Object.keys(data)[0];
  }

  getObjectEntries(data) {
    return Object.entries(data);
  }

  getFieldValue(data) {
    const entries = Object.entries(data);
    return entries[0][1];
  }

  formatDate(dateVal, format) {
    return moment(new Date(dateVal)).format(format);
  }

  searchPatients() {
    this.displayPatientSuggestion = false;
    const pageNo = 1;
    const limit = 10;
    const filter = 'mobile';
    if (this.patientSearchKeyword.length > 2) {
      this.patientService
        .searchPatients(this.patientSearchKeyword, pageNo, limit, filter)
        .subscribe(
          (response: any) => {
            if (response.size >= 1) {
              this.displayPatientSuggestion = true;
              this.suggestedPatients = response.data;
            }
          },
          (error) => {
            this.suggestedPatients.length = 0;
            this.displayPatientSuggestion = false;
          }
        );
    } else {
      this.displayPatientSuggestion = false;
    }
  }

  selectPatient(patient) {
    this.patientId = patient.id;
    this.getPatient(this.patientId);
    this.displayPatientSuggestion = false;
    this.patientSearchKeyword = '';
    this.router.navigate(['/patients/' + this.patientId]);
  }

  getPatient(id: number): void {
    this.patientService.getPatient(id).subscribe(
      (patient) => {
        this.patient = patient;
        this.birthday = patient.dob;
        this.CalculateAge();
      },
      (error) => {
        this.initiateHideToggle();
        this.diagnosesLoading = false;
        this.encountersLoading = false;
        this.medicationsLoading = false;
        this.allergiesLoading = false;
        this.labReportsLoading = false;
        if (error.status == '403') {
          this.toastr.error(error.statusText);
          this.router.navigate(['/patients']);
        }
      },
      () => {
        this.initiateHideToggle();
        this.getPatientVitals(this.VITAL_DISPLAY_MAX_LIMIT);
        this.getPatientDiagnoses(this.DIAGNOSIS_DISPLAY_MAX_LIMIT);
        this.getPatientEncounters(this.ENCOUNTER_DISPLAY_MAX_LIMIT);
        this.getPatientMedications(this.MED_DISPLAY_MAX_LIMIT);
        this.getPatientAllergyInformations();
        this.getPatientLabReports(this.LR_DISPLAY_MAX_LIMIT);
        this.getPatientFamilyHistory();
        this.getPatientKycInformation();
      }
    );
  }

  public CalculateAge(): void {
    if (this.birthday) {
      const newDate = new Date(this.birthday);
      const timeDiff = Math.abs(Date.now() - newDate.getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }
  }

  // Get and process Patient Vitals
  getPatientVitals(limit: number) {
    this.patientService.getVitals(this.patientId, limit).subscribe(
      (response: any) => {
        this.patientVitalsFields = response.vital_fields;
        this.patientVitalsData = response.vital_data;
        this.totalVitalCount = response.size;
      },
      (error) => {},
      () => {
        if (this.patientVitalsData.length <= this.VITAL_DISPLAY_MIN_LIMIT) {
          this.displayLimit = this.patientVitalsData.length;
          this.VITAL_EXPAND_TOGGLE_TEXT = false;
        }
      }
    );
  }

  getLatestVitalByType(type) {
    let vitalData;
    let typeField;
    if (this.patientVitalsFields && this.patientVitalsData !== null) {
      typeField = this.patientVitalsFields.find((field: any) => {
        return field.name === type;
      });
      if (typeField) {
        for (const patientVitalData of this.patientVitalsData) {
          vitalData = patientVitalData.data.find((data: any) => {
            return data.vital_list_id === typeField.id;
          });
          if (vitalData && vitalData.value !== '') {
            break;
          }
        }
        return vitalData;
      }
    }
    return false;
  }

  getFormattedLatestVital(type, value_name) {
    let vital;
    let formattedVital;
    let updatedDate;
    switch (type) {
      case 'height':
        type = 'Height';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + ' ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      case 'weight':
        type = 'Weight';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + ' ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      case 'blood-pressure':
        type = 'Blood Pressure';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + '/' + vital.secondary_value + ' ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      case 'sugar-level':
        type = 'Sugar Level';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + ' (' + vital.secondary_value + ') ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      case 'temperature':
        type = 'Temperature';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + ' ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      case 'respiratory-rate':
        type = 'Respiratory';
        vital = this.getLatestVitalByType(type);
        if (vital) {
          formattedVital = vital.value + ' ' + vital.uom;
          updatedDate = vital.updated_at;
        } else {
          formattedVital = 'N/A';
          updatedDate = false;
        }
        break;
      default:
        formattedVital = 'N/A';
        updatedDate = false;
    }
    return value_name === 'value' ? formattedVital : updatedDate;
  }

  getLastVitalsDate() {
    return this.patientVitalsData && this.patientVitalsData[0]
      ? this.patientVitalsData[0].date
      : false;
  }

  // Get and process Patient Diagnoses
  getPatientDiagnoses(limit: number) {
    this.diagnosesLoading = true;
    this.diagnosisService.getPatientDiagnosisList(this.patientId, 1, limit).subscribe(
      (response: any) => {
        this.patientDiagnoses = response.data;
        this.totalDiagnosisCount = response.size;
      },
      (error) => {
        this.diagnosesLoading = false;
        this.patientDiagnoses.length = 0;
      },
      () => {
        this.diagnosesLoading = false;
        if (this.patientDiagnoses.length <= this.DIAGNOSIS_DISPLAY_MIN_LIMIT) {
          this.diagnosisDisplayLimit = this.patientDiagnoses.length;
          this.DIAGNOSIS_EXPAND_TOGGLE_TEXT = false;
        }
      }
    );
  }

  getDiagnosisNames(diagnoses: Array<any>) {
    const diagnosisNames = [];
    diagnoses.forEach((diagnosis: any) => {
      diagnosisNames.push(diagnosis.name);
    });
    return diagnosisNames.join(', ');
  }

  toggleDiagnosisExpand() {
    this.showDiagnoses = !this.showDiagnoses;
    if (this.showDiagnoses) {
      this.DIAGNOSIS_EXPAND_TOGGLE_TEXT = this.DIAGNOSIS_EXPAND_TOGGLE_TEXT
        ? 'click anywhere to collapse'
        : false;
      if (this.DIAGNOSIS_DISPLAY_MAX_LIMIT >= this.patientDiagnoses.length) {
        this.diagnosisDisplayLimit = this.patientDiagnoses.length;
      } else {
        this.diagnosisDisplayLimit = this.DIAGNOSIS_DISPLAY_MAX_LIMIT;
      }
    } else {
      this.DIAGNOSIS_EXPAND_TOGGLE_TEXT = this.DIAGNOSIS_EXPAND_TOGGLE_TEXT
        ? 'click anywhere to expand'
        : false;
      if (this.DIAGNOSIS_DISPLAY_MIN_LIMIT <= this.patientDiagnoses.length) {
        this.diagnosisDisplayLimit = this.DIAGNOSIS_DISPLAY_MIN_LIMIT;
      } else {
        this.diagnosisDisplayLimit = this.patientDiagnoses.length;
      }
    }
  }

  // Get and process Patient Encounter Informations
  getPatientEncounters(limit) {
    this.encountersLoading = true;
    this.encounterService.getPatientEncounters(this.patientId, 1, limit).subscribe(
      (response: any) => {
        this.patientEncounters = response.data;
        this.totalEncounterCount = response.size;
      },
      (error) => {
        this.encountersLoading = false;
        this.patientEncounters.length = 0;
      },
      () => {
        this.encountersLoading = false;
        if (this.patientEncounters.length <= this.ENCOUNTER_DISPLAY_MIN_LIMIT) {
          this.encounterDisplayLimit = this.patientEncounters.length;
          this.ENCOUNTER_EXPAND_TOGGLE_TEXT = false;
        }
      }
    );
  }

  toggleEncounterExpand() {
    this.showEncounters = !this.showEncounters;
    if (this.showEncounters) {
      this.ENCOUNTER_EXPAND_TOGGLE_TEXT = this.ENCOUNTER_EXPAND_TOGGLE_TEXT
        ? 'click anywhere to collapse'
        : false;
      if (this.ENCOUNTER_DISPLAY_MAX_LIMIT >= this.patientEncounters.length) {
        this.encounterDisplayLimit = this.patientEncounters.length;
      } else {
        this.encounterDisplayLimit = this.ENCOUNTER_DISPLAY_MAX_LIMIT;
      }
    } else {
      this.ENCOUNTER_EXPAND_TOGGLE_TEXT = this.ENCOUNTER_EXPAND_TOGGLE_TEXT
        ? 'click anywhere to expand'
        : false;
      if (this.ENCOUNTER_DISPLAY_MIN_LIMIT <= this.patientEncounters.length) {
        this.encounterDisplayLimit = this.ENCOUNTER_DISPLAY_MIN_LIMIT;
      } else {
        this.encounterDisplayLimit = this.patientEncounters.length;
      }
    }
  }

  // Get and process Patient Medications
  getPatientMedications(limit) {
    this.medicationsLoading = true;
    this.medicationService.getPatientMedicationList(this.patientId, 1, limit).subscribe(
      (response: any) => {
        this.patientMedications = response.data;
        this.totalMedicationCount = response.size;
      },
      (error) => {
        this.medicationsLoading = false;
        this.patientMedications.length = 0;
      },
      () => {
        this.medicationsLoading = false;
        this.getMedicationList();
        if (this.patientMedications.length <= this.MED_DISPLAY_MIN_LIMIT) {
          this.medicationDisplayLimit = this.patientMedications.length;
          this.MED_EXPAND_TOGGLE_TEXT = false;
        }
      }
    );
  }

  getMedicationList() {
    let medicationCounter = 0;
    if (this.patientMedications.length !== 0) {
      for (const patientMedication of this.patientMedications) {
        for (const medication of patientMedication.medications) {
          const medicationObj = {
            date: patientMedication.date,
            name: medication.name,
            way_of_intake: medication.way_of_intake,
            type: medication.type,
            uom: medication.uom
          };
          this.totalMedicationListCount++;
          if (medicationCounter >= 3) {
            continue;
          }
          this.medicationList.push(medicationObj);
          medicationCounter++;
        }
      }
    } else {
      this.totalMedicationListCount = 0;
      this.medicationList.length = 0;
    }
  }

  // Get and process Patient Allergies
  getPatientAllergyInformations() {
    this.allergiesLoading = true;
    this.patientService.getLatestAllergyInformation(this.patientId).subscribe(
      (response: any) => {
        this.patientAllergyInformations = response.data;
      },
      (error) => {
        this.allAllergiesAvailable = false;
        this.allergiesLoading = false;
        this.patientAllergyInformations.length = 0;
      },
      () => {
        this.allergiesLoading = false;
        if (
          this.patientAllergyInformations.food.length === 0 &&
          this.patientAllergyInformations.drug.length === 0 &&
          this.patientAllergyInformations.environment.length === 0
        ) {
          this.allAllergiesAvailable = false;
        }
        this.foodAllergies = this.patientAllergyInformations.food;
        this.drugAllergies = this.patientAllergyInformations.drug;
        this.environmentAllergies = this.patientAllergyInformations.environment;
      }
    );
  }

  // Get and process Patient Lab Reports
  getPatientLabReports(limit) {
    this.labReportsLoading = true;
    this.labReportService.getPatientLabReports(this.patientId, 1, limit).subscribe(
      (response: any) => {
        this.patientLabReports = response.data;
        this.totalLabReportCount = response.size;
      },
      (error) => {
        this.labReportsLoading = false;
        this.patientLabReports.length = 0;
      },
      () => {
        this.labReportsLoading = false;
        if (this.patientLabReports.length <= this.LR_DISPLAY_MIN_LIMIT) {
          this.labReportDisplayLimit = this.patientLabReports.length;
          this.LR_EXPAND_TOGGLE_TEXT = false;
        }
      }
    );
  }

  toggleLabReportExpand() {
    this.showLabReports = !this.showLabReports;
    if (this.showLabReports) {
      this.LR_EXPAND_TOGGLE_TEXT = this.LR_EXPAND_TOGGLE_TEXT
        ? 'click anywhere to collapse'
        : false;
      if (this.LR_DISPLAY_MAX_LIMIT >= this.patientLabReports.length) {
        this.labReportDisplayLimit = this.patientLabReports.length;
      } else {
        this.labReportDisplayLimit = this.LR_DISPLAY_MAX_LIMIT;
      }
    } else {
      this.LR_EXPAND_TOGGLE_TEXT = this.LR_EXPAND_TOGGLE_TEXT ? 'click anywhere to expand' : false;
      if (this.LR_DISPLAY_MIN_LIMIT <= this.patientLabReports.length) {
        this.labReportDisplayLimit = this.LR_DISPLAY_MIN_LIMIT;
      } else {
        this.labReportDisplayLimit = this.patientLabReports.length;
      }
    }
  }

  // Get and process Patient Family Reports
  getPatientFamilyHistory() {
    this.familyHistoryLoading = true;
    this.patientService.getFamilyHistory(this.patientId).subscribe(
      (response: any) => {
        this.patientFamilyHistory = response;
      },
      (error) => {
        this.patientFamilyHistory.length = 0;
        this.familyHistoryLoading = false;
      },
      () => {
        this.familyHistoryLoading = false;
      }
    );
  }

  getFamilyIcon(relationship) {
    let icon = 'user';
    switch (relationship) {
      case 'father':
        icon = 'male';
        break;
      case 'mother':
        icon = 'female';
        break;
      default:
        icon = 'user';
    }
    return icon;
  }

  capitalizeFirstLetter(textContent) {
    return textContent.charAt(0).toUpperCase() + textContent.slice(1);
  }

  hideToggleHistoryDetail(event) {
    const thisElement = event.target;
    const bodyElement = thisElement.parentNode.parentNode.nextElementSibling;
    if (bodyElement.style.display !== 'none') {
      bodyElement.style.display = 'none';
    } else {
      bodyElement.style.display = '';
    }
    thisElement.classList.toggle('fa-chevron-up');
    thisElement.classList.toggle('fa-chevron-down');
  }

  getPatientKycInformation() {
    this.patientKycInformation = null;
    this.kycInformationService
      .getPatientKycInformation(this.patientId)
      .subscribe((response: any) => {
        if (response) {
          this.patientKycInformation = response.data;
        }
      });
  }

  initiateHideToggle() {
    this.hideToggle('vital-expand-icon', ['fa-chevron-up', 'fa-chevron-down'], 'vitals-body', [
      'vitals-collapsed'
    ]);
    this.hideToggle(
      'diagnosis-expand-icon',
      ['fa-chevron-up', 'fa-chevron-down'],
      'diagnoses-body',
      ['collapsed']
    );
    this.hideToggle(
      'encounter-expand-icon',
      ['fa-chevron-up', 'fa-chevron-down'],
      'encounters-body',
      ['collapsed']
    );
    this.hideToggle(
      'medication-expand-icon',
      ['fa-chevron-up', 'fa-chevron-down'],
      'medications-body',
      ['collapsed']
    );
    this.hideToggle('allergy-expand-icon', ['fa-chevron-up', 'fa-chevron-down'], 'allergies-body', [
      'collapsed'
    ]);
    this.hideToggle(
      'lab-report-expand-icon',
      ['fa-chevron-up', 'fa-chevron-down'],
      'lab-reports-body',
      ['collapsed']
    );
    this.hideToggle(
      'family-history-expand-icon',
      ['fa-chevron-up', 'fa-chevron-down'],
      'family-histories-body',
      ['collapsed']
    );
    this.hideToggle('view-more', ['fa-angle-up', 'fa-angle-down'], 'addition-details', [
      'collapsed-default'
    ]);
  }

  hideToggle(buttonId, buttonClass: Array<string>, bodyId, toggleClass: Array<string>) {
    if (document.body.contains(document.getElementById(buttonId))) {
      const buttonDiv = document.getElementById(buttonId);

      if (buttonId === 'view-more') {
        buttonDiv.addEventListener('click', () => {
          const bodyDiv = document.getElementById(bodyId);
          bodyDiv.classList.toggle('collapsed-default');
          if (bodyDiv.classList.contains('collapsed-default')) {
            buttonDiv.innerHTML = 'View More <i class="fa fa-angle-down" aria-hidden="true"></i>';
          } else {
            buttonDiv.innerHTML = 'View Less <i class="fa fa-angle-up" aria-hidden="true"></i>';
          }
        });
      } else {
        buttonDiv.addEventListener('click', () => {
          const bodyDiv = document.getElementById(bodyId);
          buttonClass.forEach((button) => {
            buttonDiv.classList.toggle(button);
          });
          toggleClass.forEach((hidden) => {
            bodyDiv.classList.toggle(hidden);
          });
        });
      }
    }
  }

  isImageLoading() {
    this.imageLoading = false;
  }
}
