import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class MyWorkSheetService {
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl;

  getMyWorkSheetList(filterBody, user_id = null, pageNo = null, limit = null): Observable<any> {
    const data: any = {
      nurse_name: filterBody.nurseName ? filterBody.nurseName : '',
      q: filterBody.query ? filterBody.query : '',
      date_to: filterBody.toDate ? filterBody.toDate : '',
      date_from: filterBody.fromDate ? filterBody.fromDate : ''
    };
    // const params = filterBody;
    data.pageNo = pageNo;
    data.limit = limit;
    data.user_id = user_id;
    const params = FormDataUtil.filterEmptyParams(data);
    return this.http.get(`${this.apiUrl}worksheets`, { params });
  }
}
