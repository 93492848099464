import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotifyService } from '../../../../services/notify.service';
import { Router } from '@angular/router';
import { MedicationListService } from '../../../../services/medication-list.service';
import { Medication } from 'src/app/shared/model/Medication';
import { FormDataUtil } from 'src/app/shared/utils/formData/form-data-util';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-medication-form',
  templateUrl: './medication-form.component.html',
  styleUrls: ['./medication-form.component.css']
})
export class MedicationFormComponent implements OnInit {
  medication: Medication;
  submitInProgress = false;
  submitted = false;
  medicationTypeList: [];
  medicationForm = new FormGroup({
    image: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    type_id: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    uom: new FormControl('', [Validators.required]),
    price: new FormControl('', [Validators.required]),
    prescription_status: new FormControl('', [Validators.required]),
    active: new FormControl('', [Validators.required])
  });

  selectedFile?: File;

  constructor(
    private toastr: ToastrService,
    private medicationService: MedicationListService,
    private router: Router,
    private dialogRef: MatDialogRef<MedicationFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.medication = data;
  }

  ngOnInit() {
    console.log(this.medication);
    this.getMedicationType();
    this.patchData();
  }

  getMedicationType() {
    this.medicationService.getMedicationTypeList().subscribe(
      (res) => {
        this.medicationTypeList = res.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  patchData() {
    this.medicationForm.patchValue({
      image: this.medication.medication_image_link,
      name: this.medication.name,
      type_id: this.medication.medication_type_list_id,
      description: this.medication.description,
      price: this.medication.price,
      uom: this.medication.uom,
      prescription_status: this.medication.prescription_status,
      active: this.medication.active
    });
  }

  storeData() {
    this.submitted = true;
    if (this.medicationForm.status === 'VALID') {
      const id = this.medication ? this.medication.id : null;
      const requestData = FormDataUtil.convertModelToFormData({
        name: this.medicationForm.controls.name.value,
        type_id: this.medicationForm.controls.type_id.value,
        description: this.medicationForm.controls.description.value,
        price: this.medicationForm.controls.price.value,
        uom: this.medicationForm.controls.uom.value,
        prescription_status: this.medicationForm.controls.prescription_status.value,
        active: this.medicationForm.controls.active.value,
        image: this.selectedFile
      });

      this.medicationService.save(requestData, id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          if (error.error.errors.name) {
            this.toastr.error(error.error.errors.name);
          } else {
            this.toastr.error(error.error.message);
          }
          this.submitInProgress = false;
        },
        () => {
          this.submitInProgress = false;
          this.submitted = false;
          this.dialogRef.close(true);
        }
      );
    }
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  close() {
    this.dialogRef.close();
  }

  uploadImage(event) {
    const files = event.target.files;
    console.log('IMage_file -->', files);
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      this.selectedFile = event.target.files[0];
      reader.onload = () => {
        this.medicationForm.patchValue({
          image: reader.result as string
        });
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }
}
