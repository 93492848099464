import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosisService } from '../../../services/diagnosis.service';

@Component({
  selector: 'app-diagnosis-list',
  templateUrl: './diagnosis-list.component.html',
  styleUrls: ['./diagnosis-list.component.css']
})
export class DiagnosisListComponent implements OnInit {
  patientId: number;
  patientDiagnoses: Array<any>;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private diagnosisService: DiagnosisService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientDiagnosis(this.limit);
  }

  getPatientDiagnosis(limit) {
    this.loading = true;
    this.diagnosisService.getPatientDiagnosisList(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.patientDiagnoses = response.data;
        this.patientDiagnoses.forEach((referral: any) => {
          referral.expandIcon = 'chevron-down';
          referral.expandMessage = 'Expand Details';
          referral.expanded = false;
        });
        this.total = response.size;
      },
      (error) => {
        this.patientDiagnoses.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientDiagnosis(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientDiagnosis(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientDiagnosis(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  toggleDetail(referral, event) {
    event.preventDefault();
    referral.expanded = !referral.expanded;
    if (referral.expanded) {
      referral.expandIcon = 'chevron-up';
      referral.expandMessage = 'Collapse Details';
    } else {
      referral.expandIcon = 'chevron-down';
      referral.expandMessage = 'Expand Details';
    }
  }
}
