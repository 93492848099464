import { Component, Inject, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TermsAndConditionsService } from '../../../../services/terms-and-conditions.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-terms-conditions-form',
  templateUrl: './terms-conditions-form.component.html',
  styleUrls: ['./terms-conditions-form.component.css']
})
export class TermsConditionsFormComponent implements OnInit {
  termsAndConditions: any;
  submitInProgress = false;
  submitted = false;
  termsConditionForm = new FormGroup({
    content: new FormControl('', [Validators.required])
  });
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '500px',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote'
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1'
      }
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']]
  };

  constructor(
    private toastr: ToastrService,
    private termsAndConditionsService: TermsAndConditionsService,
    private router: Router,
    private dialogRef: MatDialogRef<TermsConditionsFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.termsAndConditions = data;
  }

  ngOnInit() {
    if (this.termsAndConditions) {
      this.patchData();
    }
  }

  submit() {
    if (this.termsConditionForm.status === 'VALID') {
      this.submitInProgress = true;
      this.termsAndConditionsService.save(this.termsConditionForm.value).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
          this.submitInProgress = false;
        },
        () => {
          this.dialogRef.close(true);
          this.submitInProgress = false;
        }
      );
    }
  }
  patchData() {
    this.termsConditionForm.patchValue({
      content: this.termsAndConditions.content || ''
    });
  }
  close() {
    this.dialogRef.close();
  }
}
