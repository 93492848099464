import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { PrescriptionRefillService } from 'src/app/services/prescription-refill.service';
import { Medication } from 'src/app/shared/model/Medication';
import { BookedMedication, Prescription } from 'src/app/shared/model/prescription';

@Component({
  selector: 'app-prescription-refill-detail',
  templateUrl: './prescription-refill-detail.component.html',
  styleUrls: ['./prescription-refill-detail.component.css']
})
export class PrescriptionRefillDetailComponent implements OnInit {
  prescriptionId: number;
  prescriptionDetail: Prescription;
  bookedMedication: BookedMedication[];
  total: number;
  loading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private prescriptionRefillService: PrescriptionRefillService
  ) {}

  ngOnInit() {
    this.prescriptionId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getPrescriptionDetail(this.prescriptionId);
  }

  getPrescriptionDetail(id: number) {
    this.prescriptionRefillService.getPrescriptionRefillDetail(id).subscribe(
      (response: Prescription) => {
        this.prescriptionDetail = response;
        this.bookedMedication = response.medication;
      },
      (error) => {
        console.log('Error fetching Prescription details data', error);
        this.loading = false;
      },
      () => {
        console.log(this.prescriptionDetail);
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  StopPropagation(e: Event) {
    e.stopPropagation();
  }

  get isMedicationEmpty() {
    return !this.bookedMedication || this.bookedMedication.length == 0;
  }

  get statusClass() {
    switch (this.prescriptionDetail.status) {
      case 'pending':
        return 'text-info';
      case 'in_progress':
        return 'text-primary';
      case 'delivered':
        return 'text-success';
      case 'cancelled':
        return 'text-danger';
      default:
        return '';
    }
  }

  get prescriptionStatus(): string {
    return this.prescriptionDetail ? this.prescriptionDetail.status.split('_').join(' ') : '';
  }
}
