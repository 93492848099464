import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../workflow-forms/page.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SystemReviewService } from '../../../services/system-review.service';
import * as moment from 'moment';
import { PatientService } from '../../../services/patient.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-system-review-create',
  templateUrl: './system-review-create.component.html',
  styleUrls: ['./system-review-create.component.css']
})
export class SystemReviewCreateComponent implements OnInit {
  patientId: number;
  patient: any;
  gender: string;
  urlList: Array<string> = [];
  nextUrl: string;
  single_menapause_age: string;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  formSubmitted = false;

  systemReviewForm = new FormGroup({
    date_of_last_eye_exam: new FormControl(''),
    date_of_last_bone_density_test: new FormControl(''),
    date_of_last_chest_xray: new FormControl(''),
    last_ppd_test_date: new FormControl(''),
    ppd_test_result: new FormControl('neverdone', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    age_when_periods_begin: new FormControl(''),
    number_of_pregnancies: new FormControl(''),
    is_menapause: new FormControl(''),
    menapause_age: new FormControl([]),
    date_of_last_pap_smear: new FormControl(''),
    mammogram_periods: new FormControl(''),
    date_of_last_mammogram_period: new FormControl(''),
    mammogram_periods_frequency: new FormControl('')
  });

  reviewTypeListRaw: Array<any>;
  reviewTypeListSanitized: Array<any>;
  reviewBackendValidationError: Array<any> = [];
  selectedReviewData: Array<any> = [];

  VALIDATION_MESSAGES = [
    {
      name: 'ppd_test_result',
      error_type: 'required',
      message: 'Last TB(PPD) Test Result  is required'
    },
    {
      name: 'last_ppd_test_date',
      error_type: 'required',
      message: 'Last TB(PPD) Test Date is required'
    },
    {
      name: 'selectedReviewData',
      error_type: 'required',
      message: 'At least one REVIEW TYPE should be selected'
    },
    {
      name: 'Last how long',
      error_type: 'required',
      message: 'Last how long is required'
    },
    {
      name: 'How much',
      error_type: 'required',
      message: 'How much is required'
    },
    {
      name: 'General',
      error_type: 'required',
      message: 'Select at least one data'
    },
    {
      name: 'Muscles/Joints/Bones',
      error_type: 'required',
      message: 'Select at least one data'
    },
    {
      name: 'Throat',
      error_type: 'required',
      message: 'Select at least one data'
    },
    {
      name: 'Skin',
      error_type: 'required',
      message: 'Select at least one data'
    },
    {
      name: 'sex',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'age_when_periods_begin',
      error_type: 'required',
      message: 'Age When Periods Begin is required'
    },
    {
      name: 'number_of_pregnancies',
      error_type: 'required',
      message: 'Number of Pregnancies is required'
    },
    {
      name: 'is_menapause',
      error_type: 'required',
      message: 'Miscarriage Menapause is required'
    },
    {
      name: 'menapause_age',
      error_type: 'required',
      message: 'Menapause Age is required'
    },
    {
      name: 'mammogram_periods',
      error_type: 'required',
      message: 'Mammogram Periods is required'
    },
    {
      name: 'date_of_last_mammogram_period',
      error_type: 'required',
      message: 'Mammogram Date is required'
    },
    {
      name: 'mammogram_periods_frequency',
      error_type: 'required',
      message: 'Days frequency is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'date_of_last_eye_exam',
      error: false
    },
    {
      controlName: 'date_of_last_bone_density_test',
      error: false
    },
    {
      controlName: 'date_of_last_chest_xray',
      error: false
    },
    {
      controlName: 'ppd_test_result',
      error: false
    },
    {
      controlName: 'sex',
      error: false
    },
    {
      controlName: 'age_when_periods_begin',
      error: false
    },
    {
      controlName: 'menapause_age',
      error: false
    },
    {
      controlName: 'date_of_last_mammogram_period',
      error: false
    },
    {
      controlName: 'is_menapause',
      error: false
    },
    {
      controlName: 'last_ppd_test_date',
      error: false
    },
    {
      controlName: 'mammogram_periods',
      error: false
    },
    {
      controlName: 'mammogram_periods_frequency',
      error: false
    },
    {
      controlName: 'number_of_pregnancies',
      error: false
    },
    {
      controlName: 'date_of_last_pap_smear',
      error: false
    }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private page: PageService,
    private patientService: PatientService,
    private urlService: UrlsService,
    private systemReviewService: SystemReviewService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getPatientInfo();
    this.getMenu();
    this.getReviewTypeList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add System Review', this.patientId);
    });
  }

  getPatientInfo() {
    this.submitInProgress = true;
    this.patientService.getPatient(this.patientId).subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.patient = response;
        this.systemReviewForm.patchValue({
          sex: this.patient.gender
        });
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getReviewTypeList() {
    this.submitInProgress = true;
    this.systemReviewService.getReviewTypeList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.reviewTypeListRaw = response.data;
      },
      (error) => {
        this.submitInProgress = false;
        this.reviewTypeListSanitized.length = 0;
      },
      () => {
        this.reviewTypeListSanitized = this.sanitizeReviewTypeList(this.reviewTypeListRaw);
      }
    );
  }

  sanitizeReviewTypeList(rawTypeList) {
    const reviewLists = [];
    rawTypeList.forEach((reviewType: any) => {
      const singleReviewType = {
        id: reviewType.id,
        name: reviewType.name,
        description: '',
        others: '',
        comments: '',
        sub_type_list: [],
        selected: false
      };
      reviewType.sub_type_list.forEach((subType: any) => {
        singleReviewType.sub_type_list.push({
          id: subType.id,
          name: subType.name,
          has_nested_field: subType.has_nested_fields,
          field_name: subType.field_name === null ? '' : subType.field_name,
          no_of_fields: subType.no_of_fields === undefined ? 0 : subType.no_of_fields,
          selected: false,
          nested_field_value: ''
        });
      });
      reviewLists.push(singleReviewType);
    });
    return reviewLists;
  }

  pushReview(reviewId) {
    const reviewObj = this.findReview(reviewId);
    if (reviewObj) {
      reviewObj.selected = true;
      this.selectedReviewData.unshift(reviewObj);
    }
  }

  unselectReview(selectedReview) {
    selectedReview.selected = false;
    this.selectedReviewData.splice(this.selectedReviewData.indexOf(selectedReview), 1);
  }

  findReview(reviewId) {
    const result = this.reviewTypeListSanitized.find((review) => {
      return review.id === reviewId;
    });
    return result;
  }

  submit() {
    this.formSubmitted = true;
    this.submitInProgress = true;
    if (this.systemReviewForm.invalid || !this.areReviewValid()) {
      this.submitInProgress = false;
      return false;
    }
    const data = this.systemReviewForm.value;
    data.review_type = this.getReviewTypeData();
    this.systemReviewService.saveSystemReview(data, this.patientId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        console.log(error);
        this.submitInProgress = false;
        if (error.status === 422) {
          const errors = error.error.errors;
          this.setBackendValidationErrors(errors);
          this.setBackendReviewValidationsErrors(errors);
        }
        this.submitInProgress = false;
        this.toastr.error(error.error.message);
      },
      () => {
        this.submitInProgress = false;
        if (this.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate([this.nextUrl]);
        }
        this.saveAndContinueClicked = false;
      }
    );
    this.submitInProgress = false;
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  getReviewTypeData() {
    const review_type_list = [];
    const review_type = this.reviewTypeListSanitized.filter((review_t: any) => {
      return review_t.selected === true;
    });
    review_type.forEach((review_ty: any) => {
      const review_sub_type = review_ty.sub_type_list.filter((sub_list: any) => {
        return sub_list.selected === true;
      });
      review_ty.review_sub_type = review_sub_type;
      review_type_list.push(review_ty);
    });
    return review_type_list;
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addMenapause() {
    var data = this.systemReviewForm.value.menapause_age;
    if (this.single_menapause_age != undefined) {
      if (data.findIndex((x) => x === this.single_menapause_age) === -1) {
        data.push(this.single_menapause_age);
        this.systemReviewForm.patchValue({
          menapause_age: data
        });
      }
      this.single_menapause_age = null;
    }
    return false;
  }
  unselectMenaPause(menapause) {
    this.systemReviewForm.value.menapause_age.splice(
      this.systemReviewForm.value.menapause_age.indexOf(menapause),
      1
    );
  }
  isValidReview(reviewType) {
    var nested_field_status = false;
    const selectedSubReview = reviewType.sub_type_list.filter((subType: any) => {
      return subType.selected === true;
    });
    for (let sub_review of selectedSubReview) {
      if (sub_review.has_nested_field === 'yes') {
        if (sub_review.nested_field_value === '') {
          return false;
        } else {
          nested_field_status = true;
        }
      } else if (sub_review.has_nested_field === 'no') {
        nested_field_status = true;
      }
    }
    return reviewType.others.length > 0 || nested_field_status;
  }

  areReviewValid() {
    let i = 0;
    this.reviewTypeListSanitized.forEach((reviewType: any) => {
      if (this.isValidReview(reviewType)) {
        i++;
      }
    });
    return i !== 0;
  }

  setBackendValidationErrors(errors) {
    if (errors.date_of_last_eye_exam) {
      this.setBackendValidationError('date_of_last_eye_exam', errors.date_of_last_eye_exam[0]);
    } else {
      this.unsetBackendValidationError('date_of_last_eye_exam');
    }
    if (errors.date_of_last_bone_density_test) {
      this.setBackendValidationError(
        'date_of_last_bone_density_test',
        errors.date_of_last_bone_density_test[0]
      );
    } else {
      this.unsetBackendValidationError('date_of_last_bone_density_test');
    }
    if (errors.date_of_last_chest_xray) {
      this.setBackendValidationError('date_of_last_chest_xray', errors.date_of_last_chest_xray[0]);
    } else {
      this.unsetBackendValidationError('date_of_last_chest_xray');
    }
    if (errors.ppd_test_result) {
      this.setBackendValidationError('ppd_test_result', errors.ppd_test_result[0]);
    } else {
      this.unsetBackendValidationError('ppd_test_result');
    }
    if (errors.date_of_last_mammogram_period) {
      this.setBackendValidationError('date_of_last_mammogram_period', errors.is_menapause[0]);
    } else {
      this.unsetBackendValidationError('date_of_last_mammogram_period');
    }
    if (errors.sex) {
      this.setBackendValidationError('sex', errors.sex[0]);
    } else {
      this.unsetBackendValidationError('sex');
    }
    if (errors.age_when_periods_begin) {
      this.setBackendValidationError('age_when_periods_begin', errors.age_when_periods_begin[0]);
    } else {
      this.unsetBackendValidationError('age_when_periods_begin');
    }
    if (errors.menapause_age) {
      this.setBackendValidationError('menapause_age', errors.menapause_age[0]);
    } else {
      this.unsetBackendValidationError('menapause_age');
    }
    if (errors.date_of_last_mammogram_period) {
      this.setBackendValidationError(
        'date_of_last_mammogram_period',
        errors.date_of_last_mammogram_period[0]
      );
    } else {
      this.unsetBackendValidationError('date_of_last_mammogram_period');
    }
    if (errors.is_menapause) {
      this.setBackendValidationError('is_menapause', errors.is_menapause[0]);
    } else {
      this.unsetBackendValidationError('is_menapause');
    }
    if (errors.last_ppd_test_date) {
      this.setBackendValidationError('last_ppd_test_date', errors.last_ppd_test_date[0]);
    } else {
      this.unsetBackendValidationError('last_ppd_test_date');
    }
    if (errors.mammogram_periods) {
      this.setBackendValidationError('mammogram_periods', errors.mammogram_periods[0]);
    } else {
      this.unsetBackendValidationError('mammogram_periods');
    }
    if (errors.mammogram_periods_frequency) {
      this.setBackendValidationError(
        'mammogram_periods_frequency',
        errors.mammogram_periods_frequency[0]
      );
    } else {
      this.unsetBackendValidationError('mammogram_periods_frequency');
    }
    if (errors.number_of_pregnancies) {
      this.setBackendValidationError('number_of_pregnancies', errors.number_of_pregnancies[0]);
    } else {
      this.unsetBackendValidationError('number_of_pregnancies');
    }
    if (errors.date_of_last_pap_smear) {
      this.setBackendValidationError('date_of_last_pap_smear', errors.date_of_last_pap_smear[0]);
    } else {
      this.unsetBackendValidationError('date_of_last_pap_smear');
    }
  }
  setBackendReviewValidationsErrors(errors) {
    const keys = Object.keys(errors);
    for (const key of keys) {
      if (key.includes('review_type')) {
        this.reviewBackendValidationError[key] = errors[key];
      }
    }
  }

  hasReviewBackendValidation(reviewName, reviewField) {
    var index;
    index = this.getReviewIndexSelected(reviewName);
    return this.reviewBackendValidationError.hasOwnProperty(
      'review_type' + '.' + index + '.' + reviewField
    );
  }

  getReviewBackendValidation(reviewName, reviewField) {
    var index;
    index = this.getReviewIndexSelected(reviewName);
    return this.reviewBackendValidationError['review_type' + '.' + index + '.' + reviewField];
  }

  getReviewIndexSelected(reviewName) {
    const data = this.selectedReviewData;
    return data.findIndex((x) => x.name === reviewName);
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
