import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UrlsService } from '../../../services/urls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LabBookingService } from '../../../services/lab-booking.service';
import { LabReportService } from '../../../services/lab-report.service';
import { PatientService } from '../../../services/patient.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { NotifyService } from '../../../services/notify.service';
import { FilteredInvestigation, Investigation } from '../../../shared/model/investigation';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/shared/model/country-code';
import { CountryCodeService } from 'src/app/services/country-code.service';

function createValidateRecommendedBy(doctors: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!doctors.includes(control.value)) {
      return { recommended_by_not_match: true };
    }
    return null;
  };
}
@Component({
  selector: 'app-lab-booking-create',
  templateUrl: './lab-booking-create.component.html',
  styleUrls: ['./lab-booking-create.component.css']
})
export class LabBookingCreateComponent implements OnInit {
  @ViewChild('menu', {static: false}) menu: ElementRef;
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;
  NAME_MAX_LENGTH = 255;
  MOBILE_PATTERN = '^\\d{10}$';
  DEBOUNCE_TIME = 300;
  investigationSearchInputChanged: Subject<string> = new Subject<string>();
  today = new Date();
  countryCodes: CountryCode[] = [];
  nepalCountryCode: CountryCode = new CountryCode();

  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'required',
      message: 'Name is required'
    },
    {
      name: 'name',
      error_type: 'maxLength',
      message: 'Name can be no more than ' + this.NAME_MAX_LENGTH + ' characters'
    },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'country_code',
      error_type: 'required',
      message: 'Country Code is required'
    },
    {
      name: 'country_code',
      error_type: 'match_not_found',
      message: 'please select an option'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'recommended_by',
      error_type: 'required',
      message: 'Recommended By is required '
    },
    {
      name: 'recommended_by',
      error_type: 'recommended_by_not_match',
      message: 'Please select an option'
    },
    {
      name: 'investigation',
      error_type: 'required',
      message: 'At least one investigation should be selected'
    },
    {
      name: 'lab_id',
      error_type: 'required',
      message: 'Diagnostic Center is required'
    },
    {
      name: 'date',
      error_type: 'required',
      message: 'Date is required'
    },
    {
      name: 'time',
      error_type: 'required',
      message: 'Time is required'
    }
  ];

  labBookingForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    // country_code: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    email: new FormControl('', [Validators.email]),
    recommended_by: new FormControl('', [Validators.required, createValidateRecommendedBy([])]),
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    note: new FormControl(''),
    lab_id: new FormControl('', [Validators.required])
  });

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'recommended_by',
      error: false
    },
    {
      controlName: 'date',
      error: false
    },
    {
      controlName: 'time',
      error: false
    },
    {
      controlName: 'note',
      error: false
    },
    {
      controlName: 'bookings',
      error: false
    },
    {
      controlName: 'lab_id',
      error: false
    }
  ];

  INVESTIGATION_SELECT_ALL_MESSAGE = 'Select All';
  INVESTIGATION_DESELECT_ALL_MESSAGE = 'Deselect All';

  patientId: number;

  selectedBookingPatientType = 'new';
  fieldDisabled = false;
  patientSearchKeyword = '';
  displayPatientSuggestion = false;
  suggestedPatients: Array<any> = [];

  displayExistingPatientSuggestion = false;
  existingSuggestedPatients: Array<any> = [];

  selectedInvestigations: Array<any> = [];
  suggestedInvestigations: Array<any> = [];
  displaySuggestionList = false;

  diagnosticCenters: any[] = [];
  recommendedByList: any[] = [];

  investigationKeyword = '';
  hasInvestigations = false;

  doctorList: string[] = [];
  filteredDoctorList: Observable<string[]>;

  // For Form Footer
  cancelUrl = '/lab-bookings';
  submitInProgress = false;

  formSubmitted = false;
  formDirty = false;

  loadingPatientSearch = false;
  patientSearchHit = false;
  showOptions = false;
  mobileInputClicked = false;
  investigationClicked = false;
  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private urlService: UrlsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labBookingService: LabBookingService,
    private labReportService: LabReportService,
    private toastr: ToastrService,
    private patientService: PatientService,
    private ngZone: NgZone,
    private countryCodeService: CountryCodeService,
    private renderer: Renderer2
  ) {
    this.today.setDate(this.today.getDate());
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.menu.nativeElement && e.target !== this.menu.nativeElement) {
        this.showOptions = false;
      }
    });
  }

  ngOnInit() {
    // this.getCountryCodes();
    this.getDoctorList();
    this.getRecommendedByList();
    this.getLabList();
    this.initiateInvestigationSearchTimer();
  }

  clickedOutsideInvestigationInput() {
    this.investigationKeyword = '';
    setTimeout(() => {
      this.investigationClicked = false;
    }, 200);
  }

  clickedOutsideMobileInput() {
    this.mobileInputClicked = false;
  }

  getCountryCodes() {
    this.countryCodeService.getCountryCodes().subscribe(
      (response: CountryCode[]) => {
        this.countryCodes = response;
        response.map((x) => {
          if (x.name == 'Nepal') {
            this.nepalCountryCode = x;
          }
        });
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  initiateInvestigationSearchTimer() {
    this.investigationSearchInputChanged
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((search) => {
        this.labReportService
          .getInvestigationServiceList(
            this.labBookingForm.controls.lab_id.value,
            this.investigationKeyword,
            this.selectedInvestigations
          )
          .subscribe(
            (investigations: Investigation[]) => {
              console.log('investigation api response', investigations);
              this.suggestedInvestigations = this.getProcessedSuggestedInvestigations(
                investigations
              );
              console.log('investigation filtered response', this.suggestedInvestigations);
              this.displaySuggestionList = true;
            },
            () => {
              this.showOptions = true;
            }
          );
      });
  }
  getName(labId) {
    const labName = this.diagnosticCenters.find(
      (diagnosticCenter) => diagnosticCenter.id === labId
    );
    return labName.name;
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabList().subscribe(
      (y: any) => {
        this.diagnosticCenters = y.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
        this.labBookingForm.patchValue({
          lab_id: this.getLabId('Health at Home')
        });
      }
    );
  }
  getLabId(diagnosticName) {
    const obj = this.diagnosticCenters.find(
      (diagnosticCenter) => diagnosticCenter.name.toLowerCase() === diagnosticName.toLowerCase()
    );
    return obj.id;
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (y: any) => {
        this.submitInProgress = false;
        this.doctorList = y;
        this.filteredDoctorList = this.setACField(
          this.labBookingForm.controls.recommended_by,
          'doctorList'
        );
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  getRecommendedByList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.recommendedByList = response;
        this.labBookingForm.controls.recommended_by.clearValidators();
        this.labBookingForm.controls.recommended_by.setValidators([
          Validators.required,
          createValidateRecommendedBy(response || [])
        ]);
      },
      (error) => {
        this.submitInProgress = false;
      }
    );
  }

  searchPatients() {
    this.displayPatientSuggestion = false;
    const pageNo = 1;
    const limit = 10;
    const filter = 'mobile';
    if (this.patientSearchKeyword.length > 2) {
      this.loadingPatientSearch = true;
      this.patientSearchHit = true;
      this.patientService
        .searchPatients(this.patientSearchKeyword, pageNo, limit, filter)
        .subscribe(
          (response: any) => {
            if (response.size >= 1) {
              this.displayPatientSuggestion = true;
              this.suggestedPatients = response.data;
            } else {
              this.suggestedPatients.length = 0;
            }
            this.loadingPatientSearch = false;
          },
          (error) => {
            this.suggestedPatients.length = 0;
            this.displayPatientSuggestion = false;
            this.loadingPatientSearch = false;
          }
        );
    } else {
      this.displayPatientSuggestion = false;
    }
  }

  proceedToSearch(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.searchPatients();
    }
  }

  clearPatientSelection(event) {
    event.preventDefault();
    this.labBookingForm.controls.name.reset();
    this.labBookingForm.controls.mobile.reset();
    this.labBookingForm.controls.email.reset();
    this.patientId = null;
    this.fieldDisabled = false;
    this.displayPatientSuggestion = false;
    this.displayExistingPatientSuggestion = false;
    this.selectedBookingPatientType = 'new';
  }

  selectPatient(patient) {
    const patientName =
      patient.first_name +
      ' ' +
      (patient.middle_name ? patient.middle_name + ' ' : '') +
      patient.last_name;
    this.labBookingForm.patchValue({
      name: patientName,
      mobile: patient.mobile,
      email: patient.email
    });
    this.patientId = patient.id;

    this.patientSearchKeyword = '';
    this.displayPatientSuggestion = false;
    this.displayExistingPatientSuggestion = false;
    this.patientSearchHit = false;

    this.selectedBookingPatientType = 'old';
    this.fieldDisabled = true;
    (document.getElementById('oldPatientModalCloseBtn') as HTMLInputElement).click();
    // TODO: this implementation is generating warning while running ng test
  }

  showSuggestions() {
    if (this.investigationKeyword.length <= 3) {
      this.displaySuggestionList = false;
      this.investigationClicked = false;
      return;
    }
    this.investigationSearchInputChanged.next(this.investigationKeyword);
    this.investigationClicked = true;
    return true;
  }

  getProcessedSuggestedInvestigations(investigations: Investigation[]) {
    const filteredResult: FilteredInvestigation[] = [];
    investigations = this.renameTestKey(investigations);
    investigations.forEach((investigation) => {
      if (
        investigation.tests.length <= 0 ||
        investigation.tests.filter((test) => test.found === true).length <= 0
      ) {
        // if keyword matches investigation,
        // return instance of investigation

        filteredResult.push(investigation);
      } else {
        // if keyword matches test type,
        // filter matched test type and
        // return instance of investigation
        // with test type

        const matchedTestResults = investigation.tests.filter((test) => test.found);

        matchedTestResults.forEach((test) => {
          const matchedInvestigation: FilteredInvestigation = { ...investigation };
          matchedInvestigation.test_type_suggested = test;
          filteredResult.push(matchedInvestigation);
        });
      }
    });
    return filteredResult;
  }

  renameTestKey(investigations) {
    // renamed test key from test_type_list to tests
    // this implementation can be removed once
    // test key is returned as tests from API

    return investigations.map((investigation) => {
      investigation.tests = investigation.test_type_list;
      delete investigation.test_type_list;
      return investigation;
    });
  }

  selectInvestigation(investigation) {
    if (investigation.test_type_suggested) {
      investigation.tests
        .filter((test) => test.id === investigation.test_type_suggested.id)
        .map((test) => {
          test.selected = true;
        });
    } else {
      investigation.tests.map((test) => {
        test.selected = true;
      });
    }

    if (
      this.selectedInvestigations.filter(
        (selectedInvestigation) => investigation.id === selectedInvestigation.id
      ).length === 1
    ) {
      const foundTest = investigation.tests.find((test) => test.found === true);
      this.selectedInvestigations
        .find((selectedInv) => investigation.id === selectedInv.id)
        .tests.filter((test) => test.id === foundTest.id)
        .map((test) => (test.selected = true));
    } else {
      this.selectedInvestigations.push(investigation);
    }

    this.displaySuggestionList = false;
    this.investigationKeyword = '';

    this.setInvestigationCheckedStatus(investigation);
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  setInvestigationCheckedStatus(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    if (this.areAllTestSelected(investigation)) {
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests === 0) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = false;
      return;
    }
    if (selectedTests <= totalTests) {
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
      investigation.indeterminate = true;
      return;
    }
  }

  areAllTestSelected(investigation: any) {
    const totalTests = investigation.tests.length;
    const selectedTests = investigation.tests.filter((test: any) => test.selected).length;
    return selectedTests === totalTests;
  }

  toggleSelectTest(investigation, test) {
    test.selected = !test.selected;
    this.setInvestigationCheckedStatus(investigation);
  }

  toggleSelectAllInvestigation(investigation) {
    if (!this.areAllTestSelected(investigation)) {
      investigation.tests.map((test) => (test.selected = true));
      investigation.selectedMessage = this.INVESTIGATION_DESELECT_ALL_MESSAGE;
    } else {
      investigation.tests.map((test) => (test.selected = false));
      investigation.selectedMessage = this.INVESTIGATION_SELECT_ALL_MESSAGE;
    }
  }

  removeInvestigation(investigation) {
    this.selectedInvestigations = this.selectedInvestigations.filter((selectedInvestigation) => {
      return (
        this.selectedInvestigations.indexOf(investigation) !==
        this.selectedInvestigations.indexOf(selectedInvestigation)
      );
    });
    this.hasInvestigations = this.selectedInvestigations.length >= 1;
  }

  changeDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  submit() {
    this.formSubmitted = true;
    this.submitInProgress = true;
    this.formSubmitted = true;
    this.submitInProgress = true;
    const recommendedBy = this.labBookingForm.controls.recommended_by.value;
    // if (this.doesMatch(recommendedBy)) {
    //   return false;
    // }
    // this.labBookingForm.value.recommended_by = this.doesMatch(recommendedBy)
    //   ? recommendedBy
    //   : null;

    if (
      this.labBookingForm.status === 'VALID' &&
      this.selectedInvestigations.length >= 1 &&
      this.labBookingForm.controls.recommended_by
    ) {
      const date = this.labBookingForm.controls.date.value;
      const time = this.labBookingForm.controls.time.value;

      const request = this.labBookingForm.value;

      request.date = this.getMergedDateTime(date, time);
      delete request.time;
      if (this.patientId) {
        request.patient_id = this.patientId;
      }

      request.bookings = this.getSanitizedBookings(this.selectedInvestigations);

      this.labBookingService.store(request).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          let errorMessage;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
            for (const [key, value] of Object.entries(errors)) {
              if (key.includes('bookings')) {
                errorMessage = 'Select valid investigation';
                break;
              }
              if (key.includes('recommended_by')) {
                errorMessage = 'Recommended By is required Please select an option';
                break;
              }
            }
          }
          errorMessage = errorMessage ? errorMessage : error.error.message;
          this.toastr.error(errorMessage);
        },
        () => {
          this.submitInProgress = false;
          this.ngZone.run(() =>
            this.router.navigate(['/lab-bookings'], { queryParams: { status: 'pending' } })
          );
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  validateRecommendedBy() {
    // setTimeout(() => {
    //   const selectValue = this.labBookingForm.controls.recommended_by.value;
    //   const isSelectedValue = this.recommendedByList.includes(selectValue);
    //   if (!isSelectedValue) {
    //     this.labBookingForm.controls.recommended_by.setErrors({
    //       recommended_by: 'Please select an option'
    //     });
    //     //this.labBookingForm.controls.recommended_by.setValue('');
    //   }
    // }, 500);
  }

  // doesMatch(recommendedBy) {
  //   console.log('Recommended by -->', recommendedBy);
  //   console.log('Recommended by List-->', this.recommendedByList);
  //   console.log('Recommended by List-->', this.recommendedByList.includes(recommendedBy));
  //   this.doesntMatch = !this.recommendedByList.includes(recommendedBy);
  //   return this.doesntMatch;
  // }

  clearInvestigation() {
    this.selectedInvestigations = [];
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.recommended_by) {
      this.setBackendValidationError('recommended_by', errors.recommended_by[0]);
    } else {
      this.unsetBackendValidationError('recommended_by');
    }
    if (errors.date) {
      this.setBackendValidationError('date', errors.date[0]);
    } else {
      this.unsetBackendValidationError('date');
    }
    if (errors.time) {
      this.setBackendValidationError('time', errors.time[0]);
    } else {
      this.unsetBackendValidationError('time');
    }
    if (errors.note) {
      this.setBackendValidationError('note', errors.note[0]);
    } else {
      this.unsetBackendValidationError('note');
    }
    if (errors.bookings) {
      this.setBackendValidationError('bookings', errors.bookings[0]);
    } else {
      this.unsetBackendValidationError('bookings');
    }
    if (errors.lab_id) {
      this.setBackendValidationError('lab_id', errors.lab_id[0]);
    } else {
      this.unsetBackendValidationError('lab_id');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getSanitizedBookings(selectedInvestigations: Array<any>) {
    const investigations = [];
    selectedInvestigations.forEach((investigation: any) => {
      investigations.push({
        id: investigation.id,
        name: investigation.name,
        selected: true,
        tests: investigation.tests.filter((test) => test.selected === true)
      });
    });
    return investigations;
  }

  getMergedDateTime(date, time) {
    console.log(
      'Date and Time -->',
      moment(date + ' ' + time, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm:ss')
    );
    return moment(date + ' ' + time, 'YYYY-MM-DD HH:mm a').format('YYYY-MM-DD HH:mm:ss');
  }

  checkMatch(doctor) {}

  checkIfNumberUsed() {
    if (this.fieldDisabled) {
      return;
    }
    this.displayExistingPatientSuggestion = false;
    const mobile = this.labBookingForm.controls.mobile.value;
    const pageNo = 1;
    const limit = 10;
    const filter = 'mobile';
    if (mobile.length > 2) {
      this.patientService.searchPatients(mobile, pageNo, limit, filter).subscribe(
        (response: any) => {
          if (response.size >= 1) {
            this.showOptions = true;
            this.displayExistingPatientSuggestion = true;
            this.existingSuggestedPatients = response.data;
            this.mobileInputClicked = true;
          }
        },
        (error) => {
          this.existingSuggestedPatients.length = 0;
          this.displayExistingPatientSuggestion = false;
          this.showOptions = false;
          this.mobileInputClicked = false;
        }
      );
    } else {
      this.displayExistingPatientSuggestion = false;
    }
  }

  skipSelection() {
    this.patientId = null;
    this.displayExistingPatientSuggestion = false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
