import { Component, OnInit } from '@angular/core';
import { Patient } from '../../shared/model/patient';
import { FormControl, FormGroup } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../services/urls.service';
import { NotifyService } from '../../services/notify.service';
import * as moment from 'moment';
import { DoctorFeedbackService } from '../../services/doctor-feedback.service';

@Component({
  selector: 'app-doctor-feedback',
  templateUrl: './doctor-feedback.component.html',
  styleUrls: ['./doctor-feedback.component.css']
})
export class DoctorFeedbackComponent implements OnInit {
  doctorFeedback = [];
  searchQuery: string = null;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  clearDate = false;
  rawSearchQuery: string;
  advancedSearchForm = new FormGroup({
    q: new FormControl('')
  });

  constructor(
    private doctorFeedbackService: DoctorFeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.searchQuery = searchQuery ? searchQuery : null;

    this.getDoctorFeedback();
  }

  clearSearch() {
    this.searchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.getDoctorFeedback();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDoctorFeedback();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getDoctorFeedback();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getDoctorFeedback();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  proceedToSearch(event) {
    if (this.searchQuery) {
      event.preventDefault();
      this.page = 1;
      this.getDoctorFeedback();
    }
  }

  getDoctorFeedback(): void {
    this.loading = true;
    this.doctorFeedbackService
      .getDoctorFeedbackList(this.page, this.limit, this.searchQuery)
      .subscribe(
        (doctorFeedback: any) => {
          this.doctorFeedback = doctorFeedback.data;
          this.total = doctorFeedback.size;
        },
        (err) => {
          this.loading = false;
          this.doctorFeedback.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  formatData() {
    const data: any = {
      q: this.advancedSearchForm.value.q ? this.advancedSearchForm.value.q : ''
    };
    return data;
  }

  counter(i: number) {
    return new Array(i);
  }

  getColor(rating, i) {
    return i <= rating ? 'icon-color' : '';
  }

  addParamsToUrl(q, pageNo = null, limit = null) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        q,
        pageNo,
        limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
