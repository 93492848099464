import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PhysiotherapyService } from 'src/app/services/physiotherapy.service';

@Component({
  selector: 'app-physiotherapy-detail',
  templateUrl: './physiotherapy-detail.component.html',
  styleUrls: ['./physiotherapy-detail.component.css']
})
export class PhysiotherapyDetailComponent implements OnInit {
  physiotherapyId: string;
  physiotherapyDetail: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private physiotherapyService: PhysiotherapyService
  ) {}

  ngOnInit() {
    this.physiotherapyId = this.activatedRoute.snapshot.paramMap.get('physiotherapyId');
    this.getPhysiotherapyDetail(this.physiotherapyId);
  }

  getPhysiotherapyDetail(physiotherapyId) {
    this.physiotherapyService.getPhysiotherapyDetail(physiotherapyId).subscribe(
      (response: any) => {
        this.physiotherapyDetail = response;
      },
      (error) => {
        console.log('error fetching physiotherapy data');
      }
    );
  }

  getFormattedDate(datetime, format) {
    return moment(datetime).utc().format(format);
  }

  titleCase(str) {
    if (!str) {
      return;
    }
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  getAddress(addressString) {
    const address = JSON.parse(addressString);
    return address
      ? address.province +
          ', ' +
          address.city +
          '<br>' +
          address.address +
          ', ' +
          address.nearest_landmark
      : 'N/A';
  }

  formatNote(note) {
    return note ? note.replace(/(\r\n|\n|\r)/gm, '<br />') : '---';
  }
}
