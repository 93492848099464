import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.auth.loggingOut = true;
    this.auth.isAuthenticated().subscribe((res) => {
      if (!res) {
        this.storageService.clear();
        this.auth.VoIPLogout();
        window.location.href = 'auth/login';
      }
    });
  }
}
