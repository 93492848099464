import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EncounterService {
  private apiUrl = environment.apiUrl; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  store(data, patientId) {
    console.log(data);
    const url = `${this.apiUrl}` + patientId + '/encounter-informations';
    return this.http.post(url, data, this.httpOptions);
  }

  getPatientEncounter(patientId, encounterId) {
    return this.http.get(`${this.apiUrl}${patientId}/encounter-informations/${encounterId}`);
  }

  getPatientEncounters(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}${patientId}/encounter-informations`, {
      params: { pageNo, limit }
    });
  }

  getMedicationTypeList() {
    return this.http.get(`${this.apiUrl}medication/types`);
  }

  updatePatientEncounter(patientId, encounterId, data) {
    const url = `${this.apiUrl}${patientId}/encounter-informations/${encounterId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, data, httpOptions);
  }
}
