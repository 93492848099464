import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Medication } from '../shared/model/Medication';

@Injectable({
  providedIn: 'root'
})
export class MedicationListService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  store(request: any) {
    return this.http.post(this.apiUrl + 'medication-list', request, this.httpOptions);
  }

  getMedicationList(pageNo, limit, query, status) {
    if (query) {
      return this.http.get(`${this.apiUrl}medication-list`, {
        params: { pageNo, limit, query, status }
      });
    } else {
      return this.http.get(`${this.apiUrl}medication-list`, { params: { pageNo, limit, status } });
    }
  }

  getMedicationDetail(id: number) {
    return this.http.get<Medication>(`${this.apiUrl}medication-list/${id}`);
  }

  save(medication, id): Observable<any> {
    if (id) {
      const api = this.apiUrl + 'medication-list/' + id;
      return this.http.post(api, medication, this.httpOptions);
    } else {
      return this.http.post(`${this.apiUrl}medication-list`, medication, this.httpOptions);
    }
  }

  getMedicationTypeList(): Observable<any> {
    return this.http.get(`${this.apiUrl}medication/types`);
  }
}
