import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AllergyInformationService } from '../../../services/allergy-information.service';
import { UrlsService } from '../../../services/urls.service';
import { MatSnackBar } from '@angular/material';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-allergy-create',
  templateUrl: './allergy-create.component.html',
  styleUrls: ['./allergy-create.component.css']
})
export class AllergyCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  allergyInformationForm = new FormGroup({
    allergy_type: new FormControl('', [Validators.required]),
    allergy: new FormControl('', [Validators.required]),
    onset: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    severity: new FormControl('', [Validators.required]),
    reaction: new FormControl('', [Validators.required]),
    comment: new FormControl()
  });

  submitted = false;
  patientId = '';

  selectedAllergy: string[] = [];
  selectedAllergyFiltered: Observable<string[]>;

  // For Save and Continue
  saveAndContinueClicked = false;

  allergyDetails: Array<object> = [
    {
      id: 1,
      name: 'Drug'
    },
    {
      id: 2,
      name: 'Food'
    },
    {
      id: 3,
      name: 'Environment'
    }
  ];

  onsets: Array<object> = [
    {
      displayName: 'Adulthood',
      value: 'adulthood'
    },
    {
      displayName: 'Childhood',
      value: 'childhood'
    }
  ];

  reactions: string[] = [];
  reactionsFiltered: Observable<string[]>;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private allergyInformationService: AllergyInformationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private page: PageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getPageInfo();
    this.getReactionList();
    this.getMenu();
  }

  getPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Allergy Information', this.patientId);
    });
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getAllergyList(type): void {
    this.allergyInformationService.getAllergyList(type).subscribe((allergyLists) => {
      this.selectedAllergy = allergyLists;
      this.selectedAllergyFiltered = this.setACField(
        this.allergyInformationForm.controls.allergy,
        'selectedAllergy'
      );
    });
  }

  getReactionList(): void {
    this.allergyInformationService.getReactionList().subscribe((reactionLists) => {
      this.reactions = reactionLists;
      this.reactionsFiltered = this.setACField(
        this.allergyInformationForm.controls.reaction,
        'reactions'
      );
    });
  }

  selectAllergy(id: number): void {
    this.allergyInformationForm.controls.allergy.setValue('');
    switch (id) {
      case 1:
        this.getAllergyList('drug');

        break;
      case 2:
        this.getAllergyList('food');

        break;
      default:
        this.getAllergyList('environment');
    }
  }
  storeAllergyInformation() {
    this.submitted = true;
    this.submitInProgress = true;
    const data = this.allergyInformationForm.value;

    if (this.allergyInformationForm.status === 'VALID') {
      this.allergyInformationService.storeAllergyInformation(data, this.patientId).subscribe(
        (response: any) => {
          setTimeout(() => {
            this.toastr.success(response.status);
          }, 1000);

          console.log(response);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  showAllergy(option): string {
    return option;
  }

  showNotification(message: string) {
    this.toastr.info(message);
  }
}
