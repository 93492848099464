import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraComponent } from './camera/camera.component';
import { RoomComponent } from './room/room.component';
import { ParticipantComponent } from './participant/participant.component';
import { DeviceSelectComponent } from './device-select/device-select.component';
import { SettingsComponent } from './settings/settings.component';
import { HomeComponent } from './home/home.component';
import { TwilioVideoRoutingModule } from './twilio-video-routing.module';

@NgModule({
  declarations: [
    CameraComponent,
    RoomComponent,
    ParticipantComponent,
    DeviceSelectComponent,
    SettingsComponent,
    HomeComponent
  ],
  imports: [CommonModule, TwilioVideoRoutingModule]
})
export class TwilioVideoModule {}
