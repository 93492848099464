export const GraphLabels = {
  labBooking: {
    pending: 'Pending',
    inProgress: 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled'
  },
  patient: {
    inPatients: 'In Patients',
    outPatients: 'Out Patients'
  },
  doctorConsultation: {
    upcoming: 'Upcoming',
    instantCall: 'Instant Call',
    completed: 'Completed'
  },
  serviceConsultation: {
    pending: 'Pending',
    inProgress: 'In Progress',
    completed: 'Completed',
    cancelled: 'Cancelled'
  }
};

export const GraphColors = {
  green: '#AADEA7',
  lightGreen: '#64C2A6',
  blue: '#2D87BB',
  orange: '#F66D44',
  grey: '#565656',
  neonGreen: '#E6F69D'
}
