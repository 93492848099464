import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { LoginUserDetailComponent } from './login-user-detail/login-user-detail.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';

const routes: Routes = [
  {
    path: '',
    component: UserListComponent
  },
  {
    path: 'editprofile',
    component: ProfileEditComponent
  },
  {
    path: 'create',
    component: UserCreateComponent
  },
  {
    path: 'profile-detail',
    component: LoginUserDetailComponent
  },
  {
    path: 'change-password',
    component: UserChangePasswordComponent
  },
  {
    path: ':id',
    component: UserDetailComponent
  },
  {
    path: ':id/edit',
    component: UserEditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}
