import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-inquiry-second-step',
  templateUrl: './inquiry-second-step.component.html',
  styleUrls: ['./inquiry-second-step.component.css']
})
export class InquirySecondStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() cancelEmmitter = new EventEmitter();
  @Output() nextEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  inquirySecondForm = new FormGroup({
    monthlyVisitStatus: new FormControl('', [Validators.required]),
    totalEnroll: new FormControl('', [Validators.required]),
    deliveryPharmacyStatus: new FormControl('', [Validators.required]),
    partnerMember: new FormControl('', [Validators.required])
  });
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.inquirySecondForm.patchValue({
      monthlyVisitStatus: this.data.monthlyVisitStatus,
      totalEnroll: this.data.totalEnroll,
      deliveryPharmacyStatus: this.data.deliveryPharmacyStatus,
      partnerMember: this.data.partnerMember
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.inquirySecondForm.valid) {
      const formData = {
        currentStep: 2,
        nextStep: 3,
        data: this.inquirySecondForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 2,
      previousStep: 1,
      data: this.inquirySecondForm.value
    };
    this.previousEmmitter.emit(formData);
  }
  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
