import { Component, OnInit } from '@angular/core';
import { FamilyMember } from '../../../shared/model/family-members';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FamilyHistoryService } from '../../../services/family-history.service';
import { UrlsService } from '../../../services/urls.service';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-family-history-create',
  templateUrl: './family-history-create.component.html',
  styleUrls: ['./family-history-create.component.css']
})
export class FamilyHistoryCreateComponent implements OnInit {
  submitted = false;
  submitInProgress = false;
  urlList: Array<string> = [];
  nextUrl: string;

  patientId: number;
  fatherStatus: string;
  motherStatus: string;

  relationShipList: any;

  familyMembers: FamilyMember[] = [];

  // For Save and Continue
  saveAndContinueClicked = false;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';

  familyHistoryForm = new FormGroup({
    father_name: new FormControl('', [Validators.required]),
    father_age: new FormControl('', [Validators.required]),
    father_clinical_disorder: new FormControl(''),
    father_status: new FormControl('alive', [Validators.required]),
    father_cause_of_death: new FormControl(''),

    mother_name: new FormControl('', [Validators.required]),
    mother_age: new FormControl('', [Validators.required]),
    mother_clinical_disorder: new FormControl(''),
    mother_status: new FormControl('alive', [Validators.required]),
    mother_cause_of_death: new FormControl('')
  });

  constructor(
    private familyHistoryService: FamilyHistoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Family History', this.patientId);
    });
    this.familyHistoryService.relationShipList().subscribe((res) => {
      this.relationShipList = res;
    });
    // get all family history for the Patient
    this.getFamilyHistory();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getFamilyHistory() {
    this.submitInProgress = true;
    this.familyHistoryService.familyHistory(this.patientId).subscribe(
      (res) => {
        // get father info
        const fatherObj = res.find((y) => {
          return y.relationship === 'father';
        });
        // get mother info
        const motherObj = res.find((y) => {
          return y.relationship === 'mother';
        });
        // Set the father and mother info by default
        this.familyHistoryForm.setValue({
          father_name: fatherObj ? fatherObj.name : '',
          father_age: fatherObj ? fatherObj.age : '',
          father_clinical_disorder: fatherObj ? fatherObj.clinical_disorder : '',
          father_status: fatherObj ? fatherObj.status : '',
          father_cause_of_death: fatherObj ? fatherObj.cause_of_death : '',

          mother_name: motherObj ? motherObj.name : '',
          mother_age: motherObj ? motherObj.age : '',
          mother_clinical_disorder: motherObj ? motherObj.clinical_disorder : '',
          mother_status: motherObj ? motherObj.status : '',
          mother_cause_of_death: motherObj ? motherObj.cause_of_death : ''
        });
        // Set the status of father and mother
        this.fatherStatus = fatherObj ? fatherObj.status : 'alive';
        this.motherStatus = motherObj ? motherObj.status : 'alive';
        // get family member info without father and mother info
        const membersObj = res.filter((y) => {
          return y.relationship !== 'father' && y.relationship !== 'mother';
        });
        // set the family member info by default
        membersObj.forEach((member) => {
          const familyMember = new FamilyMember();
          familyMember.relationship = member.relationship;
          familyMember.causeOfDeath = member.cause_of_death;
          familyMember.name = member.name;
          familyMember.clinicalDisorder = member.clinical_disorder;
          familyMember.status = member.status;
          familyMember.age = member.age;
          this.familyMembers.push(familyMember);
        });
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  addMember(event): void {
    event.preventDefault();
    const familyMember = new FamilyMember();
    familyMember.status = 'alive';
    familyMember.relationship = 'sibling';
    this.familyMembers.push(familyMember);
  }

  changeFatherStatus(e): void {
    const status = e.value;
    this.fatherStatus = status;
  }

  changeMotherStatus(e): void {
    const status = e.value;
    this.motherStatus = status;
  }

  changeMemberStatus(i: number): void {
    const familyMember = this.familyMembers[i];
    familyMember.status = familyMember.status === 'alive' ? 'dead' : 'alive';
  }

  deleteFamilyMember(id: number): void {
    this.familyMembers.splice(id, 1);
  }

  store() {
    this.submitInProgress = true;
    this.submitted = true;
    if (this.familyHistoryForm.status === 'VALID') {
      // submit form
      const data = this.familyHistoryForm.value;
      data.familyMembers = this.familyMembers;
      this.familyHistoryService.store(this.patientId, data).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.submitted = false;
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }
}
