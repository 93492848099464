import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { UrlsService } from '../../../services/urls.service';
import { DiseaseService } from '../../../services/disease.service';
import { PastMedicalHistoryService } from '../../../services/past-medical-history.service';
import { Observable } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MedicationService } from '../../../services/medication.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ToastrService } from 'ngx-toastr';

function createValidate(diagnosticCenterList: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!diagnosticCenterList.includes(control.value)) {
      return { not_matched_with_list: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-past-medical-history-create',
  templateUrl: './past-medical-history-create.component.html',
  styleUrls: ['./past-medical-history-create.component.css']
})
export class PastMedicalHistoryCreateComponent implements OnInit {
  @ViewChild('outsideClickManage', {static: false}) outsideClickManage: ElementRef;
  patientId: number;
  urlList: Array<string> = [];

  nextUrl: string;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  submitted = false;

  file: File;
  fileAttachmentError = '';

  pastMedicalHistoryForm = new FormGroup({
    disease: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{4}$')]),
    diagnosticCenter: new FormControl('', [Validators.required, createValidate([])]),
    medications: new FormControl('')
  });

  formErrorObj: Array<any> = [
    {
      controlName: 'disease',
      error: false
    },
    {
      controlName: 'year',
      error: false
    },
    {
      controlName: 'diagnosis_center',
      error: false
    },
    {
      controlName: 'medications',
      error: false
    },
    {
      controlName: 'file_attachment',
      error: false
    }
  ];

  diseases: string[] = [];
  diseasesFiltered: Observable<string[]>;

  diagnosticCenters: string[] = [];
  diagnosticCentersFiltered: Observable<string[]>;

  medications: string[] = [];
  medicationsFiltered: Observable<string[]>;
  chipSeparationCodes = [ENTER, COMMA];
  selectedMedications: string[] = [];

  @ViewChild('newMedicationInput', { static: false }) newMedicationInput: ElementRef<
    HTMLInputElement
  >;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  _filter(value) {
    const filterValue = value.toLowerCase();
    return this.medications.filter(
      (medication) => medication.toLowerCase().indexOf(filterValue) === 0
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private page: PageService,
    private urlService: UrlsService,
    private diseaseService: DiseaseService,
    private medicationService: MedicationService,
    private pastMedicalHistoryService: PastMedicalHistoryService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getMenu();
    this.getDiseaseList();
    this.getDiagnosticCenterList();
    this.getMedicationList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Past Medical History', this.patientId);
    });
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getDiseaseList() {
    this.submitInProgress = true;
    this.diseaseService.getDiseaseList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.diseases = response.data;
      },
      (error) => {
        this.submitInProgress = false;
        this.diseases.length = 0;
        this.toastr.error(error.error.message);
      },
      () => {
        this.diseasesFiltered = this.setACField(
          this.pastMedicalHistoryForm.controls.disease,
          'diseases'
        );
      }
    );
  }

  getDiagnosticCenterList() {
    this.submitInProgress = true;
    this.pastMedicalHistoryService.getDiagnosticCenterList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.diagnosticCenters = response.data;
        this.pastMedicalHistoryForm.controls.diagnosticCenter.setValidators([
          Validators.required,
          createValidate(response.data || [])
        ]);
      },
      (error) => {
        this.submitInProgress = false;
        this.diagnosticCenters.length = 0;
        this.toastr.error(error.error.message);
      },
      () => {
        this.diagnosticCentersFiltered = this.setACField(
          this.pastMedicalHistoryForm.controls.diagnosticCenter,
          'diagnosticCenters'
        );
      }
    );
  }

  getMedicationList() {
    this.submitInProgress = true;
    this.medicationService.getAllMedicationList().subscribe(
      (response: any) => {
        this.submitInProgress = false;
        this.medications = response.data;
      },
      (error) => {
        this.submitInProgress = false;
        this.medications.length = 0;
        this.toastr.error(error.error.message);
      },
      () => {
        this.medicationsFiltered = this.pastMedicalHistoryForm.controls.medications.valueChanges.pipe(
          startWith(null),
          map((medication: string | null) =>
            medication ? this._filter(medication) : this.medications.slice()
          )
        );
      }
    );
  }

  add(event) {
    const input = event.input;
    const value = event.value;
    // Add our medication
    if (this.medications.includes(value) && (value || '').trim()) {
      this.selectedMedications.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.pastMedicalHistoryForm.controls.medications.setValue(null);
  }

  remove(medication): void {
    const index = this.selectedMedications.indexOf(medication);

    if (index >= 0) {
      this.selectedMedications.splice(index, 1);
    }
  }

  selected(event) {
    this.selectedMedications.push(event.option.viewValue);
    this.pastMedicalHistoryForm.controls.medications.setValue(null);
    this.newMedicationInput.nativeElement.value = '';
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  submit() {
    this.submitted = true;
    this.submitInProgress = true;

    if (this.pastMedicalHistoryForm.status === 'VALID' && this.selectedMedications.length !== 0) {
      const data = this.createRequestData({
        disease: this.pastMedicalHistoryForm.controls.disease.value,
        year: this.pastMedicalHistoryForm.controls.year.value,
        diagnosis_center: this.pastMedicalHistoryForm.controls.diagnosticCenter.value,
        file_attachment: this.file,
        medications: this.selectedMedications
      });

      this.pastMedicalHistoryService.store(this.patientId, data).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
          if (error.status === 422) {
            const errors = error.error.errors;
            if (errors.disease) {
              this.setError('disease', errors.disease[0]);
            } else {
              this.unsetError('disease');
            }
            if (errors.year) {
              this.setError('year', errors.year[0]);
            } else {
              this.unsetError('year');
            }
            if (errors.diagnosis_center) {
              this.setError('diagnosis_center', errors.diagnosis_center[0]);
            } else {
              this.unsetError('diagnosis_center');
            }
            if (errors.medications) {
              this.setError('medications', errors.medications[0]);
            } else {
              this.unsetError('medications');
            }
            if (errors.file_discharge_summary) {
              this.setError('file_discharge_summary', errors.file_discharge_summary[0]);
            } else {
              this.unsetError('file_attachment');
            }
          }
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }
}
