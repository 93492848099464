import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';
import { CallQuery, CallQueryList } from '../shared/model/call-query';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallQueryService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getCallQueryList(pageNo, limit, filterBody): Observable<CallQueryList> {
    const data: any = {
      q: filterBody.query ? filterBody.query : '',
      date_to: filterBody.toDate ? filterBody.toDate : '',
      date_from: filterBody.fromDate ? filterBody.fromDate : ''
    };
    data.pageNo = pageNo;
    data.limit = limit;
    const params = FormDataUtil.filterEmptyParams(data);
    return this.http.get<CallQueryList>(`${this.apiUrl}call-query`, { params });
  }

  getCallQueryDetail(id: number) {
    return this.http.get<CallQuery>(`${this.apiUrl}call-query/${id}`);
  }

  store(request: any) {
    return this.http.post(this.apiUrl + 'call-query', request, this.httpOptions);
  }

  updateStatus(callQueryId, data) {
    return this.http.put(`${this.apiUrl}call-query/${callQueryId}/status`, data);
  }
}
