import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SurgicalHistoryService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getLocationList() {
    return this.http.get(`${this.apiUrl}location-lists`);
  }

  store(patientId: number, data) {
    return this.http.post(
      `${this.apiUrl}patients/${patientId}/surgical-history`,
      data,
      this.httpOptions
    );
  }

  getPatientSurgicalHistoryList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}patients/${patientId}/surgical-history`, {
      params: { pageNo, limit }
    });
  }
}
