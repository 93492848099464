import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDiagnosisList(keyword, arr) {
    const url = this.apiUrl + 'diagnosis';

    return this.http.post(url, { keyword, except: arr }, this.httpOptions);
  }

  storeDiagnosis(data, patientId) {
    const url = this.apiUrl + patientId + '/diagnosis';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log('sdfdsf');

    return this.http.post(url, data, httpOptions);
  }

  getDiagnosisListAll(patientId: number) {
    const url = this.apiUrl + 'diagnosis-list/' + patientId;
    return this.http.get(url);
  }

  getDiagnosis(patientId: number, diagnosisId: number) {
    const url = this.apiUrl + patientId + '/diagnosis/' + diagnosisId;
    return this.http.get(url);
  }

  getId = (route: ActivatedRoute) => Number(route.snapshot.paramMap.get('diagnosisId'));

  getPatientDiagnosisList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}${patientId}/diagnosis`, { params: { pageNo, limit } });
  }

  saveCsv(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'diagnosis/upload-csv', request, httpOptions);
  }
}
