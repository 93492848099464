import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvestigationService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  store(data, diagnosticCenterId) {
    const url = this.apiUrl + 'diagnostic-center/' + diagnosticCenterId + '/investigations';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }
  saveCsv(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'investigation-lists/save-csv', request, httpOptions);
  }

  getInvestigationList(pageNo, limit, query, status, diagnosticCenterId) {
    if (query) {
      return this.http.get(`${this.apiUrl}diagnostic-center/${diagnosticCenterId}/investigations`, {
        params: { pageNo, limit, query, status }
      });
    } else {
      return this.http.get(`${this.apiUrl}diagnostic-center/${diagnosticCenterId}/investigations`, {
        params: { pageNo, limit, status }
      });
    }
  }

  downloadCSV(query, status) {
    return this.http.post(`${this.apiUrl}investigation-lists/download-csv`, {
      params: { query, status }
    });
  }

  getInvestigation(investigationId) {
    return this.http.get(`${this.apiUrl}investigation-lists/${investigationId}`);
  }

  update(investigationId, data) {
    const url = `${this.apiUrl}investigation-lists/${investigationId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, data, httpOptions);
  }

  updateInvestigationStatus(investigationId, data) {
    return this.http.put(
      `${this.apiUrl}investigation-lists/${investigationId}/update-status`,
      data
    );
  }
}
