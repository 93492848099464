import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConsumerMessageService } from '../../../services/consumer-message.service';

@Component({
  selector: 'app-consumer-message-list',
  templateUrl: './consumer-message-list.component.html',
  styleUrls: ['./consumer-message-list.component.css']
})
export class ConsumerMessageListComponent implements OnInit {
  messageList: Array<any> = [];
  messageReadStatusList: Array<any> = [];
  messagePriorityList: Array<any> = [];

  messageSearchQuery: string;

  iconNames = [
    {
      name: 'read',
      icon: 'fa fa-eye'
    },
    {
      name: 'unread',
      icon: 'fa fa-eye-slash'
    },
    {
      name: 'important',
      icon: 'fa fa-check-square'
    },
    {
      name: 'not_important',
      icon: 'fa fa-check-square'
    }
  ];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private consumerMessageService: ConsumerMessageService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const messageSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.messageSearchQuery = messageSearchQuery ? messageSearchQuery : null;

    this.getMessageAttributes();
  }

  getStatusIconName(message) {
    return this.iconNames.find((icon) => {
      return icon.name === message.status;
    }).icon;
  }

  getPriorityIconName(message) {
    return this.iconNames.find((icon) => {
      return icon.name === message.priority;
    }).icon;
  }

  getNextStatus(message, key) {
    const nextStatus = this.messageReadStatusList.find((status) => {
      return status.slug !== message.status;
    });
    return nextStatus[key];
  }

  getNextPriority(message, key) {
    const nextPriority = this.messagePriorityList.find((priority) => {
      return priority.slug !== message.priority;
    });
    return nextPriority[key];
  }

  getMessageAttributes() {
    this.consumerMessageService.getMessageAttributes().subscribe(
      (response: any) => {
        this.messageReadStatusList = response.data.read_status;
        this.messagePriorityList = response.data.priority;
      },
      (error) => {
        this.messageList.length = 0;
        this.messageReadStatusList.length = 0;
        this.messagePriorityList.length = 0;
      },
      () => {
        this.getConsumerMessages(this.limit);
      }
    );
  }

  proceedToSearch(event) {
    event.preventDefault();
    this.searchConsumerMessages();
  }

  clearSearch() {
    this.messageSearchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchConsumerMessages();
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  searchConsumerMessages() {
    this.getConsumerMessages(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.messageSearchQuery);
  }

  changeMessageStatus(message) {
    const nextStatus = this.getNextStatus(message, 'slug');
    const updateData = {
      status: nextStatus
    };
    this.consumerMessageService.updateConsumerMessage(message.id, updateData).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        message.status = nextStatus;
      }
    );
  }

  changeMessagePriority(message) {
    const nextPriority = this.getNextPriority(message, 'slug');
    const updateData = {
      priority: nextPriority
    };
    this.consumerMessageService.updateConsumerMessage(message.id, updateData).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        message.priority = nextPriority;
      }
    );
  }

  deleteConsumerMessage(message) {
    if (confirm('Are you sure to delete this message?')) {
      this.consumerMessageService.deleteConsumerMessage(message.id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          this.removeMessageFromList(message);
        }
      );
    }
  }

  removeMessageFromList(message) {
    this.messageList.splice(this.messageList.indexOf(message), 1);
  }

  getConsumerMessages(limit) {
    const page = this.page;
    this.loading = true;
    this.consumerMessageService.getConsumerMessages(page, limit, this.messageSearchQuery).subscribe(
      (messageList: any) => {
        this.messageList = messageList.data;
        this.total = messageList.size;
      },
      (error) => {
        this.messageList.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getConsumerMessages(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.messageSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getConsumerMessages(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.messageSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getConsumerMessages(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.messageSearchQuery);
  }
}
