import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { LabReportService } from '../../../services/lab-report.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DiagnosisService } from '../../../services/diagnosis.service';
import { MatSnackBar } from '@angular/material';
import { UrlsService } from '../../../services/urls.service';
import { PatientService } from '../../../services/patient.service';
import * as moment from 'moment';

function createValidate(diagnosedByList: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!diagnosedByList.includes(control.value)) {
      return { not_match: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-diagnosis-form',
  templateUrl: './diagnosis-form.component.html',
  styleUrls: ['./diagnosis-form.component.css']
})
export class DiagnosisFormComponent implements OnInit {
  @Output() saveForm = new EventEmitter<any>();
  @Output() saveAndContinueClicked = new EventEmitter<any>();

  // tslint:disable-next-line:no-input-rename
  @Input('ngModel') search: any;
  @Input() data;
  diagnosedByList: string[] = [];
  showSuggestionForm: boolean;
  showNotFoundMessage: boolean;
  primaryIsSelected = true;
  d: Array<object> = [];
  primaryDiagnosis: Array<object> = [];
  secondaryDiagnosis: Array<object> = [];
  arr: Array<any>;
  submitted = false;
  diagnosisCenters: string[] = [];
  diagnosisCentresFiltered: Observable<object[]>;

  diagnosedByFiltered: Observable<string[]>;

  // For Save and Continue
  //saveAndContinueClicked = false;
  diagnosisForm: FormGroup;
  diagnosisSelected: boolean;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  DEBOUNCE_TIME = 350;
  diagnosisSearchInputChanged: Subject<string> = new Subject<string>();

  constructor(
    private labReportService: LabReportService,
    private diagnosisService: DiagnosisService,
    private router: Router,
    private urlService: UrlsService,
    private patient: PatientService,
    private activatedRoute: ActivatedRoute,
    private notification: MatSnackBar
  ) {}
  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  ngOnInit() {
    this.diagnosisForm = new FormGroup({
      date: new FormControl(
        this.data && this.data.date
          ? moment(this.data.date).format('YYYY-MM-DD')
          : moment(new Date()).format('YYYY-MM-DD'),
        [Validators.required]
      ),
      diagnosed_by: new FormControl(
        this.data && this.data.diagnosed_by ? this.data.diagnosed_by : '',
        [Validators.required, createValidate([])]
      ),
      diagnosis_center: new FormControl(
        this.data && this.data.diagnosis_center_name ? this.data.diagnosis_center_name : '',
        [Validators.required, createValidate([])]
      ),
      clinical_note: new FormControl(
        this.data && this.data.clinical_note ? this.data.clinical_note : ''
      ),
      diagnosisCenterControl: new FormControl(
        this.data && this.data.diagnosisCenterControl ? this.data.diagnosisCenterControl : ''
      )
    });

    const patientDiagnonisList = this.data && this.data.diagnosis ? this.data.diagnosis : [];

    patientDiagnonisList.map((p) => {
      if (p.primary) {
        this.primaryDiagnosis.push(p);
      } else {
        this.secondaryDiagnosis.push(p);
      }
      this.d.push(p);
    });

    // get diagnosed by list from api and assign
    this.getDoctorList();

    // get diagnoses center list from api and assign
    this.getLabList();
  }

  saveAndContinue() {
    this.saveAndContinueClicked.emit('true');
    //this.saveAndContinueClicked = true;
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (y: any) => {
        this.diagnosedByList = y;
        this.diagnosisForm.controls.diagnosed_by.setValidators([
          Validators.required,
          createValidate(y || [])
        ]);
        this.diagnosedByFiltered = this.setACField(
          this.diagnosisForm.controls.diagnosed_by,
          'diagnosedByList'
        );
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabList().subscribe(
      (y: any) => {
        y.data.map((x, i) => {
          this.diagnosisCenters.push(x.name);
        });
        this.diagnosisForm.controls.diagnosis_center.setValidators([
          Validators.required,
          createValidate(this.diagnosisCenters || [])
        ]);
        this.diagnosisCentresFiltered = this.setACField(
          this.diagnosisForm.controls.diagnosis_center,
          'diagnosisCenters'
        );
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  addDiagnosis() {
    this.submitted = true;
    this.submitInProgress = true;
    this.diagnosisSelected = this.d.length === 0 ? true : false;
    if (this.diagnosisForm.invalid || this.d.length === 0) {
      this.submitInProgress = false;
      return;
    }
    const data = this.diagnosisForm.value;
    data.diagnosis = this.d;
    this.saveForm.emit({ data });
    this.submitInProgress = false;
  }

  showSuggestions(e) {
    if (this.search.length > 0) {
      this.diagnosisService.getDiagnosisList(this.search, this.d).subscribe((x: any) => {
        this.arr = x;
        this.showSuggestionForm = this.arr.length === 0 ? false : true;
        this.showNotFoundMessage = this.arr.length === 0 ? true : false;
      });
    } else {
      this.showNotFoundMessage = false;
      this.showSuggestionForm = false;
    }

    return true;
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });
    x.active = true;
    // added by sishir start
    if (this.primaryIsSelected === true) {
      x.primary = true;
      this.primaryDiagnosis.push(x);
    } else {
      x.primary = false;
      this.secondaryDiagnosis.push(x);
    }
    // added by sishir end
    // x.confirmed = false;
    // x.primary = false;

    // console.log(this.search);
    this.diagnosisSelected = false;
    this.showSuggestionForm = false;
    this.search = '';
    this.d.push(x);
  }

  togglePrimary(x) {
    if ('primary' in x && x.primary === true) {
      x.primary = false;
    } else {
      x.primary = true;
    }
    console.log(x);
  }

  toggleConfirmed(x) {
    // console.log(x);
    if ('confirmed' in x && x.confirmed === true) {
      x.confirmed = false;
    } else {
      x.confirmed = true;
    }
    console.log(x);
  }

  toggleDiagnosisActive(x) {
    if ('active' in x && x.active === true) {
      x.active = false;
    } else {
      x.active = true;
    }
    console.log(x);
  }

  togglePrimaryDiagnosis(e): void {
    this.primaryIsSelected = e.value === 'primary';
  }

  unSelectDiagnosis(x): void {
    // console.log(x);
    this.d = this.d.filter((y) => {
      return y !== x;
    });

    if (x.primary === true) {
      this.primaryDiagnosis = this.primaryDiagnosis.filter((y) => {
        return y !== x;
      });
    } else {
      this.secondaryDiagnosis = this.secondaryDiagnosis.filter((y) => {
        return y !== x;
      });
    }
  }

  showNotification(message: string) {
    this.notification.open(message, 'Ok', {
      duration: 5000
    });
  }

  initiateDiagnosisSearchTimer() {
    this.diagnosisSearchInputChanged.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((search) => {
      this.diagnosisService.getDiagnosisList(this.search, this.d).subscribe((x: any) => {
        this.arr = x;
        this.showSuggestionForm = this.arr.length === 0 ? false : true;
      });
    });
  }
}
