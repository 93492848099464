import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { UserServiceService } from '../../services/user-service.service';
import { PatientService } from '../../services/patient.service';
import { UrlsService } from '../../services/urls.service';
import { PageService } from '../workflow-forms/page.service';
import * as moment from 'moment';

@Component({
  selector: 'app-assign-users',
  templateUrl: './assign-users.component.html',
  styleUrls: ['./assign-users.component.css']
})
export class AssignUsersComponent implements OnInit {
  patientId: number;
  patientDetail: any;

  saveAndContinueClicked = false;
  urlList: Array<string> = [];
  nextUrl: string;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  displaySuggestion = false;
  userSearchKeyWord: string;
  suggestedUserList: Array<any> = [];
  selectedUserList: Array<any> = [];
  loadingUserSearch = false;
  userSearchHit = false;

  assignedUserList: Array<any> = [];

  todayDate: Date = new Date();
  nextDate: Date = new Date(this.todayDate);

  // For Form Footer
  showSaveAndContinueButton = false;
  showSaveButton = false;
  showCancelButton = false;
  showGoBackButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notification: NotifyService,
    private userService: UserServiceService,
    private patientService: PatientService,
    private urlService: UrlsService,
    private pageService: PageService
  ) {}

  ngOnInit() {
    this.nextDate.setDate(this.todayDate.getDate() + 1);
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.pageService.setTitleAndPatientId('Assign Users', this.patientId);
    });
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getPatientDetail();

    this.getAssignedUserList(this.limit);

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getPatientDetail() {
    this.submitInProgress = true;
    this.patientService.getPatient(this.patientId).subscribe(
      (response: any) => {
        this.patientDetail = response;
      },
      (error) => {
        this.submitInProgress = false;
        console.log('could not get patient detail at the moment');
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getAssignedUserList(limit) {
    this.loading = true;
    this.patientService.getAssignedUsers(this.patientId, this.page, limit).subscribe(
      (response: any) => {
        this.assignedUserList = response.data;
        this.total = response.size;
      },
      (error) => {
        this.assignedUserList.length = 0;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getAssignedUserList(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getAssignedUserList(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getAssignedUserList(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  proceedToSearch(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.searchUsers();
    }
  }

  searchUsers() {
    if (this.userSearchKeyWord.length > 2) {
      this.loadingUserSearch = true;
      this.userSearchHit = true;
      this.userService
        .searchUsersForAssignation(this.patientId, this.userSearchKeyWord, this.selectedUserList)
        .subscribe(
          (response: any) => {
            this.loadingUserSearch = false;
            this.suggestedUserList = response.data;
          },
          (error) => {
            this.loadingUserSearch = false;
            this.suggestedUserList.length = 0;
          },
          () => {
            if (this.suggestedUserList.length !== 0) {
              this.displaySuggestion = true;
              this.suggestedUserList.forEach((user: any) => {
                user.expanded = false;
                user.dateFrom = null;
                user.dateTo = null;
              });
            }
          }
        );
    } else {
      this.displaySuggestion = false;
    }
  }

  userSelectionValid(user) {
    const startDate = user.dateFrom ? new Date(user.dateFrom) : null;
    const endDate = user.dateTo ? new Date(user.dateTo) : null;
    if (startDate && endDate) {
      return endDate > startDate;
    }
    return true;
  }

  removeUserFromAssigned(user, event) {
    event.preventDefault();
    this.loading = true;
    if (confirm('Are you sure to remove this user?')) {
      this.patientService.removeAssignedUser(this.patientId, [user.id]).subscribe(
        (response: any) => {
          this.notification.show(response.message);
        },
        (error) => {
          this.loading = false;
          this.notification.show(error.error.message);
        },
        () => {
          this.removeUser(user);
          this.loading = false;
        }
      );
    } else {
      this.loading = false;
    }
  }

  removeUser(user) {
    this.assignedUserList = this.assignedUserList.filter((assignedUser: any) => {
      return assignedUser.id !== user.id;
    });
  }

  pushUser(user) {
    this.assignedUserList.push(user);
    this.suggestedUserList.splice(this.suggestedUserList.indexOf(user), 1);
    this.userSearchKeyWord = '';
  }

  assignUser(user, event) {
    this.submitInProgress = true;
    event.preventDefault();
    const userList = [];
    if (this.userSelectionValid(user)) {
      userList.push({
        id: user.id,
        dateFrom: user.dateFrom,
        dateTo: user.dateTo
      });
      // Push request to API
      this.patientService.assignUsers(this.patientId, userList).subscribe(
        (response: any) => {
          this.notification.show(response.message);
        },
        (error) => {
          this.notification.show(error.error.message);
        },
        () => {
          this.pushUser(user);
          (document.getElementById('userSearchModalCloseBtn') as HTMLInputElement).click();
          this.submitInProgress = false;
        }
      );
    }
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo: this.page,
        limit: this.limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  togglePatientMenu(e) {
    document.getElementById('wrapper-div').classList.toggle('control-sidebar-open');
    e.target.classList.toggle('open-btn');
    e.target.classList.toggle('close-btn');
  }

  onOpenModal() {
    this.userSearchHit = false;
    this.userSearchKeyWord = '';
    this.suggestedUserList = [];
  }
}
