import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetService } from '../shared/services/reset.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  UNKNOWN_RESPONSE_ERR_MSG = 'Sorry, we could not process your request at the moment';
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  backendValidationErrors = [
    {
      controlName: 'email',
      error: false,
      message: ''
    }
  ];

  VALIDATION_MESSAGES = [
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    }
  ];

  submitInProgress = false;

  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  formSubmitted = false;

  constructor(private resetService: ResetService, private router: Router) {}

  ngOnInit() {}

  submitForm() {
    this.formSubmitted = true;
    this.submitInProgress = true;
    if (this.forgotPasswordForm.status === 'VALID') {
      const data = this.forgotPasswordForm.value;
      this.resetService.sendResetLink(data).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.router.navigate(['auth/login'], {
            state: { data: { flashMessage: response.message } }
          });
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          if (error.error.message) {
            this.displayToastMessage(error.error.message, false);
          } else if (error.status !== 422 && error.error.errors.email) {
            this.displayToastMessage(error.error.errors.email, false);
          } else {
            this.displayToastMessage(this.UNKNOWN_RESPONSE_ERR_MSG, false);
          }
        },
        () => {
          this.submitInProgress = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  displayToastMessage(message: string, isSuccess: boolean) {
    this.successMessageDisplay = isSuccess;
    this.failureMessageDisplay = !isSuccess;
    this.responseMessage = message;
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }

  setBackendValidationErrors(errors) {
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
