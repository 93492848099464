import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InterceptorModule } from './interceptor.module';
import { CookieService } from 'ngx-cookie-service';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatMenuModule,
  MatSortModule,
  MatPaginatorModule,
  MatTabsModule,
  MatListModule,
  MatExpansionModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestComponent } from './test/test.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AuthGuard } from './auth/shared/guards/auth.guard';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { BusinessUnitComponent } from './add-business-unit/business-unit.component';
import { BusinessUnitListComponent } from './business-unit-list/business-unit-list.component';
import { RegionListComponent } from './region-list/region-list.component';
import { AddRegionComponent } from './add-region/add-region.component';
import { AddBusinessUnitLocationComponent } from './add-business-unit-location/add-business-unit-location.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { BusinessUnitLocationListComponent } from './business-unit-location-list/business-unit-location-list.component';
import { LabBookingListComponent } from './feature/lab-bookings/lab-booking-list/lab-booking-list.component';
import { TodoListComponent } from './feature/todos/todo-list/todo-list.component';
import { CallLogListComponent } from './feature/call-logs/call-log-list/call-log-list.component';
import { SampleCreateComponent } from './feature/lab-bookings/samples/sample-create/sample-create.component';
import { LabReportDetailComponent } from './feature/lab-bookings/lab-reports/lab-report-detail/lab-report-detail.component';
import { LabBookingDetailComponent } from './feature/lab-bookings/lab-booking-detail/lab-booking-detail.component';
import { PatientsModule } from './patients/patients.module';
import { DeliveryCreateComponent } from './feature/lab-bookings/deliveries/delivery-create/delivery-create.component';
import { LabReportCreateComponent } from './feature/lab-bookings/lab-reports/lab-report-create/lab-report-create.component';
import { InvestigationCreateComponent } from './feature/investigations/investigation-create/investigation-create.component';
import { InvestigationListComponent } from './feature/investigations/investigation-list/investigation-list.component';
import { InvestigationEditComponent } from './feature/investigations/investigation-edit/investigation-edit.component';
import { LabBookingEditComponent } from './feature/lab-bookings/lab-booking-edit/lab-booking-edit.component';
import { LabDispatchCreateComponent } from './feature/lab-bookings/lab-dispatches/lab-dispatch-create/lab-dispatch-create.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UsersModule } from './users/users.module';
import { LabPackageListComponent } from './feature/lab-packages/lab-package-list/lab-package-list.component';
import { LabPackageCreateComponent } from './feature/lab-packages/lab-package-create/lab-package-create.component';
import { SharedModule } from './shared/shared.module';
import { VideoChatModule } from './video-chat/video-chat.module';
import { ToastrModule } from 'ngx-toastr';
import { ProfilesModule } from './profiles/profiles.module';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthModule } from './auth/auth.module';
import { PasswordModule } from './password/password.module';
import { PrescriptionRefillListComponent } from './feature/prescription-refills/prescription-refill-list/prescription-refill-list.component';
import { ConsumerMessageListComponent } from './feature/consumer-messages/consumer-message-list/consumer-message-list.component';
import { BookedInvestigationsComponent } from './feature/lab-bookings/lab-booking-detail/booked-investigations/booked-investigations.component';
import { SamplesCollectedComponent } from './feature/lab-bookings/lab-booking-detail/samples-collected/samples-collected.component';
import { DeliveryDetailComponent } from './feature/lab-bookings/lab-booking-detail/delivery-detail/delivery-detail.component';
import { LabReportComponent } from './feature/lab-bookings/lab-booking-detail/lab-report/lab-report.component';
import { BookingLogsComponent } from './feature/lab-bookings/lab-booking-detail/booking-logs/booking-logs.component';
import { DispatchDetailsComponent } from './feature/lab-bookings/lab-booking-detail/dispatch-details/dispatch-details.component';
import { MessageComponent } from './feature/message/message.component';
import { ConversationBoxComponent } from './feature/message/conversation-box/conversation-box.component';
import { LabPackageDetailsComponent } from './feature/lab-packages/lab-package-details/lab-package-details.component';
import { RegistrationModule } from './registration/registration.module';
import { VaccinationRefillsComponent } from './feature/vaccination-refills/vaccination-refills/vaccination-refills.component';
import { PhysiotherapyComponent } from './feature/physiotherapy/physiotherapy.component';
import { DiagnosisListComponent } from './feature/emr-settings/diagnosis/diagnosis-list/diagnosis-list.component';
import { DiagnosisFormComponent } from './feature/emr-settings/diagnosis/diagnosis-form/diagnosis-form.component';
import { EmrSettingsListComponent } from './feature/emr-settings/emr-settings-list/emr-settings-list.component';
import { MedicationListComponent } from './feature/emr-settings/medication/medication-list/medication-list.component';
import { MedicationFormComponent } from './feature/emr-settings/medication/medication-form/medication-form.component';
import { ImmunizationListComponent } from './feature/emr-settings/immunization-vaccination/immunization-list/immunization-list.component';
import { ImmunizationFormComponent } from './feature/emr-settings/immunization-vaccination/immunization-form/immunization-form.component';
import { SpecialityListComponent } from './feature/emr-settings/speciality/speciality-list/speciality-list.component';
import { SpecialityFormComponent } from './feature/emr-settings/speciality/speciality-form/speciality-form.component';
import { DiagnosisCenterListComponent } from './feature/diagnosis-center/diagnosis-center-list/diagnosis-center-list.component';
import { DiagnosisCenterFormComponent } from './feature/diagnosis-center/diagnosis-center-form/diagnosis-center-form.component';
import { PaymentHistoryComponent } from './feature/payment-history/payment-history-list/payment-history.component';
import { UploadCsvFormComponent } from './feature/investigations/upload-csv-form/upload-csv-form.component';
import { InvestigationDetailComponent } from './feature/investigations/investigation-detail/investigation-detail.component';
import { PaymentHistoryDetailComponent } from './feature/payment-history/payment-history-detail/payment-history-detail.component';
import { LabReceivedCreateComponent } from './feature/lab-bookings/lab-received/lab-received-create/lab-received-create.component';
import { LabReceivedDetailComponent } from './feature/lab-bookings/lab-booking-detail/lab-received-detail/lab-received-detail.component';
import { MyAppointmentListComponent } from './feature/my-appointment/my-appointment-list/my-appointment-list.component';
import { AppointmentCardComponent } from './feature/my-appointment/appointment-card/appointment-card.component';
import { MyScheduleComponent } from './feature/my-appointment/my-schedule/my-schedule.component';
import { ScheduleCardComponent } from './feature/my-appointment/schedule-card/schedule-card.component';
import { StaffFormComponent } from './feature/emr-settings/staff/staff-form/staff-form.component';
import { StaffListComponent } from './feature/emr-settings/staff/staff-list/staff-list.component';
import { DoctorListComponent } from './feature/emr-settings/doctor/doctor-list/doctor-list.component';
import { DoctorFormComponent } from './feature/emr-settings/doctor/doctor-form/doctor-form.component';
import { DoctorFeedbackComponent } from './feature/doctor-feedback/doctor-feedback.component';
import { InquiryFormComponent } from './inquiry-form/inquiry-form.component';
import { InquiryHeaderComponent } from './inquiry-form/inquiry-header/inquiry-header.component';
import { InquirySidebarComponent } from './inquiry-form/inquiry-sidebar/inquiry-sidebar.component';
import { InquiryFirstStepComponent } from './inquiry-form/inquiry-first-step/inquiry-first-step.component';
import { InquirySecondStepComponent } from './inquiry-form/inquiry-second-step/inquiry-second-step.component';
import { InquiryThirdStepComponent } from './inquiry-form/inquiry-third-step/inquiry-third-step.component';
import { InquiryFourthStepComponent } from './inquiry-form/inquiry-fourth-step/inquiry-fourth-step.component';
import { InquiryFifthStepComponent } from './inquiry-form/inquiry-fifth-step/inquiry-fifth-step.component';
import { SubscriptionFormComponent } from './subscription-form/subscription-form.component';
import { SubscriptionHeaderComponent } from './subscription-form/subscription-header/subscription-header.component';
import { SubscriptionSidebarComponent } from './subscription-form/subscription-sidebar/subscription-sidebar.component';
import { SubscriptionFirstStepComponent } from './subscription-form/subscription-first-step/subscription-first-step.component';
import { SubscriptionSecondStepComponent } from './subscription-form/subscription-second-step/subscription-second-step.component';
import { SubscriptionThirdStepComponent } from './subscription-form/subscription-third-step/subscription-third-step.component';
import { SubscriptionFourthStepComponent } from './subscription-form/subscription-fourth-step/subscription-fourth-step.component';
import { InstantPayComponent } from './instant-pay/instant-pay.component';
import { InstantPayHeaderComponent } from './instant-pay/instant-pay-header/instant-pay-header.component';
import { InstantPaySidebarComponent } from './instant-pay/instant-pay-sidebar/instant-pay-sidebar.component';
import { InstantPayFirstStepComponent } from './instant-pay/instant-pay-first-step/instant-pay-first-step.component';
import { InstantPaySecondStepComponent } from './instant-pay/instant-pay-second-step/instant-pay-second-step.component';
import { ServiceTypeListComponent } from './feature/emr-settings/service-type/service-type-list/service-type-list.component';
import { ServiceTypeFormComponent } from './feature/emr-settings/service-type/service-type-form/service-type-form.component';
import { ServiceManagementListComponent } from './feature/service-management/service-management-list/service-management-list.component';
import { ServiceManagementCreateComponent } from './feature/service-management/service-management-create/service-management-create.component';
import { ServiceManagementEditComponent } from './feature/service-management/service-management-edit/service-management-edit.component';
import { TermsConditionsDetailsComponent } from './feature/emr-settings/terms-conditions/terms-conditions-details/terms-conditions-details.component';
import { TermsConditionsFormComponent } from './feature/emr-settings/terms-conditions/terms-conditions-form/terms-conditions-form.component';
import { ServiceBookingsComponent } from './feature/service-bookings/service-bookings.component';
import { MyWorkSheetComponent } from './feature/my-work-sheet/my-work-sheet.component';
import { NurseWorksheetComponent } from './feature/nurse-worksheet/nurse-worksheet.component';
import { CallCenterComponent } from './feature/call-center/call-center-list/call-center.component';
import { PhysiotherapyListComponent } from './feature/emr-settings/physiotherapy/physiotherapy-list/physiotherapy-list.component';
import { PhysiotherapyFormComponent } from './feature/emr-settings/physiotherapy/physiotherapy-form/physiotherapy-form.component';
import { CallCenterDetailComponent } from './feature/call-center/call-center-detail/call-center-detail/call-center-detail.component';
import { TwilioVideoModule } from './twilio-video/twilio-video.module';
import { SocketService } from './services/socket.service';
import { PhysiotherapyDetailComponent } from './feature/physiotherapy/physiotherapy-detail/physiotherapy-detail.component';
import { ServiceBookingDetailComponent } from './feature/service-bookings/service-booking-detail/service-booking-detail.component';
import { CallQueryCreateComponent } from './feature/call-queries/call-query-create/call-query-create.component';
import { CallQueryListComponent } from './feature/call-queries/call-query-list/call-query-list.component';
import { CallQueryDetailComponent } from './feature/call-queries/call-query-detail/call-query-detail.component';
import { PrintComponent } from './shared/components/print-component/print.component';
import { MedicationDetailComponent } from './feature/emr-settings/medication/medication-detail/medication-detail.component';
import { PrescriptionRefillDetailComponent } from './feature/prescription-refills/prescription-refill-detail/prescription-refill-detail.component';
import { BannerFormComponent } from './banner/banner-form/banner-form.component';
import { CallLogComponent } from './feature/call-log/call-log.component';
import { BannerComponent } from './banner/banner.component';
import { UploadCsvComponent } from './feature/emr-settings/diagnosis/diagnosis-list/upload-csv/upload-csv.component';
import { LabReportsComponent } from './feature/lab-bookings/lab-reports/lab-report/lab-reports.component';
import { DashboardTotalCardComponent } from './dashboard-analytics/dashboard-total-card/dashboard-total-card.component';
import { DashboardGraphComponent } from './dashboard-analytics/dashboard-graph/dashboard-graph.component';
import { DateFilterComponent } from './dashboard/date-filter/date-filter.component';
import { isAlphaNumeric } from './patients/shared/isAlphaNumeric';
import { WorkflowFormsModule } from './patients/workflow-forms/workflow-forms.module';
import { ConfirmComponent } from './shared/services/dialog/confirm/confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NotificationsComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,

    LabBookingListComponent,
    LabReportDetailComponent,
    LabReportsComponent,
    BusinessUnitComponent,
    BusinessUnitListComponent,

    AddBusinessUnitLocationComponent,
    BusinessUnitLocationListComponent,

    RegionListComponent,
    AddRegionComponent,

    TestComponent,
    TodoListComponent,
    CallLogListComponent,
    SampleCreateComponent,
    LabBookingDetailComponent,
    DeliveryCreateComponent,
    LabReportCreateComponent,
    InvestigationCreateComponent,
    InvestigationListComponent,
    InvestigationEditComponent,
    LabBookingEditComponent,
    LabDispatchCreateComponent,
    LabPackageListComponent,
    LabPackageCreateComponent,
    NotFoundComponent,
    PrescriptionRefillListComponent,
    ConsumerMessageListComponent,
    BookedInvestigationsComponent,
    SamplesCollectedComponent,
    DeliveryDetailComponent,
    LabReportComponent,
    BookingLogsComponent,
    DispatchDetailsComponent,
    MessageComponent,
    ConversationBoxComponent,
    LabPackageDetailsComponent,
    VaccinationRefillsComponent,
    PhysiotherapyComponent,
    DiagnosisListComponent,
    DiagnosisFormComponent,
    EmrSettingsListComponent,
    MedicationListComponent,
    MedicationFormComponent,
    ImmunizationListComponent,
    ImmunizationFormComponent,
    SpecialityListComponent,
    SpecialityFormComponent,
    DiagnosisCenterListComponent,
    DiagnosisCenterFormComponent,
    PaymentHistoryComponent,
    UploadCsvFormComponent,
    InvestigationDetailComponent,
    PaymentHistoryDetailComponent,
    LabReceivedCreateComponent,
    LabReceivedDetailComponent,
    LabReceivedCreateComponent,
    MyAppointmentListComponent,
    AppointmentCardComponent,
    MyScheduleComponent,
    ScheduleCardComponent,
    LabReceivedDetailComponent,
    LabReceivedCreateComponent,
    StaffFormComponent,
    StaffListComponent,
    DoctorListComponent,
    DoctorFormComponent,
    DoctorFeedbackComponent,
    InquiryFormComponent,
    InquiryHeaderComponent,
    InquirySidebarComponent,
    InquiryFirstStepComponent,
    InquirySecondStepComponent,
    InquiryThirdStepComponent,
    InquiryFourthStepComponent,
    InquiryFifthStepComponent,
    SubscriptionFormComponent,
    SubscriptionHeaderComponent,
    SubscriptionSidebarComponent,
    SubscriptionFirstStepComponent,
    SubscriptionSecondStepComponent,
    SubscriptionThirdStepComponent,
    SubscriptionFourthStepComponent,
    InstantPayComponent,
    InstantPayHeaderComponent,
    InstantPaySidebarComponent,
    InstantPayFirstStepComponent,
    InstantPaySecondStepComponent,
    InquiryFifthStepComponent,
    DoctorFormComponent,
    ServiceTypeListComponent,
    ServiceTypeFormComponent,
    ServiceManagementListComponent,
    ServiceManagementCreateComponent,
    ServiceManagementEditComponent,
    TermsConditionsDetailsComponent,
    TermsConditionsFormComponent,
    ServiceBookingsComponent,
    MyWorkSheetComponent,
    NurseWorksheetComponent,
    CallCenterComponent,
    PhysiotherapyListComponent,
    PhysiotherapyFormComponent,
    CallCenterDetailComponent,
    PhysiotherapyDetailComponent,
    ServiceBookingDetailComponent,
    CallQueryCreateComponent,
    CallQueryListComponent,
    CallQueryDetailComponent,
    PrintComponent,
    MedicationDetailComponent,
    PrescriptionRefillDetailComponent,
    BannerComponent,
    BannerFormComponent,
    CallLogComponent,
    UploadCsvComponent,
    DashboardTotalCardComponent,
    DashboardGraphComponent,
    DateFilterComponent,
    isAlphaNumeric
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    InterceptorModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatDividerModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    MatPasswordStrengthModule.forRoot(),
    MatSnackBarModule,
    MatTableModule,
    NgxMatIntlTelInputModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatExpansionModule,
    PatientsModule,
    MatDialogModule,
    UsersModule,
    ProfilesModule,
    MatListModule,
    SweetAlert2Module.forRoot(),
    SharedModule,
    VideoChatModule,
    AuthModule,
    PasswordModule,
    RegistrationModule,
    MatProgressSpinnerModule,
    TwilioVideoModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    ChartModule,
    ToastModule,
    WorkflowFormsModule
  ],
  providers: [CookieService, MatDatepickerModule, AuthGuard, SocketService],
  bootstrap: [AppComponent],
  entryComponents: [
    DiagnosisFormComponent,
    MedicationFormComponent,
    ImmunizationFormComponent,
    SpecialityFormComponent,
    DiagnosisCenterFormComponent,
    UploadCsvFormComponent,
    StaffFormComponent,
    DoctorFormComponent,
    ServiceTypeFormComponent,
    TermsConditionsFormComponent,
    PhysiotherapyFormComponent,
    BannerFormComponent,
    UploadCsvComponent,
    ConfirmComponent
  ]
})
export class AppModule {}
