import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideoChatRoutingModule } from './video-chat-routing.module';
import { VideoChatComponent } from './video-chat-component/video-chat.component';

@NgModule({
  declarations: [VideoChatComponent],
  imports: [CommonModule, VideoChatRoutingModule],
  exports: [VideoChatComponent]
})
export class VideoChatModule {}
