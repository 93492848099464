import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
  HostListener
} from '@angular/core';
import { CallService } from '../service/call.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { ToastMessageService } from 'src/app/shared/services/toast-message-service/toast-message.service';
import { UserService } from '../service/user.service';
import { VideoChatRouterParams, STARTCALL, ACCEPTCALL } from 'src/app/shared/types/custom-types';
import { SharedConstant } from 'src/app/shared/constant/shared-constant';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConnectyCube, Session } from '../../type/connectycube';
import { AuthService } from '../../auth/shared/services/auth.service';
import { environment } from '../../../environments/environment';

interface CallEmitterType {
  type: string;
  value?: boolean;
  data?: any;
}

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.css']
})
export class VideoChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription;
  private queryParamsSubscription: Subscription;
  routeParams: string;
  queryParams: any;

  audio = new Audio();
  // const iOS = Window.device.platform === "iOS";
  iOS = false;
  remoteStream: [];
  localStream: any;
  opponents: Array<any>;
  // private _session?: Session;
  isAudioMute: boolean;
  isVideoOff: boolean;
  activeDeviceId: any;

  mediaParams = {
    audio: true,
    video: true,
    elementId: 'localStream',
    options: {
      muted: true,
      mirror: true
    }
  };

  // reference
  @ViewChild('videoChatStreamLoader', { static: false }) $videoChatStreamsLoader: ElementRef;
  @ViewChild('opponentContent', { static: false }) $opponentContent: ElementRef;

  opponentPatientId: number;

  constructor(
    private callService: CallService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.isAudioMute = false;
    this.dragElement(document.getElementById('video-right') as HTMLElement);
  }

  dragElement(element: HTMLElement) {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;

    element.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:

      const newTop = element.offsetTop - pos2;
      const newLeft = element.offsetLeft - pos1;

      element.style.top = (newTop > 0 ? newTop : 0) + 'px';
      element.style.left = (newLeft > 0 ? newLeft : 0) + 'px';
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  dragMouseDown(element: HTMLElement) {}

  ngAfterViewInit() {
    this.checkRouteParams();
    this.eventEmitterListener();
    this.getPatientID();
  }

  getPatientID(): void {
    this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      if (queryParams) {
        const patientList = JSON.parse(queryParams.patientDetailList);
        if (patientList.length > 0) {
          this.opponentPatientId = patientList[0].patient_id;
        } else {
          this.opponentPatientId = 0;
        }
      }
    });
  }

  pushNotification(opponentIds, session: Session) {
    const callerName = this.callService._getUserById(session.currentUserID, 'name');
    const sessionID = session.ID;
    const answerTimeInterval = 60;
    const opponents = opponentIds;
    const payload = {
      message: `You missed a call from ${callerName}`,
      push_notification_type: 2,
      notification_type: sessionID,
      answer_timeout: answerTimeInterval
    };
    const pushParameters = {
      user: { ids: opponents },
      environment: environment.production ? 'production' : 'development',
      notificationType: 'push',
      message: this.auth.connectyCubeClient.pushnotifications.base64Encode(JSON.stringify(payload))
    };
    this.auth.connectyCubeClient.pushnotifications.events
      .create(pushParameters)
      .then((result) => {})
      .catch((error) => {});
  }

  startCall = (opponents) => {
    const options = {};
    const opponentsIds = opponents.map((v) => v.id);

    const type = this.auth.connectyCubeClient.videochat.CallType.VIDEO; // AUDIO is also possible

    if (opponentsIds.length > 0) {
      this.callService.setUsers(opponents);
      this.onDialingSound();
      this.addStreamElements(opponents);
      this.callService.startCall(opponentsIds, type, options);
      this.callService._session.getUserMedia(this.mediaParams).then((stream) => {
        this.callService._session.call({});
        this.pushNotification(opponentsIds, this.callService._session);
        this.callService.initiateVoIPSession(this.callService._session);
        this.setActiveDeviceId(stream);
        this._prepareVideoElement('localStream');
        console.log('HAH: this.startCall._session', this.callService._session);
      });
    } else {
      // this.showSnackbar("Select at less one user to start Videocall");
      this.toastMessageService.setMessage(
        SharedConstant.info,
        'Select at less one user to start Videocall'
      );
    }
  };

  checkRouteParams(): void {
    this.subscription = this.activatedRoute.params.subscribe((params: Params) => {
      console.log('HAH: params', params.call);
      switch (params.call) {
        case STARTCALL:
          console.log(STARTCALL);
          this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
            (queryParams: Params) => {
              if (queryParams) {
                const patientList = JSON.parse(queryParams.patientDetailList);
                if (patientList.length > 0) {
                  console.log(patientList);
                  this.startCall(patientList);
                } else {
                  this.router.navigate(['/dashboard']);
                  console.log('HAH: patientDetailList invalid');
                }
              }
            }
          );
          break;
        case ACCEPTCALL:
          console.log(ACCEPTCALL);
          this.acceptCall();
          break;
        default:
          // if (this.callService._session) {
          //   this.stopCall();
          // }
          // this.router.navigate(['/dashboard']);
          break;
      }
    });
  }

  onDialingSound(): void {
    this.audio.src = '../../../assets/audio/video-chat/dialing.mp3';
    this.audio.load();
    this.audio.play();
  }

  acceptCall(): any {
    if (this.callService._session) {
      const extension = {};
      const { opponentsIDs, initiatorID, currentUserID } = this.callService._session;
      const opponentsIds = [initiatorID, ...opponentsIDs].filter(
        (userId) => currentUserID !== userId
      );
      this.opponents = opponentsIds.map((id) => ({ id }));
      this.addStreamElements(this.opponents);
      this.callService._session.getUserMedia(this.mediaParams).then((stream) => {
        console.log('HAH:video stream ', stream);
        this.callService._session.accept(extension);
        this.setActiveDeviceId(stream);
        this._prepareVideoElement('localStream');
      });
      this.opponentPatientId = this.callService.users[this.opponents[0].id].patient_id;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  addStreamElements(opponents) {
    let opponentHtml = '';
    opponents.forEach((opponent) => {
      opponentHtml += `
      <div id="loader-${opponent.id}">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </div>
      <video id="${opponent.id}" style=" width: 100%; transform: scaleX(-1)" data-id="${opponent.id}"></video>`;
    });
    this.$opponentContent.nativeElement.insertAdjacentHTML('beforeend', opponentHtml);
  }

  setActiveDeviceId = (stream) => {
    if (stream && !this.iOS) {
      const videoTracks = stream.getVideoTracks();
      const videoTrackSettings = videoTracks[0].getSettings();

      this.activeDeviceId = videoTrackSettings.deviceId;
    }
  };

  _prepareVideoElement = (videoElement) => {
    const $video = document.getElementById(videoElement);

    $video.style.visibility = 'visible';

    if (this.iOS) {
      document.getElementById('videochat').style.background = 'transparent';
      $video.style.backgroundColor = '';
      $video.style.zIndex = '-1';
    }
  };

  stopCall(userId?: number, session?: Session) {
    if (this.callService._session) {
      this.audio.src = '../../../assets/audio/video-chat/end_call.mp3';
      this.audio.load();
      this.audio.play();
      this.callService.stopCall();
      this.router.navigate(['/dashboard']);
    }
    this.router.navigate(['/dashboard']);
  }

  setAudioMute() {
    this.callService.setAudioMute();
    this.isAudioMute = this.callService.isAudioMuted;
  }

  setVideoOff() {
    this.callService.setVideoOff();
    this.isVideoOff = this.callService.isVideoOff;
  }

  eventEmitterListener() {
    this.callService.callEmitter.subscribe((payload: CallEmitterType) => {
      // @ts-ignore
      const { userId, session, videoElement } = payload.data || {};
      switch (payload.type) {
        case 'stopCall':
          this.stopCall(userId, session);
          break;
        case 'videochat-stream-loader':
          //
          // setTimeout(() => {
          //   if (this.$videoChatStreamsLoader.nativeElement) {
          //     this.$videoChatStreamsLoader.nativeElement.remove();
          //   }
          // }, 3000);
          break;
        case 'prepareVideoElement':
          this._prepareVideoElement(videoElement);
          break;
        case 'acceptCall':
          this.acceptCall();
          break;
        case 'dialing':
          this.audio.pause();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.stopCallOnComponentDestroy();
  }

  @HostListener('window:beforeunload', ['$event']) onWindowClose(event: any) {
    return confirm('Are you sure you want to leave the page?');
  }

  stopCallOnComponentDestroy(): void {
    if (this.callService._session) {
      this.stopCall();
    }
    this.audio.pause();
    if (this.subscription && this.queryParamsSubscription) {
      this.subscription.unsubscribe();
      this.queryParamsSubscription.unsubscribe();
    }

    this.router.navigate(['/dashboard']);
  }
}
