import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabPackageService {
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  store(data) {
    const url = this.apiUrl + 'lab-packages';
    return this.http.post(url, data, this.httpOptions);
  }

  update(data, labPackageId) {
    const url = this.apiUrl + 'lab-packages/' + labPackageId;
    return this.http.put(url, data, this.httpOptions);
  }
  getLabPackageList(pageNo, limit, q, status): Observable<any> {
    const url = this.apiUrl + 'lab-packages';
    if (!q) {
      return this.http.get<any>(url, { params: { pageNo, limit, status } });
    } else {
      return this.http.get<any>(url, { params: { pageNo, limit, q, status } });
    }
  }

  updateLabPackageStatus(labPackageId, data) {
    return this.http.put(`${this.apiUrl}lab-packages/${labPackageId}/update-status`, data);
  }

  getLabPackage(labPackageId): Observable<any> {
    const url = this.apiUrl + 'lab-packages/' + labPackageId;
    return this.http.get<any>(url);
  }
  delete(labPackageId) {
    const url = this.apiUrl + 'lab-packages/' + labPackageId;
    return this.http.delete<any>(url);
  }
}
