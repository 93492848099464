import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getTerms() {
    return this.http.get(`${this.apiUrl}terms-and-conditions`);
  }

  save(content): Observable<any> {
    return this.http.post(`${this.apiUrl}terms-and-conditions`, content, this.httpOptions);
  }
}
