import { Component, OnInit } from '@angular/core';
import { Profile } from '../../shared/model/profile';
import { Observable } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserServiceService } from '../../services/user-service.service';
import { ProfileService } from '../../services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { StorageService } from '../../services/storage.service';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  NAME_REGEX = '^[a-zA-Z. ]+$';
  REGD_NUM_REGEX = '^[a-zA-Z0-9-]*$';
  JOB_TITLE_REGEX = '^[a-zA-Z0-9. ]+$';
  showSuggestionForm: boolean;
  showSuggestionConsultation: boolean;
  specialitySelected: boolean;
  consultationSelected: boolean;
  arr = [];
  arrConsultation = [];
  specialities = [];
  consultation_types = [];
  search: string;
  searchConsultation: string;
  MOBILE_PATTERN = '^\\d{10}$';
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;

  BIOGRAPHY_MAX_LENGTH = 65535;
  SPECIALITY_MAX_LENGTH = 255;

  MIN_CONSULTATION_FEE = 0;
  consultationFee: number;
  userId: number;
  userDetail: any;
  loading: boolean;
  backendExistMobile = [];
  profileList: Array<Profile> = [];
  specialityList: string[] = [];
  consultationTypeList: string[] = [];
  filteredSpecialityList: Observable<string[]>;
  speciality = [];
  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'pattern',
      message: 'Enter a valid Full Name'
    },
    {
      name: 'name',
      error_type: 'required',
      message: 'Full Name is required'
    },
    {
      name: 'name',
      error_type: 'maxLength',
      message: 'Name can be no more than 255 characters'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'maxLength',
      message: 'Email can be no more than 255 characters'
    },
    {
      name: 'regdNumber',
      error_type: 'minLength',
      message: 'License Number should be of minimum 4 characters'
    },
    {
      name: 'regdNumber',
      error_type: 'pattern',
      message: 'Enter a valid License Number'
    },
    {
      name: 'ein',
      error_type: 'minLength',
      message: 'EIN should be of minimum 4 characters'
    },
    {
      name: 'ein',
      error_type: 'pattern',
      message: 'Enter a valid EIN'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'speciality',
      error_type: 'maxLength',
      message: 'Speciality can be no more than ' + this.SPECIALITY_MAX_LENGTH + ' characters'
    },
    {
      name: 'biography',
      error_type: 'maxLength',
      message: 'Speciality can be no more than ' + this.BIOGRAPHY_MAX_LENGTH + ' characters'
    },
    {
      name: 'consultation_fee',
      error_type: 'min',
      message: 'Consultation Fee can be minimum of Rs ' + this.MIN_CONSULTATION_FEE
    },
    {
      name: 'gender',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'dob',
      error_type: 'required',
      message: 'Date of Birth is required'
    },
    {
      name: 'address',
      error_type: 'required',
      message: 'Address is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'regd_number',
      error: false
    },
    {
      controlName: 'status',
      error: false
    },
    {
      controlName: 'cv',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'speciality',
      error: false
    },
    {
      controlName: 'biography',
      error: false
    },
    {
      controlName: 'ein',
      error: false
    },
    {
      controlName: 'consultation_fee',
      error: false
    },
    {
      controlName: 'gender',
      error: false
    },
    {
      controlName: 'dob',
      error: false
    },
    {
      controlName: 'address',
      error: false
    }
  ];

  // For Form Footer
  cancelUrl = '/users/profile-detail';
  submitInProgress = false;

  formSubmitted = false;

  userForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(this.NAME_REGEX),
      Validators.maxLength(255)
    ]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    mobile: new FormControl('', [
      Validators.required,
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    regdNumber: new FormControl('', [
      Validators.minLength(4),
      Validators.pattern(this.REGD_NUM_REGEX)
    ]),
    ein: new FormControl('', [Validators.minLength(4), Validators.pattern(this.REGD_NUM_REGEX)]),
    consultation_fee: new FormControl({ value: '', disabled: true }, [
      Validators.min(this.MIN_CONSULTATION_FEE)
    ]),
    gender: new FormControl('male', [Validators.required]),
    biography: new FormControl('', [Validators.maxLength(this.BIOGRAPHY_MAX_LENGTH)]),
    dob: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required])
  });

  loggedInUser;

  constructor(
    private userService: UserServiceService,
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.getProfileList();
    this.getSpecialityList();
    this.getConsultationTypeList();
    this.getUserDetail();
  }

  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }
  getSpecialityList() {
    this.submitInProgress = true;
    this.userService.getSpecialityList().subscribe(
      (res) => {
        this.specialityList = res;
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getConsultationTypeList() {
    this.submitInProgress = true;
    this.userService.getConsulationTypeList().subscribe(
      (res) => {
        this.consultationTypeList = res.data;
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  formatSpeciality(specialityList) {
    const speciality = [];
    if (specialityList) {
      specialityList.forEach((spe) => {
        speciality.push(spe.name);
      });
    }
    return speciality;
  }
  getProfileList() {
    this.submitInProgress = true;
    this.profileService.getProfiles().subscribe(
      (res) => {
        this.profileList = res.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getUserDetail() {
    this.loading = true;
    this.submitInProgress = true;
    this.userService.getProfile().subscribe(
      (response: any) => {
        this.userDetail = response;
      },
      (error) => {
        this.submitInProgress = false;
        this.loading = false;
      },
      () => {
        this.patchUserDefaults(this.userDetail);
        this.submitInProgress = false;
        this.loading = false;
      }
    );
  }

  patchUserDefaults(userDetail) {
    this.userForm.patchValue({
      name: userDetail.name,
      email: userDetail.email,
      mobile: userDetail.mobile,
      consultation_fee: userDetail.consultation_fee,
      regdNumber: userDetail.regd_number,
      ein: userDetail.ein,
      gender: userDetail.gender,
      biography: userDetail.bio,
      dob: userDetail.dob,
      address: userDetail.address
    });
    this.consultationFee = userDetail.consultation_fee;
    this.specialities = userDetail.specialities;
    this.specialitySelected = this.specialities.length === 0 ? false : true;
    this.consultation_types = userDetail.consultation_types;
    this.consultationSelected = this.consultation_types.length === 0 ? false : true;
  }

  updateUser() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (
      this.userForm.status === 'VALID' &&
      !this.alreadyExistMobileValue('mobile') &&
      this.consultationSelected &&
      this.specialitySelected
    ) {
      const requestData = this.createRequestData({
        name: this.userForm.controls.name.value,
        email: this.userForm.controls.email.value,
        mobile: this.userForm.controls.mobile.value ? this.userForm.controls.mobile.value : '',
        regd_number: this.userForm.controls.regdNumber.value
          ? this.userForm.controls.regdNumber.value
          : '',
        ein: this.userForm.controls.ein.value ? this.userForm.controls.ein.value : '',
        consultation_fee: this.userForm.controls.consultation_fee.value
          ? this.userForm.controls.consultation_fee.value
          : this.consultationFee,
        gender: this.userForm.controls.gender.value,
        dob: moment(this.userForm.controls.dob.value).format('YYYY-MM-DD'),
        address: this.userForm.controls.address.value,
        biography: this.userForm.controls.biography.value
      });

      this.specialities.forEach((speciality, index) => {
        requestData.append('speciality_id[' + index + ']', speciality.id);
      });

      this.consultation_types.forEach((consultation_type, index) => {
        requestData.append('consultation_type_id[' + index + ']', consultation_type.id);
      });

      this.userService.updateProfile(requestData).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          if (error.status === 422 && error.error.errors.email) {
            this.toastr.error(error.error.errors.email[0]);
          } else {
            this.toastr.error(error.error.message);
          }
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/users/profile-detail']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }
  removeSpeciality(speciality) {
    this.specialities = this.specialities.filter((item) => {
      return item.id !== speciality.id;
    });
    this.specialitySelected = this.specialities.length === 0 ? false : true;
  }

  removeConsultationType(consultation_type) {
    this.consultation_types = this.consultation_types.filter((item) => {
      return item.id !== consultation_type.id;
    });
    this.consultationSelected = this.consultation_types.length === 0 ? false : true;
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.profile) {
      this.setBackendValidationError('profile', errors.profile[0]);
    } else {
      this.unsetBackendValidationError('profile');
    }
    if (errors.regd_number) {
      this.setBackendValidationError('regd_number', errors.regd_number[0]);
    } else {
      this.unsetBackendValidationError('regd_number');
    }
    if (errors.ein) {
      this.setBackendValidationError('ein', errors.ein[0]);
    } else {
      this.unsetBackendValidationError('ein');
    }
    if (errors.job_title) {
      this.setBackendValidationError('job_title', errors.job_title[0]);
    } else {
      this.unsetBackendValidationError('job_title');
    }
    if (errors.joined_date) {
      this.setBackendValidationError('joined_date', errors.joined_date[0]);
    } else {
      this.unsetBackendValidationError('joined_date');
    }
    if (errors.status) {
      this.setBackendValidationError('status', errors.status[0]);
    } else {
      this.unsetBackendValidationError('status');
    }
    if (errors.cv) {
      this.setBackendValidationError('cv', errors.cv[0]);
    } else {
      this.unsetBackendValidationError('cv');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
      this.addBackendExistMobile();
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.speciality) {
      this.setBackendValidationError('speciality', errors.speciality[0]);
    } else {
      this.unsetBackendValidationError('speciality');
    }
    if (errors.biography) {
      this.setBackendValidationError('biography', errors.biography[0]);
    } else {
      this.unsetBackendValidationError('biography');
    }
    if (errors.profile_image) {
      this.setBackendValidationError('profile_image', errors.profile_image[0]);
    } else {
      this.unsetBackendValidationError('profile_image');
    }
    if (errors.consultation_fee) {
      this.setBackendValidationError('consultation_fee', errors.consultation_fee[0]);
    } else {
      this.unsetBackendValidationError('consultation_fee');
    }
    if (errors.gender) {
      this.setBackendValidationError('gender', errors.gender[0]);
    } else {
      this.unsetBackendValidationError('gender');
    }
    if (errors.dob) {
      this.setBackendValidationError('dob', errors.dob[0]);
    } else {
      this.unsetBackendValidationError('dob');
    }
    if (errors.address) {
      this.setBackendValidationError('address', errors.address[0]);
    } else {
      this.unsetBackendValidationError('address');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  changeDate(dateVal) {
    return moment(dateVal).format('YYYY-MM-DD');
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
  addBackendExistMobile() {
    if (!this.backendExistMobile.includes(this.userForm.controls.mobile.value)) {
      this.backendExistMobile.push(this.userForm.controls.mobile.value);
    }
  }
  alreadyExistMobileValue(keyName) {
    if (this.backendExistMobile.includes(this.userForm.controls.mobile.value)) {
      this.setBackendValidationError(keyName, 'Mobile Number is already taken');
      return true;
    } else {
      this.unsetBackendValidationError(keyName);
      return false;
    }
  }

  selectSpeciality(speciality) {
    const spec = this.specialities.find((x) => x.id === speciality.id);
    if (spec) {
      this.toastr.warning('Speciality already exist');
    } else {
      this.specialities.push(speciality);
    }
    this.search = '';
    this.searchConsultation = '';
    this.specialitySelected = this.specialities.length === 0 ? false : true;
    this.showSuggestionForm = false;
  }

  selectConsultationtType(consultation_type) {
    const consultation = this.consultation_types.find((x) => x.id === consultation_type.id);
    if (consultation) {
      this.toastr.warning('Conslutation Type already exist');
    } else {
      this.consultation_types.push(consultation_type);
    }
    this.searchConsultation = '';
    this.consultationSelected = this.consultation_types.length === 0 ? false : true;
    this.showSuggestionConsultation = false;
  }

  showSuggestions(e) {
    if (this.search.length === 0) {
      this.specialitySelected = true;
      this.showSuggestionForm = false;
    } else {
      this.specialitySelected = false;
      this.showSuggestionForm = true;
    }

    this.arr = this.specialityList.filter((x: any) => {
      return x.name.includes(this.search);
    });
    this.specialities.forEach((speciality) => {
      this.arr = this.arr.filter((x) => {
        return x.id !== speciality.id;
      });
    });
  }

  showConsultationSuggestion(e) {
    if (this.searchConsultation.length === 0) {
      this.consultationSelected = true;
      this.showSuggestionConsultation = false;
    } else {
      this.consultationSelected = false;
      this.showSuggestionConsultation = true;
    }
    this.arrConsultation = this.consultationTypeList.filter((x: any) => {
      return x.name.toLowerCase().includes(this.searchConsultation.toLowerCase());
    });
    this.consultation_types.forEach((consultation_type) => {
      this.arrConsultation = this.arrConsultation.filter((x) => {
        return x.id !== consultation_type.id;
      });
    });
  }
}
