import { Component, OnInit } from '@angular/core';
import { BusinessUnitService } from '../services/business-unit.service';

@Component({
  selector: 'app-business-unit-list',
  templateUrl: './business-unit-list.component.html',
  styleUrls: ['./business-unit-list.component.css']
})
export class BusinessUnitListComponent implements OnInit {
  businessUnits: any = [];
  displayedColumns: string[] = ['id', 'name', 'email', 'operating_country'];

  constructor(private businessUnitService: BusinessUnitService) {}

  ngOnInit() {
    this.businessUnitService.list().subscribe((res: any) => {
      if (res.data) {
        this.businessUnits = res.data;
      }
    });
  }
}
