import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  searchUser: string;
  showSuggestionForm = false;
  arr = [];
  userMessageListStatus = false;
  callStatus = true;
  replyStatus = true;
  limit = 10;
  pageNo = 1;
  userMessageList = [];
  selectedUserMessage;
  opponent_id;
  constructor(
    private messageService: MessageService,
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getMessageList();
  }

  onElementScroll() {
    if (
      this.myScrollContainer.nativeElement.scrollTop +
        this.myScrollContainer.nativeElement.offsetHeight >
        this.myScrollContainer.nativeElement.scrollHeight &&
      this.callStatus
    ) {
      this.getMessageList();
    }
  }

  replyStatusClicked() {
    this.replyStatus = !this.replyStatus;
  }

  getMessageList() {
    this.messageService.getMessageList(this.pageNo, this.limit, this.opponent_id).subscribe(
      (messageList) => {
        console.log('MessageList here -->', messageList);
        this.userMessageListStatus = true;
        for (let message of messageList.data) {
          this.userMessageList.push(message);
        }
        if (messageList.data.length === this.limit) {
          this.pageNo++;
        } else {
          this.callStatus = false;
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.opponent_id = this.activatedRoute.snapshot.queryParamMap.get('opponent_id');
        this.selectedUserMessagefromParams();
      }
    );
  }
  getLoggedInUserId() {
    const loggedInUser = JSON.parse(this.storageService.getLoggedInUser());
    return loggedInUser.id;
  }
  selectedUserMessagefromParams() {
    const userMessageSelect = this.userMessageList.find((userMessage) => {
      return (
        userMessage.sender_id == this.opponent_id || userMessage.receiver_id == this.opponent_id
      );
    });
    this.selectedMessage(userMessageSelect);
  }
  checkActive(userMessage) {
    if (this.selectedUserMessage === userMessage) {
      return true;
    }
    return false;
  }

  showSuggestions(e) {
    if (this.searchUser.length === 0) {
      this.showSuggestionForm = false;
    } else {
      this.showSuggestionForm = true;
    }

    this.arr = this.userMessageList.filter((x) => {
      return x.opponent_name.toLowerCase().includes(this.searchUser.toLowerCase());
    });
  }
  selectedMessage(userMessage) {
    this.selectedUserMessage = userMessage;
    if (!userMessage.is_type_sent) {
      var request = {
        id: [userMessage.id],
        status: 'Seen'
      };
      this.messageService.updateStatus(request).subscribe(
        (res: any) => {
          console.log(res.data);
        },
        (err) => {
          console.log(err);
        },
        () => {}
      );
    }
    this.showSuggestionForm = false;
    this.searchUser = '';
    this.setUrlsParams();
  }

  setUrlsParams() {
    this.opponent_id =
      this.getLoggedInUserId() === this.selectedUserMessage.sender_id
        ? this.selectedUserMessage.receiver_id
        : this.selectedUserMessage.sender_id;
    const queryParams = {
      opponent_id: this.opponent_id
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
  clearSearch() {
    this.searchUser = null;
    this.pageNo = 1;
    this.limit = 10;
    this.getMessageList();
  }
  checkSeenStatus(userMessage) {
    if (userMessage.status == 'Seen' || userMessage.is_type_sent === true) {
      return true;
    }
    return false;
  }
}
