import { Component, ViewChild, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import {
  Room,
  LocalTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteParticipant,
  LocalDataTrack
} from 'twilio-video';
import { RoomComponent } from '../room/room.component';
import { CameraComponent } from '../camera/camera.component';
import { SettingsComponent } from '../settings/settings.component';
import { ParticipantComponent } from '../participant/participant.component';
import { RoomDetails, VideoChatService } from '../../services/video-chat.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '../../services/device.service';
import { SocketService } from '../../services/socket.service';
import { Time } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('rooms', { static: false }) rooms: RoomComponent;
  @ViewChild('camera', { static: false }) camera: CameraComponent;
  @ViewChild('settings', { static: false }) settings: SettingsComponent;
  @ViewChild('participants', { static: false }) participants: ParticipantComponent;
  appSettingHidden: boolean = false;
  appointmentId;
  patientId;
  opponentPatientId;
  activeRoom: Room;
  info: RoomDetails;
  dataTrack: LocalDataTrack;
  isAudioEnabled: boolean = true;
  isVideoEnabled: boolean = true;

  ss: number = 0;
  mm: number = 0;
  hh: number = 0;
  timerId;

  constructor(
    private readonly videoChatService: VideoChatService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private socketService: SocketService
  ) {}

  async ngOnInit() {
    this.appointmentId = this.activatedRoute.snapshot.queryParamMap.get('appointment_id');
    this.patientId = this.activatedRoute.snapshot.queryParamMap.get('patient_id');
    this.videoChatService.getCallRejectStatus().subscribe((res) => {
      this.onLeaveRoom();
    });
    //await this.prepareCall();

    // const builder = new HubConnectionBuilder()
    //   .configureLogging(LogLevel.Information)
    //   .withUrl(`${location.origin}/notificationHub`);
    //
    // this.notificationHub = builder.build();
    // this.notificationHub.on('RoomsUpdated', async (updated) => {
    //   if (updated) {
    //     await this.rooms.updateRooms();
    //   }
    // });
    // await this.notificationHub.start();
  }

  clickHandler() {
    this.timerId = setInterval(() => {
      this.ss++;

      if (this.ss >= 60) {
        this.mm++;
        this.ss = 0;
      }
      if (this.mm >= 60) {
        this.hh++;
        this.mm = 0;
      }
    }, 1000);
  }

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
    const localTrack = await this.camera.initializePreview(deviceInfo);
    if (this.activeRoom) {
      await this.activeRoom.localParticipant.publishTracks(localTrack);
    }
  }
  getPatientId(opponentId) {
    this.videoChatService.getpatientId(opponentId).subscribe((res) => {
      this.opponentPatientId = res ? res[0].id : this.patientId;
    });
  }
  async onLeaveRoom() {
    /*const videoDevice = this.settings.hidePreviewCamera();
    await this.camera.initializePreview(videoDevice);*/
    if (this.activeRoom) {
      this.sendEvent({ room: this.activeRoom.name, type: 'CALL_DISCONNECT' });
      this.activeRoom.disconnect();
      this.activeRoom = null;
    }
    this.participants.clear();
    this.router.navigate(['']);
  }
  sendEvent(payload: object) {
    if (this.dataTrack) {
      this.dataTrack.send(JSON.stringify(payload));
    }
  }
  async onRoomChanged(appointmentId: number) {
    if (appointmentId) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      this.camera.finalizePreview();
      this.dataTrack = new LocalDataTrack();
      const tracks = await this.settings.showPreviewCamera();
      tracks.push(this.dataTrack);
      const { info, room } = await this.videoChatService.joinOrCreateRoom(appointmentId, tracks);
      this.activeRoom = room;
      this.info = info;
      this.getPatientId(this.info.room.opponents[0].id);
      if (this.activeRoom.participants.size === 0) {
        if (this.info.room) {
          this.socketService.emit(SocketService.events.EVENT_ROOM_INVITE, {
            ...this.info.room,
            callType: 'CALL_STARTED'
          });
        }
      }
      this.participants.initialize(this.activeRoom.participants);
      this.registerRoomEvents();

      //this.notificationHub.send('RoomsUpdated', true);
    }
  }
  toggleAudio() {
    if (this.activeRoom) {
      this.isAudioEnabled = !this.isAudioEnabled;
      this.activeRoom.localParticipant.audioTracks.forEach((track) => {
        track.track.enable(this.isAudioEnabled);
      });
    }
  }

  toggleVideo() {
    if (this.activeRoom) {
      this.isVideoEnabled = !this.isVideoEnabled;
      this.activeRoom.localParticipant.videoTracks.forEach((track) => {
        track.track.enable(this.isVideoEnabled);
      });
      this.settings.showOrHideImage(this.isVideoEnabled, this.info.room.initiator);
    }
  }
  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected', (room: Room) =>
        room.localParticipant.tracks.forEach((publication) => {
          console.log(publication);
          this.detachLocalTrack(publication.track);
        })
      )
      .on('participantConnected', (participant: RemoteParticipant) => {
        console.log('participantConnected', participant.sid);
        this.participants.add(participant);
      })
      .on('participantDisconnected', (participant: RemoteParticipant) => {
        this.participants.remove(participant);
        this.router.navigate(['']);
      })
      .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) =>
        this.participants.loudest(dominantSpeaker)
      );
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
  }
  joinRoom() {
    this.onRoomChanged(this.appointmentId);
    this.appSettingHidden = true;
    this.clickHandler();
  }
  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return (
      !!track &&
      ((track as LocalAudioTrack).detach !== undefined ||
        (track as LocalVideoTrack).detach !== undefined)
    );
  }
}
