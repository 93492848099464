export const ModalConstant = {
  modalMessage: {
    header: 'Video Chat',
    message: 'Incoming call from '
  },

  modalButton: {
    reject: 'Reject',
    accept: 'Accept',
    ok: 'Ok'
  },

  message: {
    dateTime: 'Sent Date',
    from: 'Sender',
    subject: 'Subject',
    message: 'Message'
  },

  dateFormat: {
    medium: 'medium'
  }
};
