import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CallQueryService } from 'src/app/services/call-query.service';
import { NotifyService } from 'src/app/services/notify.service';
import { UrlsService } from 'src/app/services/urls.service';
import { RouterService } from 'src/app/shared/router.service';

@Component({
  selector: 'app-call-query-create',
  templateUrl: './call-query-create.component.html',
  styleUrls: ['./call-query-create.component.css']
})
export class CallQueryCreateComponent implements OnInit {
  formSubmitted = false;
  cancelUrl = '/call-query';
  submitInProgress = false;
  selectedInvestigations: Array<any> = [];

  NAME_REGEX = '^[a-zA-Z. ]+$';
  MOBILE_PATTERN = '^([0-9]{7}|^[0-9]{10})$';
  MOBILE_MIN_LENGTH = 7;
  MOBILE_MAX_LENGTH = 10;
  EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  callQueryForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(this.NAME_REGEX),
      Validators.maxLength(255)
    ]),
    gender: new FormControl('', [Validators.required]),
    phone: new FormControl('', [
      Validators.required,
      // Validators.minLength(this.MOBILE_MIN_LENGTH),
      // Validators.maxLength(this.MOBILE_MAX_LENGTH)
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    address: new FormControl('', [Validators.required]),
    query: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    email: new FormControl('', [Validators.maxLength(255), Validators.pattern(this.EMAIL_REGEX)]),
    status: new FormControl('', [Validators.required])
  });

  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'pattern',
      message: 'Enter a valid Full Name'
    },
    {
      name: 'name',
      error_type: 'required',
      message: 'Full Name is required'
    },
    {
      name: 'name',
      error_type: 'maxLength',
      message: 'Name can be no more than 255 characters'
    },
    {
      name: 'gender',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'address',
      error_type: 'required',
      message: 'Address is required'
    },
    // {
    //   name: 'email',
    //   error_type: 'email',
    //   message: 'Enter a valid Email '
    // },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'pattern',
      message: 'Enter a valid Email e.g. ( example@mail.com )'
    },
    {
      name: 'email',
      error_type: 'maxLength',
      message: 'Email can be no more than 255 characters'
    },
    {
      name: 'status',
      error_type: 'required',
      message: 'Status is required'
    },
    {
      name: 'phone',
      error_type: 'required',
      message: 'Phone Number is required'
    },
    // {
    //   name: 'phone',
    //   error_type: 'minLength',
    //   message: 'Phone Number must be either ' + this.MOBILE_MIN_LENGTH + ' or ' + this.MOBILE_MAX_LENGTH + ' digits '
    // },
    // {
    //   name: 'phone',
    //   error_type: 'maxLength',
    //   message: 'Phone Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    // },
    {
      name: 'phone',
      error_type: 'pattern',
      message:
        'Phone Number must be either ' +
        this.MOBILE_MIN_LENGTH +
        ' or ' +
        this.MOBILE_MAX_LENGTH +
        ' digits '
    },
    {
      name: 'query',
      error_type: 'required',
      message: 'Please add your query'
    },
    {
      name: 'query',
      error_type: 'maxlength',
      message: 'You can only use 255 words maximum'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'gender',
      error: false
    },
    {
      controlName: 'address',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'phone',
      error: false
    },
    {
      controlName: 'query',
      error: false
    },
    {
      controlName: 'status',
      error: false
    }
  ];

  gender = [
    {
      slug: 'male',
      display: 'Male'
    },
    {
      slug: 'female',
      display: 'Female'
    },
    {
      slug: 'other',
      display: 'Other'
    }
  ];

  querystatus = [
    {
      slug: 'pending',
      display: 'Pending'
    },
    {
      slug: 'processed',
      display: 'Processed'
    }
  ];

  constructor(
    private callQueryService: CallQueryService,
    private router: RouterService,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }

  submit() {
    console.log(this.callQueryForm);
    this.submitInProgress = true;
    this.formSubmitted = true;
    if (this.callQueryForm.status === 'VALID') {
      const requestData = this.createRequestData({
        name: this.callQueryForm.controls.name.value,
        gender: this.callQueryForm.controls.gender.value,
        phone: this.callQueryForm.controls.phone.value,
        address: this.callQueryForm.controls.address.value,
        query: this.callQueryForm.controls.query.value,
        email: this.callQueryForm.controls.email.value,
        status: this.callQueryForm.controls.status.value
      });
      this.callQueryService.store(requestData).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          if (error.status === 422 && error.error.errors.email) {
            this.toastr.error(error.error.errors.email);
          } else {
            this.toastr.error(error.error.message);
          }
        },
        () => {
          this.submitInProgress = false;
          this.router.navigate(['/call-query']);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.status) {
      this.setBackendValidationError('status', errors.status[0]);
    } else {
      this.unsetBackendValidationError('status');
    }
    if (errors.phone) {
      this.setBackendValidationError('phone', errors.phone[0]);
    } else {
      this.unsetBackendValidationError('phone');
    }
    if (errors.gender) {
      this.setBackendValidationError('gender', errors.gender[0]);
    } else {
      this.unsetBackendValidationError('gender');
    }
    if (errors.address) {
      this.setBackendValidationError('address', errors.address[0]);
    } else {
      this.unsetBackendValidationError('address');
    }
  }
}
