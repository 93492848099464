import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabReportService } from '../../../services/lab-report.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { PageService } from '../../workflow-forms/page.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

function createValidate(diagnosedByList: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!diagnosedByList.includes(control.value)) {
      return { not_match: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-lab-report-create',
  templateUrl: './lab-report-create.component.html',
  styleUrls: ['./lab-report-create.component.css']
})
export class LabReportCreateComponent implements OnInit {
  @ViewChild('menu', {static: false}) menu: ElementRef;
  urlList: Array<string> = [];
  nextUrl: string;

  doctors1: string[] = [];
  filteredDoctors1: Observable<string[]>;

  doctors2: string[] = [];
  filteredDoctors2: Observable<string[]>;

  doctors3: string[] = [];
  filteredDoctors3: Observable<string[]>;

  // For Save and Continue
  saveAndContinueClicked = false;

  l: Array<object> = [];
  arr: Array<any>;
  testTypes: Array<object> = [];
  showSuggestionForm = false;
  showOptions = false;
  search = '';

  data: Array<object> = [];
  testType: Array<object> = [];
  form: Array<object> = [];
  isDisabled = true;

  submitted: Boolean = false;

  file_attachment_err: boolean = false;

  labList: any;

  file: File;
  patientId: number;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  investigationsSelected: boolean = false;

  labReportsForm = new FormGroup({
    reported_date: new FormControl('', [Validators.required]),
    lab_no: new FormControl('', [Validators.required]),
    registered_date: new FormControl('', [Validators.required]),
    lab_id: new FormControl('', [Validators.required]),
    conducted_by_1: new FormControl('', [Validators.required, createValidate([])]),
    conducted_by_2: new FormControl('', [Validators.required, createValidate([])]),
    conducted_by_3: new FormControl('', [Validators.required, createValidate([])])
  });
  emptySuggestion: boolean = false;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.menu.nativeElement && e.target !== this.menu.nativeElement) {
        this.showOptions = false;
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Lab Report', this.patientId);
    });
    this.getLabList();
    this.getDoctorList();

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (y: any) => {
        this.doctors1 = y;
        this.doctors2 = y;
        this.doctors3 = y;
        this.labReportsForm.controls.conducted_by_1.setValidators([
          Validators.required,
          createValidate(y || [])
        ]);
        this.labReportsForm.controls.conducted_by_2.setValidators([
          Validators.required,
          createValidate(y || [])
        ]);
        this.labReportsForm.controls.conducted_by_3.setValidators([
          Validators.required,
          createValidate(y || [])
        ]);
        this.filteredDoctors1 = this.setACField(
          this.labReportsForm.controls.conducted_by_1,
          'doctors1'
        );
        this.filteredDoctors2 = this.setACField(
          this.labReportsForm.controls.conducted_by_2,
          'doctors2'
        );
        this.filteredDoctors3 = this.setACField(
          this.labReportsForm.controls.conducted_by_3,
          'doctors3'
        );
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabListForLabReport().subscribe(
      (response: any) => {
        this.labList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.labReportService
        .getInvestigationServiceList(this.labReportsForm.controls.lab_id.value, keyword, this.l)
        .subscribe((x: any) => {
          // console.log(x);
          this.arr = x;
          this.showSuggestionForm = true;
          this.showOptions = true;
        });
    } else {
      this.showOptions = false;
      this.showSuggestionForm = false;
      this.emptySuggestion = true;
    }
  }
  showTestTypes(e, id) {
    console.log(e);
    const x = this.arr.find((y) => {
      return y.id === id;
    });

    this.showSuggestionForm = false;
    this.showOptions = false;
    this.search = '';
    this.l.push(x);

    this.labReportService.getTestTypeServiceList(id).subscribe((y: any) => {
      this.testTypes.push(y);
    });
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });

    this.showSuggestionForm = false;
    this.search = '';
    this.l.push(x);
  }

  addLabReports() {
    this.submitted = true;
    this.submitInProgress = true;
    if (this.labReportsForm.invalid) {
      this.submitInProgress = false;
      return;
    }
    this.showSuggestionForm = false;
    this.search = '';
    const data = this.labReportsForm.value;

    const formData: FormData = new FormData();
    formData.append('testType', JSON.stringify(this.testTypes));
    this.investigationsSelected = true;
    let refId: any;
    formData.append('patientId', String(this.patientId));
    refId = this.patientId;
    formData.append('lab_no', data.lab_no);
    formData.append('reported_date', moment(data.reported_date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('lab_id', data.lab_id);
    formData.append('registered_date', moment(data.registered_date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('conducted_by_1', this.labReportsForm.controls.conducted_by_1.value);
    formData.append('conducted_by_2', this.labReportsForm.controls.conducted_by_2.value);
    formData.append('conducted_by_3', this.labReportsForm.controls.conducted_by_3.value);
    formData.append('file_attachment', this.file);

    this.labReportService.add(refId, formData).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (err) => {
        this.submitInProgress = false;
        if (err.status === 422) {
          for (const error in err.error.errors) {
            // tslint:disable-next-line:triple-equals
            if (error == 'file_attachment') {
              this.file_attachment_err = err.error.errors[error];
            }
          }
        } else {
          this.toastr.error(err.error.message);
        }
      },
      () => {
        this.submitInProgress = false;
        if (this.saveAndContinueClicked) {
          this.router.navigate(['/patients/' + this.patientId]);
        } else {
          this.router.navigate([this.nextUrl]);
        }
        this.saveAndContinueClicked = false;
      }
    );
  }

  setvalue(e, x) {
    x.value = e.target.value;
  }
  setmethod(e, x) {
    x.method = e.target.value;
  }

  removeInvestigation(investigation) {
    this.testTypes.splice(this.testTypes.indexOf(investigation), 1);
    this.l.splice(this.l.indexOf(investigation), 1);
  }

  hideSuggestion(e) {
    if (e.target.value.length !== 0) {
      e.target.value = '';
      this.labReportsForm.get('investigationName').setValue('');
      this.showSuggestions(e);
      // this.showSuggestionForm = false;
    }
  }

  keyPressNumbersDecimal(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
