import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestigationService } from '../../../services/investigation.service';
import { NotifyService } from '../../../services/notify.service';
import { UrlsService } from '../../../services/urls.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UploadCsvFormComponent } from '../upload-csv-form/upload-csv-form.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-investigation-create',
  templateUrl: './investigation-create.component.html',
  styleUrls: ['./investigation-create.component.css']
})
export class InvestigationCreateComponent implements OnInit {
  diagnosticCenterId;
  formErrorObj: Array<any> = [
    {
      controlName: 'name',
      error: false
    }
  ];

  testTypes: Array<any> = [];
  cancelUrl;
  afterSaveUrl;
  investigationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    testTypes: new FormControl('')
  });

  // For Form Footer
  submitInProgress = false;

  submitted = false;
  showModal = false;
  sampleTemplateCsv = 'http://18.136.220.168:1111/InvestigationSample.csv';

  constructor(
    private router: Router,
    private investigationService: InvestigationService,
    private toastr: ToastrService,
    private urlService: UrlsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.diagnosticCenterId = +this.activatedRoute.snapshot.paramMap.get('diagnosticCenterId');
    this.cancelUrl = '/diagnostic-center/' + this.diagnosticCenterId;
    this.afterSaveUrl = '/diagnostic-center/' + this.diagnosticCenterId;

    if (this.urlService.getPreviousUrl() === '/lab-bookings/create') {
      this.cancelUrl = '/lab-bookings/create';
      this.afterSaveUrl = '/lab-bookings/create';
    }
    console.log('Submitted check -->', this.submitted);
  }

  addTestType(event) {
    event.preventDefault();
    if (this.isTestListValid()) {
      const test = {
        name: '',
        price: '',
        prerequisite: '',
        validName: false,
        validPrice: false,
        saved: false,
        saveTooltipText: 'Save test',
        touched: false
      };
      this.testTypes.push(test);
      console.log('Test Types -->', this.testTypes);
    }
  }

  removeTestType(index, event) {
    event.preventDefault();
    this.testTypes = this.testTypes.filter((testType: any) => {
      return index !== this.testTypes.indexOf(testType);
    });
  }

  storeInvestigation() {
    this.submitted = true;
    this.submitInProgress = true;
    console.log('investigation form here -->', this.testTypes.length);

    // Validate Form
    if (this.investigationForm.invalid || this.testTypes.length === 0 || !this.isTestListValid()) {
      this.submitInProgress = false;
      this.toastr.error('Test Name or Price should not be empty');
      return;
    }

    // build data
    const data = this.investigationForm.value;
    data.tests = this.testTypes;

    // push data
    this.investigationService.store(data, this.diagnosticCenterId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.submitInProgress = false;
        if (error.status === 422) {
          const errors = error.error.errors;
          if (errors.name) {
            this.setError('name', errors.name[0]);
          } else {
            this.unsetError('name');
          }
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error(error.error.message);
        }
      },
      () => {
        this.submitInProgress = false;
        this.router.navigate([this.afterSaveUrl]);
      }
    );
  }

  isTestListValid() {
    const numOfTests = this.testTypes.length;
    let numOfValidTests = 0;
    this.testTypes.forEach((testType) => {
      if (testType.name.trim() !== '' && testType.price.trim().length !== 0) {
        numOfValidTests++;
      }
    });
    return numOfValidTests === numOfTests;
  }

  isTestListTouched() {
    const numOfTests = this.testTypes.length;
    let numOfTouchedTests = 0;
    this.testTypes.forEach((testType) => {
      if (testType.touched) {
        numOfTouchedTests++;
      }
    });
    return numOfTouchedTests === numOfTests;
  }

  getFormattedTestList() {
    const testList: Array<string> = [];
    this.testTypes.forEach((testType: any) => {
      testList.push(testType.name.trim());
    });
    return testList;
  }

  toggleEditTest(test, event) {
    event.stopPropagation();
    event.preventDefault();
    if (test.name.trim().length !== 0 && test.price.trim().length !== 0) {
      test.validName = false;
      test.validPrice = false;
      test.saved = !test.saved;
      test.saveTooltipText = test.saved ? 'Edit test' : 'Save test';
    }
    if (test.name.trim().length === 0) {
      test.validName = true;
    }
    if (test.price.trim().length === 0) {
      test.validPrice = true;
    }
  }

  processKeyUp(test, event) {
    event.preventDefault();
    test.touched = true;
    if (!test.saved && event.key === 'Enter') {
      this.toggleEditTest(test, event);
      if (test.saved) {
        this.addTestType(event);
      }
    }
  }

  preventPaste(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  setError(keyName, message) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasError(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getErrorMessage(keyName) {
    const keyObject = this.formErrorObj.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  openModal(diagnosticCenterId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '300px';
    dialogConfig.data = diagnosticCenterId;

    const dialogRef = this.dialog.open(UploadCsvFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.router.navigate([this.afterSaveUrl]);
      }
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
