import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TermsAndConditionsService } from '../../../../services/terms-and-conditions.service';
import { TermsConditionsFormComponent } from '../terms-conditions-form/terms-conditions-form.component';

@Component({
  selector: 'app-terms-conditions-details',
  templateUrl: './terms-conditions-details.component.html',
  styleUrls: ['./terms-conditions-details.component.css']
})
export class TermsConditionsDetailsComponent implements OnInit {
  showModal = false;
  termsAndCondtions;
  constructor(
    private dialog: MatDialog,
    private termsAndConditionsService: TermsAndConditionsService
  ) {}

  ngOnInit() {
    this.getTermsConditions();
  }

  getTermsConditions() {
    this.termsAndConditionsService.getTerms().subscribe(
      (response: any) => {
        this.termsAndCondtions = response || { content: null };
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }
  openModal(termsAndCondtions) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '740px';
    dialogConfig.width = '70%';
    dialogConfig.data = termsAndCondtions;

    const dialogRef = this.dialog.open(TermsConditionsFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getTermsConditions();
      }
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
