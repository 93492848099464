import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KycInformationService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  storeKycInformation(data, patientId) {
    const url = this.apiUrl + patientId + '/kyc-informations';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }

  getCountryList() {
    const url = this.apiUrl + 'countries';
    return this.http.get(url);
  }

  getCountryDefaultFields() {
    const url = this.apiUrl + 'country/fields/default';
    return this.http.get(url);
  }

  getCountryFields(id: number): Observable<any> {
    const url = this.apiUrl + 'country/' + id + '/fields';
    return this.http.get(url);
  }

  getFieldOptions(fieldId: number, selectedId: number): Observable<any> {
    const url = this.apiUrl + 'country/field/' + fieldId + '/?selected=' + selectedId;
    console.log(url);
    return this.http.get(url);
  }

  getPatientKycInformation(patientId: number) {
    const url = this.apiUrl + patientId + '/kyc-informations';
    return this.http.get(url);
  }
}
