import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-inquiry-fifth-step',
  templateUrl: './inquiry-fifth-step.component.html',
  styleUrls: ['./inquiry-fifth-step.component.css']
})
export class InquiryFifthStepComponent implements OnInit {
  paymentMethod;
  formSubmitted;
  @Input() data;
  @Output() saveData = new EventEmitter();
  @Output() cancelEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.paymentMethod = this.data.paymentMethod;
  }
  selectPayment(selectedPayment) {
    this.paymentMethod = selectedPayment;
  }

  validate() {
    this.formSubmitted = true;
    if (this.paymentMethod) {
      this.saveData.emit({
        paymentMethod: this.paymentMethod
      });
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 5,
      previousStep: 4,
      data: {
        paymentMethod: this.paymentMethod
      }
    };
    this.previousEmmitter.emit(formData);
  }

  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
