import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-form-action-buttons',
  templateUrl: './form-action-buttons.component.html',
  styleUrls: ['./form-action-buttons.component.css']
})
export class FormActionButtonsComponent implements OnInit {
  SAVE_ICON = 'fa fa-save';
  SAVE_AND_CONTINUE_ICON = 'fa fa-arrow-circle-right';

  @Input() showSaveButton: Boolean = true;
  @Input() showCancelButton: Boolean = true;
  @Input() showSaveAndContinueButton: Boolean;
  @Input() showGoBackButton: Boolean = false;
  @Input() saveButtonLabel = 'Save';

  @Input() formDirty = false;
  @Input() cancelUrl: string;

  @Input() submitInProgress: Boolean;
  @Input() disabled;

  @Output() saveAndContinue: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private location: Location) {}

  ngOnInit() {}

  saveAndContinueAction() {
    this.saveAndContinue.emit();
  }

  navigateByUrl(cancelUrl) {
    this.router.navigateByUrl(cancelUrl);
  }
}
