import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() {}

  getTimeDiff(startTime, endTime) {
    if (!endTime) {
      return 'ongoing';
    }
    const startedAt = new Date(startTime).getTime();
    const endedAt = new Date(endTime).getTime();

    if (isNaN(startedAt) || isNaN(endedAt)) {
      return '';
    }

    const milliSecDiff = endedAt - startedAt;

    const days = Math.floor(milliSecDiff / 1000 / 60 / (60 * 24));
    const hours = Math.floor(milliSecDiff / 1000 / 60 / 60) % 24;
    const minutes = Math.floor(milliSecDiff / 1000 / 60) % 60;
    const seconds = Math.floor(milliSecDiff / 1000) % 60;

    const dateDiff = new Date(milliSecDiff);

    const dateString =
      (days >= 1 ? days + 'd ' : ' ') +
      (hours >= 1 ? hours + 'h ' : ' ') +
      (minutes >= 1 ? minutes + 'm ' : minutes === 0 && seconds > 0 ? minutes + 'm ' : '') +
      (seconds >= 1 ? seconds + 's ' : ' ');

    return dateString.trim();
  }
}
