import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-header',
  templateUrl: './subscription-header.component.html',
  styleUrls: ['./subscription-header.component.css']
})
export class SubscriptionHeaderComponent implements OnInit, OnChanges {
  @Input() status;
  @Input() payment_type;
  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnChanges(changes: any) {
    if (changes.status.currentValue === 'success' && changes.payment_type.currentValue === 'cod') {
      this.successMessageDisplay = true;
      this.responseMessage = 'Request has been processed';
    } else if (changes.status.currentValue === 'success') {
      this.successMessageDisplay = true;
      this.responseMessage = 'Payment Successfully';
    }
  }
  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
    this.router.navigate([]);
  }
}
