import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { KycInformationService } from '../../../services/kyc-information.service';
import { UrlsService } from '../../../services/urls.service';
import { PageService } from '../../workflow-forms/page.service';
import { NotifyService } from '../../../services/notify.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-kyc-create',
  templateUrl: './kyc-create.component.html',
  styleUrls: ['./kyc-create.component.css']
})
export class KycCreateComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  countries: any;
  addressData: Array<any> = [];
  permCountryFields: Array<any> = [];
  tempCountryFields: Array<any> = [];
  response: Array<object> = [];
  submitted = false;
  patientId = '';
  bloodGroups = ['A+ve', 'B+ve', 'AB+ve', 'O+ve', 'A-ve', 'B-ve', 'AB-ve', 'O-ve'];

  kycInformationForm = new FormGroup({
    email_id: new FormControl(),
    pan_no: new FormControl(''),
    citizenship_no: new FormControl(),
    passport_no: new FormControl(),
    nationality: new FormControl(),
    voter_id: new FormControl(),
    education: new FormControl(),
    blood_group: new FormControl(),
    occupation: new FormControl(),
    company_name: new FormControl(),
    company_address: new FormControl(),
    company_type: new FormControl(),
    company_phone: new FormControl(),
    emergency_contact_no: new FormControl(),
    emergency_contact_person: new FormControl()
  });

  // For Save and Continue
  saveAndContinueClicked = false;

  constructor(
    private kycInformationService: KycInformationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      console.log('hello v' + params.id);
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Patient Additional Details', this.patientId);
    });
    this.kycInformationService.getCountryList().subscribe((res) => {
      this.countries = res;
    });

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  storeKycInformation() {
    this.submitted = true;
    const data = this.kycInformationForm.value;
    data.address = this.addressData;

    console.log(JSON.stringify(data));
    if (this.kycInformationForm.invalid) {
      return;
    }

    this.kycInformationService.storeKycInformation(data, this.patientId).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (error) => {
        this.toastr.error(error.error.message);
      },
      () => {
        if (this.saveAndContinueClicked) {
          this.router.navigate([this.nextUrl]);
        } else {
          this.router.navigate(['/patients/' + this.patientId]);
        }
        this.saveAndContinueClicked = false;
      }
    );
  }

  getCountryFieldList(id: number, addressType: number) {
    // reinitialize the inneroptions array if already initiated
    if (addressType === 1) {
      this.permCountryFields = [];
    } else {
      this.tempCountryFields = [];
    }
    if (id) {
      this.kycInformationService.getCountryFields(id).subscribe((res) => {
        this.response = res;
        this.response.forEach((resp) => {
          if (addressType === 1) {
            this.permCountryFields.push(resp);
          } else {
            this.tempCountryFields.push(resp);
          }
        });
        console.log(this.permCountryFields);
      });
    } else {
      if (addressType === 1) {
        this.permCountryFields = [];
      } else {
        this.tempCountryFields = [];
      }
    }
  }

  getFieldOptions(field, event, addressType: number) {
    if ('id' in field && event.target.value) {
      this.pushToAddress(
        field.id,
        +event.target.value,
        event.target.options[event.target.options.selectedIndex].text,
        addressType
      );
      this.kycInformationService.getFieldOptions(field.id, event.target.value).subscribe((res) => {
        if ('children' in field && field.children.length > 0) {
          for (const child of field.children) {
            if (addressType === 1) {
              this.permCountryFields = this.permCountryFields.filter((x) => {
                // @ts-ignore
                return x.name !== child;
              });
            } else {
              this.tempCountryFields = this.tempCountryFields.filter((x) => {
                // @ts-ignore
                return x.name !== child;
              });
            }
          }
        }

        if (res.length > 0) {
          const children = [];
          if (addressType === 1) {
            const parentIndex = this.permCountryFields.indexOf(field);
            for (const r of res) {
              this.permCountryFields.splice(parentIndex + 1, 0, r);
              children.push(r.name);
            }
            field.children = children;
          } else {
            const parentIndex = this.tempCountryFields.indexOf(field);
            for (const r of res) {
              this.tempCountryFields.splice(parentIndex + 1, 0, r);
              children.push(r.name);
            }
            field.children = children;
          }
          console.log(children);
        }

        console.log(this.permCountryFields.indexOf(field));
        console.log(this.tempCountryFields.indexOf(field));
      });
    } else {
      console.log('select an option from the field');
    }
  }

  pushToAddress(fieldId, fieldOptId, value, addressType) {
    const addressArray = [fieldId, fieldOptId, value, addressType];
    if (this.addressData.find((y) => y[0] === fieldId && y[3] === addressType)) {
      const thisData = this.addressData.find((y) => y[0] === fieldId && y[3] === addressType);
      const index = this.addressData.indexOf(thisData);
      this.addressData.splice(index, 1);
      this.addressData.push(addressArray);
    } else {
      this.addressData.push(addressArray);
    }
    console.log(this.addressData);
  }
}
