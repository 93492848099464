import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-inquiry-fourth-step',
  templateUrl: './inquiry-fourth-step.component.html',
  styleUrls: ['./inquiry-fourth-step.component.css']
})
export class InquiryFourthStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() saveData = new EventEmitter();
  @Output() nextEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  @Output() cancelEmmitter = new EventEmitter();
  inquiryFourthForm = new FormGroup({
    enrollReason: new FormControl('', [Validators.required]),
    serviceStatus: new FormControl('', [Validators.required]),
    individualNecessityStatus: new FormControl('', [Validators.required]),
    dependentCareStatus: new FormControl('', [Validators.required])
  });
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.inquiryFourthForm.patchValue({
      enrollReason: this.data.enrollReason,
      serviceStatus: this.data.serviceStatus,
      individualNecessityStatus: this.data.individualNecessityStatus,
      dependentCareStatus: this.data.dependentCareStatus
    });
  }
  validate() {
    this.formSubmitted = true;

    if (this.inquiryFourthForm.valid) {
      this.saveData.emit({
        data: this.inquiryFourthForm.value
      });
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 4,
      previousStep: 3,
      data: this.inquiryFourthForm.value
    };
    this.previousEmmitter.emit(formData);
  }
  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
