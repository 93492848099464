import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionRefillService {
  private apiUrl = environment.apiUrl; // URL to web api

  constructor(private http: HttpClient) {}

  getPrescriptionRefillList(
    pageNo,
    limit,
    status = null,
    refill_type = null,
    prescriptionId = null
  ) {
    var url;
    if (prescriptionId) {
      url = this.apiUrl + 'patients/' + prescriptionId + '/prescription-refill-requests';
    } else {
      url = `${this.apiUrl}prescription-refills`;
    }
    if (status) {
      return this.http.get(url, {
        params: { pageNo, limit, status, refill_type }
      });
    }
    return this.http.get(url, {
      params: { pageNo, limit, refill_type }
    });
  }

  getRefillStatusList() {
    return this.http.get(`${this.apiUrl}prescription-refills/status-list`);
  }

  updatePrescriptionRefill(refillId, data) {
    return this.http.put(`${this.apiUrl}prescription-refills/${refillId}`, data);
  }

  getPrescriptionRefillDetail(id) {
    return this.http.get(`${this.apiUrl}prescription-refills/${id}`);
  }
}
