import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestigationService } from '../../../services/investigation.service';

@Component({
  selector: 'app-investigation-detail',
  templateUrl: './investigation-detail.component.html',
  styleUrls: ['./investigation-detail.component.css']
})
export class InvestigationDetailComponent implements OnInit {
  investigationId: number;
  diagnosticCenterId: number;
  investigationDetail;
  loading = false;
  totalPrice = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private investigationService: InvestigationService
  ) {}

  ngOnInit() {
    this.investigationId = +this.activatedRoute.snapshot.paramMap.get('investigationId');
    this.diagnosticCenterId = +this.activatedRoute.snapshot.paramMap.get('diagnosticCenterId');
    this.getInvestigationDetail(this.investigationId);
  }

  getInvestigationDetail(investigationId) {
    this.loading = true;
    this.investigationService.getInvestigation(investigationId).subscribe(
      (response: any) => {
        this.investigationDetail = response;
        console.log(this.investigationDetail);
      },
      (error) => {},
      () => {
        this.calculateTotalPrice(this.investigationDetail.test_type_list);
        this.loading = false;
      }
    );
  }

  calculateTotalPrice(test_type_list) {
    test_type_list.forEach((test: any) => {
      this.totalPrice += test.price;
    });
  }
}
