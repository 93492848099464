import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appValidJobTitle]'
})
export class ValidJobTitleDirective {
  JOB_TITLE_REGEX = '^[a-zA-Z0-9. ]+$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.JOB_TITLE_REGEX).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z0-9. ]/g, '');
      event.preventDefault();
    }, 100);
  }
}
