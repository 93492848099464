import { Component, OnInit } from '@angular/core';
import { ServiceTypeListService } from '../../services/service-type-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../services/urls.service';
import { NotifyService } from '../../services/notify.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-service-bookings',
  templateUrl: './service-bookings.component.html',
  styleUrls: ['./service-bookings.component.css']
})
export class ServiceBookingsComponent implements OnInit {
  activeSlug: string;
  serviceBookingList: any;
  selectedIndex: number;
  bookingStatus = [];
  loading = true;
  rawSearchQuery: string = null;
  total: number;
  page = 1;
  limit = 10;
  status: any;
  advancedSearchForm = new FormGroup({
    query: new FormControl(''),
    bookingFrom: new FormControl(''),
    bookingTo: new FormControl('')
  });

  selectedFromDate: Date | undefined;
  selectedToDate: Date | undefined;

  constructor(
    private serviceTypeListService: ServiceTypeListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService
  ) {}

  ngOnInit() {
    this.getUrlParams();
    this.getServiceBooking();
    this.getServiceStatus();
    this.hideToggleSearchFilters();
  }

  onFromDateSelected(e) {
    this.selectedFromDate = e.value;
  }

  onToDateSelected(e) {
    this.selectedToDate = e.value;
  }

  getUrlParams() {
    const activeSlug: any = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.activeSlug = activeSlug !== null ? activeSlug : 'all';

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.advancedSearchForm.patchValue({
      query: this.activatedRoute.snapshot.queryParamMap.get('query'),
      bookingFrom: this.activatedRoute.snapshot.queryParamMap.get('bookingFrom'),
      bookingTo: this.activatedRoute.snapshot.queryParamMap.get('bookingTo')
    });

    //this.rawSearchQuery = this.getQueryFromInputParams();
  }

  setUrlParams() {
    const queryParams = {
      status: this.activeSlug ? this.activeSlug : null,
      pageNo: this.page && this.page > 1 ? this.page : null,
      limit: this.limit && this.page > 1 ? this.limit : null,
      query: this.advancedSearchForm.get('query').value
        ? this.advancedSearchForm.get('query').value
        : null,
      bookingFrom: this.advancedSearchForm.get('bookingFrom').value
        ? this.advancedSearchForm.get('bookingFrom').value
        : null,
      bookingTo: this.advancedSearchForm.get('bookingTo').value
        ? this.advancedSearchForm.get('bookingTo').value
        : null
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  getQueryFromInputParams() {
    let stringParams = '';
    for (const entry in this.advancedSearchForm.value) {
      if (this.advancedSearchForm.get(entry).value) {
        if (entry === 'query') {
          stringParams = stringParams.trim() + this.advancedSearchForm.get(entry).value + ' ';
        } else {
          stringParams =
            stringParams.trim() +
            ' ' +
            entry +
            ': ' +
            this.advancedSearchForm.get(entry).value +
            ';';
        }
      }
    }
    return stringParams;
  }

  patchAdvancedFrom() {
    let queryValue = '';
    const fieldList = ['query', 'bookingFrom', 'bookingTo'];
    const initialSplit = this.rawSearchQuery.trim().split(';');
    initialSplit.forEach((chunk: string) => {
      if (chunk.trim().includes(':')) {
        const innerSplit = chunk.split(':');
        const chunkValuePart = innerSplit[innerSplit.length - 1];
        innerSplit.splice(innerSplit.indexOf(chunkValuePart), 1);
        const keyPartSplit = innerSplit[0].trim().split(' ');
        keyPartSplit.forEach((keyPartSingle: string) => {
          if (fieldList.includes(keyPartSingle.trim())) {
            this.advancedSearchForm.controls[keyPartSingle].patchValue(chunkValuePart.trim());
          } else {
            queryValue = queryValue.trim() + ' ' + keyPartSingle;
          }
        });
      } else {
        queryValue = queryValue.trim() + ' ' + chunk.trim();
      }
    });
    this.advancedSearchForm.patchValue({
      query: queryValue.trim()
    });
  }

  applyAdvancedSearch() {
    this.page = 1;
    if (this.checkChangeInsearchVariables().includes(true)) {
      this.setUrlParams();
      this.getServiceBooking();
    }
  }

  checkChangeInsearchVariables() {
    return Object.keys(this.advancedSearchForm.controls).map((param)=>{
      this.checkEmptySearch(param);
      if(this.activatedRoute.snapshot.queryParamMap.get(param) !== this.advancedSearchForm.get(param).value) {
        return true;
      }
      return false;
    })
  }

  checkEmptySearch(field) {
    if (this.advancedSearchForm.get(field).value === '') {
      this.advancedSearchForm.get(field).setValue(null);
    }
  }

  clearSearch() {
    this.rawSearchQuery = null;
    this.advancedSearchForm.get('query').reset();
    this.applyAdvancedSearch();
  }

  filterBookings(index) {
    this.page = 1;
    this.selectedIndex = index;
    this.activeSlug = this.getSlugFromIndex(index);
    this.rawSearchQuery = '';
    this.advancedSearchForm.reset({ recommendedBy: '' });
    this.setUrlParams();
    this.getServiceBooking();
  }

  getSlugFromIndex(index: number) {
    return this.bookingStatus.find((status) => {
      return this.bookingStatus.indexOf(status) === index;
    }).slug;
  }

  goToPage(n: number): void {
    this.page = n;
    this.getServiceBooking();
    this.setUrlParams();
  }

  onNext(): void {
    this.page++;
    this.getServiceBooking();
    this.setUrlParams();
  }

  onPrev(): void {
    this.page--;
    this.getServiceBooking();
    this.setUrlParams();
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  parse(object) {
    return JSON.parse(object).join(', ') ? JSON.parse(object).join(', ') : 'No Tests';
  }

  hideToggleSearchFilters() {
    document.getElementById('dropdown-toggle').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.toggle('show');
    });
    document.getElementById('search-icon').addEventListener('click', () => {
      document.getElementById('dropdown-form').classList.remove('show');
    });
    document.addEventListener('click', (event) => {
      const eventTarget = event.target as HTMLElement;
      const formElement = document.getElementById('dropdown-form');
      if (formElement && formElement.classList.contains('show')) {
        const targetId = eventTarget.id;
        const isInsideBody = document.getElementsByTagName('body')[0].contains(eventTarget);
        const isInsideForm = document.getElementById('dropdown-form').contains(eventTarget);
        const isInsideBodyAndOutsideForm = isInsideBody && !isInsideForm;
        const calenderClass = document.getElementsByClassName('cdk-overlay-container')[0];
        const isCalender = calenderClass ? calenderClass.contains(eventTarget) : false;
        if (
          isInsideBodyAndOutsideForm &&
          targetId !== 'dropdown-form' &&
          targetId !== 'dropdown-toggle' &&
          targetId !== 'search-icon' &&
          !isCalender
        ) {
          formElement.classList.remove('show');
        }
      }
    });
  }
  resetAdvancedSearchForm() {
    this.advancedSearchForm.reset({
      bookingFrom: null,
      bookingTo: null
    });
    this.page = 1;
    this.setUrlParams();
    this.getServiceBooking();
  }

  getAddress(address) {
    return address
      ? address.province +
          ', ' +
          address.city +
          ',' +
          address.address +
          '<br>' +
          address.nearest_landmark
      : '--';
  }
  getServiceStatus() {
    this.serviceTypeListService.getServiceStatus().subscribe(
      (res: any) => {
        this.bookingStatus = res.data;
      },
      (err) => {
        this.loading = false;
        this.bookingStatus.length = 0;
      },
      () => {
        this.loading = false;
        this.selectedIndex = this.bookingStatus.indexOf(
          this.bookingStatus.find((status: any) => {
            return status.slug === this.activeSlug;
          })
        );
      }
    );
  }

  updateStatus(booking, status) {
    if (confirm('Are you sure to update this status?')) {
      const data = { status };
      this.serviceTypeListService.updateServiceStatus(booking.id, data).subscribe(
        (response: any) => {
          this._notify.show(response.message);
        },
        (error) => {
          this._notify.show(error.error.message);
        },
        () => {
          this.getServiceBooking();
        }
      );
    }
  }
  getServiceBooking() {
    this.loading = true;
    this.serviceTypeListService
      .getServiceBookingList(this.page, this.limit, this.advancedSearchForm.value, this.activeSlug)
      .subscribe(
        (res: any) => {
          this.serviceBookingList = res.data;
          this.total = res.size;
        },
        (err) => {
          this.loading = false;
          this.serviceBookingList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onServiceBookingClick(id: number) {
    this.router.navigate(['service-bookings/' + id]);
  }

  StopPropagation(e: Event) {
    e.stopPropagation();
  }
}
