import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransformUtil {
  public toNumber(inputString: string): number {
    return Number(inputString);
  }

  public toString(inputNumber: number): string {
    return String(inputNumber);
  }
}
