import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiagnosisNameService } from '../../../../services/diagnosis-name.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DiagnosisFormComponent } from '../diagnosis-form/diagnosis-form.component';
import { UploadCsvComponent } from './upload-csv/upload-csv.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-diagnosis-list',
  templateUrl: './diagnosis-list.component.html',
  styleUrls: ['./diagnosis-list.component.scss']
})
export class DiagnosisListComponent implements OnInit {
  @Input() clearUrl: boolean;
  diagnosis;
  diagnosisId;
  diagnosisList = [];
  showModal = false;
  afterSaveUrl;

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;
  sampleTemplateCsv = environment.ftpUrl + '/DiagnosisSample.csv';

  diagnosisSearchQuery: string;
  constructor(
    private diagnosisService: DiagnosisNameService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (!this.clearUrl) {
      const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
      const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
      const diagnosisSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');
      this.limit = limit ? limit : this.limit;
      this.page = pageNo ? pageNo : this.page;
      this.diagnosisSearchQuery = diagnosisSearchQuery ? diagnosisSearchQuery : null;
      this.clearUrl = false;
    } else {
      this.clearSearch();
    }
    this.getDiagnosis();

    this.diagnosisId = +this.activatedRoute.snapshot.paramMap.get('diagnosisId');
    this.afterSaveUrl = '/diagnosis/upload-csv';
  }

  openModal(diagnosis) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '300px';
    dialogConfig.data = diagnosis;

    const dialogRef = this.dialog.open(DiagnosisFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getDiagnosis();
      }
    });
  }
  getDiagnosis(): void {
    const page = this.page;
    this.loading = true;
    this.diagnosisService
      .getDiagnosisList(this.page, this.limit, this.diagnosisSearchQuery)
      .subscribe(
        (response: any) => {
          this.diagnosisList = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.diagnosisList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchDiagnosis();
  }

  searchDiagnosis() {
    this.getDiagnosis();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisSearchQuery);
  }

  clearSearch() {
    this.diagnosisList = null;
    this.page = 1;
    this.limit = 10;
    this.diagnosisSearchQuery = null;
    this.searchDiagnosis();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getDiagnosis();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getDiagnosis();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getDiagnosis();
    this.addParamsToUrl(this.page, this.limit, this.diagnosisSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  openCsvModal(diagnosisId) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.height = '210px';
    dialogConfig.width = '300px';
    dialogConfig.data = diagnosisId;

    const dialogRef = this.dialog.open(UploadCsvComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getDiagnosis();
      }
    });
  }

  closeModal(event) {
    this.showModal = false;
  }
}
