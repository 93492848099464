import { Injectable } from '@angular/core';

import { Profile } from '../shared/model/profile';

import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Patient } from '../shared/model/patient';
import { environment } from '../../environments/environment';
import { PaginatedResponse } from '../shared/model/PaginatedResponse';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api

  getProfiles(): Observable<PaginatedResponse<Profile>> {
    return this.http.get<PaginatedResponse<Profile>>(this.apiUrl + 'profiles/list');
  }
  getProfilesList(profile_type): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.apiUrl + 'profiles', { params: { profile_type } });
  }
  getProfile(profileId): Observable<Profile> {
    return this.http.post<Profile>(this.apiUrl + 'profiles/' + profileId, {}, this.httpOptions);
  }

  getActions(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'actions');
  }

  public add(data): Observable<any> {
    return this.http.post(this.apiUrl + 'profiles', data, this.httpOptions);
  }

  public update(data, profileId): Observable<any> {
    return this.http.put(this.apiUrl + 'profiles/' + profileId, data, this.httpOptions);
  }

  public getParentMenu(profileId) {
    return this.http.get(this.apiUrl + 'parent-menu?profile_id=' + profileId);
  }

  getProfileList(pageNo, limit, q) {
    if (!q) {
      return this.http.get(this.apiUrl + 'profiles/list', { params: { pageNo, limit } });
    } else {
      return this.http.get(this.apiUrl + 'profiles/list', { params: { pageNo, limit, q } });
    }
  }
}
