import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisNameService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDiagnosisList(pageNo, limit, query) {
    if (query) {
      return this.http.get(`${this.apiUrl}diagnosis-list`, { params: { pageNo, limit, query } });
    } else {
      return this.http.get(`${this.apiUrl}diagnosis-list`, { params: { pageNo, limit } });
    }
  }

  save(name, id): Observable<any> {
    if (id) {
      const api = this.apiUrl + 'diagnosis-list/' + id;
      return this.http.put(api, name, this.httpOptions);
    } else {
      return this.http.post(`${this.apiUrl}diagnosis-list`, name, this.httpOptions);
    }
  }
}
