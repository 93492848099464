import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService {
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl;

  getServiceRequestCarePlanList(): Observable<any> {
    return this.http.get(this.apiUrl + 'service-request-care-plan-list');
  }

  getCareNeededForList(): Observable<any> {
    return this.http.get(this.apiUrl + 'care-needed-for-list');
  }

  getCareDurationServiceTypeList(): Observable<any> {
    return this.http.get(this.apiUrl + 'care-duration-service-type-list');
  }

  getRefferalInfoGroupList(): Observable<any> {
    return this.http.get(this.apiUrl + 'refferal-info-group-list');
  }

  storeserviceRequest(data, patientId) {
    const url = this.apiUrl + patientId + '/service-requests';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log('sdfdsf');

    return this.http.post(url, data, httpOptions);
  }

  getPatientServiceRequestList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}${patientId}/service-requests`, {
      params: { pageNo, limit }
    });
  }
}
