import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { StorageService } from '../../services/storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  VALIDATION_MESSAGES = [
    {
      name: 'username',
      error_type: 'required',
      message: 'Email or Mobile No. is required'
    },
    {
      name: 'username',
      error_type: 'mobile',
      message: 'Enter a valid Mobile No.'
    },
    {
      name: 'username',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'password',
      error_type: 'required',
      message: 'Password is required'
    }
  ];

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  successMessageDisplay = false;
  failureMessageDisplay = false;
  responseMessage: string;
  loginInProgress = false;
  formSubmitted = false;
  hide = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.auth.isAuthenticated().subscribe((res) => {
      if (res) {
        this.router.navigate(['']);
      }
    });
  }

  ngOnInit() {
    this.checkAndDisplayFlashMessage();
  }

  checkAndDisplayFlashMessage() {
    const historyData = history.state.data;
    if (historyData && historyData.flashMessage) {
      this.successMessageDisplay = true;
      this.failureMessageDisplay = false;
      this.responseMessage = historyData.flashMessage;
    }
  }

  loginUser() {
    this.loginInProgress = true;
    this.formSubmitted = true;
    const formData = this.loginForm.value;
    if (this.loginForm.status === 'VALID') {
      this.auth.sendLoginRequest(formData).subscribe(
        (res) => {
          this.removeToastMessage();
        },
        (err) => {
          this.loginInProgress = false;
          if (err.status === 401) {
            this.failureMessageDisplay = true;
            this.successMessageDisplay = false;
            this.responseMessage = err.error.message;
          }
        },
        () => {
          window.location.href = '';
          this.loginInProgress = false;
        }
      );
    } else {
      this.loginInProgress = false;
    }
  }

  getTokenObject(responseObject) {
    return {
      expiresAt: this.getExpiryDate(responseObject.expires_in),
      token_type: responseObject.token_type,
      access_token: responseObject.access_token,
      refresh_token: responseObject.refresh_token
    };
  }

  getExpiryDate(tokenExpiresIn) {
    const dateTime = new Date();
    dateTime.setSeconds(dateTime.getSeconds() + tokenExpiresIn);
    return dateTime;
  }

  removeToastMessage() {
    this.successMessageDisplay = false;
    this.failureMessageDisplay = false;
    this.responseMessage = '';
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
