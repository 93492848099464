import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NurseAssessmentFormService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getNurseAssessmentForm(patientId): Observable<any> {
    return this.http.get(`${this.apiUrl}patients/${patientId}/personal-care-plan`);
  }
  storeAssesmentNote(patientId, assessmentData) {
    return this.http.post(
      this.apiUrl + 'patients/' + patientId + '/patient-notes',
      assessmentData,
      this.httpOptions
    );
  }
}
