import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LabReportService } from '../../../services/lab-report.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { PageService } from '../../workflow-forms/page.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lab-report-edit',
  templateUrl: './lab-report-edit.component.html',
  styleUrls: ['./lab-report-edit.component.css']
})
export class LabReportEditComponent implements OnInit {
  urlList: Array<string> = [];
  nextUrl: string;

  doctors1: string[] = [];
  filteredDoctors1: Observable<string[]>;

  doctors2: string[] = [];
  filteredDoctors2: Observable<string[]>;

  doctors3: string[] = [];
  filteredDoctors3: Observable<string[]>;

  l: Array<object> = [];
  arr: Array<any>;
  testTypes: Array<object> = [];
  patientId: number;
  labReportId: number;
  showSuggestionForm = false;
  search = '';

  data: Array<object> = [];
  testType: Array<object> = [];
  form: Array<object> = [];
  file: any;
  isDisabled = true;

  submitted = false;

  file_attachment_err = '';

  labReportsForm = new FormGroup({
    reported_date: new FormControl('', [Validators.required]),
    lab_no: new FormControl('', [Validators.required]),
    registered_date: new FormControl('', [Validators.required]),
    lab_id: new FormControl('', [Validators.required]),
    conducted_by_1: new FormControl('', [Validators.required]),
    conducted_by_2: new FormControl('', [Validators.required]),
    conducted_by_3: new FormControl('', [Validators.required])
  });

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private labReportService: LabReportService,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private page: PageService
  ) {}

  ngOnInit() {
    this.page.title.next('Edit Lab Reports');
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.labReportId = +this.activatedRoute.snapshot.paramMap.get('labReportId');

    this.labReportService.getDoctorList().subscribe((y: any) => {
      this.doctors1 = y;
      this.doctors2 = y;
      this.doctors3 = y;
      this.filteredDoctors1 = this.setACField(
        this.labReportsForm.controls.conducted_by_1,
        'doctors1'
      );
      this.filteredDoctors2 = this.setACField(
        this.labReportsForm.controls.conducted_by_2,
        'doctors2'
      );
      this.filteredDoctors3 = this.setACField(
        this.labReportsForm.controls.conducted_by_3,
        'doctors3'
      );
    });

    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          this.urlList.push(
            children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
          );
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patient-list';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });

    this.getPatientLabReport(this.patientId, this.labReportId);
  }

  getPatientLabReport(patientId, labReportId) {
    this.labReportService.getPatientLabReport(patientId, labReportId).subscribe(
      (response: any) => {
        this.labReportsForm.patchValue({
          reported_date: response.reported_date,
          lab_no: response.lab_no,
          registered_date: response.registered_date,
          lab_id: response.lab_id,
          conducted_by_1: response.conducted_by_1,
          conducted_by_2: response.conducted_by_2,
          conducted_by_3: response.conducted_by_3
        });
        response.test_type.forEach((testType: any) => {
          this.testTypes.push([testType]);
          testType.enabledRow = true;
          this.l.push(testType.investigation_list);
        });
      },
      (error) => {
        console.log('could not fetch lab report data.');
      },
      () => {
        console.log('next');
      }
    );
  }

  showSuggestions(e) {
    const keyword = e.target.value;
    if (keyword.length > 2) {
      this.labReportService
        .getInvestigationServiceList(this.labReportsForm.controls.lab_id.value, keyword, this.l)
        .subscribe((x: any) => {
          // console.log(x);
          this.arr = x;
          this.showSuggestionForm = true;
        });
    } else {
      this.showSuggestionForm = false;
    }
  }
  showTestTypes(e, id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });
    // console.log(this.search);
    this.showSuggestionForm = false;
    this.search = '';
    this.l.push(x);
    this.labReportService.getTestTypeServiceList(id).subscribe((y: any) => {
      console.log(y[0].name);
      this.testTypes.push(y);
      console.log('testtypes' + y);
    });
  }

  selectSuggestion(id) {
    const x = this.arr.find((y) => {
      return y.id === id;
    });

    console.log(x);

    // console.log(this.search);
    this.showSuggestionForm = false;
    this.search = '';
    this.l.push(x);
  }

  addLabReports() {
    this.submitted = true;
    if (this.labReportsForm.invalid) {
      return;
    }
    this.showSuggestionForm = false;
    this.search = '';
    const data = this.labReportsForm.value;

    const formData: FormData = new FormData();
    // console.log(this.arr);
    formData.append('testType', JSON.stringify(this.testTypes));
    formData.append('lab_no', data.lab_no);
    formData.append('reported_date', data.reported_date);
    formData.append('lab_id', data.lab_id);
    formData.append('registered_date', data.registered_date);
    formData.append('conducted_by_1', this.labReportsForm.controls.conducted_by_1.value);
    formData.append('conducted_by_2', this.labReportsForm.controls.conducted_by_2.value);
    formData.append('conducted_by_3', this.labReportsForm.controls.conducted_by_3.value);
    formData.append('file_attachment', this.file);
    formData.append('_method', 'put');
    this.labReportService.update(this.patientId, this.labReportId, formData).subscribe(
      (response: any) => {
        this.toastr.success(response.message);
      },
      (err) => {
        this.toastr.error(err.error.message);
      },
      () => {
        this.router.navigate(['/patients/' + this.patientId + '/lab-reports']);
      }
    );
  }

  setvalue(e, x) {
    x.value = e.target.value;
  }
  setmethod(e, x) {
    x.method = e.target.value;
    console.log(this.testTypes);
  }
  uploadFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.file = files[0];
    } else {
      this.file = '';
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.file = null;
  }

  removeInvestigation(investigation) {
    this.testTypes.splice(this.testTypes.indexOf(investigation), 1);
    this.l.splice(this.l.indexOf(investigation), 1);
  }

  setEnable(e, x) {
    x.enabledRow = x.enabledRow === false;
  }
}
