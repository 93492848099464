import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PastMedicalHistoryService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getDiagnosticCenterList() {
    return this.http.get(`${this.apiUrl}diagnosis-centers`);
  }

  store(patientId: number, data: any) {
    const url = this.apiUrl + 'patients/' + patientId + '/past-medical-history';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }

  getPatientPastMedicalHistoryList(patientId, pageNo, limit) {
    return this.http.get(`${this.apiUrl}patients/${patientId}/past-medical-history`, {
      params: { pageNo, limit }
    });
  }
}
