import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PastMedicalHistoryService } from '../../../services/past-medical-history.service';

@Component({
  selector: 'app-past-medical-history-list',
  templateUrl: './past-medical-history-list.component.html',
  styleUrls: ['./past-medical-history-list.component.css']
})
export class PastMedicalHistoryListComponent implements OnInit {
  patientId: number;
  patientPastMedicalHistories: Array<any> = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pastMedicalHistoryService: PastMedicalHistoryService
  ) {}

  ngOnInit() {
    this.patientId = +this.activatedRoute.snapshot.paramMap.get('id');

    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;

    this.getPatientPastMedicalHistories(this.limit);
  }

  getPatientPastMedicalHistories(limit) {
    this.loading = true;
    this.pastMedicalHistoryService
      .getPatientPastMedicalHistoryList(this.patientId, this.page, limit)
      .subscribe(
        (response: any) => {
          this.patientPastMedicalHistories = response.data;
          this.total = response.size;
        },
        (error) => {
          this.loading = false;
          this.patientPastMedicalHistories.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getPatientPastMedicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getPatientPastMedicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getPatientPastMedicalHistories(this.limit);
    this.addPaginationParamsToUrl(this.page, this.limit);
  }

  addPaginationParamsToUrl(pageNo, limit) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { pageNo, limit },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
