import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../workflow-forms/page.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImmunizationService } from '../../../services/immunization.service';
import * as moment from 'moment';
import { ImmunizationNameService } from '../../../services/immunization-name.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-immunization-create',
  templateUrl: './immunization-create.component.html',
  styleUrls: ['./immunization-create.component.css']
})
export class ImmunizationCreateComponent implements OnInit {
  patientId: number;
  patient: any;

  urlList: Array<string> = [];
  nextUrl: string;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  immunizationForm = new FormGroup({
    vaccinationType: new FormControl('', [Validators.required]),
    doseInfo: new FormControl('', [Validators.required]),
    takenDate: new FormControl('', [Validators.required]),
    nextDate: new FormControl('', [Validators.required])
  });

  vaccinations: Array<any> = [];
  vaccinationTypeList: Array<any> = [];

  doseTypeList = ['1st Dose', '2nd Dose', '3rd Dose', '4th Dose', '5th Dose', 'None'];

  submitted = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private page: PageService,
    private urlService: UrlsService,
    private immunizationService: ImmunizationService,
    private immunizationListService: ImmunizationNameService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getMenu();
    this.getVaccinationList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Immunization', this.patientId);
    });
  }

  getVaccinationList() {
    this.immunizationListService.getImmunizationList(null, null, null).subscribe(
      (res: any) => {
        this.vaccinationTypeList = res.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  addVaccination(event) {
    event.preventDefault();
    this.vaccinations.push({
      vaccinationType: '',
      doseInfo: '',
      takenDate: '',
      nextDate: ''
    });
  }

  removeVaccination(index) {
    this.vaccinations.splice(index, 1);
  }

  changeDate(event) {
    return moment(event.target.value).format('YYYY-MM-DD');
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  submit() {
    this.submitted = true;
    this.submitInProgress = true;
    if (this.immunizationForm.status === 'VALID' && this.vaccinationListValid()) {
      const data = this.getVaccinationData(this.immunizationForm.value, this.vaccinations);
      this.immunizationService.store(this.patientId, data).subscribe(
        (response: any) => {
          this.submitInProgress = false;
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
        },
        () => {
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  vaccinationListValid() {
    let validVaccinationCount = 0;
    const allVaccinationCount = this.vaccinations.length;
    this.vaccinations.forEach((vaccination: any) => {
      if (this.vaccinationValid(vaccination)) {
        validVaccinationCount++;
      }
    });
    return validVaccinationCount === allVaccinationCount;
  }

  getVaccinationData(formGroupValue, arrayValue) {
    const data = [];
    data.push(formGroupValue);
    arrayValue.forEach((value: any) => {
      data.push(value);
    });
    return data;
  }

  vaccinationValid(vaccination) {
    return !(
      vaccination.vaccinationType === null ||
      vaccination.vaccinationType === '' ||
      vaccination.doseInfo === null ||
      vaccination.doseInfo === '' ||
      vaccination.takenDate === null ||
      vaccination.takenDate === '' ||
      vaccination.nextDate === null ||
      vaccination.nextDate === ''
    );
  }
}
