import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-instant-pay-second-step',
  templateUrl: './instant-pay-second-step.component.html',
  styleUrls: ['./instant-pay-second-step.component.css']
})
export class InstantPaySecondStepComponent implements OnInit {
  paymentMethod;
  formSubmitted;
  amount = 1000;
  @Input() data;
  @Output() saveData = new EventEmitter();
  @Output() cancelEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.paymentMethod = this.data.paymentMethod;
    this.amount = this.data.amount;
  }
  selectPayment(selectedPayment) {
    this.paymentMethod = selectedPayment;
  }

  validate() {
    this.formSubmitted = true;
    if (this.amount && this.paymentMethod) {
      this.saveData.emit({
        paymentMethod: this.paymentMethod,
        amount: this.amount
      });
    }
  }
  changeAmount(event) {
    this.amount = event.target.value;
  }

  goPrevious() {
    const formData = {
      currentStep: 2,
      previousStep: 1,
      data: {
        paymentMethod: this.paymentMethod,
        amount: this.amount
      }
    };
    this.previousEmmitter.emit(formData);
  }

  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
