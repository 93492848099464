import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appValidNumberDecimal]'
})
export class ValidNumberDecimalDirective {
  selectAll = false;
  NMC_REGEX = '^\\d*(\\.\\d{0,3})?$';

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    if (new RegExp('[0-9.]').test(event.key)) {
      const inputString = this.el.nativeElement.value;
      setTimeout(() => {
        const inputStringAfterEvent = this.el.nativeElement.value;
        if (!new RegExp(this.NMC_REGEX).test(inputStringAfterEvent)) {
          this.el.nativeElement.value = inputString;
        }
        event.preventDefault();
      }, 100);
    } else {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      const inputString = this.el.nativeElement.value.replace(/^(\d+.?\d{0,3})\d*$/, '$1');
      if (!new RegExp(this.NMC_REGEX).test(inputString)) {
        this.el.nativeElement.value = '';
      } else {
        this.el.nativeElement.value = inputString;
      }
      event.preventDefault();
    }, 100);
  }
}
