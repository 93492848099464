import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientEditComponent } from '../patient-edit/patient-edit.component';
import { VitalCreateComponent } from '../vitals/vital-create/vital-create.component';
import { VitalEditComponent } from '../vitals/vital-edit/vital-edit.component';
import { MedicationCreateComponent } from '../medications/medication-create/medication-create.component';
import { MedicationEditComponent } from '../medications/medication-edit/medication-edit.component';
import { LabReportCreateComponent } from '../lab-reports/lab-report-create/lab-report-create.component';
import { LabReportEditComponent } from '../lab-reports/lab-report-edit/lab-report-edit.component';
import { PersonalCarePlanCreateComponent } from '../personal-care-plans/personal-care-plan-create/personal-care-plan-create.component';
import { PersonalCarePlansEditComponent } from '../personal-care-plans/personal-care-plans-edit/personal-care-plans-edit.component';
import { AllergyCreateComponent } from '../allergies/allergy-create/allergy-create.component';
import { FamilyHistoryCreateComponent } from '../family-histories/family-history-create/family-history-create.component';
import { KycCreateComponent } from '../kycs/kyc-create/kyc-create.component';
import { DiagnosisCreateComponent } from '../diagnoses/diagnosis-create/diagnosis-create.component';
import { DiagnosisEditComponent } from '../diagnoses/diagnosis-edit/diagnosis-edit.component';
import { WorkflowFormsComponent } from './workflow-forms.component';
import { AssignUsersComponent } from '../assign-users/assign-users.component';
import { EncounterCreateComponent } from '../encounters/enounter-create/encounter-create.component';
import { EncounterEditComponent } from '../encounters/encounter-edit/encounter-edit.component';
import { ServiceRequestCreateComponent } from '../service-requests/service-request-create/service-request-create.component';
import { PastMedicalHistoryCreateComponent } from '../past-medical-histories/past-medical-history-create/past-medical-history-create.component';
import { RadiologyCreateComponent } from '../radiology/radiology-create/radiology-create.component';
import { ImmunizationCreateComponent } from '../immunizations/immunization-create/immunization-create.component';
import { SurgicalHistoryCreateComponent } from '../surgical-histories/surgical-history-create/surgical-history-create.component';
import { SystemReviewCreateComponent } from '../system-reviews/system-review-create/system-review-create.component';
import { NurseAssessmentFormCreateComponent } from '../nurse-assessment-form/nurse-assessment-form-create/nurse-assessment-form-create.component';

const routes: Routes = [
  {
    path: '',
    component: WorkflowFormsComponent,
    children: [
      {
        path: ':id/edit',
        component: PatientEditComponent
      },
      {
        path: ':id/vitals/create',
        component: VitalCreateComponent
      },
      {
        path: ':id/vitals/:vitalId/edit',
        component: VitalEditComponent
      },
      {
        path: ':id/medications/create',
        component: MedicationCreateComponent
      },
      {
        path: ':id/medications/:medicationId/edit',
        component: MedicationEditComponent
      },
      {
        path: ':id/lab-reports/create',
        component: LabReportCreateComponent
      },
      {
        path: ':id/lab-reports/:labReportId/edit',
        component: LabReportEditComponent
      },
      {
        path: ':id/personal-care-plans/create',
        component: PersonalCarePlanCreateComponent
      },
      {
        path: ':id/personal-care-plans/:carePlanId/edit',
        component: PersonalCarePlansEditComponent
      },
      {
        path: ':id/allergies/create',
        component: AllergyCreateComponent
      },
      {
        path: ':id/family-histories/create',
        component: FamilyHistoryCreateComponent
      },
      {
        path: ':id/kyc/create',
        component: KycCreateComponent
      },
      {
        path: ':id/diagnoses/create',
        component: DiagnosisCreateComponent
      },
      {
        path: ':id/diagnoses/:diagnosisId/edit',
        component: DiagnosisEditComponent
      },
      {
        path: ':id/assign-users',
        component: AssignUsersComponent
      },
      {
        path: ':id/encounters/create',
        component: EncounterCreateComponent
      },
      {
        path: ':id/encounters/:encounterId/edit',
        component: EncounterEditComponent
      },
      {
        path: ':id/service-requests/create',
        component: ServiceRequestCreateComponent
      },
      {
        path: ':id/radiology/create',
        component: RadiologyCreateComponent
      },
      {
        path: ':id/immunizations/create',
        component: ImmunizationCreateComponent
      },
      {
        path: ':id/surgical-histories/create',
        component: SurgicalHistoryCreateComponent
      },
      {
        path: ':id/past-medical-histories/create',
        component: PastMedicalHistoryCreateComponent
      },
      {
        path: ':id/system-reviews/create',
        component: SystemReviewCreateComponent
      },
      {
        path: ':id/nurse-assessment-form/create',
        component: NurseAssessmentFormCreateComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkflowFormsRoutingModule {}
