import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoChatComponent } from './video-chat-component/video-chat.component';
import { VideoChatGuard } from './guard/video-chat.guard';

const routes: Routes = [
  {
    path: '',
    component: VideoChatComponent,
    canActivate: [VideoChatGuard],
    canDeactivate: [VideoChatGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoChatRoutingModule {}
