import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRegionList(): Observable<any> {
    return this.http.get(this.apiUrl + 'regions');
  }

  storeRegion(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.apiUrl + 'regions/save', data, httpOptions);
  }
}
