import { FormDataUtil } from 'src/app/shared/utils/formData/form-data-util';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicationService {
  private apiUrl = environment.apiUrl; // URL to web api
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) {}

  getMedicationList(keyword, arr) {
    const url = this.apiUrl + 'medications';

    return this.http.post(url, { keyword, except: arr }, this.httpOptions);
  }

  storeMedication(data, patientId) {
    const url = this.apiUrl + patientId + '/medication';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, data, httpOptions);
  }

  getPatientMedicationList(patientId: number, pageNo: number, limit: number, q?: string) {
    const params = FormDataUtil.filterEmptyParams({ pageNo, limit, q });
    return this.http.get(`${this.apiUrl}${patientId}/medication`, {params: params});
  }

  updateMedication(patientId, medicationId, data) {
    const url = this.apiUrl + patientId + '/medication/' + medicationId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(url, data, httpOptions);
  }

  getPatientMedication(patientId, medicationId) {
    const url = this.apiUrl + patientId + '/medications/' + medicationId;
    return this.http.get(url);
  }

  getAllMedicationList() {
    return this.http.get(`${this.apiUrl}medications`);
  }
}
