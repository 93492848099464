import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../workflow-forms/page.service';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { LabReportService } from '../../../services/lab-report.service';
import { Observable } from 'rxjs';
import { RadiologyService } from '../../../services/radiology.service';
import { ToastrService } from 'ngx-toastr';
import { ConvertByte } from '../../shared/utility/convertByte.util';

function createValidate(doctors: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!doctors.includes(control.value)) {
      return { not_matched_with_list: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-radiology-create',
  templateUrl: './radiology-create.component.html',
  styleUrls: ['./radiology-create.component.css']
})
export class RadiologyCreateComponent implements OnInit {
  patientId: number;
  fileSize: any;
  rawFileSize: number;
  MAX_FILE_SIZE: number = 10048576;

  urlList: Array<string> = [];
  nextUrl: string;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  // For form Validation
  submitted = false;

  radiologyForm = new FormGroup({
    date: new FormControl('', [Validators.required]),
    doctorName: new FormControl('', [Validators.required, createValidate([])]),
    center: new FormControl('', [Validators.required]),
    for: new FormControl('', [Validators.required]),
    angle: new FormControl('', [Validators.required, Validators.min(0), Validators.max(360)]),
    impression: new FormControl('', [Validators.required])
  });
  type = '';
  category = '';
  file = '';
  fileAttachmentError = '';

  doctors: string[] = [];
  filteredDoctors: Observable<string[]>;

  labList: Array<any> = [];

  radiologyForList = [
    {
      id: 1,
      slug: 'head',
      name: 'Head'
    },
    {
      id: 2,
      slug: 'chest',
      name: 'Chest'
    },
    {
      id: 3,
      slug: 'elbow',
      name: 'Elbow'
    },
    {
      id: 4,
      slug: 'shoulder',
      name: 'Shoulder'
    },
    {
      id: 5,
      slug: 'left-hand',
      name: 'Left Hand'
    },
    {
      id: 6,
      slug: 'right-hand',
      name: 'Right Hand'
    },
    {
      id: 7,
      slug: 'knee',
      name: 'Knee'
    },
    {
      id: 8,
      slug: 'left-ankle',
      name: 'Left Ankle'
    },
    {
      id: 9,
      slug: 'right-ankle',
      name: 'Right Ankle'
    },
    {
      id: 10,
      slug: 'foot',
      name: 'Foot'
    },
    {
      id: 11,
      slug: 'spine',
      name: 'Spine'
    },
    {
      id: 12,
      slug: 'wrist',
      name: 'Wrist'
    },
    {
      id: 13,
      slug: 'left-hip',
      name: 'Left Hip'
    },
    {
      id: 14,
      slug: 'right-hip',
      name: 'Right Hip'
    },
    {
      id: 15,
      slug: 'finger',
      name: 'Finger'
    },
    {
      id: 16,
      slug: 'other',
      name: 'Other'
    }
  ];
  radiologyTypeList = [
    {
      id: 1,
      name: 'X-ray',
      category: ['digital', 'analog']
    },
    {
      id: 2,
      name: 'CT-Scan',
      category: ['16', '64', '124']
    },
    {
      id: 3,
      name: 'USG',
      category: null
    },
    {
      id: 4,
      name: 'MRI',
      category: ['1.5 Tesla', '3 Tesla']
    }
  ];
  categoryList: any;

  /**
   * Set Auto Complete Field
   *
   * @params formControlField -> listens to value change of that form control
   * @params referenceArray -> to search filter|search from that array
   */
  setACField(formControlField: AbstractControl, referenceArray: string) {
    return formControlField.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this[referenceArray].filter((option) => {
          return option.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private page: PageService,
    private urlService: UrlsService,
    private labReportService: LabReportService,
    private radiologyService: RadiologyService,
    private toastr: ToastrService,
    private convertByte: ConvertByte
  ) {}

  ngOnInit() {
    this.setPageInfo();
    this.getMenu();
    this.getDoctorList();
    this.getLabList();
  }

  setPageInfo() {
    this.activatedRoute.params.subscribe((params) => {
      this.patientId = params.id;
      this.page.setTitleAndPatientId('Add Radiology', this.patientId);
    });
  }

  getMenu() {
    this.urlService.getMenu().subscribe((y: any) => {
      y.forEach((headerMenu: any) => {
        headerMenu.children.forEach((children) => {
          if (children.url !== null) {
            this.urlList.push(
              children.url.replace(/{patientId}/g, String(this.activatedRoute.snapshot.params.id))
            );
          }
        });
      });
      if (this.router.url === this.urlList[this.urlList.length - 1]) {
        this.nextUrl = '/patients';
      } else {
        this.nextUrl = this.urlList[this.urlList.indexOf(this.router.url) + 1];
      }
    });
  }

  getDoctorList() {
    this.submitInProgress = true;
    this.labReportService.getDoctorList().subscribe(
      (response: any) => {
        this.doctors = response;
        this.filteredDoctors = this.setACField(this.radiologyForm.controls.doctorName, 'doctors');
        this.radiologyForm.controls.doctorName.setValidators([
          Validators.required,
          createValidate(response || [])
        ]);
      },
      (error) => {
        this.submitInProgress = false;
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  getLabList() {
    this.submitInProgress = true;
    this.labReportService.getLabListForLabReport().subscribe(
      (response: any) => {
        this.labList = response.data;
      },
      (error) => {
        this.submitInProgress = false;
        console.log('Could not fetch lab list at the moment');
      },
      () => {
        this.submitInProgress = false;
      }
    );
  }

  submit() {
    this.submitInProgress = true;
    this.submitted = true;
    if (
      this.radiologyForm.status === 'VALID' &&
      this.type !== null &&
      this.type !== '' &&
      ((this.categoryList !== null && this.category !== null && this.category !== '') ||
        (this.categoryList === null && (this.category === null || this.category === '')))
    ) {
      const data = this.radiologyForm.value;
      data.date = this.changeDate(this.radiologyForm.controls.date);
      data.type = this.type;
      data.category = this.category;
      data.file_attachment = this.file;

      const requestData = this.createRequestData(data);

      this.radiologyService.store(this.patientId, requestData).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          this.toastr.error(error.error.message);
          if (error.error.errors.file_attachment) {
            this.fileAttachmentError = error.error.errors.file_attachment[0];
          }
        },
        () => {
          this.submitInProgress = false;
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
          } else {
            this.router.navigate([this.nextUrl]);
          }
          this.saveAndContinueClicked = false;
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  changeDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  changeType() {
    this.categoryList = this.radiologyTypeList.find((radiology) => {
      return radiology.name === this.type;
    }).category;
    this.category = null;
  }

  uploadFile(event) {
    this.fileAttachmentError = null;
    this.fileSize = null;

    const files = event.target.files;
    if (files.length > 0) {
      this.rawFileSize = files[0].size;
      if (this.rawFileSize < this.MAX_FILE_SIZE && this.convertByte.extractExtension(files[0])) {
        this.fileSize = this.convertByte.convert(files[0].size);
        this.file = files[0];
      } else {
        this.toastr.error(
          'The file attachment must be a file type of jpg, jpeg, png, pdf',
          'File size should not exceed 10 MB.'
        );
      }
    }
  }

  removeFile(event) {
    event.preventDefault();
    this.file = null;
    this.fileAttachmentError = null;
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  }

  keyPressNumbersDecimal(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (this.checkAlphabets(charCode) && this.checkSpecialCharacters(charCode)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  checkAlphabets(charCode) {
    return charCode != 46 && charCode > 31 ? true : false;
  }

  checkSpecialCharacters(charCode) {
    return charCode < 47 || charCode > 57 ? true : false;
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }
}
