import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { ServiceTypeListService } from '../../../../services/service-type-list.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-type-form',
  templateUrl: './service-type-form.component.html',
  styleUrls: ['./service-type-form.component.css']
})
export class ServiceTypeFormComponent implements OnInit {
  serviceType: any;
  submitInProgress = false;
  submitted = false;
  serviceTypeForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    active: new FormControl(1)
  });
  constructor(
    private toastr: ToastrService,
    private serviceTypeService: ServiceTypeListService,
    private router: Router,
    private dialogRef: MatDialogRef<ServiceTypeFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.serviceType = data;
  }

  ngOnInit() {
    console.log(this.serviceType);
    if (this.serviceType) {
      this.patchData();
    }
  }

  patchData() {
    this.serviceTypeForm.patchValue({
      name: this.serviceType.name,
      active: this.serviceType.active
    });
  }
  submit() {
    this.submitInProgress = true;
    if (this.serviceTypeForm.status === 'VALID') {
      const id = this.serviceType ? this.serviceType.id : null;
      this.serviceTypeService.save(this.serviceTypeForm.value, id).subscribe(
        (res) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          this.dialogRef.close(true);
          this.submitInProgress = false;
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
