import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabPackageService } from '../../../services/lab-package.service';

@Component({
  selector: 'app-lab-package-details',
  templateUrl: './lab-package-details.component.html',
  styleUrls: ['./lab-package-details.component.css']
})
export class LabPackageDetailsComponent implements OnInit {
  labPackage;
  labPackageId;

  constructor(
    private labPackageService: LabPackageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.labPackageId = params.labPackageId;
    });
    this.getLabPackage(this.labPackageId);
  }

  getLabPackage(labPackageId) {
    this.labPackageService.getLabPackage(labPackageId).subscribe((labPackage: any) => {
      this.labPackage = labPackage.data;
      console.log(labPackage);
    });
  }
}
