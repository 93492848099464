import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, BehaviorSubject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { FormDataUtil } from '../shared/utils/formData/form-data-util';

@Injectable({
  providedIn: 'root'
})
export class CallLogService {
  private apiUrl = environment.apiUrl;
  private PING_INTERVAL = 30;
  private pingPatientList = new BehaviorSubject<any>({ data: [] });

  constructor(private http: HttpClient) {}

  getCallLogList(pageNo, limit) {
    return this.http.get(this.apiUrl + 'video-app/conversations', {
      params: { pageNo, limit }
    });
  }

  getCallLogs(pageNo, limit, filterBody) {
    const data: any = {
      q: filterBody.query ? filterBody.query : '',
      date_to: filterBody.toDate ? filterBody.toDate : '',
      date_from: filterBody.fromDate ? filterBody.fromDate : ''
    };
    data.pageNo = pageNo;
    data.limit = limit;
    const params = FormDataUtil.filterEmptyParams(data);
    console.log('got search click');
    return this.http.get(`${this.apiUrl}video-app/getAllVideoChatCallLogs`, { params });
  }

  ping() {
    return this.http.get(this.apiUrl + 'ping');
  }

  pingPatient() {
    interval(this.PING_INTERVAL * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.ping())
      )
      .subscribe(
        (res: any) => {
          this.setPingPatientList(res);
        },
        (error) => {
          this.setPingPatientList({ data: [] });
        }
      );
  }

  /**
   * This method sets active PatientList
   * @param pingPatientList This is the first parameter that accepts message
   */
  setPingPatientList(pingPatientList: any): void {
    this.pingPatientList.next(pingPatientList);
  }

  /**
   * This method get PingPatientList
   */
  getPingPatientList(): BehaviorSubject<number> {
    return this.pingPatientList;
  }
}
