import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Vital } from '../shared/model/vital';

import { environment } from '../../environments/environment';
import { VitalList } from '../shared/model/vitalList';

@Injectable({
  providedIn: 'root'
})
export class VitalService {
  constructor(private http: HttpClient) {}

  private apiUrl = environment.apiUrl; // URL to web api

  getVitalList(limit = null, place): Observable<VitalList[]> {
    return this.http.get<VitalList[]>(this.apiUrl + 'vital-list/' + place);
  }

  add(patienId, vitals) {
    const url = `${this.apiUrl}` + patienId + '/vitals';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, vitals, httpOptions);
  }

  getPatientVitals(patientId, pageNo, limit, sortBy, sortOrder): Observable<any> {
    return this.http.get(`${this.apiUrl}patient/${patientId}/vitals`, {
      params: { pageNo, limit, sortBy, sortOrder }
    });
  }

  getVitalsData(patientId, vitalType): Observable<any> {
    return this.http.get(`${this.apiUrl}patient/${patientId}/vitals/${vitalType}`);
  }

  getPatientVital(patientId, vitalId): Observable<any> {
    return this.http.get(`${this.apiUrl}${patientId}/vitals/${vitalId}`);
  }

  updatePatientVital(patientId, vitalId, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put(`${this.apiUrl}${patientId}/vitals/${vitalId}`, data, httpOptions);
  }
}
