import { Component, OnInit, Output, EventEmitter, Injectable } from '@angular/core';
import { UserServiceService } from '../services/user-service.service';
import { CallService } from '../video-chat/service/call.service';
import { ToastMessageService } from '../shared/services/toast-message-service/toast-message.service';
import { AuthService } from '../auth/shared/services/auth.service';
import { CallLogService } from '../services/call-log.service';
import { DashboardService } from '../services/dashboard.service';
import { PermissionsConstants } from '../shared/constant/permission.constants';
import { PermissionCheckService } from '../shared/services/permissions/permission-check.service';
import { SocketService } from '../services/socket.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  imageUrl: string;
  vitalsAlertCount: number;
  unreadConsumerMessageCount: number;

  @Output() headerToggled = new EventEmitter();

  requiredPermissions = {
    vitalsAlertCount: PermissionsConstants.DASHBOARD_VITAL_ALERT_COUNT,
    consumerMessageCount: PermissionsConstants.DASHBOARD_CONSUMER_MESSAGE_COUNT
  };

  constructor(
    private userService: UserServiceService,
    private auth: AuthService,
    private callService: CallService,
    private toastMessageService: ToastMessageService,
    private callLogService: CallLogService,
    private dashboardService: DashboardService,
    private permissionCheckService: PermissionCheckService
  ) {}

  ngOnInit() {
    this.permissionCheckService.permissionListEm.subscribe((permissionListLoaded) => {
      this.getVitalCount();
      this.getConsumerMessageCount();
    });

    this.getVitalCount();
    this.getConsumerMessageCount();
    this.auth.VoIPInit();
    this.initVoipService({});
  }

  private getVitalCount() {
    if (this.permissionCheckService.hasPermission(this.requiredPermissions.vitalsAlertCount)) {
      this.dashboardService.getVitalCount().subscribe(
        (response: any) => {
          this.vitalsAlertCount = response.total;
        },
        (error) => {
          this.vitalsAlertCount = 0;
        }
      );
    }
  }

  private getConsumerMessageCount() {
    if (this.permissionCheckService.hasPermission(this.requiredPermissions.consumerMessageCount)) {
      this.dashboardService.getConsumerMessageCount().subscribe(
        (response: any) => {
          this.unreadConsumerMessageCount = response.data;
        },
        (error) => {
          this.unreadConsumerMessageCount = 0;
        }
      );
    }
  }

  toggleSidebar() {
    document.body.classList.toggle('sidebar-active');
    document.querySelector('section.float-navigation.bg-drak').classList.toggle('float-active');
  }

  onHeaderToggle() {
    this.headerToggled.emit();
  }

  initVoipService(rerseponse: any) {
    this.auth.getConnectyCubeUserDetails().subscribe(
      (voIPUser) => {
        this.auth
          .VoIPLogin({
            id: voIPUser.id,
            name: voIPUser.full_name,
            login: voIPUser.login,
            email: voIPUser.email,
            password: voIPUser.password
          })
          .then(() => {
            this.auth.setVoIPLoginStatus(true);
            this.callService.init();
            this.callLogService.pingPatient();
          })
          .catch((error) => {
            this.auth.setVoIPLoginStatus(false);
          });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
