import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlsService } from '../../../services/urls.service';
import { NotifyService } from '../../../services/notify.service';
import { ServiceTypeListService } from '../../../services/service-type-list.service';

@Component({
  selector: 'app-service-management-list',
  templateUrl: './service-management-list.component.html',
  styleUrls: ['./service-management-list.component.css']
})
export class ServiceManagementListComponent implements OnInit {
  serviceManagementList;

  loading = true;
  searchQuery: string = null;
  total: number;
  page = 1;
  limit = 10;
  status: any = 'all';

  constructor(
    private serviceTypeListService: ServiceTypeListService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlService: UrlsService,
    private _notify: NotifyService
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const searchQuery: any = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.searchQuery = searchQuery ? searchQuery : null;

    this.getServiceManagement();
  }

  getServiceManagement() {
    this.loading = true;
    this.serviceTypeListService
      .serviceManagementListService(this.page, this.limit, this.searchQuery, this.status)
      .subscribe(
        (res: any) => {
          this.serviceManagementList = res.data;
          this.total = res.size;
        },
        (err) => {
          this.loading = false;
          this.serviceManagementList.length = 0;
        },
        () => {
          this.loading = false;
        }
      );
  }

  goToPage(n: number): void {
    this.page = n;
    this.getServiceManagement();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onNext(): void {
    this.page++;
    this.getServiceManagement();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  onPrev(): void {
    this.page--;
    this.getServiceManagement();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  proceedToSearch(event) {
    if (this.searchQuery) {
      event.preventDefault();
      this.page = 1;
      this.searchServiceManagement();
    }
  }

  searchServiceManagement() {
    this.getServiceManagement();
    this.addParamsToUrl(this.searchQuery, this.page, this.limit);
  }

  clearSearch() {
    this.searchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchServiceManagement();
  }

  addParamsToUrl(q, pageNo = null, limit = null) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        q,
        pageNo,
        limit
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  editServiceManagement(serviceManagement) {
    this.router.navigate(['/service-management/' + serviceManagement.id + '/edit']);
  }

  getPaymentStatus(payment_service_list, payment_name) {
    const payment = payment_service_list.find((payment_service) => {
      return payment_service.payment_method === payment_name;
    });
    return payment ? true : false;
  }
}
