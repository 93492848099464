import { DashboardGraphs } from './../shared/model/dashboard';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardCounts } from '../shared/model/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl = environment.apiUrl; // URL to web api
  constructor(private http: HttpClient) {}

  getDashboardData(): Observable<DashboardCounts> {
    const url = this.apiUrl + 'get-dashboard-data';
    return this.http.get(url);
  }

  getDashboardDataCounts(params): Observable<DashboardGraphs> {
    const url = this.apiUrl + 'dashboard/details';
    return this.http.get(url, { params });
  }

  getVitalCount() {
    const url = this.apiUrl + 'vitals_alert';
    return this.http.get(url);
  }

  getPatientsCount() {
    const url = this.apiUrl + 'total-patients';
    return this.http.get(url);
  }

  getLabBookingsCount() {
    const url = this.apiUrl + 'total-lab-bookings';
    return this.http.get(url);
  }

  getVitalList() {
    const url = this.apiUrl + 'vitals_alert_list';
    return this.http.get(url);
  }

  getConsumerMessageCount() {
    return this.http.get(`${this.apiUrl}dashboard/count/unread-consumer-message`);
  }

  getPrescriptionRefillRequestCount() {
    return this.http.get(`${this.apiUrl}dashboard/count/prescription-refill`);
  }

  getConsultationCount() {
    return this.http.get(`${this.apiUrl}dashboard/count/total-consultation-bookings`);
  }
}
