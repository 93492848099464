import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { Patient, Prefix } from '../../shared/model/patient';
import { PatientTypeList } from '../../shared/model/patientTypeList';
import { RelationList } from '../../shared/model/relationList';
import { UrlsService } from '../../services/urls.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PatientRoute as routes } from '../../../environments/patient.route';
import { RouterService } from '../../shared/router.service';
import { ToastrService } from 'ngx-toastr';
import { CountryCodeService } from 'src/app/services/country-code.service';
import { CountryCode } from 'src/app/shared/model/country-code';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-create',
  templateUrl: './patient-create.component.html',
  styleUrls: ['./patient-create.component.css']
})
export class PatientCreateComponent implements OnInit {
  MOBILE_PATTERN = '^\\d{10}$';
  PHONE_PATTERN = '^\\d{9,10}$';
  PHONE_MAX_LENGTH = 10;
  PHONE_MIN_LENGTH = 9;
  MOBILE_MIN_LENGTH = 10;
  MOBILE_MAX_LENGTH = 10;

  alreadyUsedBackendMessage = {
    mobile: 'Mobile Number is already taken',
    email: 'Email is already taken'
  };

  alreadyUsedMobileNumbers = [];
  alreadyUsedEmails = [];
  prefixList: Prefix[];
  countryCodes: CountryCode[] = [];

  VALIDATION_MESSAGES = [
    {
      name: 'first_name',
      error_type: 'required',
      message: 'First Name is required'
    },
    {
      name: 'first_name',
      error_type: 'maxLength',
      message: 'First Name can be no more than 255 characters'
    },
    {
      name: 'middle_name',
      error_type: 'maxLength',
      message: 'Middle Name can be no more than 255 characters'
    },
    {
      name: 'last_name',
      error_type: 'required',
      message: 'Last Name is required'
    },
    {
      name: 'last_name',
      error_type: 'maxLength',
      message: 'Last Name can be no more than 255 characters'
    },
    {
      name: 'patient_type_id',
      error_type: 'required',
      message: 'Patient Type is required'
    },
    {
      name: 'gender',
      error_type: 'required',
      message: 'Gender is required'
    },
    {
      name: 'dob',
      error_type: 'required',
      message: 'Date of Birth is required'
    },
    {
      name: 'dob',
      error_type: 'max',
      message: 'Date of Birth cannot exceed today'
    },
    {
      name: 'address',
      error_type: 'required',
      message: 'Address is required'
    },
    {
      name: 'address',
      error_type: 'maxLength',
      message: 'Address can be no more than 255 Characters'
    },
    {
      name: 'mobile',
      error_type: 'required',
      message: 'Mobile Number is required'
    },
    {
      name: 'mobile',
      error_type: 'minLength',
      message: 'Mobile Number must be at least ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'maxLength',
      message: 'Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'mobile',
      error_type: 'pattern',
      message: 'Enter a Valid Mobile Number'
    },
    {
      name: 'mobile',
      error_type: 'unique',
      message: this.alreadyUsedBackendMessage.mobile
    },
    {
      name: 'country_code',
      error_type: 'required',
      message: 'Country Code is required'
    },
    {
      name: 'country_code',
      error_type: 'match_not_found',
      message: 'please select an option'
    },
    {
      name: 'phone',
      error_type: 'minLength',
      message: 'Phone Number should be of minimum ' + this.PHONE_MIN_LENGTH + ' characters'
    },
    {
      name: 'phone',
      error_type: 'maxLength',
      message: 'Phone Number can be no more than ' + this.PHONE_MAX_LENGTH + ' characters'
    },
    {
      name: 'phone',
      error_type: 'pattern',
      message: 'Enter a Valid Phone Number'
    },
    {
      name: 'email',
      error_type: 'email',
      message: 'Enter a valid Email'
    },
    {
      name: 'email',
      error_type: 'required',
      message: 'Email is required'
    },
    {
      name: 'email',
      error_type: 'unique',
      message: this.alreadyUsedBackendMessage.email
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'minLength',
      message: 'Secondary Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'maxLength',
      message:
        'Secondary Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'secondary_mobile_no',
      error_type: 'pattern',
      message: 'Enter a Valid Secondary Mobile Number'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'minLength',
      message: 'Relative Mobile Number must be exactly ' + this.MOBILE_MIN_LENGTH + ' characters'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'maxLength',
      message:
        'Relative Mobile Number can be no more than ' + this.MOBILE_MAX_LENGTH + ' characters'
    },
    {
      name: 'relative_mobile_no',
      error_type: 'pattern',
      message: 'Enter a Valid Relative Mobile Number'
    },
    {
      name: 'status',
      error_type: 'required',
      message: 'Status is required'
    },
    {
      name: 'prefix',
      error_type: 'required',
      message: 'prefix is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'first_name',
      error: false
    },
    {
      controlName: 'middle_name',
      error: false
    },
    {
      controlName: 'last_name',
      error: false
    },
    {
      controlName: 'patient_type_id',
      error: false
    },
    {
      controlName: 'blood_group',
      error: false
    },
    {
      controlName: 'gender',
      error: false
    },
    {
      controlName: 'dob',
      error: false
    },
    {
      controlName: 'address',
      error: false
    },
    {
      controlName: 'phone',
      error: false
    },
    {
      controlName: 'mobile',
      error: false
    },
    {
      controlName: 'email',
      error: false
    },
    {
      controlName: 'secondary_mobile_no',
      error: false
    },
    {
      controlName: 'relation_name',
      error: false
    },
    {
      controlName: 'relation_id',
      error: false
    },
    {
      controlName: 'relative_mobile_no',
      error: false
    },
    {
      controlName: 'status',
      error: false
    },
    {
      controlName: 'is_online',
      error: false
    },
    {
      controlName: 'prefix',
      error: false
    }
  ];

  todayDate = new Date();

  patientForm = new FormGroup({
    prefix: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    middle_name: new FormControl('', [Validators.maxLength(255)]),
    last_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    patient_type_id: new FormControl('', [Validators.required]),
    blood_group: new FormControl(''),
    gender: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    phone: new FormControl('', [
      Validators.minLength(this.PHONE_MIN_LENGTH),
      Validators.maxLength(this.PHONE_MAX_LENGTH),
      Validators.pattern(this.PHONE_PATTERN)
    ]),
    country_code: new FormControl(''),
    mobile: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MAX_LENGTH)
    ]),
    email: new FormControl('', [Validators.email]),
    secondary_mobile_no: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MIN_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    relation_name: new FormControl(''),
    relation_id: new FormControl(''),
    relative_mobile_no: new FormControl('', [
      Validators.minLength(this.MOBILE_MIN_LENGTH),
      Validators.maxLength(this.MOBILE_MIN_LENGTH),
      Validators.pattern(this.MOBILE_PATTERN)
    ]),
    is_online: new FormControl('false')
  });

  formSubmitted = false;

  // For Save and Continue
  saveAndContinueClicked = false;

  bloodGroups = [
    {
      value: 'A+ve',
      name: 'A +ve'
    },
    {
      value: 'B+ve',
      name: 'B +ve'
    },
    {
      value: 'AB+ve',
      name: 'AB +ve'
    },
    {
      value: 'O+ve',
      name: 'O +ve'
    },
    {
      value: 'A-ve',
      name: 'A -ve'
    },
    {
      value: 'B-ve',
      name: 'B -ve'
    },
    {
      value: 'AB-ve',
      name: 'AB -ve'
    },
    {
      value: 'O-ve',
      name: 'O -ve'
    },
    {
      value: 'N/A',
      name: 'N/A'
    }
  ];

  patientTypeLists: PatientTypeList[];
  relationLists: RelationList[];
  x: object;
  nepalCountryCode: CountryCode;

  // For Form Footer
  showSaveAndContinueButton = true;
  cancelUrl = '/patients';
  submitInProgress = false;
  patientId: number;

  constructor(
    private patientService: PatientService,
    private router: RouterService,
    private route: ActivatedRoute,
    private urlService: UrlsService,
    private toastr: ToastrService,
    private countryCodeService: CountryCodeService
  ) {}

  ngOnInit(): void {
    this.patientId = this.patientService.getId(this.route);
    this.getPatientTypeList();
    this.getRelationList();
    this.getPrefixList();
    this.getCountryCodes();
  }

  saveAndContinue() {
    this.saveAndContinueClicked = true;
  }

  getCountryCodes() {
    this.countryCodeService.getCountryCodes().subscribe(
      (response: CountryCode[]) => {
        this.countryCodes = response;
        console.log('Country codes here -->', this.countryCodes);
        response.map((x) => {
          if (x.name == 'Nepal') {
            this.nepalCountryCode = x;
          }
        });
      }
    );
  }

  setMobileValidation(e) {
    e === 977 ? this.MOBILE_MAX_LENGTH = 10 : this.MOBILE_MAX_LENGTH = 15;
    this.patientForm.get('mobile').setValidators([
      Validators.maxLength(this.MOBILE_MAX_LENGTH),
      Validators.minLength(this.MOBILE_MIN_LENGTH)
    ]);
    this.patientForm.get('mobile').updateValueAndValidity();
  }

  getPrefixList() {
    this.patientService.getPrefixList().subscribe((prefixList: Prefix[]) => {
      this.prefixList = prefixList;
    });
  }

  getPatientTypeList(): void {
    this.patientService.getPatientTypeList().subscribe((patientTypeLists) => {
      this.patientTypeLists = patientTypeLists;
    });
  }

  getRelationList(): void {
    this.patientService.getRelationList().subscribe((relationLists) => {
      this.relationLists = relationLists;
    });
  }

  register(patient: Patient) {
    this.formSubmitted = true;
    this.submitInProgress = true;
    if (this.patientForm.status === 'VALID') {
      const data = this.patientForm.value;
      patient.value.dob = moment(patient.value.dob).format('YYYY-MM-DD');
      patient.value.is_online = this.patientForm.controls.is_online.value === 'true';
      this.patientService.register(data).subscribe(
        (res: any) => {
          this.submitInProgress = false;
          const patientId = res.data.id;
          let nextUrl: string;
          const urlList: Array<string> = [];
          if (this.saveAndContinueClicked) {
            this.router.navigate(['/patients/' + this.patientId]);
            return true;
          }
          console.log(patientId);
          this.urlService.getMenu().subscribe((y: any) => {
            y.forEach((headerMenu: any) => {
              headerMenu.children.forEach((children) => {
                if (children.url !== null) {
                  urlList.push(children.url.replace(/{patientId}/g, String(patientId)));
                }
              });
            });
            nextUrl = this.getNextUrl(urlList, patientId);
            this.router.redirectToNextPageWithMessage(res.message, nextUrl);
            this.saveAndContinueClicked = false;
            return true;
          });
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
          }
          this.toastr.error(error.error.message);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  getPatientDetailPage(patientId: number) {
    return routes.convert(routes.detailPage, patientId);
  }

  getPatientEditPage(patientId: number) {
    return routes.convert(routes.editPage, patientId);
  }

  getNextUrl(urlList, patientId) {
    if (urlList.length === 0) {
      return routes.convert(routes.listPage, patientId);
    }
    // if next url in urlList is edit patient
    if (urlList[0] === this.getPatientEditPage(patientId)) {
      // if list has more than 1 url, return next url
      if (urlList.length > 1) {
        return urlList[1];
      }
      // else return patient list
      return routes.listPage;
    } else {
      return urlList[0];
    }
    return routes.listPage;
  }

  setBackendValidationErrors(errors) {
    if (errors.first_name) {
      this.setBackendValidationError('first_name', errors.first_name[0]);
    } else {
      this.unsetBackendValidationError('first_name');
    }
    if (errors.middle_name) {
      this.setBackendValidationError('middle_name', errors.middle_name[0]);
    } else {
      this.unsetBackendValidationError('middle_name');
    }
    if (errors.last_name) {
      this.setBackendValidationError('last_name', errors.last_name[0]);
    } else {
      this.unsetBackendValidationError('last_name');
    }
    if (errors.patient_type_id) {
      this.setBackendValidationError('patient_type_id', errors.patient_type_id[0]);
    } else {
      this.unsetBackendValidationError('patient_type_id');
    }
    if (errors.blood_group) {
      this.setBackendValidationError('blood_group', errors.blood_group[0]);
    } else {
      this.unsetBackendValidationError('blood_group');
    }
    if (errors.gender) {
      this.setBackendValidationError('gender', errors.gender[0]);
    } else {
      this.unsetBackendValidationError('gender');
    }
    if (errors.dob) {
      this.setBackendValidationError('dob', errors.dob[0]);
    } else {
      this.unsetBackendValidationError('dob');
    }
    if (errors.address) {
      this.setBackendValidationError('address', errors.address[0]);
    } else {
      this.unsetBackendValidationError('address');
    }
    if (errors.phone) {
      this.setBackendValidationError('phone', errors.phone[0]);
    } else {
      this.unsetBackendValidationError('phone');
    }
    if (errors.mobile) {
      this.setBackendValidationError('mobile', errors.mobile[0]);
      // check if response message is like 'already taken' && push the mobile to already taken list
      const thisMobile = this.patientForm.controls.mobile.value;
      if (
        errors.mobile.includes(this.alreadyUsedBackendMessage.mobile) &&
        !this.alreadyUsedMobileNumbers.includes(thisMobile)
      ) {
        this.alreadyUsedMobileNumbers.push(this.patientForm.controls.mobile.value);
      }
    } else {
      this.unsetBackendValidationError('mobile');
    }
    if (errors.email) {
      this.setBackendValidationError('email', errors.email[0]);
      // check if response message is like 'already taken' && push the email to already taken list
      const thisEmail = this.patientForm.controls.email.value;
      if (
        errors.email.includes(this.alreadyUsedBackendMessage.email) &&
        !this.alreadyUsedEmails.includes(thisEmail)
      ) {
        this.alreadyUsedEmails.push(this.patientForm.controls.email.value);
      }
    } else {
      this.unsetBackendValidationError('email');
    }
    if (errors.secondary_mobile_no) {
      this.setBackendValidationError('secondary_mobile_no', errors.secondary_mobile_no[0]);
    } else {
      this.unsetBackendValidationError('secondary_mobile_no');
    }
    if (errors.relation_name) {
      this.setBackendValidationError('relation_name', errors.relation_name[0]);
    } else {
      this.unsetBackendValidationError('relation_name');
    }
    if (errors.relation_id) {
      this.setBackendValidationError('relation_id', errors.relation_id[0]);
    } else {
      this.unsetBackendValidationError('relation_id');
    }
    if (errors.relative_mobile_no) {
      this.setBackendValidationError('relative_mobile_no', errors.relative_mobile_no[0]);
    } else {
      this.unsetBackendValidationError('relative_mobile_no');
    }
    if (errors.status) {
      this.setBackendValidationError('status', errors.status[0]);
    } else {
      this.unsetBackendValidationError('status');
    }
    if (errors.is_online) {
      this.setBackendValidationError('is_online', errors.is_online[0]);
    } else {
      this.unsetBackendValidationError('is_online');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }

  togglePatientMenu(e) {
    document.getElementById('wrapper-div').classList.toggle('control-sidebar-open');
    e.target.classList.toggle('open-btn');
    e.target.classList.toggle('close-btn');
  }

  changeRegistrationType() {
    const is_online = this.patientForm.controls.is_online.value === 'true';

    if (is_online) {
      this.patientForm.controls.email.setValidators([Validators.required]);
    } else {
      this.patientForm.controls.email.clearValidators();
      this.patientForm.controls.email.setValidators([Validators.email]);
    }
  }

  isInTakenList(type) {
    let isTaken = false;
    switch (type) {
      case 'email':
        if (this.alreadyUsedEmails.includes(this.patientForm.controls.email.value)) {
          isTaken = true;
        }
        break;
      case 'mobile':
        if (this.alreadyUsedMobileNumbers.includes(this.patientForm.controls.mobile.value)) {
          isTaken = true;
        }
        break;
      default:
        isTaken = true;
    }
    return isTaken;
  }
}
