import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import CountDownTimer from '../../../shared/utils/timer-utils';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { STARTCALL } from '../../../shared/types/custom-types';
import { CallLogService } from '../../../services/call-log.service';
import { CallService } from '../../../video-chat/service/call.service';
import { NotifyService } from '../../../services/notify.service';
import { MyAppointmentService } from '../../../services/my-appointment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.css']
})
export class AppointmentCardComponent implements OnInit {
  @Input() appointment;
  @Input() type;
  @Output() cancelConsultation = new EventEmitter();
  completed = 'completed';
  cancelled = 'cancelled';
  appointmentRemainingDuration;
  pingPatientSubscription;
  activeIds;
  appointmentType: any = '';
  loading = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private callLogService: CallLogService,
    private callService: CallService,
    private myAppointmentService: MyAppointmentService,
    private serializer: UrlSerializer,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.appointmentType = this.activatedRoute.snapshot.queryParamMap.get('appointment_type');
    this.pingPatient();
    this.callService.callRejectEmmitter.subscribe((res) => {
      this.pingPatient();
    });
    const countDownTimer = new CountDownTimer(this.getRemainingDuration().asMilliseconds());
    countDownTimer.setListener((remainingTime) => {
      this.setRemainingDuration(remainingTime);
    });
    countDownTimer.startTimer();
    console.log('Appointment -->', this.type);
  }

  getAddress(address) {
    return address
      ? '<span>' +
          address.province +
          ', ' +
          address.city +
          '</span><br><span class="margin-l-20">' +
          address.address +
          ', ' +
          address.nearest_landmark +
          '</span>'
      : '--';
  }
  eventStartTime() {
    return moment(
      this.appointment.date +
        ' ' +
        (this.appointment.consultation_time ? this.appointment.consultation_time.start_time : '')
    );
  }

  eventEndTime() {
    return moment(
      this.appointment.date +
        ' ' +
        (this.appointment.consultation_time ? this.appointment.consultation_time.end_time : '')
    );
  }
  isEventStarted() {
    return this.eventStartTime().isBefore(moment());
  }
  isEventEnded() {
    return this.eventEndTime().isBefore(moment());
  }

  getRemainingDuration() {
    const date = moment(moment().utcOffset('+05:45').format('YYYY-MM-DD HH:mm:ss'));
    return moment.duration(this.eventStartTime().diff(date));
  }

  setRemainingDuration(durationInMills?: number) {
    this.appointmentRemainingDuration = '';
    const duration =
      durationInMills === undefined
        ? this.getRemainingDuration()
        : moment.duration(durationInMills);
    this.appointmentRemainingDuration +=
      Math.floor(duration.asDays()) === 0 ? '' : Math.floor(duration.asDays()) + ' Days ';
    this.appointmentRemainingDuration += duration.hours() === 0 ? '' : duration.hours() + ' Hours ';
    this.appointmentRemainingDuration +=
      duration.minutes() === 0 ? '' : duration.minutes() + ' Minutes ';
    this.appointmentRemainingDuration +=
      duration.seconds() === 0 ? '00 Seconds' : duration.seconds() + ' Seconds ';
  }

  endAppointment(id) {
    if (confirm('Are you sure you want to end Appointment?')) {
      this.myAppointmentService.updateStatus(id, this.completed).subscribe(
        (res: any) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          this.cancelConsultation.emit(true);
          this.router.navigate(['my-appointment']);
        }
      );
    }
  }
  cancelAppointment(id) {
    if (confirm('Are you sure you want to cancel Consultation?')) {
      this.myAppointmentService.updateStatus(id, this.cancelled).subscribe(
        (res: any) => {
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.error.message);
        },
        () => {
          this.cancelConsultation.emit(true);
          this.router.navigate(['my-appointment']);
        }
      );
    }
  }
  goToMessage() {
    const queryParams = {
      opponent_id: this.appointment.booked_by
    };
    this.router.navigate(['messages'], {
      queryParams
    });
  }
  startCallTwilio() {
    const url = this.router.createUrlTree(['/call'], {
      queryParams: {
        appointment_id: this.appointment.id,
        patient_id: this.appointment.booked_by_user.id
      }
    });
    window.open(this.serializer.serialize(url));
  }

  startCall() {
    const patientDetails = [];
    if (this.checkActive(this.appointment.booked_by_user.id)) {
      patientDetails.push({
        id: this.appointment.booked_by_connectycube_user_id,
        name: this.appointment.booked_by_user.name,
        patient_id: this.appointment.booked_by_user.id
      });
      const patientDetailList = JSON.stringify(patientDetails);

      this.router.navigate(['/video', STARTCALL], { queryParams: { patientDetailList } });
    } else {
      this.toastr.error('User is not Active');
    }
  }

  pingPatient() {
    this.pingPatientSubscription = this.callLogService.getPingPatientList().subscribe(
      (res: any) => {
        this.activeIds = res.data.map((x) => {
          return x.id;
        });
      },
      (error) => {}
    );
  }

  checkActive(id) {
    // console.log('ids', id, this.activeIds, this.activeIds.indexOf(id));
    // if (this.activeIds.indexOf(id !== -1)) {
    if (this.activeIds.includes(id)) {
      return true;
    }
    return false;
  }

  onImageLoad() {
    this.loading = false;
  }
}
