import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConvertByte {
  public convert(x) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  public extractExtension(file) {
    const fileExtension = file.name.slice(file.length - 4).toLowerCase();
    return (
      fileExtension.includes('pdf') ||
      fileExtension.includes('jpeg') ||
      fileExtension.includes('jpg') ||
      fileExtension.includes('png')
    );
  }
}
