import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../../../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  value: boolean;

  constructor(
    public auth: AuthService,
    public router: Router,
    private storageService: StorageService
  ) {}
  canActivate() {
    return this.auth.isAuthenticated().pipe(
      map((res: any) => {
        if (!res) {
          let token = this.storageService.getAPIToken();
          if (token) {
            this.auth.VoIPLogout();
            return this.router.parseUrl('auth/time-out');
          }
          window.location.href = 'auth/login';
        }
        return res;
      })
    );
  }
}
