import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-inquiry-third-step',
  templateUrl: './inquiry-third-step.component.html',
  styleUrls: ['./inquiry-third-step.component.css']
})
export class InquiryThirdStepComponent implements OnInit {
  @Input() data;
  formSubmitted;
  @Output() cancelEmmitter = new EventEmitter();
  @Output() nextEmmitter = new EventEmitter();
  @Output() previousEmmitter = new EventEmitter();
  inquiryThirdForm = new FormGroup({
    requireUpdate: new FormControl('', [Validators.required]),
    diagnosisConditionStatus: new FormControl('', [Validators.required]),
    urgentRating: new FormControl('', [Validators.required]),
    individualDiagnosis: new FormControl('', [Validators.required])
  });
  constructor() {}

  ngOnInit() {
    if (this.data) {
      this.setData();
    }
  }

  setData() {
    this.inquiryThirdForm.patchValue({
      requireUpdate: this.data.requireUpdate,
      diagnosisConditionStatus: this.data.diagnosisConditionStatus,
      urgentRating: this.data.urgentRating,
      individualDiagnosis: this.data.individualDiagnosis
    });
  }
  validateForm() {
    this.formSubmitted = true;

    if (this.inquiryThirdForm.valid) {
      const formData = {
        currentStep: 3,
        nextStep: 4,
        data: this.inquiryThirdForm.value
      };
      this.nextEmmitter.emit(formData);
    }
  }

  goPrevious() {
    const formData = {
      currentStep: 3,
      previousStep: 2,
      data: this.inquiryThirdForm.value
    };
    this.previousEmmitter.emit(formData);
  }
  cancel() {
    this.cancelEmmitter.emit(true);
  }
}
