import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { Profile } from '../../shared/model/profile';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css']
})
export class ProfileListComponent implements OnInit {
  profiles: Profile[] = [];

  // Pagination Variables
  loading = true;
  total: number;
  page = 1;
  limit = 10;

  profileSearchQuery: string;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const pageNo: any = this.activatedRoute.snapshot.queryParamMap.get('pageNo');
    const limit: any = this.activatedRoute.snapshot.queryParamMap.get('limit');
    const profileSearchQuery = this.activatedRoute.snapshot.queryParamMap.get('q');

    this.limit = limit ? limit : this.limit;
    this.page = pageNo ? pageNo : this.page;
    this.profileSearchQuery = profileSearchQuery ? profileSearchQuery : null;

    this.getProfiles(this.limit);
  }

  getProfiles(limit): void {
    const page = this.page;
    this.loading = true;
    this.profileService.getProfileList(page, limit, this.profileSearchQuery).subscribe(
      (response: any) => {
        this.profiles = response.data;
        this.total = response.size;
      },
      (error) => {
        this.loading = false;
        this.profiles.length = 0;
      },
      () => {
        this.loading = false;
      }
    );
  }

  proceedToSearch(event) {
    this.page = 1;
    event.preventDefault();
    this.searchProfiles();
  }

  searchProfiles() {
    this.getProfiles(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.profileSearchQuery);
  }

  clearSearch() {
    this.profileSearchQuery = null;
    this.page = 1;
    this.limit = 10;
    this.searchProfiles();
  }

  goToPage(n: number): void {
    this.page = n;
    this.getProfiles(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.profileSearchQuery);
  }

  onNext(): void {
    this.page++;
    this.getProfiles(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.profileSearchQuery);
  }

  onPrev(): void {
    this.page--;
    this.getProfiles(this.limit);
    this.addParamsToUrl(this.page, this.limit, this.profileSearchQuery);
  }

  addParamsToUrl(pageNo, limit, q) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageNo,
        limit,
        q
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }
}
