import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '../../../../services/notify.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PhysiotherapyService } from '../../../../services/physiotherapy.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-physiotherapy-form',
  templateUrl: './physiotherapy-form.component.html',
  styleUrls: ['./physiotherapy-form.component.css']
})
export class PhysiotherapyFormComponent implements OnInit {
  physiotherapy: any;
  submitInProgress = false;
  formSubmitted;
  submitted = false;
  physiotherapyForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    price: new FormControl('', [Validators.required]),
    active: new FormControl(1, [Validators.required])
  });
  file = '';
  oldFileName = '';
  VALIDATION_MESSAGES = [
    {
      name: 'name',
      error_type: 'required',
      message: 'Name is required'
    },
    {
      name: 'price',
      error_type: 'required',
      message: 'Price is required'
    }
  ];

  backendValidationErrors: Array<any> = [
    {
      controlName: 'name',
      error: false
    },
    {
      controlName: 'price',
      error: false
    },
    {
      controlName: 'description',
      error: false
    },
    {
      controlName: 'image',
      error: false
    }
  ];
  constructor(
    private toastr: ToastrService,
    private physiotherapyService: PhysiotherapyService,
    private router: Router,
    private dialogRef: MatDialogRef<PhysiotherapyFormComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.physiotherapy = data;
  }

  ngOnInit() {
    this.patchData();
    console.log(this.physiotherapy);
  }

  patchData() {
    this.physiotherapyForm.patchValue({
      name: this.physiotherapy.name,
      price: this.physiotherapy.price,
      description: this.physiotherapy.description,
      active: this.physiotherapy.active
    });
    this.oldFileName = this.physiotherapy.image ? this.physiotherapy.image : '';

    console.log(this.physiotherapyForm.value);
  }
  submit() {
    this.submitInProgress = true;
    this.formSubmitted = true;
    console.log(this.physiotherapyForm.status);
    if (this.physiotherapyForm.status === 'VALID' && (this.file || this.oldFileName)) {
      console.log(this.physiotherapyForm.value);
      const requestData = this.createRequestData({
        name: this.physiotherapyForm.controls.name.value,
        price: this.physiotherapyForm.controls.price.value,
        description: this.physiotherapyForm.controls.description.value
          ? this.physiotherapyForm.controls.description.value
          : ' ',
        active: this.physiotherapyForm.controls.active.value,
        image: this.file
      });
      const id = this.physiotherapy ? this.physiotherapy.id : null;
      this.physiotherapyService.savePhysiotherapyType(requestData, id).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
        },
        (error) => {
          this.submitInProgress = false;
          if (error.status === 422) {
            console.log(error);
            const errors = error.error.errors;
            this.setBackendValidationErrors(errors);
            this.toastr.error(errors);
          }
        },
        () => {
          this.submitInProgress = false;
          this.formSubmitted = false;
          this.dialogRef.close(true);
        }
      );
    } else {
      this.submitInProgress = false;
    }
  }

  createRequestData(data: object) {
    const formData: FormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
      if (key === 'image' && !value) {
        formData.delete('image');
      }
    });
    return formData;
  }

  close() {
    this.dialogRef.close();
  }
  uploadFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.file = files[0];
      this.oldFileName = '';
    } else {
      this.file = '';
    }
  }

  removeFile() {
    this.file = '';
  }

  removeOldFileName(event) {
    event.preventDefault();
    this.oldFileName = '';
    this.file = '';
  }
  setBackendValidationErrors(errors) {
    if (errors.name) {
      this.setBackendValidationError('name', errors.name[0]);
    } else {
      this.unsetBackendValidationError('name');
    }
    if (errors.price) {
      this.setBackendValidationError('price', errors.price[0]);
    } else {
      this.unsetBackendValidationError('price');
    }
    if (errors.description) {
      this.setBackendValidationError('description', errors.description[0]);
    } else {
      this.unsetBackendValidationError('description');
    }
    if (errors.image) {
      this.setBackendValidationError('image', errors.image[0]);
    } else {
      this.unsetBackendValidationError('image');
    }
  }

  setBackendValidationError(keyName, message) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = true;
    keyObject.message = message;
  }

  unsetBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    keyObject.error = false;
  }

  hasBackendValidationError(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    return keyObject.error;
  }

  getBackendValidationErrorMessage(keyName) {
    const keyObject = this.backendValidationErrors.find((key) => {
      return key.controlName === keyName;
    });
    if (keyObject.error === true) {
      return keyObject.message;
    }
    return false;
  }

  getValidationMessage(fieldName, errorType) {
    return this.VALIDATION_MESSAGES.find((messageObject) => {
      return messageObject.name === fieldName && messageObject.error_type === errorType;
    }).message;
  }
}
