import { Directive, HostListener, ElementRef, forwardRef } from '@angular/core';
import {
  Validators,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS,
  Validator,
  NgForm
} from '@angular/forms';

@Directive({
  selector: '[appEmailOrMobile]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailOrMobileDirective),
      multi: true
    }
  ]
})
export class EmailOrMobileDirective {
  validate(c: AbstractControl): ValidationErrors | null {
    if (!(c.value.length > 0)) {
      return null;
    }

    const isMobile = !isNaN(c.value);

    const emailPattern = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/i;

    if (isMobile) {
      return c.value.length === 10 ? null : { mobile: true };
    }

    return c.value.match(emailPattern) ? null : { email: true };
  }
}
