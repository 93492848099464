import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientCreateComponent } from './patient-create/patient-create.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { VitalListComponent } from './vitals/vital-list/vital-list.component';
import { MedicationListComponent } from './medications/medication-list/medication-list.component';
import { LabReportListComponent } from './lab-reports/lab-report-list/lab-report-list.component';
import { EncounterListComponent } from './encounters/encounter-list/encounter-list.component';
import { DiagnosisListComponent } from './diagnoses/diagnosis-list/diagnosis-list.component';
import { LabBookingCreateComponent } from '../feature/lab-bookings/lab-booking-create/lab-booking-create.component';
import { ServiceRequestListComponent } from './service-requests/service-request-list/service-request-list.component';
import { PastMedicalHistoryListComponent } from './past-medical-histories/past-medical-history-list/past-medical-history-list.component';
import { RadiologyListComponent } from './radiology/radiology-list/radiology-list.component';
import { SurgicalHistoryListComponent } from './surgical-histories/surgical-history-list/surgical-history-list.component';
import { ImmunizationListComponent } from './immunizations/immunization-list/immunization-list.component';
import { AllergyListComponent } from './allergies/allergy-list/allergy-list.component';
import { VitalGraphComponent } from './vitals/vital-graph/vital-graph.component';
import { PrescriptionRefillRequestsComponent } from './prescription-refill-requests/prescription-refill-requests.component';

const routes: Routes = [
  {
    path: '',
    component: PatientListComponent
  },
  {
    path: 'create',
    component: PatientCreateComponent
  },
  {
    path: ':id',
    component: PatientDetailComponent
  },
  {
    path: ':id/vitals',
    component: VitalListComponent
  },
  {
    path: ':id/medications',
    component: MedicationListComponent
  },
  {
    path: ':id/lab-reports',
    component: LabReportListComponent
  },
  {
    path: ':id/encounters',
    component: EncounterListComponent
  },
  {
    path: ':id/diagnoses',
    component: DiagnosisListComponent
  },
  {
    path: ':id/lab-bookings/create',
    component: LabBookingCreateComponent
  },
  {
    path: ':id/service-requests',
    component: ServiceRequestListComponent
  },
  {
    path: ':id/past-medical-histories',
    component: PastMedicalHistoryListComponent
  },
  {
    path: ':id/radiology',
    component: RadiologyListComponent
  },
  {
    path: ':id/surgical-histories',
    component: SurgicalHistoryListComponent
  },
  {
    path: ':id/immunizations',
    component: ImmunizationListComponent
  },
  {
    path: ':id/allergies',
    component: AllergyListComponent
  },
  {
    path: ':id/vitals/graph',
    component: VitalGraphComponent
  },
  {
    path: ':id/prescription-refill-requests',
    component: PrescriptionRefillRequestsComponent
  },
  {
    path: '',
    loadChildren: './workflow-forms/workflow-forms.module#WorkflowFormsModule'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientsRoutingModule {}
